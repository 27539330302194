import React, {type FC, type PropsWithChildren} from 'react';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import {Route, Routes, useParams} from 'react-router-dom';

import EmptyUnitPage from 'components/CoursebookContentsViewer/EmptyUnitPage';
import {
  loadCoursebookData,
  type LoadCoursebookDataResponseAction
} from 'components/CoursebookLibrary/actions/action';
import {setCoursebookUnits} from 'components/CoursebookLibrary/UnitsList/action';
import UnitsListHeader from 'components/CoursebookLibrary/UnitsList/views/UnitsListHeader';
import UnitsList from 'components/CoursebookLibrary/UnitsList/UnitsList';
import {type AppState, CoursebookRequestError, type CoursebookUnit} from 'store/interface';
import injectReducer from 'store/injectReducer';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import Loader from 'components/Loader';
import {type ServerCoursebook} from 'components/CoursebookLibrary/interface';
import {type AxiosRequestError} from 'services/axios/interface';
import WampErrorMask from 'components/WampErrorMask';

import coursebookPageReducer from './state/coursebookPageReducer';
import {reset, setCoursebookError, setCoursebookInfo} from './state/action';
import HeaderContainer from './Header/HeaderContainer';
import {Header} from './Unit/UnitExerciseList/containers/Header';
import UnitSectionsHeader from './Unit/Sections/containers/Header';
import UnitRoute from './Unit';
import UpdatingUnitOverlay from './UpdatingUnitOverlay';
import CoursebookPageDragLayer from './DragLayer';
import {PageNotFound} from '../../PageNotFound/components/PageNotFound';
import {type CoursebookRouteParams} from './interface';
import CoursebookDataModal from '../Common/CoursebookDataModal';
import {AnimationContextProvider} from './AnimationElementsContext';

import './CoursebookPage.scss';

interface StateProps {
  coursebookDataLoaded?: boolean;
  requestError?: CoursebookRequestError;
  updatingUnit?: boolean;
  updatingCoursebook?: boolean;
}

interface DispatchProps {
  loadData: () => Promise<LoadCoursebookDataResponseAction>;
  reset: () => void;
  setUnits: (units: CoursebookUnit[]) => void;
  setCoursebookInfo: (coursebook: ServerCoursebook) => void;
  setCoursebookError: (error: CoursebookRequestError) => void;
}

interface Props extends PropsWithChildren<CoursebookRouteParams>, StateProps, DispatchProps {}

class CoursebookPage extends React.Component<Props> {
  private request?: CancellablePromise;

  public componentDidMount(): void {
    const {coursebookDataLoaded} = this.props;

    if (!coursebookDataLoaded) {
      this.loadUnitsAndCoursebookInfo();
    }
  }

  public componentWillUnmount(): void {
    if (this.request) {
      this.request.cancel();
    }
    this.props.reset();
  }

  public componentDidUpdate() {
    const {coursebookDataLoaded} = this.props;
    if (!coursebookDataLoaded && !this.request) {
      this.loadUnitsAndCoursebookInfo();
    }
  }

  public render() {
    const {coursebookDataLoaded, requestError, coursebookId, updatingUnit, updatingCoursebook} =
      this.props;
    if (requestError === CoursebookRequestError.COURSEBOOK_NOT_FOUND) {
      return <PageNotFound />;
    }
    if (requestError === CoursebookRequestError.OTHER_ERROR) {
      return (
        <div className="coursebook-page">
          <WampErrorMask reload={this.props.reset} />
        </div>
      );
    }
    if (!coursebookDataLoaded) {
      return <Loader />;
    }
    const unitList = (
      <UnitsList
        editCoursebookSuccessCallback={this.loadUnitsAndCoursebookInfo}
        shouldScrollToId={true}
      />
    );

    return (
      <AnimationContextProvider>
        <div className="coursebook-page">
          <CoursebookDataModal editCoursebookSuccessCallback={this.loadUnitsAndCoursebookInfo} />
          <CoursebookPageDragLayer />
          <HeaderContainer>
            <UnitsListHeader
              coursebookId={coursebookId}
              editCoursebookSuccessCallback={this.loadUnitsAndCoursebookInfo}
            />
            <Routes>
              <Route path="unit/:unitId">
                <Route
                  index={true}
                  element={<Header reloadUnit={this.loadUnitsAndCoursebookInfo} />}
                />
                <Route
                  path="section/:coursebookSectionId?"
                  element={
                    <UnitSectionsHeader reloadCoursebookInfo={this.loadUnitsAndCoursebookInfo} />
                  }
                />
              </Route>
            </Routes>
          </HeaderContainer>
          <div className="coursebook-page-content">
            <Routes>
              <Route index={true} element={unitList} />
              <Route path="unit/:unitId/*">
                <Route index={true} element={unitList} />
                <Route path="section" element={unitList} />
                <Route path="section/:coursebookSectionId" element={unitList} />
              </Route>
            </Routes>

            <Routes>
              <Route index={true} element={<EmptyUnitPage />} />
              <Route path="unit/:unitId/*" element={<UnitRoute />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
            {updatingUnit ? <UpdatingUnitOverlay /> : null}
          </div>
          {updatingCoursebook ? (
            <div className="coursebook-updating-mask">
              <Loader />
            </div>
          ) : null}
        </div>
      </AnimationContextProvider>
    );
  }

  private loadUnitsAndCoursebookInfo = () => {
    this.request = makeCancellable(
      this.props.loadData(),
      this.setLoadedInfo,
      this.handleRequestError,
      this.deleteRequest
    );
  };

  private setLoadedInfo = (action: LoadCoursebookDataResponseAction) => {
    this.deleteRequest();
    this.props.setUnits(action.payload.data.units);
    this.props.setCoursebookInfo(action.payload.data);
  };

  private handleRequestError = (action: AxiosRequestError) => {
    this.deleteRequest();
    const isNotFoundError = action.error.response && action.error.response.data.statusCode === 400;
    this.props.setCoursebookError(
      isNotFoundError
        ? CoursebookRequestError.COURSEBOOK_NOT_FOUND
        : CoursebookRequestError.OTHER_ERROR
    );
  };

  private deleteRequest = () => delete this.request;
}

const mapStateToProps = ({coursebookPage}: AppState): StateProps => ({
  coursebookDataLoaded: !!coursebookPage!.units && !!coursebookPage!.coursebookInfo,
  requestError: coursebookPage!.coursebookRequestError,
  updatingUnit: coursebookPage!.updatingUnit,
  updatingCoursebook: coursebookPage!.isUpdating
});

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  ownProps: CoursebookRouteParams
): DispatchProps => {
  const {coursebookId} = ownProps;
  return {
    loadData: () => dispatch(loadCoursebookData(coursebookId, true)),
    reset: () => dispatch(reset()),
    setUnits: (units: CoursebookUnit[]) => dispatch(setCoursebookUnits(units)),
    setCoursebookInfo: (coursebook: ServerCoursebook) => dispatch(setCoursebookInfo(coursebook)),
    setCoursebookError: (error: CoursebookRequestError) => dispatch(setCoursebookError(error))
  };
};

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(CoursebookPage);

const CoursebookRoute: FC = () => {
  const {coursebookId} = useParams<CoursebookRouteParams>();

  // 'key' is here to avoid issues with stale (source) coursebook data after clone coursebook
  // TODO: should be fixed somehow and remove key
  return <ConnectedPage key={coursebookId} coursebookId={coursebookId!} />;
};

export default injectReducer({coursebookPage: coursebookPageReducer})(CoursebookRoute);
