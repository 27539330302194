import {type Editor} from '@englex/slate';

import {type XWidgetAction} from '../../actions/interface';

const prefix = 'XWIDGET/';

export const MATCHING_EXTRA_CONTENT_CHANGE = prefix + 'MATCHING_EXTRA_CONTENT_CHANGE';

export interface XMatchingFreeChoiceActions extends XWidgetAction {
  change: Editor;
}

export const changeExtraContent = (
  xwidgetId: string,
  change: Editor
): XMatchingFreeChoiceActions => ({
  type: MATCHING_EXTRA_CONTENT_CHANGE,
  xwidgetId,
  change
});
