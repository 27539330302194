import React from 'react';
import {type ConnectDragSource} from 'react-dnd';

import DragHandle from '../../../components/DragHandle/DragHandle';

interface Props {
  connectDragSource?: ConnectDragSource;
}

export default class LibraryPageListElHandle extends React.Component<Props> {
  public render() {
    return this.props.connectDragSource
      ? this.props.connectDragSource(this.renderView())
      : this.renderView();
  }

  private renderView = () => {
    return (
      <div className="drag-handle">
        <DragHandle />
      </div>
    );
  };
}
