import React, {type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';
import ModalFooter from 'react-bootstrap/lib/ModalFooter';
import {FormattedMessage} from 'react-intl';

import ImageCropUploader from 'common/ImageUpload/ImageCropUploader';

import {type UploaderModalFooterProps} from './interface';
import PictureUploadingStatus from '../../../../common/ImageUpload/PictureUploadingStatus';

const UploaderFooter: FC<UploaderModalFooterProps & {minHeight?: number}> = ({
  crop,
  errorMessage,
  freeze,
  handleError,
  handleResponse,
  hideModal,
  isErrorStatus,
  minHeight,
  minWidth,
  retry,
  setStatus,
  startUploading,
  status,
  turnOffRetry,
  uploadingStarted,
  validatedFile
}) => (
  <ModalFooter className="dnd-image-modal-footer">
    <div className="image-upload-controls">
      <Button bsSize="small" onClick={hideModal}>
        <FormattedMessage id="Slate.Modal.Image.CancelButton" />
      </Button>
      {isErrorStatus && !validatedFile && (
        <PictureUploadingStatus
          isError={true}
          status={status!}
          minResolution={[minWidth, minHeight || minWidth]}
        />
      )}
      {uploadingStarted && crop && validatedFile && (
        <ImageCropUploader
          crop={crop}
          errorMessage={errorMessage}
          file={validatedFile.file}
          handleError={handleError}
          handleResponse={handleResponse}
          isErrorStatus={isErrorStatus}
          setStatus={setStatus}
          shouldRetry={retry}
          status={status}
          turnOffRetry={turnOffRetry}
        />
      )}
      <Button
        bsSize="small"
        bsStyle="primary"
        onClick={startUploading}
        disabled={freeze || !validatedFile}
      >
        <FormattedMessage id="Common.Upload" />
      </Button>
    </div>
  </ModalFooter>
);

export default UploaderFooter;
