import React, {type FC, useCallback, useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import Checkbox from 'react-bootstrap/lib/Checkbox';

import {FilterContext, FilterDispatchContext} from '../context/filter';
import {setShowProcessed} from '../context/actions';

export const ShowProcessedCheckbox: FC = () => {
  const {showProcessed} = useContext(FilterContext);
  const {dispatch} = useContext(FilterDispatchContext);

  const onChange = useCallback(() => {
    dispatch(setShowProcessed(!showProcessed));
  }, [dispatch, showProcessed]);

  return (
    <Checkbox checked={showProcessed} onChange={onChange}>
      <FormattedMessage id="Feedback.ShowProcessed" />
    </Checkbox>
  );
};
