import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {Map} from 'immutable';

import {type XDraftExercisesMap} from './widgets/interface';
import {EXERCISE_WAS_CHANGED, DELETE_DRAFT_EXERCISE} from './actionTypes/xdraftexercises';
import {type ChangeDraftExercisesAction, type DeleteDraftExerciseAction} from './actions/interface';

const REDUCERS: ReducersMapObject = {
  [EXERCISE_WAS_CHANGED]: (
    state: XDraftExercisesMap,
    action: ChangeDraftExercisesAction
  ): XDraftExercisesMap => {
    const key = `${action.payload.exercise.id}`; // convert id = null to string
    return state.set(key, action.payload.exercise);
  },
  [DELETE_DRAFT_EXERCISE]: (
    state: XDraftExercisesMap,
    action: DeleteDraftExerciseAction
  ): XDraftExercisesMap => {
    return state.delete(action.payload.id);
  }
};

const initialState: XDraftExercisesMap = Map();

function xDraftExercisesReducer(
  state: XDraftExercisesMap = initialState,
  action: Action
): XDraftExercisesMap {
  const reducer: Reducer<XDraftExercisesMap> = REDUCERS[action.type];

  return reducer ? reducer(state, action) : state;
}

export default xDraftExercisesReducer;
