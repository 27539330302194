import {type Action} from 'redux';
import {type Editor, type ValueJSON} from '@englex/slate';

import {type AxiosRequestAction} from 'services/axios/interface';
import {type CoursebookSection} from 'store/interface';

import {
  CHANGE_UNIT_SECTION_CONTENTS,
  CLOSE_COURSEBOOK_SECTION_MODAL,
  CONTENTS_EDIT_SAVED,
  CREATE_COURSEBOOK_SECTION_REQUEST,
  CREATE_UNIT_SECTION_CONTENTS_REQUEST,
  DELETE_COURSEBOOK_SECTION_REQUEST,
  DELETE_UNIT_SECTION_CONTENTS_REQUEST,
  EDIT_UNIT_SECTION_CONTENTS_REQUEST,
  MOVE_COURSEBOOK_SECTION_REQUEST,
  OPEN_COURSEBOOK_SECTION_MODAL,
  REQUEST_SECTIONS,
  REQUEST_UNIT_SECTION_CONTENTS,
  RESET_SECTIONS_TAB,
  REVERT_CONTENTS_EDIT,
  SET_COURSEBOOK_SECTIONS,
  SET_UNIT_SECTION_INITIAL_CONTENTS,
  SET_UPDATING_CONTENTS,
  UPDATE_COURSEBOOK_SECTION_REQUEST
} from './actionTypes';

export const resetContentsTab = (): Action => ({
  type: RESET_SECTIONS_TAB
});

export interface SetCoursebookSectionsAction extends Action {
  sections: CoursebookSection[];
}

export const setCoursebookSections = (
  sections: CoursebookSection[]
): SetCoursebookSectionsAction => ({
  type: SET_COURSEBOOK_SECTIONS,
  sections
});

export const requestUnitSectionContents = (
  coursebookId: string,
  unitId: number,
  coursebookSectionId: number
): AxiosRequestAction => ({
  type: REQUEST_UNIT_SECTION_CONTENTS,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/coursebook/${coursebookId}/unit/${unitId}/section/${coursebookSectionId}`,
      validateStatus: (status: number) => status === 404 || (status >= 200 && status < 300)
    }
  }
});

export interface SetUnitSectionContentsAction extends Action {
  value: ValueJSON;
}

export const setUnitSectionInitialContents = (value: ValueJSON): SetUnitSectionContentsAction => ({
  type: SET_UNIT_SECTION_INITIAL_CONTENTS,
  value
});

export interface ChangeUnitSectionContentsAction extends Action {
  change: Editor;
}

export const changeUnitSectionContents = (change: Editor): ChangeUnitSectionContentsAction => ({
  type: CHANGE_UNIT_SECTION_CONTENTS,
  change
});

export const revertContentsEdit = (): Action => ({
  type: REVERT_CONTENTS_EDIT
});

export const contentsEditSaved = (): Action => ({
  type: CONTENTS_EDIT_SAVED
});

export interface SetUpdatingContentsAction extends Action {
  isUpdating: boolean;
}

export const setUpdatingContents = (isUpdating: boolean): SetUpdatingContentsAction => ({
  type: SET_UPDATING_CONTENTS,
  isUpdating
});

export interface DeleteUnitSectionContentsProps {
  coursebookId: string;
  unitId: number;
  coursebookSectionId: number;
}

export const deleteUnitSectionContentsRequest = ({
  coursebookId,
  coursebookSectionId,
  unitId
}: DeleteUnitSectionContentsProps): AxiosRequestAction => ({
  type: DELETE_UNIT_SECTION_CONTENTS_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'DELETE',
      url: `v2/coursebook/${coursebookId}/unit/${unitId}/section/${coursebookSectionId}`
    }
  }
});

export interface CreateUnitSectionContentsProps extends DeleteUnitSectionContentsProps {
  value: ValueJSON;
}

export const createUnitSectionContentsRequest = ({
  coursebookId,
  unitId,
  value,
  coursebookSectionId
}: CreateUnitSectionContentsProps): AxiosRequestAction => ({
  type: CREATE_UNIT_SECTION_CONTENTS_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      url: `v2/coursebook/${coursebookId}/unit/${unitId}/section`,
      data: {
        coursebookSectionId,
        description: value
      }
    }
  }
});

export const editUnitSectionContentsRequest = ({
  coursebookId,
  unitId,
  value,
  coursebookSectionId
}: CreateUnitSectionContentsProps): AxiosRequestAction => ({
  type: EDIT_UNIT_SECTION_CONTENTS_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      url: `v2/coursebook/${coursebookId}/unit/${unitId}/section/${coursebookSectionId}`,
      data: {
        description: value
      }
    }
  }
});

export interface OpenCoursebookSectionModalAction extends Action {
  editedCoursebookSectionId?: number;
}

export const openCoursebookSectionModal = (
  editedCoursebookSectionId?: number
): OpenCoursebookSectionModalAction => ({
  type: OPEN_COURSEBOOK_SECTION_MODAL,
  editedCoursebookSectionId
});

export const closeCoursebookSectionModal = (): Action => ({
  type: CLOSE_COURSEBOOK_SECTION_MODAL
});

export const requestSections = (): AxiosRequestAction => ({
  type: REQUEST_SECTIONS,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/section`
    }
  }
});

type UpdateCoursebookSectionRequestData =
  | {
      title: string;
    }
  | {sectionId: number};

export interface CreateCoursebookSectionParams {
  coursebookId: string;
  requestData: UpdateCoursebookSectionRequestData;
}

export const createCoursebookSectionRequest = (
  params: CreateCoursebookSectionParams
): AxiosRequestAction => ({
  type: CREATE_COURSEBOOK_SECTION_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      url: `/v2/coursebook/${params.coursebookId}/section`,
      data: params.requestData
    }
  }
});

export interface UpdateCoursebookSectionParams extends CreateCoursebookSectionParams {
  coursebookSectionId: number;
}

export const updateCoursebookSectionRequest = (
  params: UpdateCoursebookSectionParams
): AxiosRequestAction => ({
  type: UPDATE_COURSEBOOK_SECTION_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      url: `/v2/coursebook/${params.coursebookId}/section/${params.coursebookSectionId}`,
      data: params.requestData
    }
  }
});

export const deleteCoursebookSectionRequest = (
  coursebookId: string,
  coursebookSectionId: number
): AxiosRequestAction => ({
  type: DELETE_COURSEBOOK_SECTION_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'DELETE',
      url: `v2/coursebook/${coursebookId}/section/${coursebookSectionId}`
    }
  }
});

export const moveCoursebookSectionRequest = (
  coursebookId: string,
  coursebookSectionId: number,
  targetPos: number
): AxiosRequestAction => ({
  type: MOVE_COURSEBOOK_SECTION_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      url: `v2/coursebook/${coursebookId}/section`,
      data: {
        id: coursebookSectionId,
        ordinal: targetPos
      }
    }
  }
});
