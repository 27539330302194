import React, {type FC} from 'react';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';
import {newExercisePath} from 'common/paths';

import {type UnitRouteParams} from '../../../interface';
import SupplementaryPanelView from '../views/SupplementaryUnitExercisePanel';
import {redirectFromUnitPage} from '../../../state/action';

interface RouteProps extends UnitRouteParams {}

interface OwnProps {
  unitExerciseIndex: number;
  pageIndex: number;
  unitExerciseId: number;
  exerciseId: string;
  animationElements?: Array<string | number>;

  isInDragItem?: boolean;
  isDragItemInSupplementaryPosition?: boolean;
  toggleSupplementaryExercisesPanel: () => void;
  scrollToParent: () => void;
}

interface StateProps {
  supplementaryIds: number[];
  supplementaryExerciseIds: string[];
  showSupplementaryExercisesPanel: boolean;
}

interface DispatchProps {
  redirectFromUnitPage: (path: string) => void;
}

interface Props extends StateProps, OwnProps, DispatchProps, RouteProps {}

class Container extends React.Component<Props> {
  public componentDidMount() {
    const {
      animationElements,
      supplementaryExerciseIds,
      toggleSupplementaryExercisesPanel,
      scrollToParent
    } = this.props;

    if (animationElements?.length) {
      const hasElement = animationElements.some((element: string) =>
        supplementaryExerciseIds.includes(element)
      );

      if (hasElement) {
        toggleSupplementaryExercisesPanel();
        scrollToParent();
      }
    }
  }

  public render() {
    const {
      supplementaryIds,
      showSupplementaryExercisesPanel,
      pageIndex,
      unitExerciseIndex,
      isInDragItem,
      exerciseId
    } = this.props;
    return (
      <SupplementaryPanelView
        parentExerciseId={exerciseId}
        supplementaryIds={supplementaryIds}
        showSupplementaryExercisesPanel={showSupplementaryExercisesPanel}
        pageIndex={pageIndex}
        unitExerciseIndex={unitExerciseIndex}
        isInDragItem={isInDragItem}
        createSupplementaryExercise={this.createNewSupplementaryExercise}
      />
    );
  }

  private createNewSupplementaryExercise = () => {
    const {coursebookId, unitId, exerciseId} = this.props;
    this.props.redirectFromUnitPage(newExercisePath(coursebookId, Number(unitId), exerciseId));
  };
}

const mapStateToProps = (
  state: AppState,
  {unitExerciseIndex, pageIndex, unitExerciseId, isDragItemInSupplementaryPosition}: OwnProps
): StateProps => {
  const mainUnitExercise = state
    .coursebookPage!.unit!.current.pages.get(pageIndex)
    .unitExerciseList.get(unitExerciseIndex);

  // show empty exercise list because application logic prevents moving exercises that have supplementary
  // exercises to be supplementary exercises themselves
  const supplementaryProps: {
    ids: number[];
    exerciseIds: string[];
  } = isDragItemInSupplementaryPosition
    ? {ids: [], exerciseIds: []}
    : mainUnitExercise.supplementaryUnitExerciseList.reduce(
        (r: {ids: number[]; exerciseIds: string[]}, ex) => ({
          ids: [...r.ids, ex!.id],
          exerciseIds: [...r.exerciseIds, ex!.exerciseId]
        }),
        {ids: [], exerciseIds: []}
      );

  return {
    showSupplementaryExercisesPanel:
      state.coursebookPage!.suppPanelOpenForExercises.includes(unitExerciseId),
    supplementaryIds: supplementaryProps.ids,
    supplementaryExerciseIds: supplementaryProps.exerciseIds
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  redirectFromUnitPage: (path: string) => dispatch(redirectFromUnitPage(path))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(Container);

const SupplementaryExercisesPanel: FC<OwnProps> = props => {
  const {coursebookId, unitId} = useParams<UnitRouteParams>();
  return <Connected {...props} coursebookId={coursebookId!} unitId={unitId} />;
};

export default SupplementaryExercisesPanel;
