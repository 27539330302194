import React, {type FC, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import * as toastr from 'components/toastr';
import {type AppState} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import Confirm from 'components/modals/Confirm';
import {messages} from 'components/CoursebookLibrary/UnitsList/i18n';
import {deleteUnit, setUnitIdToDelete} from 'components/CoursebookLibrary/UnitsList/action';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';

import {type CoursebookRouteParams} from '../../interface';

interface Props {
  editCoursebookSuccessCallback: () => void;
}

export const DeleteUnitModal: FC<Props> = ({editCoursebookSuccessCallback}) => {
  const intl = useIntl();
  const dispatch = useAxiosDispatch();

  const {coursebookId} = useParams<CoursebookRouteParams>();
  const [awaitingResponse, setAwaitingResponse] = useState(false);

  const {unitIdToDelete, isRevision} = useSelector((state: AppState) => {
    const coursebookPage = state.coursebookPage!;
    const unitIdToDelete = state.coursebookPage!.unitIdToDelete;
    const {isRevision} = unitIdToDelete
      ? coursebookPage.units!.find(u => u.id === unitIdToDelete)!
      : {isRevision: undefined};
    return {unitIdToDelete, isRevision};
  });

  const dispatchDeleteUnit = useCallback(
    (coursebookId: string, unitId: number) => dispatch(deleteUnit(coursebookId, unitId)),
    [dispatch]
  );
  const dispatchUnsetUnitIdToDelete = useCallback(() => dispatch(setUnitIdToDelete()), [dispatch]);

  const submit = useCallback(() => {
    setAwaitingResponse(true);

    dispatchDeleteUnit(coursebookId!, unitIdToDelete!)
      .then((response: AxiosResponseAction<unknown>) => {
        if (response.payload.status === 204) {
          editCoursebookSuccessCallback();
          toastr.success(
            '',
            intl.formatMessage(
              isRevision ? messages.deleteModalTestSuccess : messages.deleteModalSuccess
            )
          );
        }
      })
      .catch(() =>
        toastr.error(
          '',
          intl.formatMessage(isRevision ? messages.deleteModalTestFail : messages.deleteModalFail)
        )
      )
      .finally(() => {
        dispatchUnsetUnitIdToDelete();
        setAwaitingResponse(false);
      });
  }, [
    coursebookId,
    dispatchDeleteUnit,
    dispatchUnsetUnitIdToDelete,
    editCoursebookSuccessCallback,
    intl,
    isRevision,
    unitIdToDelete
  ]);

  const show = !!unitIdToDelete;
  return (
    <Confirm
      show={show}
      headerText={intl.formatMessage(messages.deleteModalTitle)}
      onAccept={submit}
      onDecline={dispatchUnsetUnitIdToDelete}
      disableButtons={awaitingResponse}
    />
  );
};
