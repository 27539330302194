import React, {type FC} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import styles from './CollapsedButton.module.scss';

interface Props {
  isCollapsed: boolean;
  isRightSide?: boolean;
  onClick: (e?: React.MouseEvent) => void;
}

export const CollapsedButton: FC<Props> = ({isCollapsed, isRightSide = false, onClick}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(styles.collapsedButton, {[styles.collapsedButtonIsRight]: isRightSide})}
    >
      <div
        className={classNames({
          [styles.rightArrow]: !isRightSide,
          [styles.leftArrow]: isRightSide,
          [styles.rightArrowNotCollapsed]: !isRightSide && !isCollapsed,
          [styles.leftArrowNotCollapsed]: isRightSide && !isCollapsed
        })}
      >
        <Icon name="pc-thin-right-arrow" />
      </div>
    </div>
  );
};
