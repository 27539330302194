import React, {type FC, type SyntheticEvent, useCallback, useState} from 'react';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import {Link} from 'react-router-dom';
import {useIntl} from 'react-intl';

import {ReactComponent as LoadingSVG} from 'components/LoadableImage/img-normal.svg';
import {editorPath} from 'common/paths';

import {type ExerciseFeedback, type ExerciseFeedbackDetails} from '../interface';
import DetailsComponent from './Details';

import './FeedbackContent.scss';

interface Props {
  feedback: ExerciseFeedback;
  details: ExerciseFeedbackDetails;
}

const FeedbackContent: FC<Props> = ({feedback: {message, pixelRatio, screenshot}, details}) => {
  const intl = useIntl();
  const [width, setWidth] = useState<number | undefined>(undefined);

  const onLoad = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    setWidth(e.currentTarget.naturalWidth);
  }, []);

  return (
    <>
      <div className="feedback-content">
        <div className="image-canvas">
          <Scrollbars autoHide={true} className="image-canvas-scrollbar">
            <img
              alt="feedback screenshot"
              className={classNames('image', {loaded: width})}
              onLoad={onLoad}
              src={screenshot}
              style={width ? {width: width / pixelRatio} : {}}
            />
            {!width && <LoadingSVG />}
          </Scrollbars>
        </div>
        <DetailsComponent {...details} message={message} />
      </div>
      <div className="footer">
        <Link
          to={editorPath(details.coursebookId, details.unitId, details.exerciseId)}
          className="btn btn-sm btn-success"
          target="_blank"
        >
          {intl.formatMessage({id: 'Feedback.OpenExercise'})}
        </Link>
      </div>
    </>
  );
};

export default FeedbackContent;
