import React, {type FC, useCallback, useState} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import classNames from 'classnames';

import {type CoursebookUnit} from 'store/interface';
import Icon from 'components/Icon';
import {type IntroRecord} from 'store/intro/IntroRecord';

import Confirm from '../../../../../components/modals/Confirm';

import './UnitIntro.scss';

const messages = defineMessages({
  DeleteUnitIntroConfirm: {
    id: 'Coursebook.DeleteUnitIntroConfirm'
  },
  CreateUnitIntro: {
    id: 'Coursebook.CreateUnitIntro'
  }
});

interface Props {
  animated: boolean;
  intro?: IntroRecord;
  unit: CoursebookUnit;
  onClick: () => void;
  onDelete: () => void;
  onAnimationEnd: () => void;
}

export const UnitIntro: FC<Props> = React.memo(
  ({intro, unit: {isRevision}, animated, onClick, onDelete, onAnimationEnd}) => {
    const {formatMessage} = useIntl();

    const [isOpen, setIsOpen] = useState(false);

    const onOpen = useCallback(() => setIsOpen(true), []);
    const onClose = useCallback(() => setIsOpen(false), []);

    const onDeleteHandler = useCallback(() => {
      onClose();
      onDelete();
    }, [onClose, onDelete]);

    const content = intro ? intro.excerpt : formatMessage(messages.CreateUnitIntro);

    return (
      <>
        <div className={classNames('unit-intro', {empty: !intro})}>
          <div className="unit-intro__left">
            {isRevision ? <Icon name="virc-test" /> : <Icon name="virc-unit" />}
          </div>
          <div
            className={classNames('unit-intro__main', {animated: animated})}
            onClick={onClick}
            onAnimationEnd={onAnimationEnd}
          >
            <div className="content">{content}</div>
          </div>
          <div className="unit-intro__right">
            <div className="delete" onClick={onOpen}>
              <Icon name="trash" />
            </div>
          </div>
        </div>

        <Confirm
          show={isOpen}
          onAccept={onDeleteHandler}
          onDecline={onClose}
          headerText={formatMessage(messages.DeleteUnitIntroConfirm)}
          disableButtons={false}
        />
      </>
    );
  }
);
