import React, {type FC} from 'react';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {type Value, type ValueJSON, type Editor} from '@englex/slate';

import {type AppState} from 'store/interface';
import Loader from 'components/Loader';
import {valueJSONFromText} from 'components/Slate/utils';
import WampErrorMask from 'components/WampErrorMask';
import EmptyUnitPage from 'components/CoursebookContentsViewer/EmptyUnitPage';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';

import {type SectionRouteParams} from '../../../interface';
import {
  changeUnitSectionContents,
  requestUnitSectionContents,
  setUnitSectionInitialContents
} from '../actions/action';
import ContentsEditor from '../views/ContentsEditor';

interface OwnProps extends SectionRouteParams {}

interface State {
  loading: boolean;
  error: boolean;
}

interface StateProps {
  contentsValue?: Value;
}

interface DispatchProps {
  requestUnitSectionContents: () => Promise<Action>;
  setUnitSectionInitialContents: (contents: ValueJSON) => void;
  changeUnitSectionContents: (change: Editor) => void;
}

interface Props extends OwnProps, DispatchProps, StateProps {}

class UnitSectionContents extends React.Component<Props, State> {
  public state: State = {loading: false, error: false};
  private request: CancellablePromise;

  public componentWillUnmount(): void {
    if (this.request) {
      this.request.cancel();
    }
  }

  public componentDidMount(): void {
    const {unitId, coursebookSectionId} = this.props;
    if (unitId !== undefined && coursebookSectionId !== undefined) {
      this.requestUnitSectionContents();
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {unitId, coursebookSectionId} = this.props;
    const {unitId: prevUnitId, coursebookSectionId: prevCoursebookSectionId} = prevProps;

    if (
      unitId !== undefined &&
      coursebookSectionId !== undefined &&
      (unitId !== prevUnitId || coursebookSectionId !== prevCoursebookSectionId)
    ) {
      this.requestUnitSectionContents();
    }
  }

  public render() {
    if (this.state.error) {
      return <WampErrorMask reload={this.requestUnitSectionContents} />;
    }
    if (this.state.loading) {
      return <Loader />;
    }
    if (!this.props.contentsValue) {
      return <EmptyUnitPage />;
    }
    return (
      <ContentsEditor
        value={this.props.contentsValue}
        onChange={this.props.changeUnitSectionContents}
      />
    );
  }

  private requestUnitSectionContents = () => {
    if (this.request) {
      this.request.cancel();
    }
    this.setState({loading: true, error: false});
    this.request = makeCancellable(
      this.props.requestUnitSectionContents(),
      this.handleUnitSectionContentsLoaded,
      this.handleRequestError
    );
  };

  private handleUnitSectionContentsLoaded = (
    action: AxiosResponseAction<{description: ValueJSON}>
  ) => {
    this.setState({loading: false});
    if (action.payload.status === 404) {
      this.props.setUnitSectionInitialContents(valueJSONFromText(''));
    } else {
      this.props.setUnitSectionInitialContents(action.payload.data.description);
    }
  };

  private handleRequestError = () => this.setState({error: true});
}

const mapStateToProps = (state: AppState): StateProps => {
  const contentsRecord = state.coursebookPage!.sections.unitSectionContents;
  return {
    contentsValue: contentsRecord && contentsRecord.value
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>,
  {coursebookSectionId, coursebookId, unitId}: OwnProps
): DispatchProps => ({
  requestUnitSectionContents: () =>
    dispatch(requestUnitSectionContents(coursebookId, Number(unitId), Number(coursebookSectionId))),
  setUnitSectionInitialContents: (value: ValueJSON) =>
    dispatch(setUnitSectionInitialContents(value)),
  changeUnitSectionContents: (change: Editor) => dispatch(changeUnitSectionContents(change))
});

const ConnectedContents = connect(mapStateToProps, mapDispatchToProps)(UnitSectionContents);

const UnitContents: FC = () => {
  const {unitId, coursebookId, coursebookSectionId} = useParams<SectionRouteParams>();

  return (
    <ConnectedContents
      coursebookId={coursebookId!}
      unitId={unitId}
      coursebookSectionId={coursebookSectionId}
    />
  );
};

export default UnitContents;
