const prefix = 'XWIDGET/';

export const DIALOGUE_ADD_SPEAKER = prefix + 'DIALOGUE_ADD_SPEAKER';
export const DIALOGUE_REMOVE_SPEAKER = prefix + 'DIALOGUE_REMOVE_SPEAKER';
export const DIALOGUE_CHANGE_AVATAR = prefix + 'DIALOGUE_CHANGE_AVATAR';
export const DIALOGUE_CHANGE_NAME = prefix + 'DIALOGUE_CHANGE_NAME';

export const DIALOGUE_ADD_MESSAGE = prefix + 'DIALOGUE_ADD_MESSAGE';
export const DIALOGUE_REMOVE_MESSAGE = prefix + 'DIALOGUE_REMOVE_MESSAGE';
export const DIALOGUE_CHANGE_MESSAGE = prefix + 'DIALOGUE_CHANGE_MESSAGE';
export const DIALOGUE_CHANGE_SPEAKER = prefix + 'DIALOGUE_CHANGE_SPEAKER';
export const DIALOGUE_MOVE_MESSAGE_DOWN = prefix + 'DIALOGUE_MOVE_MESSAGE_DOWN';
export const DIALOGUE_MOVE_MESSAGE_UP = prefix + 'DIALOGUE_MOVE_MESSAGE_UP';

export const DIALOGUE_CHANGE_COLOR_SCHEMA = prefix + 'DIALOGUE_CHANGE_COLOR_SCHEMA';
