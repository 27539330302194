import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import {type ExerciseFeedbackDetails} from '../interface';

const DetailsComponent: FC<ExerciseFeedbackDetails & {message: string | null}> = ({
  coursebookTitle,
  unitTitle,
  pageNumber,
  exerciseRevisionId,
  lastExerciseRevisionId,
  message,
  student
}) => {
  return (
    <div className="meta">
      {!!student && (
        <div className="meta-piece user">
          <Icon name="user" />
          {`${student.profile.lastName} ${student.profile.firstName}`}
        </div>
      )}
      <div className="meta-piece book">
        <Icon name="book" />
        {coursebookTitle}
      </div>
      <div className="meta-piece unit">
        <Icon name="virc-unit" />
        {unitTitle}
      </div>
      <div className="meta-piece page">
        <Icon name="file-text" />
        <span>
          <FormattedMessage id="Feedback.Page" /> {pageNumber}
        </span>
      </div>
      {exerciseRevisionId !== lastExerciseRevisionId && (
        <div className="meta-piece warning">
          <Icon name="warning" />
          <span>
            <span className="bold">
              <FormattedMessage id="MediaDevicesWizard.GreetingAttention" />
            </span>{' '}
            <FormattedMessage id="Feedback.StaleWarning" />
          </span>
        </div>
      )}
      {!message ? null : (
        <div className="meta-piece text">
          <FormattedMessage id="Feedback.MessageTitle" />
          <p>{message}</p>
        </div>
      )}
    </div>
  );
};

export default DetailsComponent;
