import {List, Map} from 'immutable';

import {type AppState} from '../../../interface';
import {type ExerciseMediaSources} from '../../player/interface';
import {type XMediaSourcesErrors} from '../interface';
import {
  type ChangePositionMediaSourceAction,
  type ChangePositionMediaSourcePayloadProps,
  type CreateMediaSourceAction,
  type DeleteMediaSourceAction,
  type UpdateMediaSourceAction,
  type UpdateMediaSourcePayloadProps
} from './interface';

const APP_NAME = 'MEDIA_SOURCES';

export const CREATE_MEDIA_SOURCE = `${APP_NAME}/CREATE_MEDIA_SOURCE`;
export const DELETE_MEDIA_SOURCE = `${APP_NAME}/DELETE_MEDIA_SOURCE`;
export const UPDATE_MEDIA_SOURCE = `${APP_NAME}/UPDATE_MEDIA_SOURCE`;
export const CHANGE_POSITION_MEDIA_SOURCE = `${APP_NAME}/CHANGE_POSITION_MEDIA_SOURCE`;

export enum MediaSourceField {
  Title = 'title',
  Url = 'url'
}

// ACTIONS
export function createMediaSource(id: string): CreateMediaSourceAction {
  return {
    type: CREATE_MEDIA_SOURCE,
    payload: {id}
  };
}

export function deleteMediaSource(id: string): DeleteMediaSourceAction {
  return {
    type: DELETE_MEDIA_SOURCE,
    payload: {id}
  };
}

export function updateMediaSource(payload: UpdateMediaSourcePayloadProps): UpdateMediaSourceAction {
  return {
    type: UPDATE_MEDIA_SOURCE,
    payload: payload
  };
}

export function changePositionMediaSource(
  payload: ChangePositionMediaSourcePayloadProps
): ChangePositionMediaSourceAction {
  return {
    type: CHANGE_POSITION_MEDIA_SOURCE,
    payload: payload
  };
}

// SELECTORS
export function getMediaSources(state: AppState): ExerciseMediaSources {
  if (state.xeditor) {
    return state.xeditor.xexercise.mediaSources;
  }

  return List([]);
}

export function getMediaSourcesErrors(state: AppState): XMediaSourcesErrors | undefined {
  if (state.xeditor) {
    return state.xeditor.errors.get('mediaSources', Map());
  }

  return undefined;
}
