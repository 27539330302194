import React, {useCallback, useState} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {connect, useDispatch} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {type AppState} from 'store/interface';

import Icon from '../../Icon';
import {clearGrammarPreviewOpen, clearMainExerciseId} from './actions';
import UnitPreviewContainer from './UnitPreviewContainer';
import Loader from '../../Loader';
import './UnitPreviewModal.scss';

type Props = OwnProps & StateProps;
interface OwnProps {
  unitId: number;
  coursebookId: string;
  onHide: () => void;
}

interface StateProps {
  mainId: string | null;
  grammarPreview?: string;
}

const UnitPreviewModal: React.FC<Props> = ({
  onHide,
  unitId,
  coursebookId,
  mainId,
  grammarPreview
}) => {
  const dispatch = useDispatch();
  const [animationFinished, setAnimationFinished] = useState<boolean>(false);
  const onEntered = useCallback(async () => {
    setAnimationFinished(true);
  }, []);

  return (
    <Modal
      backdrop="static"
      className="unit-preview-modal"
      onHide={onHide}
      show={true}
      onEntered={onEntered}
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="UnitPreview.Modal.Title" />
        </Modal.Title>
        <Button onClick={onHide} className="btn-ico modal-close">
          <Icon name="pc-multiply" />
        </Button>
      </Modal.Header>
      <Modal.Body>
        {animationFinished ? (
          <UnitPreviewContainer unitId={unitId} coursebookId={coursebookId} />
        ) : (
          <Loader />
        )}
      </Modal.Body>
      <Modal.Footer>
        {mainId && (
          <Button bsStyle="default" bsSize="sm" onClick={() => dispatch(clearMainExerciseId)}>
            <FormattedMessage id="Common.Back" />
          </Button>
        )}
        {grammarPreview && (
          <Button bsStyle="default" bsSize="sm" onClick={() => dispatch(clearGrammarPreviewOpen())}>
            <FormattedMessage id="Common.Back" />
          </Button>
        )}
        <Button bsStyle="primary" bsSize="sm" type="submit" onClick={onHide}>
          <FormattedMessage id="Common.Close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    mainId: (state.unitPreview && state.unitPreview.mainId) || null,
    grammarPreview: state.unitPreview?.grammarPreview
  };
};

export default connect(mapStateToProps)(UnitPreviewModal);
