import React from 'react';

import DragHandle from '../../../../components/DragHandle/DragHandle';
import './UnitItemDragItem.scss';

interface Props {
  elementWidth: number;
  isRevision?: boolean;
  ordinal: number | null;
  style: React.CSSProperties;
  title: string;
}

class UnitItemDragItem extends React.Component<Props> {
  public render() {
    const {elementWidth, isRevision, ordinal, style, title} = this.props;
    return (
      <div
        style={{...style, width: elementWidth, right: elementWidth - 25}}
        className="unit-item-drag-item"
      >
        <div className="info">
          <span className={`label${isRevision ? ' revision' : ''}`}>
            {isRevision ? 'R' : ordinal}
          </span>
          <span className="title">{title}</span>
        </div>
        <div className="unit-item-drag-handle">
          <DragHandle />
        </div>
      </div>
    );
  }
}

export default UnitItemDragItem;
