import React from 'react';
import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import MainUnitExerciseView from '../Unit/UnitExerciseList/views/UnitExerciseMain';
import {type UserV2} from '../../../../components/CoursebookLibrary/interface';
import type UnitExerciseMainRecord from '../Unit/UnitExerciseList/dataRecords/UnitExerciseMainRecord';
import {type UnitExerciseDragObject} from '../Unit/UnitExerciseList/interface';

interface OwnProps {
  style: React.CSSProperties;
  elementWidth: number;
  dragItem: UnitExerciseDragObject;
}

interface StateProps {
  supplementaryUnitExerciseNumber: number;
  pageIndex: number;
  excerpt: string;
  mainUnitExerciseIndex: number;
  unitExerciseId: number;
  lockedBy?: UserV2;
  isDragItemInSupplementaryPosition: boolean;
  exerciseId: string;
}

interface Props extends StateProps, OwnProps {}

class UnitExerciseMainDragItem extends React.Component<Props> {
  public render() {
    const {
      style,
      elementWidth,
      pageIndex,
      unitExerciseId,
      excerpt,
      mainUnitExerciseIndex,
      supplementaryUnitExerciseNumber,
      lockedBy,
      isDragItemInSupplementaryPosition,
      exerciseId
    } = this.props;
    return (
      <div style={{...style, width: elementWidth, position: 'relative', right: elementWidth - 13}}>
        <MainUnitExerciseView
          unitExerciseId={unitExerciseId}
          index={mainUnitExerciseIndex}
          excerpt={excerpt}
          lockedBy={lockedBy && `${lockedBy.profile.lastName} ${lockedBy.profile.firstName}`}
          supplementaryExercisesNumber={supplementaryUnitExerciseNumber}
          pageIndex={pageIndex}
          isDragItem={true}
          deleteUnitExercise={this.emptyFunction}
          toggleSupplementaryExercisesPanel={this.emptyFunction}
          getMainElementRef={this.emptyFunction}
          isDragItemInSupplementaryPosition={isDragItemInSupplementaryPosition}
          exerciseId={exerciseId}
          createSupplementaryExercise={this.emptyFunction}
          viewExercise={this.emptyFunction}
          showSupplementaryExercisesPanel={false}
        />
      </div>
    );
  }

  private emptyFunction = () => null;
}

const mapStateToProps = (state: AppState, {dragItem}: OwnProps): StateProps => {
  const {pageIndex, mainUnitExerciseIndex, unitExerciseId, suppUnitExerciseIndex} =
    dragItem.position;

  const isInSupplementaryPosition = dragItem.position.isSuppUnitExercise;

  const mainUnitExercise = state
    .coursebookPage!.unit!.current.pages.get(pageIndex)
    .unitExerciseList.get(mainUnitExerciseIndex);

  const unitExercise = isInSupplementaryPosition
    ? mainUnitExercise.supplementaryUnitExerciseList.get(suppUnitExerciseIndex!)
    : mainUnitExercise;

  // if in supplementary position, assume list is empty because we can't drag
  // main exercise to supplementary position if it's supplementary list is not empty
  const suppUnitExerciseNumber = isInSupplementaryPosition
    ? 0
    : (unitExercise as UnitExerciseMainRecord).supplementaryUnitExerciseList.size;

  return {
    pageIndex,
    mainUnitExerciseIndex,
    unitExerciseId,
    supplementaryUnitExerciseNumber: suppUnitExerciseNumber,
    excerpt: unitExercise.excerpt,
    lockedBy: unitExercise.lockedBy,
    isDragItemInSupplementaryPosition: dragItem.position.isSuppUnitExercise,
    exerciseId: unitExercise.exerciseId
  };
};

export default connect(mapStateToProps)(UnitExerciseMainDragItem);
