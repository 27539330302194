const prefix = 'XWIDGET/';

export const WORD_PICTURE_SET_ADD_CARD = prefix + 'WORD_PICTURE_SET_ADD_CARD';
export const WORD_PICTURE_SET_REMOVE_CARD = prefix + 'WORD_PICTURE_SET_REMOVE_CARD';
export const WORD_PICTURE_SET_CHANGE_VISIBILITY = prefix + 'WORD_PICTURE_SET_CHANGE_VISIBILITY';
export const WORD_PICTURE_SET_SWITCH_INTERACTIVE = prefix + 'WORD_PICTURE_SET_SWITCH_INTERACTIVE';
export const WORD_PICTURE_SET_CHANGE_TEXT = prefix + 'WORD_PICTURE_SET_CHANGE_TEXT';
export const WORD_PICTURE_SET_CHANGE_IMAGE = prefix + 'WORD_PICTURE_SET_CHANGE_IMAGE';
export const WORD_PICTURE_SET_CHANGE_CARD_SIZE_TYPE =
  prefix + 'WORD_PICTURE_SET_CHANGE_CARD_SIZE_TYPE';
export const WORD_PICTURE_SET_MOVE_CARD = prefix + 'WORD_PICTURE_SET_MOVE_CARD';
export const WORD_PICTURE_SET_ROLL_BACK_CARS = prefix + 'WORD_PICTURE_SET_ROLL_BACK_CARS';

export const WORD_PICTURE_SET_CHANGE_WIDGET_THEME = prefix + 'WORD_PICTURE_SET_CHANGE_WIDGET_THEME';
