import React from 'react';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import DropdownMenu from 'react-bootstrap/lib/DropdownMenu';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import CustomToggle from 'components/CustomDropdown/CustomToggle';

import {dropdownDropupCheckCreator} from '../../../../../../helpers/dropdownDropupCheck';

interface Props {
  shouldRenderSupplementaryExercisesActions: boolean;
  shouldRenderEditExerciseAction: boolean;
  shouldRenderDeleteAction: boolean;
  wasCopiedToClipboard?: boolean;

  deleteExercise: () => void;
  createSupplementaryExercise?: () => void;
  editExercise?: (e: React.MouseEvent<HTMLDivElement>) => void;
  copyExercise?(): void;
  copyToClipboard?(): void;
  viewExercise: () => void;
}

interface State {
  dropup: boolean;
}

export default class UnitExerciseInfoDropdown extends React.PureComponent<Props, State> {
  private static dropdownButtonHeight = 30;
  private static defaultButtonsNumber = 1;

  public state: State = {dropup: false};

  public render() {
    const {
      shouldRenderSupplementaryExercisesActions,
      shouldRenderDeleteAction,
      shouldRenderEditExerciseAction
    } = this.props;
    return (
      <Dropdown
        id="dropdown-custom-components"
        className="exercise-list-item-dropdown"
        dropup={this.state.dropup}
      >
        <CustomToggle bsRole="toggle" onClick={this.dropdownDropupCheck}>
          <Icon name="virc-actions" />
        </CustomToggle>

        <DropdownMenu>
          {shouldRenderSupplementaryExercisesActions
            ? this.renderSupplementaryExercisesActions()
            : null}
          <MenuItem onClick={this.viewExercise}>
            <Icon name="play-circle" />
            <FormattedMessage id="Coursebook.View" />
          </MenuItem>
          {shouldRenderEditExerciseAction ? this.renderEditExerciseAction() : null}
          {this.renderCopyExerciseAction()}
          {this.renderCopyToClipboardExerciseAction()}
          {shouldRenderDeleteAction ? this.renderDeleteExerciseAction() : null}
        </DropdownMenu>
      </Dropdown>
    );
  }

  private getDropdownHeight = () => {
    const {
      shouldRenderEditExerciseAction,
      shouldRenderDeleteAction,
      shouldRenderSupplementaryExercisesActions
    } = this.props;
    let buttonsNumber = UnitExerciseInfoDropdown.defaultButtonsNumber;
    if (shouldRenderEditExerciseAction) {
      buttonsNumber++;
    }
    if (shouldRenderSupplementaryExercisesActions) {
      buttonsNumber = buttonsNumber + 2;
    }
    if (shouldRenderDeleteAction) {
      buttonsNumber++;
    }
    return buttonsNumber * UnitExerciseInfoDropdown.dropdownButtonHeight;
  };

  private dropdownDropupCheck = dropdownDropupCheckCreator(
    (dropup: boolean) => this.setState({dropup}),
    this.getDropdownHeight.bind(this)()
  );

  private renderSupplementaryExercisesActions = () => (
    <>
      <MenuItem key="1" onClick={this.onCreateSupplementaryClick}>
        <Icon name="plus-circle" />
        <FormattedMessage id="Coursebook.CreateSuppExercise" />
      </MenuItem>
      <MenuItem key="2" disabled={true} onClick={this.stopPropagation}>
        <Icon name="folder-open" />
        <FormattedMessage id="Coursebook.AddSuppExercise" />
      </MenuItem>
    </>
  );

  private renderEditExerciseAction = () => (
    <MenuItem onClick={this.onEditClick}>
      <Icon name="pencil" />
      <FormattedMessage id="Coursebook.Edit" />
    </MenuItem>
  );

  private renderCopyExerciseAction = () =>
    this.props.copyExercise ? (
      <MenuItem onClick={this.onCopyClick}>
        <Icon name="copy" />
        <FormattedMessage id="Coursebook.Copy" />
      </MenuItem>
    ) : null;

  private renderCopyToClipboardExerciseAction = () =>
    this.props.copyToClipboard ? (
      <MenuItem disabled={this.props.wasCopiedToClipboard} onClick={this.copyToClipboard}>
        <Icon name="clipboard" />
        <FormattedMessage id="Coursebook.CopyToClipboard" />
      </MenuItem>
    ) : null;

  private renderDeleteExerciseAction = () => (
    <MenuItem onClick={this.onDeleteClick}>
      <Icon name="trash" />
      <FormattedMessage id="Coursebook.Delete" />
    </MenuItem>
  );

  private onCreateSupplementaryClick = (e: React.MouseEvent<{}>) => {
    e.stopPropagation();
    const {createSupplementaryExercise} = this.props;
    if (createSupplementaryExercise) {
      createSupplementaryExercise();
    }
  };

  private onDeleteClick = (e: React.MouseEvent<{}>) => {
    e.stopPropagation();
    this.props.deleteExercise();
  };

  private onEditClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    this.props.editExercise!(e);
  };

  private stopPropagation = (e: React.MouseEvent<{}>) => e.stopPropagation();

  private viewExercise = (e: React.MouseEvent<{}>) => {
    e.stopPropagation();
    this.props.viewExercise();
  };

  private onCopyClick = (e: React.MouseEvent<{}>) => {
    e.stopPropagation();
    this.props.copyExercise?.();
  };

  private copyToClipboard = (e: React.MouseEvent<{}>) => {
    e.stopPropagation();

    if (this.props.wasCopiedToClipboard) return;

    this.props.copyToClipboard?.();
  };
}
