import React from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {type ConnectDragSource, type ConnectDropTarget} from 'react-dnd';

import {type UnitPage, type UnitExerciseMain} from 'store/interface';

import {MainExerciseContainer} from '../containers/UnitExerciseMain';
import MainExerciseView from './UnitExerciseMain';
import './UnitPage.scss';

interface Props {
  pageIndex: number;
  page: UnitPage;
  readonly?: boolean;
  isDragItem?: boolean;
  isInvisible?: boolean;

  getMainComponentRef: (el: HTMLDivElement | null) => void;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
}

export default class UnitPageView extends React.PureComponent<Props> {
  public render() {
    const {
      pageIndex,
      page,
      readonly,
      connectDragSource,
      isDragItem,
      getMainComponentRef,
      isInvisible,
      connectDropTarget
    } = this.props;
    const className = classNames('unit-page', {
      readonly,
      'is-drag-item': isDragItem,
      'is-invisible': isInvisible
    });
    return connectDropTarget(
      <div className={className} ref={getMainComponentRef}>
        <div className="page-number">
          <div className="number">{pageIndex + 1}</div>
          <div className="caption">
            <FormattedMessage id="Coursebook.PageCaption" />
          </div>
        </div>
        <div className="unit-exercise-list">{page.unitExerciseList.map(this.renderExercise)}</div>
        {connectDragSource(
          <div className="drag-handle" draggable={false}>
            <div className="drag-handle-image" />
          </div>
        )}
      </div>
    );
  }

  private renderExercise = (unitExercise: UnitExerciseMain, index: number) => {
    if (this.props.isDragItem) {
      return (
        <MainExerciseView
          unitExerciseId={unitExercise.id}
          key={unitExercise.id}
          excerpt={unitExercise.excerpt}
          index={index}
          supplementaryExercisesNumber={unitExercise.supplementaryUnitExerciseList.length}
          pageIndex={this.props.pageIndex}
          readonly={this.props.readonly}
          isInsideDragItem={true}
          exerciseId={unitExercise.exerciseId}
          deleteUnitExercise={this.emptyFunction}
          toggleSupplementaryExercisesPanel={this.emptyFunction}
          getMainElementRef={this.emptyFunction}
          createSupplementaryExercise={this.emptyFunction}
          viewExercise={this.emptyFunction}
          showSupplementaryExercisesPanel={false}
        />
      );
    }
    return (
      <MainExerciseContainer
        unitExerciseId={unitExercise.id}
        key={unitExercise.id}
        excerpt={unitExercise.excerpt}
        supplementaryListLength={unitExercise.supplementaryUnitExerciseList.length}
        pageIndex={this.props.pageIndex}
        readonly={this.props.readonly}
        index={index}
        isOnlyExerciseInPage={this.props.page.unitExerciseList.length === 1}
        lockedBy={unitExercise.lockedBy}
        exerciseId={unitExercise.exerciseId}
      />
    );
  };

  private emptyFunction = () => null;
}
