import {Value, type ValueJSON} from '@englex/slate';

import {isDocumentEmpty} from 'components/Slate/utils/documentNotEmpty';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

interface ContentsRecordProps {
  readonly value: Value;
  readonly initialValue: Value;
  readonly valueChanged: boolean;
}

interface Props extends ContentsRecordProps {}

const Record = recordBase()(BaseRecord);

class ContentsRecord extends Record implements Props {
  public declare readonly value: Value;

  public declare readonly initialValue: Value;

  public declare readonly valueChanged: boolean;

  public constructor(initialValue: ValueJSON) {
    super();
    this.initValues({
      value: Value.fromJSON(initialValue),
      initialValue: Value.fromJSON(initialValue),
      valueChanged: false
    });
  }

  public setNewValue(value: Value) {
    const valueEqualsInitial =
      JSON.stringify(value.toJSON()) === JSON.stringify(this.initialValue.toJSON());
    return this.set('value', value).set('valueChanged', !valueEqualsInitial);
  }

  public editSaved() {
    return this.set('initialValue', this.value).set('valueChanged', false);
  }

  public revertEdits() {
    return this.set('value', this.initialValue).set('valueChanged', false);
  }

  public get initialValueEmpty() {
    return isDocumentEmpty(this.initialValue.document);
  }

  public get valueEmpty() {
    return isDocumentEmpty(this.value.document);
  }
}

decorate(ContentsRecord, {
  value: property(),
  initialValue: property(),
  valueChanged: property()
});

record()(ContentsRecord);

export default ContentsRecord;
