const prefix = 'COURSEBOOK_CONTENTS/';

export const RESET_SECTIONS_TAB = prefix + 'RESET_SECTIONS_TAB';
export const SET_COURSEBOOK_SECTIONS = prefix + 'SET_COURSEBOOK_SECTIONS';
export const REQUEST_UNIT_SECTION_CONTENTS = prefix + 'REQUEST_UNIT_SECTION_CONTENTS';
export const SET_UNIT_SECTION_INITIAL_CONTENTS = prefix + 'SET_UNIT_SECTION_INITIAL_CONTENTS';
export const CHANGE_UNIT_SECTION_CONTENTS = prefix + 'CHANGE_UNIT_SECTION_CONTENTS';
export const REVERT_CONTENTS_EDIT = prefix + 'REVERT_CONTENTS_EDIT';
export const CONTENTS_EDIT_SAVED = prefix + 'CONTENTS_EDIT_SAVED';
export const SET_UPDATING_CONTENTS = prefix + 'SET_UPDATING_CONTENTS';
export const CREATE_UNIT_SECTION_CONTENTS_REQUEST = prefix + 'CREATE_UNIT_SECTION_CONTENTS_REQUEST';
export const DELETE_UNIT_SECTION_CONTENTS_REQUEST = prefix + 'DELETE_UNIT_SECTION_CONTENTS_REQUEST';
export const EDIT_UNIT_SECTION_CONTENTS_REQUEST = prefix + 'EDIT_UNIT_SECTION_CONTENTS_REQUEST';
export const OPEN_COURSEBOOK_SECTION_MODAL = prefix + 'OPEN_COURSEBOOK_SECTION_MODAL';
export const CLOSE_COURSEBOOK_SECTION_MODAL = prefix + 'CLOSE_COURSEBOOK_SECTION_MODAL';
export const REQUEST_SECTIONS = prefix + 'REQUEST_SECTIONS';
export const CREATE_COURSEBOOK_SECTION_REQUEST = prefix + 'CREATE_COURSEBOOK_SECTION_REQUEST';
export const UPDATE_COURSEBOOK_SECTION_REQUEST = prefix + 'UPDATE_COURSEBOOK_SECTION_REQUEST';
export const DELETE_COURSEBOOK_SECTION_REQUEST = prefix + 'DELETE_COURSEBOOK_SECTION_REQUEST';
export const MOVE_COURSEBOOK_SECTION_REQUEST = prefix + 'MOVE_COURSEBOOK_SECTION_REQUEST';
