import React from 'react';
import {useDispatch} from 'react-redux';
import {useDrop} from 'react-dnd';

import {DndTypes} from 'components/dnd/interface';

import EmptySupplementaryListDropTargetView from '../views/EmptySupplementaryList';
import {
  dragPreviewMakeExerciseSupplementary,
  type MakeUnitExerciseSupplementaryOptions
} from '../actions/dragPreviewActions';
import {type UnitExerciseDragObject} from '../interface';

interface Props {
  pageIndex: number;
  unitExerciseIndex: number;
}

export const EmptySupplementaryListDropTarget: React.FC<Props> = React.memo(
  ({pageIndex, unitExerciseIndex}) => {
    const dispatch = useDispatch();

    const [{canDrop, dropDisallowed}, connectDropTarget] = useDrop({
      accept: DndTypes.UNIT_EXERCISE,
      canDrop(item: UnitExerciseDragObject) {
        return !item.supplementaryListHasElements;
      },
      collect: monitor => ({
        canDrop: monitor.canDrop() && monitor.isOver(),
        dropDisallowed: monitor.isOver() && !monitor.canDrop()
      }),
      drop: (item: UnitExerciseDragObject, monitor) => {
        if (!monitor.canDrop()) return;

        const itemOnAnotherPage = item.position.pageIndex !== pageIndex;
        const isSuppUnitExercise = item.position.isSuppUnitExercise;

        const itemIsParentUnitExercise =
          item.position.mainUnitExerciseIndex === unitExerciseIndex && !isSuppUnitExercise;

        if (itemOnAnotherPage || itemIsParentUnitExercise) return;

        if (!isSuppUnitExercise) {
          const sourcePosition = item.position.mainUnitExerciseIndex;
          const draggedFromTop = sourcePosition < unitExerciseIndex;
          const targetElementPosition = draggedFromTop ? unitExerciseIndex - 1 : unitExerciseIndex;

          const options: MakeUnitExerciseSupplementaryOptions = {
            pageIndex,
            sourcePosition,
            targetUnitExercisePosition: targetElementPosition,
            targetPositionInSupplementaryList: 0
          };
          item.position.makeUnitExerciseSupplementary(options);
          dispatch(dragPreviewMakeExerciseSupplementary(options));
        }
      }
    });

    return (
      <EmptySupplementaryListDropTargetView
        connectDropTarget={connectDropTarget}
        canDrop={canDrop}
        dropDisallowed={dropDisallowed}
      />
    );
  }
);
