import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import Panel from 'react-bootstrap/lib/Panel';

import Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import Actions from './Actions';

interface Props {
  id: number;
  date: string;
  isOpening: boolean;
  name: string;
  processedAt: string | null;
  onDelete(id: number, from404?: true): void;
  onProcess(id: number, processedAt: string | null): void;
}

const PanelHeading: FC<Props> = ({id, date, name, isOpening, onDelete, onProcess, processedAt}) => {
  return (
    <Panel.Heading>
      <Panel.Title toggle={true}>
        {!isOpening ? <Icon name="angle-right" /> : <Spinner size={21} />}
        <div className="info">
          <span className="info-sender">
            <span className="info-label">
              <FormattedMessage id="Feedback.Sender" />:
            </span>
            <div>{name}</div>
          </span>
          <div className="info-time">
            <span className="info-label">
              <FormattedMessage id="Feedback.Sent" />:
            </span>{' '}
            {moment(date).format('DD.MM.YYYY, HH:mm')}
          </div>
        </div>
        <Actions id={id} processed={!!processedAt} onDelete={onDelete} onProcess={onProcess} />
      </Panel.Title>
    </Panel.Heading>
  );
};

export default PanelHeading;
