import {List} from 'immutable';

import {BaseRecord} from 'immutable-record/Record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {type WidgetJSON} from 'store/exercise/player/interface';
import {decorate} from 'immutable-record/decorate.util';

const Record = recordBase()(BaseRecord);

interface XWizardProperties {
  step: number;
  stepFields: List<Partial<WidgetJSON>>;
}

class XWizardRecord extends Record implements XWizardProperties {
  public declare readonly step: number;

  public declare readonly stepFields: List<Partial<WidgetJSON>>;

  get widgetFields() {
    return this.stepFields.reduce((fields, el) => ({...fields, ...el}), {});
  }
}

decorate(XWizardRecord, {
  step: property(0),
  stepFields: property(List<Partial<WidgetJSON>>())
});
record()(XWizardRecord);
export default XWizardRecord;
