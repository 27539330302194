import React, {type FC, useCallback, useContext} from 'react';
import RetinaImage from '@englex/react-retina-image';
import {useNavigate} from 'react-router-dom';

import {type CoursebookItemComponentProps} from 'components/CoursebookLibrary/interface';
import EnglexCoursebookCover from 'components/EnglexCoursebookCover';
import {coursebookPath} from 'common/paths';

import CoursebookInfoHeader from '../Common/CoursebookInfoHeader';
import CoursebookInfoBody from '../Common/CoursebookInfoBody';
import {CoursebookLibraryContext} from './context';
import './CoursebookItem.scss';

export const CoursebookItem: FC<CoursebookItemComponentProps> = ({
  coursebook,
  deleteCoursebook: deleteCoursebookProp
}) => {
  const navigate = useNavigate();
  const context = useContext(CoursebookLibraryContext);

  const deleteCoursebook = useCallback(
    () => deleteCoursebookProp(coursebook.id),
    [coursebook.id, deleteCoursebookProp]
  );

  const openCoursebook = () => {
    const selection = window.getSelection();
    if (!selection || !selection.toString().length) {
      navigate(coursebookPath(coursebook.id));
    }
  };
  return (
    <div className="coursebook-item" onClick={openCoursebook}>
      <div className="book-cover">
        {coursebook.cover ? (
          <>
            <div className="mask" />
            <RetinaImage src={coursebook.cover.urls} />
          </>
        ) : (
          <EnglexCoursebookCover fontSize={6} />
        )}
      </div>
      <div className="content">
        <CoursebookInfoHeader
          inItem={true}
          coursebook={coursebook}
          deleteCoursebook={deleteCoursebook}
          openViewContentsModal={context.viewContentsOfCoursebook}
          openViewGrammarModal={context.viewGrammarOfCoursebook}
        />
        <CoursebookInfoBody coursebook={coursebook} collapsibleLists={true} />
      </div>
    </div>
  );
};
