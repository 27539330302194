import {type Action} from 'redux';

import {type CoursebookDataModalState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  type OpenCoursebookDataModalAction,
  type SetAvailableCoursesAction,
  type SetAvailableLevelsAction
} from './action';
import {
  CLOSE_COURSEBOOK_DATA_MODAL,
  OPEN_COURSEBOOK_DATA_MODAL,
  SET_AVAILABLE_COURSES,
  SET_AVAILABLE_LEVELS
} from './actionTypes';

export const initialCoursebookDataModalState: CoursebookDataModalState = {};

const ACTION_HANDLERS: ActionHandlersList<CoursebookDataModalState, Action> = {
  [OPEN_COURSEBOOK_DATA_MODAL]: (
    state: CoursebookDataModalState,
    action: OpenCoursebookDataModalAction
  ) => ({
    ...state,
    copiedCoursebook: action.copiedCoursebook,
    editedCoursebook: action.editedCoursebook,
    isOpen: true
  }),
  [CLOSE_COURSEBOOK_DATA_MODAL]: (state: CoursebookDataModalState) => ({
    ...state,
    isOpen: false,
    editedCoursebook: undefined
  }),
  [SET_AVAILABLE_COURSES]: (
    state: CoursebookDataModalState,
    {availableCourses}: SetAvailableCoursesAction
  ) => ({
    ...state,
    availableCourses
  }),
  [SET_AVAILABLE_LEVELS]: (
    state: CoursebookDataModalState,
    {availableLevels}: SetAvailableLevelsAction
  ) => ({
    ...state,
    availableLevels
  })
};

export default function (
  state: CoursebookDataModalState,
  action: Action
): CoursebookDataModalState {
  const coursebookDataModalReducer = ACTION_HANDLERS[action.type];
  return coursebookDataModalReducer ? coursebookDataModalReducer(state, action) : state;
}
