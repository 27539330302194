import {List} from 'immutable';

import genKey from 'components/Slate/utils/genKey';
import {type UnitExerciseJSON} from 'store/interface';
import {BaseRecord} from 'immutable-record/Record';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {decorate} from 'immutable-record/decorate.util';

import type UnitExerciseMainRecord from './UnitExerciseMainRecord';

interface UnitPageRecordProps {
  readonly unitExerciseList: List<UnitExerciseMainRecord>;
  readonly id: string;
}

interface Props extends UnitPageRecordProps {}

const Record = recordBase()(BaseRecord);

class UnitPageRecord extends Record implements Props {
  public declare readonly unitExerciseList: List<UnitExerciseMainRecord>;

  public declare readonly id: string;

  public constructor(unitExerciseList: UnitExerciseMainRecord[], id?: string) {
    super();
    this.initValues({
      unitExerciseList: List(unitExerciseList),
      id: id || genKey()
    });
  }

  public toJSON(): UnitExerciseJSON[] {
    return this.unitExerciseList.reduce<UnitExerciseJSON[]>(
      (reduction: UnitExerciseJSON[], ueRecord: UnitExerciseMainRecord, index: number) =>
        reduction.concat(
          ueRecord.toJSON().map((ueJSON: UnitExerciseJSON) => ({
            ...ueJSON,
            ordinal: ueJSON.parentExerciseId ? ueJSON.ordinal : index
          }))
        ),
      []
    );
  }
}

decorate(UnitPageRecord, {
  unitExerciseList: property(List()),
  id: property()
});
record()(UnitPageRecord);
export default UnitPageRecord;
