import React from 'react';
import {connect} from 'react-redux';
import {type WrappedComponentProps, injectIntl} from 'react-intl';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {type AppState, type CoursebookUnit} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import * as toastr from 'components/toastr';
import {messages} from 'components/CoursebookLibrary/UnitsList/i18n';
import {createUnit} from 'components/CoursebookLibrary/UnitsList/action';
import EditTitleModal from 'components/modals/EditTitleModal/EditTitleModal';

interface OwnProps {
  close: () => void;
  coursebookId: string;
  editCoursebookSuccessCallback: () => void;
  isRevision: boolean;
  show: boolean;
}

interface DispatchProps {
  createUnit: (
    coursebookId: string,
    revision: boolean,
    title: string
  ) => Promise<AxiosResponseAction<CoursebookUnit>>;
}

interface Props extends DispatchProps, OwnProps, WrappedComponentProps {}

interface State {
  submittingForm: boolean;
}

class CreateUnitModal extends React.Component<Props, State> {
  public state: State = {submittingForm: false};
  private static readonly unitTitleMaxLength = 256;

  private get modalTitle() {
    const {intl, isRevision} = this.props;
    return isRevision
      ? intl.formatMessage({id: 'ServerCoursebook.UnitsList.CreateRevisionModal.Title'})
      : intl.formatMessage({id: 'ServerCoursebook.UnitsList.CreateUnitModal.Title'});
  }

  public render() {
    const {close, intl, show} = this.props;
    return (
      <EditTitleModal
        handleSubmit={this.submit}
        onHide={close}
        show={show}
        submitting={this.state.submittingForm}
        title={this.modalTitle}
        label={intl.formatMessage({id: 'ServerCoursebook.UnitsList.RenameModal.Label'})}
        placeholder={intl.formatMessage(messages.renameModalPlaceholder)}
        maxLengthErrorMessage={intl.formatMessage(messages.editModalValidationMaxLength, {
          count: CreateUnitModal.unitTitleMaxLength
        })}
        requiredErrorMessage={intl.formatMessage(messages.editModalValidationRequired)}
      />
    );
  }

  private submit = (title: string) => {
    const {
      close,
      coursebookId,
      editCoursebookSuccessCallback,
      intl: {formatMessage},
      isRevision
    } = this.props;
    this.setState({submittingForm: true});
    this.props
      .createUnit(coursebookId, isRevision, title)
      .then(() => {
        editCoursebookSuccessCallback();
        toastr.success(
          '',
          formatMessage(isRevision ? messages.createTestSuccess : messages.createModalSuccess)
        );
      })
      .catch(() => {
        toastr.error(
          '',
          formatMessage(isRevision ? messages.createModalTestFail : messages.createModalFail)
        );
      })
      .finally(() => {
        close();
        this.setState({submittingForm: false});
      });
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  createUnit: (coursebookId: string, isRevision: boolean, title: string) =>
    dispatch(createUnit(coursebookId, isRevision, title))
});

export default injectIntl(connect(null, mapDispatchToProps)(CreateUnitModal));
