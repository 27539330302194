import React from 'react';
import Tooltip from 'rc-tooltip';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {type ConnectDropTarget} from 'react-dnd';

import Icon from 'components/Icon';

import UnitExerciseInfoDropdown from './UnitExerciseInfoDropdown';
import './UnitExerciseInfo.scss';

interface Props {
  supplementaryExercisesNumber?: number;
  excerpt: string;
  lockedBy?: string;
  readonly?: boolean;
  hideDropdown?: boolean;
  isDragItem?: boolean;
  invisible?: boolean;
  showSupplementaryExercisesPanel?: boolean;
  wasCopiedToClipboard?: boolean;
  animated?: boolean;

  getRef?: (el: HTMLDivElement | null) => void;
  openExercise?: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleSupplementaryExercisesTrigger?: () => void;
  deleteUnitExercise: () => void;
  connectDropTarget?: ConnectDropTarget;
  createSupplementaryExercise?: () => void;
  viewExercise: () => void;
  copyExercise?: () => void;
  copyToClipboard?: () => void;
  onAnimationEnd?: () => void;
}

export default class UnitExerciseInfo extends React.PureComponent<Props> {
  public itemEl: HTMLDivElement | null;

  public render() {
    const {
      excerpt,
      openExercise,
      copyExercise,
      copyToClipboard,
      lockedBy,
      handleSupplementaryExercisesTrigger,
      readonly,
      hideDropdown,
      isDragItem,
      invisible,
      connectDropTarget,
      deleteUnitExercise,
      createSupplementaryExercise,
      wasCopiedToClipboard,
      viewExercise,
      animated,
      onAnimationEnd
    } = this.props;
    const className = classNames('unit-exercise-list-item-info', {
      supplementary: !handleSupplementaryExercisesTrigger,
      locked: !!lockedBy,
      readonly,
      'hide-dropdown': hideDropdown,
      'drag-item': isDragItem,
      'invisible-unit-exercise': invisible,
      animated: animated
    });
    const content = (
      <div
        className={className}
        onClick={openExercise}
        ref={this.getRef}
        onAnimationEnd={onAnimationEnd}
      >
        {lockedBy ? this.renderLockedIndicator() : null}
        <span className="excerpt">{excerpt}</span>
        {this.isMainExercise() ? this.renderSupplementaryExercisesTrigger() : null}
        <UnitExerciseInfoDropdown
          shouldRenderDeleteAction={!this.props.readonly}
          shouldRenderEditExerciseAction={!this.props.lockedBy}
          shouldRenderSupplementaryExercisesActions={this.isMainExercise() && !this.props.readonly}
          wasCopiedToClipboard={wasCopiedToClipboard}
          deleteExercise={deleteUnitExercise}
          createSupplementaryExercise={createSupplementaryExercise}
          editExercise={openExercise}
          copyExercise={copyExercise}
          copyToClipboard={copyToClipboard}
          viewExercise={viewExercise}
        />
        {this.props.children}
      </div>
    );
    return connectDropTarget ? connectDropTarget(content) : content;
  }

  private isMainExercise = () => !!this.props.handleSupplementaryExercisesTrigger;

  private renderLockedIndicator = () => (
    <Tooltip
      destroyTooltipOnHide={true}
      placement="right"
      overlay={this.props.lockedBy}
      getTooltipContainer={this.getTooltipContainer}
      overlayClassName="black-tooltip"
    >
      <span className="locked-indicator">
        <Icon name="lock" />
      </span>
    </Tooltip>
  );

  private getTooltipContainer = () => this.itemEl || document.body;

  private getRef = (el: HTMLDivElement | null) => {
    this.itemEl = el;
    if (this.props.getRef) {
      this.props.getRef(el);
    }
  };

  private renderSupplementaryExercisesTrigger = () => {
    const {showSupplementaryExercisesPanel, supplementaryExercisesNumber} = this.props;
    const hasSupplementaryExercises = supplementaryExercisesNumber;
    const className = classNames('supplementary-exercises-trigger', {
      'no-supplementary-exercises': !hasSupplementaryExercises
    });
    return (
      <Tooltip
        destroyTooltipOnHide={true}
        getTooltipContainer={this.getTooltipContainer}
        overlayClassName="black-tooltip"
        placement="left"
        overlay={
          showSupplementaryExercisesPanel ? (
            <FormattedMessage id="Coursebook.SupplementaryExercisesHideLabel" />
          ) : (
            <FormattedMessage id="Coursebook.SupplementaryExercisesShowLabel" />
          )
        }
      >
        <div className={className} onClick={this.onSupplementaryExercisesTrigger}>
          {hasSupplementaryExercises ? `+${supplementaryExercisesNumber}` : '0'}
        </div>
      </Tooltip>
    );
  };

  private onSupplementaryExercisesTrigger = (e: React.MouseEvent<{}>) => {
    e.stopPropagation();
    this.props.handleSupplementaryExercisesTrigger!();
  };
}
