import React, {type FC, useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';
import Collapse from 'react-bootstrap/lib/Collapse';
import Row from 'react-bootstrap/lib/Row';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import Col from 'react-bootstrap/lib/Col';

import ReactSelect from 'components/ReactSelect/ReactSelect';

import {FilterContext, FilterDispatchContext} from '../context/filter';
import {setSelectedCoursebookIds} from '../context/actions';
import {type CoursebookOption} from '../interface';
import {ShowProcessedCheckbox} from './ShowProcessedCheckbox';
import './SearchPanel.scss';

interface Props {
  coursebookOptions: CoursebookOption[];
  isOpen: boolean;
}

export const SearchPanel: FC<Props> = ({coursebookOptions, isOpen}) => {
  const intl = useIntl();
  const {dispatch} = useContext(FilterDispatchContext);
  const {selectedCoursebookIds} = useContext(FilterContext);

  const isValueSelected = useCallback(
    ({value}: CoursebookOption) => !!selectedCoursebookIds?.includes(value),
    [selectedCoursebookIds]
  );

  const onCoursebookSelect = useCallback(
    (selectedOptions: CoursebookOption[] | null) => {
      dispatch(setSelectedCoursebookIds(selectedOptions?.map(o => o.value)));
    },
    [dispatch]
  );

  return (
    <Collapse in={isOpen}>
      <div className="search-panel">
        <div className="content">
          <Row>
            <Col sm={1}>
              <ControlLabel>{intl.formatMessage({id: 'Coursebook.Coursebook'})}:</ControlLabel>
            </Col>
            <Col sm={11}>
              <ReactSelect
                placeholder={intl.formatMessage({id: 'CoursebookLibrary.SearchbarPlaceholder'})}
                onChange={onCoursebookSelect}
                options={coursebookOptions}
                isValueSelected={isValueSelected}
              />
            </Col>
          </Row>
          <Row>
            <Col smOffset={1} sm={11}>
              <ShowProcessedCheckbox />
            </Col>
          </Row>
        </div>
      </div>
    </Collapse>
  );
};
