import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {defineMessages, FormattedMessage, injectIntl} from 'react-intl';
import classNames from 'classnames';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import {type RequestLevelsResponseAction} from 'store/exercise/editor/actions/xeditor';
import {type AxiosRequestError} from 'services/axios/interface';

import Loader from '../../../../components/Loader';
import {type CancellablePromise, makeCancellable} from '../../../../helpers/cancellablePromise';
import CoursebookDataForm from './CoursebookDataForm';
import ModalError from '../../../../components/ModalError';
import {xeditorSidebarErrors} from '../../XEditorPage/components/Sidebar/i18n';
import {type CreateCoursebookResponseAction, type RequestCoursesResponseAction} from './action';
import {type CoursebookDataModalProps} from './interface';

import './CoursebookDataModal.scss';

interface State {
  loadDataError?: boolean;
  submittingForm?: boolean;
}

const messages = defineMessages({
  CreateCoursebookSuccess: {
    id: 'CoursebookLibrary.DataModal.CreateSuccessToast'
  },
  EditCoursebookSuccess: {
    id: 'CoursebookLibrary.DataModal.EditSuccessToast'
  }
});

class CoursebookDataModal extends React.Component<CoursebookDataModalProps, State> {
  public state: State = {};

  private submitRequest: CancellablePromise;
  private coursesRequest: CancellablePromise;
  private levelsRequest: CancellablePromise;

  public componentDidUpdate(prevProps: Readonly<CoursebookDataModalProps>) {
    const {isOpen, availableCourses, availableLevels} = this.props;
    if (!prevProps.isOpen && isOpen && (!availableLevels || !availableCourses)) {
      this.loadData();
    }
  }

  public componentWillUnmount(): void {
    if (this.coursesRequest) {
      this.coursesRequest.cancel();
    }
    if (this.levelsRequest) {
      this.levelsRequest.cancel();
    }
    if (this.submitRequest) {
      this.submitRequest.cancel();
    }
  }

  public render() {
    const {editedCoursebook, isOpen} = this.props;
    return (
      <Modal
        backdrop="static"
        show={isOpen}
        onHide={this.closeIfNotSubmitting}
        id="coursebook-data-modal"
        className="coursebook-data-modal modal-gray"
      >
        <Modal.Header>
          <Modal.Title>
            {editedCoursebook ? (
              <FormattedMessage id="CoursebookLibrary.DataModal.EditTitle" />
            ) : (
              <FormattedMessage id="CoursebookLibrary.DataModal.CreateTitle" />
            )}
            <a onClick={this.closeIfNotSubmitting}>
              <Icon name="pc-close" tag="i" />
            </a>
          </Modal.Title>
        </Modal.Header>

        {this.renderBodyAndFooter()}
      </Modal>
    );
  }

  private closeIfNotSubmitting = () => {
    if (!this.state.submittingForm) {
      this.props.close();
    }
  };

  private loadData = () => {
    const {availableLevels, availableCourses, requestLevels, requestCourses} = this.props;
    if (this.state.loadDataError) {
      this.setState({loadDataError: false});
    }
    if (!availableCourses) {
      this.coursesRequest = makeCancellable(
        requestCourses(),
        this.handleCoursesRequest,
        this.handleLoadRequestFail
      );
    }
    if (!availableLevels) {
      this.levelsRequest = makeCancellable(
        requestLevels(),
        this.handleLevelsRequest,
        this.handleLoadRequestFail
      );
    }
  };

  private handleFormSubmit = (
    title: string,
    levels: number[],
    courses: number[],
    selfCheck: boolean
  ) => {
    const {createCoursebook, editedCoursebook, copiedCoursebook, editCoursebook, copyCoursebook} =
      this.props;

    this.setState({submittingForm: true});

    if (editedCoursebook) {
      this.submitRequest = makeCancellable(
        editCoursebook({id: editedCoursebook.id, title, levels, courses, selfCheck}),
        this.handleEditCoursebookRequestSuccess,
        this.handleSubmitRequestFail
      );
      return;
    }

    if (copiedCoursebook) {
      this.submitRequest = makeCancellable(
        copyCoursebook({id: copiedCoursebook.id, title, levels, courses, selfCheck}),
        this.handleCopyCoursebookRequestSuccess,
        this.handleSubmitRequestFail
      );
      return;
    }

    this.submitRequest = makeCancellable(
      createCoursebook({title, levels, courses, selfCheck}),
      this.handleCreateCoursebookRequestSuccess,
      this.handleSubmitRequestFail
    );
  };

  private handleCoursesRequest = (action: RequestCoursesResponseAction) => {
    this.props.setAvailableCourses(action.payload.data);
  };

  private handleLevelsRequest = (action: RequestLevelsResponseAction) => {
    this.props.setAvailableLevels(action.payload.data);
  };

  private handleLoadRequestFail = () => this.setState({loadDataError: true});

  private handleCreateCoursebookRequestSuccess = (action: CreateCoursebookResponseAction) => {
    this.setState({submittingForm: false});
    this.props.close();
    this.props.redirectToCoursebook(action.payload.data.id);
    toastr.success('', this.props.intl.formatMessage(messages.CreateCoursebookSuccess));
  };

  private handleEditCoursebookRequestSuccess = () => {
    const {
      close,
      editCoursebookSuccessCallback,
      intl: {formatMessage}
    } = this.props;
    this.setState({submittingForm: false});
    close();
    editCoursebookSuccessCallback();
    toastr.success('', formatMessage(messages.EditCoursebookSuccess));
  };

  private handleCopyCoursebookRequestSuccess = (action: CreateCoursebookResponseAction) => {
    this.setState({submittingForm: false});
    this.props.close();
    this.props.redirectToCoursebook(action.payload.data.id);
  };

  private handleSubmitRequestFail = (action: AxiosRequestError) => {
    const {
      intl: {formatMessage}
    } = this.props;
    this.setState({submittingForm: false});

    if (action.error.response?.status === 403) {
      return toastr.error('', formatMessage(xeditorSidebarErrors.restrictedAction));
    }

    toastr.error('', formatMessage(xeditorSidebarErrors.toastrErrorMessage));
  };

  private renderBodyAndFooter = () => {
    const {isOpen, availableLevels, availableCourses, editedCoursebook, copiedCoursebook} =
      this.props;
    const {loadDataError} = this.state;
    if (!isOpen) {
      return null;
    }
    if (loadDataError) {
      return (
        <React.Fragment>
          {this.renderErrorBody()}
          <Modal.Footer>{this.renderCancelBtn()}</Modal.Footer>
        </React.Fragment>
      );
    }
    if (!availableLevels || !availableCourses) {
      return (
        <React.Fragment>
          <Modal.Body>
            <Loader />
          </Modal.Body>
          <Modal.Footer>{this.renderCancelBtn()}</Modal.Footer>
        </React.Fragment>
      );
    }
    return (
      <CoursebookDataForm
        editedCoursebook={editedCoursebook || copiedCoursebook}
        availableLevels={availableLevels}
        availableCourses={availableCourses}
        handleSubmit={this.handleFormSubmit}
        renderFooter={() => (
          <Modal.Footer>
            {this.renderCancelBtn()}
            {this.renderSubmitBtn()}
          </Modal.Footer>
        )}
      />
    );
  };

  private renderErrorBody = () => {
    const errorTitle = <FormattedMessage id="CoursebookLibrary.DataModal.LoadDataErrorHeader" />;
    const errorText = <FormattedMessage id="MessageModal.GetSubjectsErrorText" />;
    return (
      <Modal.Body>
        {' '}
        <ModalError reload={this.loadData} errorTitle={errorTitle} errorText={errorText} />
      </Modal.Body>
    );
  };

  private renderCancelBtn = () => (
    <Button
      bsStyle="default"
      className="btn-transparent"
      type="reset"
      bsSize="sm"
      onClick={this.props.close}
      disabled={this.state.submittingForm}
    >
      <FormattedMessage id="Common.Cancel" />
    </Button>
  );

  private renderSubmitBtn = () => {
    const {editedCoursebook} = this.props;
    const {submittingForm} = this.state;
    const className = classNames('submit-coursebook-modal', {submitting: submittingForm});
    return (
      <Button
        bsStyle="primary"
        bsSize="sm"
        type="submit"
        disabled={submittingForm}
        className={className}
      >
        {submittingForm ? (
          <div className="loader-button-positioning-helper">
            <Loader shouldRender={submittingForm} />
          </div>
        ) : null}
        {editedCoursebook ? (
          <FormattedMessage id="Common.Save" />
        ) : (
          <FormattedMessage id="Common.Create" />
        )}
      </Button>
    );
  };
}

export default injectIntl(CoursebookDataModal);
