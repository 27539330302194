import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import {ADD_WIDGET_FIELD, NEXT_STEP, PREV_STEP} from './actionTypes/xwizard';
import {type WidgetJSON} from '../player/interface';
import XWizardRecord from './widgets/XWizardRecord';
import {type XWizardAddWidgetFieldAction} from './actions/interface';

const REDUCERS: ReducersMapObject = {
  [NEXT_STEP]: (state: XWizardRecord) => state.set('step', state.step + 1),
  [PREV_STEP]: (state: XWizardRecord) => state.set('step', state.step - 1),
  [ADD_WIDGET_FIELD]: <T extends keyof WidgetJSON>(
    state: XWizardRecord,
    action: XWizardAddWidgetFieldAction<T>
  ) => {
    const {key, value} = action;
    const index = state.stepFields.findIndex(el => Object.keys(el!)[0] === key);
    const keyAlreadyExist = index !== -1;

    if (keyAlreadyExist) {
      return state.set('stepFields', state.stepFields.splice(index, 1, {[key]: value}));
    }

    return state.set('stepFields', state.stepFields.push({[key]: value}));
  }
};

function xwizardReducer(state: XWizardRecord = new XWizardRecord(), action: Action): XWizardRecord {
  const reducer: Reducer<XWizardRecord> = REDUCERS[action.type];
  return reducer ? reducer(state, action) : state;
}

export default xwizardReducer;
