const prefix = 'DRAG_PREVIEW/';
export const DRAG_PREVIEW_CREATE = prefix + 'DRAG_PREVIEW_CREATE';
export const DRAG_PREVIEW_APPROVE = prefix + 'DRAG_PREVIEW_APPROVE';
export const DRAG_PREVIEW_DISCARD = prefix + 'DRAG_PREVIEW_DISCARD';
export const DRAG_PREVIEW_MOVE_PAGE = prefix + 'DRAG_PREVIEW_MOVE_PAGE';
export const DRAG_PREVIEW_CREATE_PAGE_WITH_UNIT_EXERCISE =
  prefix + 'DRAG_PREVIEW_CREATE_PAGE_WITH_UNIT_EXERCISE';
export const DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_PAGE =
  prefix + 'DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_PAGE';
export const DRAG_PREVIEW_MOVE_UNIT_EXERCISE_BETWEEN_PAGES =
  prefix + 'DRAG_PREVIEW_MOVE_UNIT_EXERCISE_BETWEEN_PAGES';
export const DRAG_PREVIEW_MAKE_UNIT_EXERCISE_MAIN = prefix + 'DRAG_PREVIEW_MAKE_UNIT_EXERCISE_MAIN';
export const DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_SUPPLEMENTARY_LIST =
  prefix + 'DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_SUPPLEMENTARY_LIST';
export const DRAG_PREVIEW_MAKE_UNIT_EXERCISE_SUPPLEMENTARY =
  prefix + 'DRAG_PREVIEW_MAKE_UNIT_EXERCISE_SUPPLEMENTARY';
