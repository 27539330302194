import {type Action} from 'redux';

import {type ActionHandlersList} from 'store/reducers';

import type UnitRecord from '../dataRecords/UnitRecord';
import {
  DRAG_PREVIEW_CREATE_PAGE_WITH_UNIT_EXERCISE,
  DRAG_PREVIEW_MAKE_UNIT_EXERCISE_MAIN,
  DRAG_PREVIEW_MAKE_UNIT_EXERCISE_SUPPLEMENTARY,
  DRAG_PREVIEW_MOVE_PAGE,
  DRAG_PREVIEW_MOVE_UNIT_EXERCISE_BETWEEN_PAGES,
  DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_PAGE,
  DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_SUPPLEMENTARY_LIST
} from '../actions/dragPreviewActionTypes';
import {
  type CreatePageWithUnitExerciseAction,
  type MakeUnitExerciseMainAction,
  type MakeUnitExerciseSupplementaryAction,
  type MoveUnitExerciseBetweenPagesAction,
  type MoveUnitExerciseWithinPageAction,
  type MoveUnitPageAction,
  type MoveWithinSupplementaryListAction
} from '../actions/dragPreviewActions';
import UnitPageRecord from '../dataRecords/UnitPageRecord';
import UnitExerciseMainRecord from '../dataRecords/UnitExerciseMainRecord';
import UnitExerciseSupplementaryRecord from '../dataRecords/UnitExerciseSupplementaryRecord';

const ACTION_HANDLERS: ActionHandlersList<UnitRecord, Action> = {
  [DRAG_PREVIEW_MOVE_PAGE]: (state: UnitRecord, {sourcePos, targetPos}: MoveUnitPageAction) => {
    const targetPage = state.pages.get(sourcePos);
    const newPagesState = state.pages.splice(sourcePos, 1).splice(targetPos, 0, targetPage);
    return state.set('_pages', newPagesState);
  },
  [DRAG_PREVIEW_CREATE_PAGE_WITH_UNIT_EXERCISE]: (
    state: UnitRecord,
    {
      sourcePageIndex,
      unitExerciseIndex,
      createdPageIndex,
      createdPageId
    }: CreatePageWithUnitExerciseAction
  ) => {
    const targetUnitExercise = state.pages
      .get(sourcePageIndex)
      .unitExerciseList.get(unitExerciseIndex);
    const newState = state.deleteIn([
      '_pages',
      sourcePageIndex,
      'unitExerciseList',
      unitExerciseIndex
    ]);
    return newState.set(
      '_pages',
      newState.pages.insert(
        createdPageIndex,
        new UnitPageRecord([targetUnitExercise], createdPageId)
      )
    );
  },
  [DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_PAGE]: (
    state: UnitRecord,
    {pageIndex, targetPos, sourcePos}: MoveUnitExerciseWithinPageAction
  ) => {
    const targetUnitExercise = state.pages.get(pageIndex).unitExerciseList.get(sourcePos);
    return state.setIn(
      ['_pages', pageIndex, 'unitExerciseList'],
      state.pages
        .get(pageIndex)
        .unitExerciseList.delete(sourcePos)
        .insert(targetPos, targetUnitExercise)
    );
  },
  [DRAG_PREVIEW_MOVE_UNIT_EXERCISE_BETWEEN_PAGES]: (
    state: UnitRecord,
    {targetPageIndex, targetPos, sourcePageIndex, sourcePos}: MoveUnitExerciseBetweenPagesAction
  ) => {
    const targetUnitExercise = state.pages.get(sourcePageIndex).unitExerciseList.get(sourcePos);
    return state
      .deleteIn(['_pages', sourcePageIndex, 'unitExerciseList', sourcePos])
      .setIn(
        ['_pages', targetPageIndex, 'unitExerciseList'],
        state.pages.get(targetPageIndex).unitExerciseList.insert(targetPos, targetUnitExercise)
      );
  },
  [DRAG_PREVIEW_MAKE_UNIT_EXERCISE_MAIN]: (
    state: UnitRecord,
    {
      pageIndex,
      parentUnitExerciseIndex,
      suppUnitExerciseIndex,
      targetPosition
    }: MakeUnitExerciseMainAction
  ) => {
    const targetUnitExercise = state.pages
      .get(pageIndex)
      .unitExerciseList.get(parentUnitExerciseIndex)
      .supplementaryUnitExerciseList.get(suppUnitExerciseIndex);
    const targetExerciseToMainExercise = new UnitExerciseMainRecord(
      {
        ...targetUnitExercise.toJSON()[0]
      },
      []
    );

    const newState = state.deleteIn([
      '_pages',
      pageIndex,
      'unitExerciseList',
      parentUnitExerciseIndex,
      'supplementaryUnitExerciseList',
      suppUnitExerciseIndex
    ]);

    return newState.setIn(
      ['_pages', pageIndex, 'unitExerciseList'],
      newState._pages
        .get(pageIndex)
        .unitExerciseList.insert(targetPosition, targetExerciseToMainExercise)
    );
  },
  [DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_SUPPLEMENTARY_LIST]: (
    state: UnitRecord,
    {
      pageIndex,
      parentUnitExerciseIndex,
      sourcePosition,
      targetPosition
    }: MoveWithinSupplementaryListAction
  ) => {
    const supplementaryList = state._pages
      .get(pageIndex)
      .unitExerciseList.get(parentUnitExerciseIndex).supplementaryUnitExerciseList;
    const sourceUnitExercise = supplementaryList.get(sourcePosition);
    return state.setIn(
      [
        '_pages',
        pageIndex,
        'unitExerciseList',
        parentUnitExerciseIndex,
        'supplementaryUnitExerciseList'
      ],
      supplementaryList.delete(sourcePosition).insert(targetPosition, sourceUnitExercise)
    );
  },
  [DRAG_PREVIEW_MAKE_UNIT_EXERCISE_SUPPLEMENTARY]: (
    state: UnitRecord,
    {
      pageIndex,
      sourcePosition,
      targetUnitExercisePosition,
      targetPositionInSupplementaryList
    }: MakeUnitExerciseSupplementaryAction
  ) => {
    const sourceUnitExercise = state._pages.get(pageIndex).unitExerciseList.get(sourcePosition);

    const newState = state.deleteIn(['_pages', pageIndex, 'unitExerciseList', sourcePosition]);
    const supplementaryList = newState._pages
      .get(pageIndex)
      .unitExerciseList.get(targetUnitExercisePosition).supplementaryUnitExerciseList;
    return newState.setIn(
      [
        '_pages',
        pageIndex,
        'unitExerciseList',
        targetUnitExercisePosition,
        'supplementaryUnitExerciseList'
      ],
      supplementaryList.insert(
        targetPositionInSupplementaryList,
        new UnitExerciseSupplementaryRecord(sourceUnitExercise.toJSON()[0])
      )
    );
  }
};

export default function (state: UnitRecord, action: Action): UnitRecord {
  const reducer = ACTION_HANDLERS[action.type];
  return reducer ? reducer(state, action).removeEmptyPages() : state;
}
