import {type ReactElement, type ReactNode} from 'react';
import {type CompleteCrop} from 'react-image-crop';
import {type WrappedComponentProps} from 'react-intl';

import {
  type UploadingPictureStatus,
  type UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import {type ValidatedImageFile} from 'common/ImageUpload/functions';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type EnglexImage} from 'store/interface';

export type ErrorHandler = (error: AxiosRequestError) => void;
export type ResponseHandler = (response: AxiosResponseAction<EnglexImage>) => void;
export type Status = UploadingPictureStatus | UploadingPictureValidationError;
type PromiseHandlers = {
  resolve(res: AxiosResponseAction<{}>): void;
  reject(rej: AxiosRequestError): void;
};
export type AttachCoverModule = {
  promiseCreator(data: EnglexImage): Promise<AxiosResponseAction<{}>>;
} & PromiseHandlers;
export type DetachCoverModule = {
  promiseCreator(): Promise<{}>;
} & PromiseHandlers;

export interface CoverUploaderProps extends WrappedComponentProps {
  aspect?: number;
  children: ReactNode;
  className?: string;
  confirmHeaderText: ReactElement;
  coverExists: boolean;
  coverMinWidth: number;
  attachModule: AttachCoverModule;
  detachModule: DetachCoverModule;
}

export interface ValidatedFile {
  file: ValidatedImageFile;
  dataUrl: string;
}

export enum Phase {
  DEFAULT = 'default',
  CROP = 'crop',
  UPLOADING = 'uploading'
}

interface UploaderModalState {
  crop?: CompleteCrop;
  isErrorStatus: boolean;
  retry: boolean;
  startUploading(): void;
  turnOffRetry(): void;
  uploadingStarted: boolean;
  validatedFile: ValidatedFile | null;
}

export interface UploaderModalProps {
  aspect: number;
  errorMessage?: string;
  freeze: boolean;
  handleError: ErrorHandler;
  handleResponse: ResponseHandler;
  hideModal: () => void;
  minWidth: number;
  setErrorMessage(message?: string): void;
  setFreeze(freeze: boolean): void;
  setStatus(status?: Status): void;
  status?: Status;
  show: boolean;
}

export interface UploaderModalFooterProps extends UploaderModalProps, UploaderModalState {}
