import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import CreateUnitModal from 'routes/Library/CoursebookPage/UnitsList/containers/CreateUnitModal';

import {PasteUnitsButton} from '../containers/PasteUnitsButton';

import './UnitsListHeader.scss';

interface Props {
  coursebookId: string;
  editCoursebookSuccessCallback: () => void;
  disableAllControls?: boolean;
}

interface State {
  isRevision: boolean;
  showModal: boolean;
}

export default class UnitsListHeader extends React.Component<Props, State> {
  public state: State = {isRevision: false, showModal: false};

  public render() {
    const {coursebookId, disableAllControls, editCoursebookSuccessCallback} = this.props;
    return (
      <div className="units-list-header coursebook-page-column-header">
        <CreateUnitModal
          close={this.closeCreateUnitModal}
          coursebookId={coursebookId}
          editCoursebookSuccessCallback={editCoursebookSuccessCallback}
          isRevision={this.state.isRevision}
          show={this.state.showModal}
        />
        <DropdownButton
          bsStyle="success"
          bsSize="sm"
          title={this.renderDropdownTitle()}
          id="exercise-list-actions-dropdown"
          noCaret={true}
          disabled={disableAllControls}
        >
          <MenuItem onClick={this.openCreateUnitModal()}>
            <Icon name="virc-unit" />
            <FormattedMessage id="Coursebook.Unit" />
          </MenuItem>
          <MenuItem onClick={this.openCreateUnitModal(true)}>
            <Icon name="virc-test" />
            <FormattedMessage id="Coursebook.Test" />
          </MenuItem>
        </DropdownButton>

        <PasteUnitsButton className="offset-left" />
      </div>
    );
  }

  private openCreateUnitModal =
    (isRevision: boolean = false) =>
    () =>
      this.setState({isRevision, showModal: true});
  private closeCreateUnitModal = () => this.setState({isRevision: false, showModal: false});

  private renderDropdownTitle = () => {
    return (
      <React.Fragment>
        <Icon name="plus-circle" />
        <span className="title-text">
          <FormattedMessage id="Coursebook.Unit" />
        </span>
        <Icon name="angle-down" />
      </React.Fragment>
    );
  };
}
