import React, {type FC} from 'react';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {useParams} from 'react-router-dom';

import {push} from 'store/router';
import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';
import {requestCoursebookSections} from 'components/CoursebookContentsViewer/action';
import {type AppState, type CoursebookSection} from 'store/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import {type AxiosResponseAction} from 'services/axios/interface';
import {unselectedSectionPath} from 'common/paths';

import SectionsList from './containers/SectionsList';
import UnitSectionContents from './containers/UnitSectionContents';
import {resetContentsTab, setCoursebookSections} from './actions/action';
import {type SectionRouteParams} from '../../interface';
import UpdatingUnitOverlay from '../../UpdatingUnitOverlay';
import CoursebookSectionModal from './containers/CoursebookSectionModal';

interface OwnProps extends SectionRouteParams {}

interface StateProps {
  coursebookSections?: CoursebookSection[];
  updatingContents?: boolean;
}

interface DispatchProps {
  reset: () => void;
  requestCoursebookSections: (coursebookId: string) => Promise<Action>;
  setCoursebookSections: (sections: CoursebookSection[]) => void;
  push: (path: string) => void;
}

interface State {
  sectionsRequestError?: boolean;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

class UnitSections extends React.Component<Props, State> {
  public state: State = {};
  private request: CancellablePromise;

  public componentDidMount(): void {
    this.requestCoursebookSections();
  }

  public componentWillUnmount(): void {
    this.request.cancel();
    this.props.reset();
  }

  public render() {
    if (this.state.sectionsRequestError) {
      return <WampErrorMask reload={this.reload} />;
    }
    const {coursebookSections, updatingContents, coursebookId} = this.props;
    if (!coursebookSections) {
      return <Loader />;
    }
    return (
      <React.Fragment>
        <UnitSectionContents />
        <SectionsList
          coursebookId={coursebookId}
          coursebookSections={coursebookSections}
          reloadList={this.requestCoursebookSections}
        />
        {updatingContents ? <UpdatingUnitOverlay /> : null}
        <CoursebookSectionModal
          coursebookId={coursebookId}
          requestCoursebookSections={this.requestCoursebookSections}
        />
      </React.Fragment>
    );
  }

  private requestCoursebookSections = () => {
    const {coursebookId} = this.props;
    if (this.state.sectionsRequestError) {
      this.setState({sectionsRequestError: false});
    }
    this.request = makeCancellable(
      this.props.requestCoursebookSections(coursebookId),
      this.handleRequestSuccess,
      this.handleRequestError
    );
  };

  private reload = () => {
    this.props.reset();
    this.requestCoursebookSections();
  };

  private handleRequestSuccess = (action: AxiosResponseAction<CoursebookSection[]>) => {
    const {coursebookSectionId, coursebookId, unitId, push} = this.props;
    this.props.setCoursebookSections(action.payload.data);

    const selectedSectionWasDeleted = !action.payload.data.find(
      section => section.id === Number(coursebookSectionId)
    );
    if (selectedSectionWasDeleted) {
      push(unselectedSectionPath(coursebookId, Number(unitId)));
    }
  };

  private handleRequestError = () => this.setState({sectionsRequestError: true});
}

const mapStateToProps = (state: AppState): StateProps => ({
  coursebookSections: state.coursebookPage!.sections.coursebookSections,
  updatingContents: state.coursebookPage!.sections.updatingContents
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  reset: () => dispatch(resetContentsTab()),
  requestCoursebookSections: (coursebookId: string) =>
    dispatch(requestCoursebookSections(coursebookId)),
  setCoursebookSections: (sections: CoursebookSection[]) =>
    dispatch(setCoursebookSections(sections)),
  push: (path: string) => dispatch(push(path))
});

const ConnectedSections = connect(mapStateToProps, mapDispatchToProps)(UnitSections);

const UnitRouteSections: FC = () => {
  const {coursebookId, unitId, coursebookSectionId} = useParams<SectionRouteParams>();
  return (
    <ConnectedSections
      coursebookSectionId={coursebookSectionId}
      coursebookId={coursebookId!}
      unitId={unitId}
    />
  );
};

export default UnitRouteSections;
