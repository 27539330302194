import React from 'react';
import {injectIntl, type MessageDescriptor, type WrappedComponentProps} from 'react-intl';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import Scrollbars from 'react-custom-scrollbars';

import {replace} from 'store/router';
import * as toastr from 'components/toastr';
import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';
import EmptyUnitPage from 'components/CoursebookContentsViewer/EmptyUnitPage';
import {coursebookPath, introPath} from 'common/paths';
import {
  type AppState,
  type CoursebookUnit,
  type Cover,
  type EnglexImage,
  type UnitExerciseJSON,
  UnitExerciseRequestError,
  type UnitIntroJSON,
  type UnitPage
} from 'store/interface';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type IntroRecord} from 'store/intro/IntroRecord';

import {type CancellablePromise, makeCancellable} from '../../../../../helpers/cancellablePromise';
import {redirectFromUnitPage, reset} from '../../state/action';
import {UnitPageContainer} from './containers/UnitPage';
import {BetweenPagesDropTarget} from './containers/BetweenPagesDropTarget';
import {type CoursebookRouteParams} from '../../interface';
import {
  clearUnitExerciseList,
  deleteUnitIntro,
  editUnitCover,
  loadUnitExerciseList,
  type LoadUnitExerciseSuccessAction,
  loadUnitIntro,
  type LoadUnitIntroSuccessAction,
  setUnitCover,
  setUnitExerciseError,
  setUnitExerciseList,
  setUnitIntro
} from './actions/unitExerciseListActions';
import ExerciseViewer from './ExerciseViewerModal';
import EmptyListHint from './views/EmptyListHint';
import {exerciseListMessages} from './messages';
import CoverAndTitle from './CoverAndTitle';
import {UnitIntro} from './UnitIntro';
import {AnimationElement} from '../../AnimationElementsContext';

interface OwnProps {
  coursebookId: string;
  selectedUnitId: number;
}

interface StateProps {
  pages?: UnitPage[];
  readonly?: boolean;
  unit?: CoursebookUnit;
  unitHistoryNotEmpty?: boolean;
  intro?: IntroRecord;
  unitExerciseRequestError?: UnitExerciseRequestError;
}

interface DispatchProps {
  editCover: (
    coursebookId: string,
    unitId: number,
    unitTitle: string,
    coverId: number | null
  ) => Promise<AxiosResponseAction<CoursebookUnit>>;
  setUnitCover: (unitId: number, cover?: Cover) => void;
  loadExercisesList: (
    coursebookId: string,
    unitId: number
  ) => Promise<LoadUnitExerciseSuccessAction>;
  loadUnitIntro: (coursebookId: string, unitId: number) => Promise<LoadUnitIntroSuccessAction>;
  setUnitIntro: (intro: UnitIntroJSON | null) => void;
  setExercisesList: (exercises: UnitExerciseJSON[]) => void;
  setUnitExerciseRequestError: (error: UnitExerciseRequestError) => void;
  clearUnitExerciseList: () => void;
  resetCoursebookPage: () => void;
  redirectFromUnitPage: (path: string) => void;
  deleteUnitIntro: (coursebookId: string, unitId: number) => Promise<void>;
  replace: (path: string) => void;
}

interface Props
  extends OwnProps,
    StateProps,
    DispatchProps,
    CoursebookRouteParams,
    WrappedComponentProps {}

interface State {
  onBeforeUnloadSet?: boolean;
}

class ExercisesListView extends React.Component<Props, State> {
  public state: State = {};

  private exercisesRequest?: CancellablePromise;
  private introRequest?: CancellablePromise;

  public componentDidMount() {
    if (this.shouldRequestExercisesList()) {
      this.requestData();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {unitHistoryNotEmpty, selectedUnitId} = this.props;
    if (selectedUnitId !== prevProps.selectedUnitId && this.shouldRequestExercisesList()) {
      this.requestData();
    }
    if (unitHistoryNotEmpty && !this.state.onBeforeUnloadSet) {
      window.addEventListener('beforeunload', this.onBeforeUnload);
      this.setState({onBeforeUnloadSet: true});
    }
    const unitHistoryEmpty = !unitHistoryNotEmpty;
    if (unitHistoryEmpty && this.state.onBeforeUnloadSet) {
      window.removeEventListener('beforeunload', this.onBeforeUnload);
      this.setState({onBeforeUnloadSet: false});
    }
  }

  public componentWillUnmount(): void {
    this.cancelRequests();

    if (this.state.onBeforeUnloadSet) {
      window.removeEventListener('beforeunload', this.onBeforeUnload);
    }
  }

  public render() {
    const {unitExerciseRequestError} = this.props;
    return (
      <React.Fragment>
        {this.renderContent()}
        {unitExerciseRequestError ? <WampErrorMask reload={this.reloadUnitExerciseList} /> : null}
      </React.Fragment>
    );
  }

  private cancelRequests() {
    if (this.exercisesRequest) {
      this.exercisesRequest.cancel();
    }

    if (this.introRequest) {
      this.introRequest.cancel();
    }
  }

  private reloadUnitExerciseList = () => {
    const {replace, unitExerciseRequestError, coursebookId} = this.props;
    if (unitExerciseRequestError === UnitExerciseRequestError.UNIT_NOT_FOUND) {
      replace(coursebookPath(coursebookId));
      this.props.resetCoursebookPage();
    } else {
      this.props.clearUnitExerciseList();
    }
  };

  private renderContent = () => {
    const {pages, unit, intro} = this.props;
    if (!pages) {
      return <Loader />;
    }
    if (!pages.length && !intro) {
      return (
        <EmptyUnitPage>
          <EmptyListHint />
        </EmptyUnitPage>
      );
    }
    return (
      <React.Fragment>
        <ExerciseViewer />
        <Scrollbars>
          {unit ? (
            <>
              <CoverAndTitle
                attachModule={this.attachCoverModule}
                detachModule={this.detachCoverModule}
                unit={unit}
              />
              <AnimationElement elementId="intro">
                {({animated, onAnimationEnd}) => (
                  <UnitIntro
                    intro={intro}
                    unit={unit}
                    animated={animated}
                    onClick={this.openInto}
                    onDelete={this.deleteIUnitInto}
                    onAnimationEnd={onAnimationEnd}
                  />
                )}
              </AnimationElement>
            </>
          ) : null}
          {pages.map(this.renderUnitPage)}
        </Scrollbars>
      </React.Fragment>
    );
  };

  private renderUnitPage = (page: UnitPage, index: number) => {
    const {readonly} = this.props;
    const isFirstPage = index === 0;
    return (
      <React.Fragment key={page.id}>
        <BetweenPagesDropTarget previousPageIndex={index - 1} isHidden={!isFirstPage} />
        <UnitPageContainer pageIndex={index} page={page} readonly={readonly} />
        <BetweenPagesDropTarget previousPageIndex={index} />
      </React.Fragment>
    );
  };

  private get attachCoverModule() {
    const {coursebookId, editCover, selectedUnitId, setUnitCover, unit} = this.props;
    return {
      promiseCreator: (data: EnglexImage) =>
        editCover(coursebookId, selectedUnitId, unit!.title, data.id),
      resolve: (res: AxiosResponseAction<CoursebookUnit>) => {
        setUnitCover(selectedUnitId, res.payload.data.cover);
      },
      reject: () => this.onCoverEditFailure(exerciseListMessages.UnitCoverAttachError)
    };
  }

  private get detachCoverModule() {
    const {coursebookId, editCover, selectedUnitId, setUnitCover, unit} = this.props;
    return {
      promiseCreator: () => editCover(coursebookId, selectedUnitId, unit!.title, null),
      resolve: () => {
        setUnitCover(selectedUnitId, undefined);
      },
      reject: () => this.onCoverEditFailure(exerciseListMessages.UnitCoverDeleteError)
    };
  }

  private onCoverEditFailure = (messageDescriptor: MessageDescriptor) => () =>
    toastr.error('', this.props.intl.formatMessage(messageDescriptor));

  private shouldRequestExercisesList = () => {
    const {pages, selectedUnitId, unitExerciseRequestError} = this.props;
    return !pages && selectedUnitId && !unitExerciseRequestError;
  };

  private requestData = () => {
    const {loadExercisesList, loadUnitIntro, selectedUnitId, coursebookId} = this.props;

    this.cancelRequests();

    this.introRequest = makeCancellable(
      loadUnitIntro(coursebookId, selectedUnitId),
      this.handleIntroLoaded,
      this.handleIntroError,
      this.deleteIntroRequest
    );

    this.exercisesRequest = makeCancellable(
      loadExercisesList(coursebookId, selectedUnitId),
      this.handleExercisesLoaded,
      this.handleExercisesError,
      this.deleteExercisesRequest
    );
  };

  private handleExercisesLoaded = (responseAction: LoadUnitExerciseSuccessAction) => {
    this.deleteExercisesRequest();
    this.props.setExercisesList(responseAction.payload.data);
  };

  private handleIntroLoaded = (responseAction: LoadUnitIntroSuccessAction) => {
    this.deleteIntroRequest();
    this.props.setUnitIntro(responseAction.payload.data);
  };

  private handleIntroDeleted = () => {
    this.deleteIntroRequest();
    this.props.setUnitIntro(null);
  };

  private handleIntroError = (rejection: AxiosRequestError) => {
    this.deleteIntroRequest();
    const response = rejection.error.response;
    const isNotFoundError = response && response.data.statusCode === 404;

    if (isNotFoundError) {
      this.props.setUnitIntro(null);
    } else {
      this.props.setUnitExerciseRequestError(UnitExerciseRequestError.OTHER_ERROR);
    }
  };

  private handleExercisesError = (rejection: AxiosRequestError) => {
    this.deleteExercisesRequest();
    const response = rejection.error.response;
    const isNotFoundError = response && response.data.statusCode === 404;
    if (isNotFoundError) {
      this.props.setUnitExerciseRequestError(UnitExerciseRequestError.UNIT_NOT_FOUND);
    } else {
      this.props.setUnitExerciseRequestError(UnitExerciseRequestError.OTHER_ERROR);
    }
  };

  private deleteExercisesRequest = () => delete this.exercisesRequest;

  private deleteIntroRequest = () => delete this.introRequest;

  private onBeforeUnload = (event: Event) => (event.returnValue = true);

  private openInto = () => {
    const path = introPath(this.props.coursebookId, this.props.unit!.id);
    this.props.redirectFromUnitPage(path);
  };

  private deleteIUnitInto = () => {
    const {selectedUnitId, coursebookId, deleteUnitIntro} = this.props;

    this.introRequest = makeCancellable(
      deleteUnitIntro(coursebookId, selectedUnitId),
      this.handleIntroDeleted,
      this.handleIntroError,
      this.deleteIntroRequest
    );
  };
}

const mapStateToProps = (state: AppState, {selectedUnitId}: OwnProps): StateProps => ({
  pages: state.coursebookPage!.unit && state.coursebookPage!.unit.current.pages.toJS(),
  unit: state.coursebookPage!.units!.find(u => u.id === selectedUnitId),
  intro: state.coursebookPage!.intro,
  unitHistoryNotEmpty:
    state.coursebookPage!.unit &&
    (state.coursebookPage!.unit.hasUndos || state.coursebookPage!.unit.hasRedos),
  unitExerciseRequestError: state.coursebookPage!.unitExerciseRequestError
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  editCover: (coursebookId: string, unitId: number, unitTitle: string, coverId: number | null) =>
    dispatch(editUnitCover(coursebookId, unitId, unitTitle, coverId)),
  setUnitCover: (unitId: number, cover?: Cover) => dispatch(setUnitCover(unitId, cover)),
  loadExercisesList: (coursebookId: string, unitId: number) =>
    dispatch(loadUnitExerciseList(coursebookId, unitId)),
  loadUnitIntro: (coursebookId: string, unitId: number) =>
    dispatch(loadUnitIntro(coursebookId, unitId)),
  setUnitIntro: (intro: UnitIntroJSON | null) => dispatch(setUnitIntro(intro)),
  deleteUnitIntro: (coursebookId: string, unitId: number) =>
    dispatch(deleteUnitIntro(coursebookId, unitId)),
  setExercisesList: (pages: UnitExerciseJSON[]) => dispatch(setUnitExerciseList(pages)),
  setUnitExerciseRequestError: (error: UnitExerciseRequestError) =>
    dispatch(setUnitExerciseError(error)),
  clearUnitExerciseList: () => dispatch(clearUnitExerciseList()),
  resetCoursebookPage: () => dispatch(reset()),
  redirectFromUnitPage: (path: string) => dispatch(redirectFromUnitPage(path)),
  replace: (path: string) => dispatch(replace(path))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ExercisesListView));
