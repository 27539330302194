import React from 'react';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {type FormikBag, type FormikProps, withFormik} from 'formik';
import {defineMessages, FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import * as Yup from 'yup';

import {
  type NumberOptionType,
  type OptionsType,
  type OptionType
} from 'components/ReactSelect/interface';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import {type Course, type Coursebook, type LanguageLevel} from 'store/interface';

const messages = defineMessages({
  titlePlaceholder: {
    id: 'CoursebookLibrary.DataModal.TitlePlaceholder'
  },
  titleTooLong: {
    id: 'CoursebookLibrary.DataModal.TitleTooLong'
  },
  titleEmpty: {
    id: 'CoursebookLibrary.DataModal.TitleEmpty'
  },
  levelsEmpty: {
    id: 'CoursebookLibrary.DataModal.LevelsEmpty'
  }
});

interface Values {
  title: string;
  courses: number[];
  levels: number[];
  selfCheckEnabled: boolean;
}

interface Props extends WrappedComponentProps {
  editedCoursebook?: Coursebook;
  renderFooter: () => JSX.Element;
  availableLevels: LanguageLevel[];
  availableCourses: Course[];
  handleSubmit: (title: string, levels: number[], courses: number[], selfCheck: boolean) => void;
}

type FormProps = Props & FormikProps<Values>;

class CoursebookDataForm extends React.Component<FormProps> {
  public static maxTitleLength = 256;

  private levelsTouched = false;

  public render() {
    const {
      renderFooter,
      values,
      intl: {formatMessage},
      errors,
      availableLevels,
      availableCourses,
      touched,
      handleBlur
    } = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Modal.Body>
          <FormGroup
            controlId="coursebook-title"
            validationState={errors.title && touched.title ? 'error' : undefined}
          >
            <ControlLabel>
              <FormattedMessage id="CoursebookLibrary.DataModal.Title" />
              {': '}
            </ControlLabel>
            <FormControl
              name="title"
              value={values.title}
              onChange={this.handleTitleChange}
              onBlur={handleBlur}
              placeholder={formatMessage(messages.titlePlaceholder)}
              autoComplete="off"
            />
            <div className="help-block error">{errors.title}</div>
          </FormGroup>

          <FormGroup controlId="coursebook-courses">
            <ControlLabel>
              <FormattedMessage id="CoursebookLibrary.Course" />
              {': '}
            </ControlLabel>
            <ReactSelect
              options={availableCourses.map(course => ({value: course.id, label: course.name}))}
              isValueSelected={this.isCourseSelected}
              onChange={this.onCourseInputChange}
            />
          </FormGroup>

          <FormGroup
            controlId="coursebook-levels"
            validationState={
              errors.levels && (touched.levels || this.levelsTouched) ? 'error' : undefined
            }
          >
            <ControlLabel>
              <FormattedMessage id="CoursebookLibrary.Level" />
              {': '}
            </ControlLabel>
            <ReactSelect
              options={availableLevels.map(level => ({value: level.id, label: level.title}))}
              isValueSelected={this.isLevelSelected}
              onChange={this.onLevelInputChange}
            />
            <div className="help-block error">{errors.levels}</div>
          </FormGroup>
          <FormGroup controlId="coursebook-self-check">
            <Checkbox onChange={this.onSelfCheckChange} checked={values.selfCheckEnabled}>
              <FormattedMessage id="CoursebookLibrary.SelfCheck" />
            </Checkbox>
          </FormGroup>
        </Modal.Body>
        {renderFooter()}
      </form>
    );
  }

  private handleTitleChange = (e: React.FormEvent<FormControl>) => {
    // set field as touched so that validation message can appear before input blurred
    this.props.setFieldTouched('title');
    this.props.handleChange(e);
  };

  private isCourseSelected = (option: NumberOptionType) =>
    this.props.values.courses?.includes(option.value) || false;

  private onCourseInputChange = (selectedOptions: OptionsType<OptionType> | null) =>
    this.props.setFieldValue('courses', selectedOptions?.map(option => option.value) || []);

  private isLevelSelected = (option: NumberOptionType) =>
    this.props.values.levels?.includes(option.value) || false;

  private onLevelInputChange = (selectedOptions: OptionsType<OptionType> | null) => {
    this.props.setFieldTouched('levels');
    // handleBlur and touched props appear to be buggy with react-select causing to clear touch state
    // on submit attempt. hopefully temporary workaround
    this.levelsTouched = true;
    this.props.setFieldValue('levels', selectedOptions?.map(option => option.value) || [], true);
  };

  private onSelfCheckChange = () =>
    this.props.setFieldValue('selfCheckEnabled', !this.props.values.selfCheckEnabled);
}

const mapPropsToValues = (props: Props): Values => {
  if (props.editedCoursebook) {
    return {
      title: props.editedCoursebook.title,
      levels: props.editedCoursebook.levels.map(level => level.id),
      courses: props.editedCoursebook.courses.map(course => course.id),
      selfCheckEnabled: props.editedCoursebook.selfCheckEnabled
    };
  }
  return {
    title: '',
    levels: [],
    courses: [],
    selfCheckEnabled: false
  };
};

const handleSubmit = (values: Values, {props}: FormikBag<Props, Values>) => {
  props.handleSubmit(
    values.title,
    values.levels || [],
    values.courses || [],
    values.selfCheckEnabled
  );
};

const validationSchema = ({intl: {formatMessage}}: FormProps) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(formatMessage(messages.titleEmpty))
      .max(
        CoursebookDataForm.maxTitleLength,
        formatMessage(messages.titleTooLong, {length: CoursebookDataForm.maxTitleLength})
      ),
    levels: Yup.array()
      .required(formatMessage(messages.levelsEmpty))
      .min(1, formatMessage(messages.levelsEmpty))
  });

const WithFormik = withFormik<Props, Values>({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  validateOnChange: true
})(CoursebookDataForm);

export default injectIntl(WithFormik);
