import {type CoursebookFilter} from 'store/interface';
import {type BooleanEnum, type CoursebookAuthorRole} from 'common/enums';

export function getCoursebookFilter(search: string): CoursebookFilter {
  const searchParams = new URLSearchParams(search);
  const courses = searchParams.get('courses')
    ? searchParams
        .get('courses')!
        .split(',')
        .map(id => Number(id))
    : null;
  const levels = searchParams.get('levels')
    ? searchParams
        .get('levels')!
        .split(',')
        .map(id => Number(id))
    : null;
  return {
    title: searchParams.get('title'),
    role: searchParams.get('role') as CoursebookAuthorRole | null,
    published: searchParams.get('published') as BooleanEnum | null,
    courses,
    levels,
    authorId: searchParams.get('authorId') ? Number(searchParams.get('authorId')) : null,
    originalAuthorId: searchParams.get('originalAuthorId')
      ? Number(searchParams.get('originalAuthorId'))
      : null
  };
}
