import {List} from 'immutable';

import {type UnitExerciseJSON} from 'store/interface';
import {BaseRecord} from 'immutable-record/Record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import UnitPageRecord from './UnitPageRecord';
import UnitExerciseMainRecord from './UnitExerciseMainRecord';

interface ExercisesListRecordProps {
  readonly _pages: List<UnitPageRecord> | undefined;
  readonly dragPreview?: UnitRecord;
}

interface Props extends ExercisesListRecordProps {}

const Record = recordBase()(BaseRecord);

class UnitRecord extends Record implements Props {
  public get pages() {
    return (this.dragPreview && this.dragPreview._pages) || this._pages;
  }

  public declare readonly _pages: List<UnitPageRecord>;

  public declare readonly dragPreview?: UnitRecord;

  public constructor(exercises: UnitExerciseJSON[]) {
    super();
    const pages: UnitExerciseMainRecord[][] = this.groupExercisesByPages(exercises);
    this.initValues({
      _pages: List(pages.map(page => new UnitPageRecord(page)))
    });
  }

  public removeEmptyPages() {
    return this.set(
      '_pages',
      this._pages.filter((page: UnitPageRecord) => !!page.unitExerciseList.size)
    );
  }
  public toJSON(): UnitExerciseJSON[] {
    return this._pages.reduce<UnitExerciseJSON[]>(
      (reduction: UnitExerciseJSON[], page: UnitPageRecord, index: number) =>
        reduction.concat(page.toJSON().map(ue => ({...ue, pageNumber: index + 1}))),
      []
    );
  }

  private groupExercisesByPages(exercises: UnitExerciseJSON[]) {
    if (!exercises.length) {
      return [];
    }
    const pagesNumber = exercises[exercises.length - 1].pageNumber;
    const pages: UnitExerciseMainRecord[][] = [];
    for (let i = 0; i < pagesNumber; i++) {
      const mainExercises = exercises
        .filter(exercise => exercise.pageNumber === i + 1 && exercise.parentExerciseId === null)
        .map(exercise => {
          const supplementary = this.getSupplementaryExercises(exercises, exercise.exercise.id);
          return new UnitExerciseMainRecord(exercise, supplementary);
        });
      pages.push(mainExercises);
    }
    return pages;
  }

  private getSupplementaryExercises(exercises: UnitExerciseJSON[], mainUnitExerciseId: string) {
    return exercises
      .filter(exercise => exercise.parentExerciseId === mainUnitExerciseId)
      .sort((ex1: UnitExerciseJSON, ex2: UnitExerciseJSON) => (ex1.ordinal < ex2.ordinal ? -1 : 1));
  }
}

decorate(UnitRecord, {
  _pages: property(),
  dragPreview: property()
});
record()(UnitRecord);
export default UnitRecord;
