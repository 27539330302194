import {type Action} from 'redux';

import {type CoursebookOption} from '../interface';

export const RESET_FILTERS = 'RESET_FILTERS';
export const SET_SHOW_PROCESSED = 'SET_SHOW_PROCESSED';
export const SET_SELECTED_COURSEBOOK_IDS = 'SET_SELECTED_COURSEBOOK_IDS';
export const SET_COURSEBOOK_OPTIONS = 'SET_COURSEBOOK_OPTIONS';

export const resetFilters = (): Action => ({type: RESET_FILTERS});

export interface SetShowProcessedAction extends Action {
  showProcessed: boolean;
}
export const setShowProcessed = (showProcessed: boolean): SetShowProcessedAction => ({
  type: SET_SHOW_PROCESSED,
  showProcessed
});

export interface SetSelectedCoursebookIdsAction extends Action {
  selectedCoursebookIds?: string[];
}
export const setSelectedCoursebookIds = (
  selectedCoursebookIds?: string[]
): SetSelectedCoursebookIdsAction => ({
  type: SET_SELECTED_COURSEBOOK_IDS,
  selectedCoursebookIds
});

export interface SetCoursebookOptionsAction extends Action {
  coursebookOptions: CoursebookOption[];
}
export const setCoursebookOptions = (
  coursebookOptions: CoursebookOption[]
): SetCoursebookOptionsAction => ({
  type: SET_COURSEBOOK_OPTIONS,
  coursebookOptions
});
