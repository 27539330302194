import React, {type FC, useCallback, useContext, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import {useApiRequest} from 'hooks/rest/useApiRequest';
import {type ServerCoursebook} from 'components/CoursebookLibrary/interface';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';

import {FilterContext, FilterDispatchContext} from '../context/filter';
import {resetFilters, setCoursebookOptions} from '../context/actions';
import {SearchPanel} from './SearchPanel';
import {requestCoursebooks} from '../actions';

export const Header: FC = () => {
  const intl = useIntl();
  const {dispatch, initSelectedCoursebookIds} = useContext(FilterDispatchContext);
  const {coursebookOptions, showProcessed, selectedCoursebookIds} = useContext(FilterContext);
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  const searchIsActive = useMemo<boolean>(
    () => showProcessed || !!selectedCoursebookIds?.length,
    [selectedCoursebookIds, showProcessed]
  );

  const request = useMemo(() => requestCoursebooks(), []);
  const successHandler = useCallback(
    (data: ServerCoursebook[]) => {
      dispatch(setCoursebookOptions(data.map(sc => ({value: sc.id, label: sc.title}))));
      initSelectedCoursebookIds(data.map(sc => sc.id));
    },
    [dispatch, initSelectedCoursebookIds]
  );
  const {isLoading} = useApiRequest(request, successHandler);

  const toggleSearchPanel = useCallback(() => {
    if (isLoading) return;
    setSearchIsOpen(!searchIsOpen);
  }, [isLoading, searchIsOpen]);

  const resetFiltersCallback = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  return (
    <>
      <div className="header">
        <h1>
          <FormattedMessage id="Feedback.Feedback" />
        </h1>
        <Button
          className={classNames('toggle-filter-panel-btn', {
            open: searchIsOpen
          })}
          onClick={toggleSearchPanel}
          title={intl.formatMessage({id: 'CoursebookLibrary.ExtendedSearch'})}
        >
          {isLoading ? <Spinner size={20} /> : <Icon name="sliders" />}
        </Button>
        {searchIsActive && (
          <Button className="refresh-filter-btn" bsSize="sm" onClick={resetFiltersCallback}>
            <FormattedMessage id="CoursebookLibrary.ResetFilters" />
            <Icon name="pc-drop-filter" className="mobile-icon drop-filters-icon" />
          </Button>
        )}
      </div>
      {coursebookOptions && (
        <SearchPanel isOpen={searchIsOpen} coursebookOptions={coursebookOptions} />
      )}
    </>
  );
};
