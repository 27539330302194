import React, {useCallback, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {type DropTargetMonitor, useDrag, useDrop} from 'react-dnd';

import {type UnitPage} from 'store/interface';
import {DndTypes} from 'components/dnd/interface';

import {type UnitPageDragObject} from '../interface';
import UnitPageView from '../views/UnitPage';
import {
  dragPreviewApprove,
  dragPreviewCreate,
  dragPreviewDiscard,
  dragPreviewMovePage
} from '../actions/dragPreviewActions';
import {isCursorInTopHalfOfElement} from '../../../../../../common/Dnd/helpers';
import {useDndEmptyImage} from '../../../../../../components/dnd/useDndEmptyImage';
import {dndThrottle} from '../../../state/DndThrottle';

const defaultElWidth = 794;

interface Props {
  pageIndex: number;
  page: UnitPage;
  readonly?: boolean;
  isDragItem?: boolean;
}

export const UnitPageContainer: React.FC<Props> = React.memo(
  ({page, pageIndex, readonly, isDragItem}) => {
    const dispatch = useDispatch();

    const mainElement = useRef<HTMLDivElement | null>(null);

    const [{draggedPageIndex}, connectDragSource, connectDragPreview] = useDrag({
      type: DndTypes.UNIT_PAGE,
      item: () => {
        dispatch(dragPreviewCreate());

        const elementWidth = mainElement.current ? mainElement.current.clientWidth : defaultElWidth;

        return {
          page,
          pageIndex,
          elementWidth,
          initialPageIndex: pageIndex
        };
      },
      collect: monitor => {
        const item: UnitPageDragObject = monitor.getItem();
        const itemType = monitor.getItemType();

        return {
          isDragging: monitor.isDragging(),
          draggedPageIndex: item && itemType === DndTypes.UNIT_PAGE ? item.pageIndex : undefined
        };
      },
      end: (item: UnitPageDragObject) => {
        if (item.initialPageIndex !== item.pageIndex) {
          // if position of page changed after it was dropped, add drag preview as new revision in history
          dispatch(dragPreviewApprove());
        } else {
          // else pretend nothing happened
          dispatch(dragPreviewDiscard());
        }
      }
    });

    const [, connectDropTarget] = useDrop({
      accept: DndTypes.UNIT_PAGE,
      hover: (item: UnitPageDragObject, monitor: DropTargetMonitor) => {
        if (!mainElement.current) return;

        const dragIndex = item.pageIndex;
        const hoverIndex = pageIndex;

        if (dragIndex === hoverIndex) {
          return;
        }

        const cursorInTopHalfOfEl = isCursorInTopHalfOfElement(mainElement.current, monitor);
        const cursorInBottomHalfOfEl = !cursorInTopHalfOfEl;
        const draggingDownwards = dragIndex < hoverIndex;
        const draggingUpwards = !draggingDownwards;

        if (
          (draggingDownwards && cursorInTopHalfOfEl) ||
          (draggingUpwards && cursorInBottomHalfOfEl)
        ) {
          return;
        }

        dndThrottle.throttleAction(() => {
          item.pageIndex = hoverIndex;
          dispatch(dragPreviewMovePage(dragIndex, hoverIndex));
        });
      }
    });

    useDndEmptyImage(connectDragPreview);

    const getRef = useCallback((el: HTMLDivElement | null) => (mainElement.current = el), []);

    return (
      <UnitPageView
        page={page}
        pageIndex={pageIndex}
        readonly={readonly}
        isDragItem={isDragItem}
        getMainComponentRef={getRef}
        isInvisible={draggedPageIndex === pageIndex && !isDragItem}
        connectDragSource={connectDragSource}
        connectDropTarget={connectDropTarget}
      />
    );
  }
);
