import {type Action, type ReducersMapObject} from 'redux';
import {List, Map} from 'immutable';

import {type XWidgetProperties} from './widgets/interface';
import {type ExerciseMediaSource, type ExerciseMediaSources} from '../player/interface';
import {
  CHANGE_POSITION_MEDIA_SOURCE,
  CREATE_MEDIA_SOURCE,
  DELETE_MEDIA_SOURCE,
  UPDATE_MEDIA_SOURCE
} from './actions/mediaSources';
import {
  type ChangePositionMediaSourceAction,
  type CreateMediaSourceAction,
  type DeleteMediaSourceAction,
  type UpdateMediaSourceAction
} from './actions/interface';

const REDUCERS: ReducersMapObject = {
  [CREATE_MEDIA_SOURCE]: (state: ExerciseMediaSources, action: CreateMediaSourceAction) => {
    const newMediaResource = Map({
      id: action.payload.id,
      title: '',
      url: ''
    }) as ExerciseMediaSource;

    return state.push(newMediaResource);
  },
  [DELETE_MEDIA_SOURCE]: (state: ExerciseMediaSources, action: DeleteMediaSourceAction) => {
    return state.filter(source => source?.get('id') !== action.payload.id);
  },
  [UPDATE_MEDIA_SOURCE]: (state: ExerciseMediaSources, action: UpdateMediaSourceAction) => {
    const index = state.findIndex(source => source?.get('id') === action.payload.id);

    return state.update(index, source => source.set(action.payload.field, action.payload.value));
  },
  [CHANGE_POSITION_MEDIA_SOURCE]: (
    state: ExerciseMediaSources,
    action: ChangePositionMediaSourceAction
  ) => {
    return state.update(sources =>
      sources.withMutations(sources => {
        const start = sources.get(action.payload.startIndex);
        const end = sources.get(action.payload.endIndex);
        return sources.set(action.payload.endIndex, start).set(action.payload.startIndex, end);
      })
    );
  }
};

const initialState = List<ExerciseMediaSource>();

function xmediaSourcesReducer(
  state: ExerciseMediaSources = initialState,
  action: Action
): List<XWidgetProperties> {
  const reducer = REDUCERS[action.type];

  return reducer ? reducer(state, action) : state;
}

export default xmediaSourcesReducer;
