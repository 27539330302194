import React, {type FC} from 'react';
import {useLocation} from 'react-router-dom';

import {Feedback} from './Feedback';
import {FilterContextProvider} from './context/filter';

export const FeedbackPage: FC = () => {
  const {key, search} = useLocation();
  return (
    <FilterContextProvider search={search}>
      <Feedback locationKey={key} />
    </FilterContextProvider>
  );
};
