import {defineMessages} from 'react-intl';

export const coursebookMessages = defineMessages({
  UnitUpdateSuccessful: {
    id: 'Coursebook.UnitUpdateSuccessful'
  },
  UnitUpdateError: {
    id: 'Coursebook.UnitUpdateError'
  },
  UnitUpdatedByAnotherUserError: {
    id: 'Coursebook.UnitUpdatedByAnotherUserError'
  }
});
