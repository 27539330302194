import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {List} from 'immutable';

import {type AxiosResponseAction} from 'services/axios/interface';

import XExerciseMetaRecord from './widgets/XExerciseMetaRecord';
import {SELECT_CATEGORY, SELECT_LEVEL} from './actionTypes/xmeta';
import {
  REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE_SUCCESS,
  REQUEST_COURSEBOOK_UNIT_SUCCESS
} from './actionTypes/xeditor';
import {type CoursebookUnitExerciseResponse, type CoursebookUnitResponse} from './actions/xeditor';
import {type ExerciseMetaProperties} from './widgets/interface';
import {type CategorySelectAction, type LevelSelectAction} from './actions/interface';

const REDUCERS: ReducersMapObject = {
  [SELECT_CATEGORY]: (
    state: XExerciseMetaRecord,
    {categories}: CategorySelectAction
  ): ExerciseMetaProperties => {
    return state.set('categories', List(categories));
  },
  [SELECT_LEVEL]: (
    state: XExerciseMetaRecord,
    {levels}: LevelSelectAction
  ): ExerciseMetaProperties => {
    return state.set('levels', List(levels));
  },
  [REQUEST_COURSEBOOK_UNIT_SUCCESS]: (
    state: XExerciseMetaRecord,
    action: AxiosResponseAction<CoursebookUnitResponse>
  ): ExerciseMetaProperties => {
    const levels = action.payload.data.coursebook.languageLevelIds;
    return !levels ? state : state.set('levels', List(levels));
  },
  [REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE_SUCCESS]: (
    state: XExerciseMetaRecord,
    action: AxiosResponseAction<CoursebookUnitExerciseResponse>
  ): ExerciseMetaProperties => {
    const levels = action.payload.data.unit.coursebook.languageLevelIds;
    return !levels ? state : state.set('levels', List(levels));
  }
};

const initialState: XExerciseMetaRecord = new XExerciseMetaRecord({
  categories: [],
  levels: []
});

function xMetaReducer(
  state: ExerciseMetaProperties = initialState,
  action: Action
): ExerciseMetaProperties {
  const reducer: Reducer<ExerciseMetaProperties> = REDUCERS[action.type];
  return reducer ? reducer(state, action) : state;
}

export default xMetaReducer;
