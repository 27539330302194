import {defineMessages} from 'react-intl';

export const sectionHeadings = defineMessages({
  author: {
    id: `Exercise.Sidebar.SectionHeading.Author`
  },
  originalAuthor: {
    id: `Exercise.Sidebar.SectionHeading.OriginalAuthor`
  },
  textbooks: {
    id: `Exercise.Sidebar.SectionHeading.Textbooks`
  },
  type: {
    id: `Exercise.Sidebar.SectionHeading.Type`
  },
  contains: {
    id: `Exercise.Sidebar.SectionHeading.Contains`
  },
  categories: {
    id: `Exercise.Sidebar.SectionHeading.Categories`
  },
  levels: {
    id: `Exercise.Sidebar.SectionHeading.Levels`
  },
  grammar: {
    id: `Exercise.Sidebar.SectionHeading.Grammar`
  }
});

export const messages = defineMessages({
  textbooksCount: {
    id: `Exercise.Sidebar.TextbooksCount`
  },
  levelsPlaceholder: {
    id: `Exercise.Sidebar.LevelsPlaceholder`
  },
  grammarPlaceholder: {
    id: `Exercise.Sidebar.GrammarPlaceholder`
  },
  categoriesPlaceholder: {
    id: `Exercise.Sidebar.CategoriesPlaceholder`
  },
  allCategories: {
    id: `Exercise.Sidebar.AllCategories`
  },
  newCategory: {
    id: `Exercise.Sidebar.NewCategory`
  },
  audios: {
    id: `Exercise.Sidebar.ContainsAudio`
  },
  videos: {
    id: `Exercise.Sidebar.ContainsVideo`
  },
  images: {
    id: `Exercise.Sidebar.ContainsImages`
  },
  createCategorySuccess: {
    id: `Exercise.Sidebar.CreateCategorySuccess`
  },
  renameCategorySuccess: {
    id: `Exercise.Sidebar.RenameCategorySuccess`
  },
  deleteCategorySuccess: {
    id: `Exercise.Sidebar.DeleteCategorySuccess`
  }
});

export const xeditorSidebarErrors = defineMessages({
  blankCategory: {
    id: `Exercise.Sidebar.Error.BlankCategory`
  },
  longNameCategory: {
    id: `Exercise.Sidebar.Error.LongNameCategory`
  },
  categoryExists: {
    id: `Exercise.Sidebar.Error.CategoryExists`
  },
  toastrErrorMessage: {
    id: `Exercise.Sidebar.Error.ToastrError`
  },
  restrictedAction: {
    id: 'Common.RestrictedAction'
  }
});
