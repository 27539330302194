import React, {type FC, type ReactElement, useCallback, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {type DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState, type CoursebookUnit} from 'store/interface';
import Confirm from 'components/modals/Confirm';
import Icon from 'components/Icon';
import {type SectionRouteParams} from 'routes/Library/CoursebookPage/interface';
import {type UnitItemDragObject} from 'routes/Library/CoursebookPage/Unit/UnitExerciseList/interface';
import {dndThrottle} from 'routes/Library/CoursebookPage/state/DndThrottle';
import {copyUnit} from 'store/exercise/editor/actions/xeditor';
import {CLIPBOARD_MAX_UNIT_COUNT} from 'store/exercise/editor/clipboardReducer';

import * as toastr from '../../../toastr';
import UnitItemTitleIcon from '../views/UnitItemTitleIcon';
import {setEditedUnitId, setUnitIdToDelete} from '../action';
import {LibraryListAlignment} from '../../LibraryPageList/constants';
import LibraryPageListEl from '../../LibraryPageList/LibraryPageListEl';
import LibraryPageListElHandle from '../../LibraryPageList/LibraryPageListElHandle';
import {AnimationElement} from '../../../../routes/Library/CoursebookPage/AnimationElementsContext';
import {coursebookLibraryMessages} from '../../messages';
import {DndTypes} from '../../../dnd/interface';
import {useDndEmptyImage} from '../../../dnd/useDndEmptyImage';

interface Props extends CoursebookUnit {
  endDrag: () => void;
  getContainer: () => HTMLDivElement;
  moveItem: (dragSequence: number, hoverSequence: number, id: number) => void;
  processingRequest: boolean;
  showSpinner: boolean;
  openUnitPreview: () => void;
  elementId?: string;
  selectUnitId: (id: number) => void;
  confirmMessage: string | ReactElement;
}

export const UnitItem: FC<Props> = ({
  id,
  getContainer,
  ordinal,
  title,
  isRevision,
  processingRequest,
  showSpinner,
  elementId,
  sequence,
  endDrag,
  moveItem,
  openUnitPreview,
  selectUnitId,
  confirmMessage
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const mainEl = useRef<HTMLDivElement | null>(null);
  const {unitId} = useParams<SectionRouteParams>();

  const intl = useIntl();
  const dispatch = useDispatch();

  const clipboardUnitsLength = useSelector((s: AppState) => s.clipboard.units.length);
  const wasCopiedToClipboard = useSelector((s: AppState) => s.clipboard.units.includes(id));
  const shouldConfirmBeforeSelecting = useSelector((s: AppState) => {
    const coursebookPage = s.coursebookPage;
    if (!coursebookPage) return false;
    const {
      unit: unitState,
      sections: {unitSectionContents: contentsState}
    } = coursebookPage;
    const exercisesHistoryNotEmpty = Boolean(
      unitState && (unitState.hasRedos || unitState.hasUndos)
    );
    const contentsHistoryNotEmpty = Boolean(contentsState && contentsState.valueChanged);

    return exercisesHistoryNotEmpty || contentsHistoryNotEmpty;
  });

  const unitNotEmpty = useSelector((s: AppState) => {
    const units = s.coursebookPage?.units;
    const overview = units?.find(unit => unit.id === id)?.overview;
    return !!overview?.mainExercisesCount || !!overview?.extraExercisesCount;
  });

  const [{dragItemId}, connectDragSource, connectDragPreview] = useDrag({
    type: DndTypes.UNIT_ITEM,
    item: (): UnitItemDragObject => {
      return {
        id,
        isRevision,
        elementWidth: mainEl.current ? mainEl.current.clientWidth : LibraryPageListEl.defaultWidth,
        ordinal,
        sequence,
        title
      };
    },
    collect: monitor => {
      const item = monitor.getItem<UnitItemDragObject>();
      return {
        dragItemSequence: item ? item.sequence : null, // TODO: remove me?
        dragItemId: item ? item.id : undefined
      };
    },
    end: () => endDrag()
  });
  const [, connectDropTarget] = useDrop({
    accept: DndTypes.UNIT_ITEM,
    hover: (item: UnitItemDragObject, monitor: DropTargetMonitor) => {
      if (sequence === item.sequence) {
        return;
      }
      throttle(() => move(mainEl.current, monitor, sequence, moveItem));
    }
  });
  const editUnitId = useCallback((id: number) => dispatch(setEditedUnitId(id)), [dispatch]);
  const unitIdToDelete = useCallback((id: number) => dispatch(setUnitIdToDelete(id)), [dispatch]);
  const clipboardCopyUnitId = useCallback((id: number) => dispatch(copyUnit(id)), [dispatch]);
  const selectUnit = useCallback(() => {
    showConfirm && setShowConfirm(false);
    selectUnitId(id);
  }, [id, selectUnitId, showConfirm]);
  const handleUnitItemClick = useCallback(() => {
    if (shouldConfirmBeforeSelecting) {
      setShowConfirm(true);
    } else {
      selectUnit();
    }
  }, [selectUnit, shouldConfirmBeforeSelecting]);
  const invokeDeleteModal = useCallback(() => unitIdToDelete(id), [id, unitIdToDelete]);
  const invokeRenameModal = useCallback(() => editUnitId(id), [id, editUnitId]);
  const copyToClipboard = useCallback(() => {
    if (wasCopiedToClipboard) return;

    if (clipboardUnitsLength >= CLIPBOARD_MAX_UNIT_COUNT) {
      return toastr.error(
        '',
        intl.formatMessage(coursebookLibraryMessages.ExceedUnits, {
          limit: CLIPBOARD_MAX_UNIT_COUNT
        })
      );
    }

    clipboardCopyUnitId(id);
  }, [clipboardUnitsLength, clipboardCopyUnitId, id, intl, wasCopiedToClipboard]);

  const onClickPreviewUnit = useCallback(() => {
    return unitNotEmpty
      ? openUnitPreview()
      : toastr.error('', intl.formatMessage({id: 'Coursebook.Unit.EmptyError'}));
  }, [intl, openUnitPreview, unitNotEmpty]);

  const getDropdownButtons = useMemo(
    () => [
      <MenuItem onClick={onClickPreviewUnit} key="1">
        <Icon name="play-circle" />
        <FormattedMessage id="Coursebook.UnitsList.Actions.Open" />
      </MenuItem>,
      <MenuItem onClick={invokeRenameModal} key="2">
        <Icon name="pencil" />
        <FormattedMessage id="Common.Rename" />
      </MenuItem>,
      <MenuItem disabled={wasCopiedToClipboard} onClick={copyToClipboard} key="3">
        <Icon name="clipboard" />
        <FormattedMessage id="Coursebook.CopyToClipboard" />
      </MenuItem>,
      <MenuItem onClick={invokeDeleteModal} key="4">
        <Icon name="trash" />
        <FormattedMessage id="Common.Delete" />
      </MenuItem>
    ],
    [
      copyToClipboard,
      invokeDeleteModal,
      invokeRenameModal,
      onClickPreviewUnit,
      wasCopiedToClipboard
    ]
  );

  const declineUnitSelect = useCallback(() => setShowConfirm(false), []);

  const getRef = useCallback((el: HTMLDivElement | null) => (mainEl.current = el), []);

  useDndEmptyImage(connectDragPreview);

  const dragging = dragItemId === undefined ? false : dragItemId === id;
  const isActive = unitId !== undefined && id === Number(unitId);

  return (
    <>
      <AnimationElement elementId={id}>
        {({elements, animated, onAnimationEnd}) => {
          const [first] = elements || [];

          const withAutoScroll = animated && first === id;

          return (
            <LibraryPageListEl
              elementId={elementId}
              dropdownId={`unit-actions-${id}`}
              dragItemId={dragItemId}
              dragging={dragging}
              getContainer={getContainer}
              isActive={isActive}
              processingRequest={processingRequest}
              title={title}
              handleClick={handleUnitItemClick}
              dropdownButtons={getDropdownButtons}
              alignment={LibraryListAlignment.LEFT}
              getRef={getRef}
              connectDropTarget={connectDropTarget}
              animated={animated}
              withAutoScroll={withAutoScroll}
              onAnimationEnd={onAnimationEnd}
              renderHandle={() => <LibraryPageListElHandle connectDragSource={connectDragSource} />}
            >
              <UnitItemTitleIcon
                showSpinner={showSpinner}
                isRevision={isRevision}
                ordinal={ordinal}
              />
            </LibraryPageListEl>
          );
        }}
      </AnimationElement>
      <Confirm
        show={showConfirm}
        onAccept={selectUnit}
        onDecline={declineUnitSelect}
        headerText={confirmMessage}
        disableButtons={false}
      />
    </>
  );
};

function throttle(callback: () => void) {
  dndThrottle.throttleAction(callback);
}

function move(
  element: Element | null,
  monitor: DropTargetMonitor,
  sequence: number,
  moveItem: Props['moveItem']
) {
  const item = monitor.getItem<UnitItemDragObject>();
  const clientOffset = monitor.getClientOffset();
  if (!item || !clientOffset) {
    return;
  }
  const [dragSequence, dragId] = [item.sequence, item.id];
  const hoverSequence = sequence;
  const neighbours = Math.abs(hoverSequence - dragSequence) === 1;
  const hoverBox = element?.getBoundingClientRect();
  if (!hoverBox) return;
  const hoverMiddleY = (hoverBox.bottom - hoverBox.top) / 2;
  const hoverClientY = clientOffset.y - hoverBox.top;

  if (dragSequence > hoverSequence && hoverClientY > hoverMiddleY && neighbours) {
    return;
  }
  if (dragSequence < hoverSequence && hoverClientY < hoverMiddleY && neighbours) {
    return;
  }
  item.sequence = hoverSequence;
  moveItem(dragSequence, hoverSequence, dragId);
}
