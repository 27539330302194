import {produce, type Draft} from 'immer';
import {type Action} from 'redux';

import {type XIntroState} from './interface';
import {
  type LoadIntroDataAction,
  IntoActionTypes,
  type ChangeIntroAction,
  type SetValidationErrorAction
} from './actions';
import {IntroRecord} from './IntroRecord';

const initialState: XIntroState = {
  loading: true,
  error: false,
  saving: false
};

type DraftState = Draft<XIntroState>;

const handlers = {
  [IntoActionTypes.LoadIntroData]: () => {
    return initialState;
  },
  [IntoActionTypes.LoadIntroDataSuccess]: (
    draft: DraftState,
    {payload: {coursebook, unit}}: LoadIntroDataAction
  ) => {
    draft.loading = false;
    draft.coursebook = coursebook;
    draft.unit = unit;
    draft.intro = unit.intro ? new IntroRecord(unit.intro) : IntroRecord.create();
  },
  [IntoActionTypes.LoadIntroDataError]: (draft: DraftState) => {
    draft.loading = false;
    draft.error = true;
  },
  [IntoActionTypes.ChangeIntro]: (draft: DraftState, {payload}: ChangeIntroAction) => {
    draft.intro = draft.intro!.change(payload);
    draft.validationError = undefined;
  },
  [IntoActionTypes.SaveIntro]: (draft: DraftState) => {
    draft.saving = true;
  },
  [IntoActionTypes.SaveIntroSuccess]: (draft: DraftState) => {
    draft.saving = false;
  },
  [IntoActionTypes.SaveIntroError]: (draft: DraftState) => {
    draft.saving = false;
  },
  [IntoActionTypes.SetValidationError]: (
    draft: DraftState,
    {payload: {error}}: SetValidationErrorAction
  ) => {
    draft.validationError = error;
  },
  [IntoActionTypes.Reset]: () => {
    return initialState;
  }
};

const introReducer = produce((draft: DraftState, action: Action) => {
  return handlers[action.type]?.(draft, action);
}, initialState);

export default introReducer;
