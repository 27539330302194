import React from 'react';
import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import {type UserV2} from '../../../../components/CoursebookLibrary/interface';
import UnitExerciseInfo from '../Unit/UnitExerciseList/views/UnitExerciseInfo';

interface OwnProps {
  pageIndex: number;
  parentIndex: number;
  index: number;
}

interface StateProps {
  excerpt: string;
  lockedBy?: UserV2;
}

interface Props extends OwnProps, StateProps {}

class InsideItemUnitExerciseSupplementary extends React.Component<Props> {
  public render() {
    const {excerpt, lockedBy} = this.props;
    return (
      <UnitExerciseInfo
        excerpt={excerpt}
        deleteUnitExercise={this.emptyFunction}
        viewExercise={this.emptyFunction}
        lockedBy={lockedBy && `${lockedBy.profile.lastName} ${lockedBy.profile.firstName}`}
        hideDropdown={true}
      />
    );
  }

  private emptyFunction = () => null;
}

const mapStateToProps = (
  state: AppState,
  {pageIndex, parentIndex, index}: OwnProps
): StateProps => {
  const unitExercise = state
    .coursebookPage!.unit!.current.pages.get(pageIndex)
    .unitExerciseList.get(parentIndex)
    .supplementaryUnitExerciseList.get(index);
  return {
    excerpt: unitExercise.excerpt,
    lockedBy: unitExercise.lockedBy
  };
};

export default connect(mapStateToProps)(InsideItemUnitExerciseSupplementary);
