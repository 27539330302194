import React, {useCallback} from 'react';
import Select, {components, type InputActionMeta, type InputProps} from 'react-select';
import {type MessageDescriptor, useIntl} from 'react-intl';

import DropdownIndicator from './DropdownIndicator';
import {type OptionsType, type OptionType, type OptionTypeBase, type ValueType} from './interface';
import {reactSelectMessages} from './i18n';

type Fn = () => string | null;

interface Props<V = string> {
  defaultValue?: OptionsType<OptionType>;
  noOptionsMessage?: MessageDescriptor | Fn;
  onChange: (value: string) => void;
  onOptionSelected?: (option: OptionType<V>) => void;
  options: OptionsType<OptionType<V>>;
  placeholder?: MessageDescriptor;
  inputValue?: string;
  value?: ValueType<OptionTypeBase, boolean>;
  customFilter?: (options: OptionsType<OptionType<V>>) => OptionsType<OptionType<V>>;
  isOptionSelected?: (option: OptionType<V>) => boolean;
  isDisabled?: boolean;
  controlShouldRenderValue?: boolean;
}

const Input = (props: InputProps) => <components.Input {...props} isHidden={false} />;

// special case of Select where value is of type string and entered through text input;
// options are just suggestions for this text input;
function EnglexSuggest<V = string>({
  value,
  defaultValue,
  onChange,
  options,
  placeholder,
  inputValue,
  isDisabled,
  noOptionsMessage,
  customFilter,
  onOptionSelected,
  controlShouldRenderValue
}: Props<V>) {
  const intl = useIntl();

  const onInputChanged = useCallback(
    (newValue: string, actionMeta: InputActionMeta) => {
      if (actionMeta.action !== 'input-blur' && actionMeta.action !== 'menu-close') {
        onChange(newValue);
      }
    },
    [onChange]
  );

  const thisOnOptionSelected = useCallback(
    (selectedOption: OptionType<V>) => {
      if (selectedOption.value) {
        onChange(String(selectedOption.value));
      }
    },
    [onChange]
  );

  const noOptions = useCallback(
    () =>
      typeof noOptionsMessage === 'function'
        ? noOptionsMessage()
        : intl.formatMessage(noOptionsMessage || reactSelectMessages.noOptionsMessage),
    [intl, noOptionsMessage]
  );
  return (
    <Select
      defaultValue={defaultValue}
      isMulti={false}
      options={customFilter ? customFilter(options) : options}
      isClearable={false}
      className="react-select"
      classNamePrefix="rs"
      noOptionsMessage={noOptions}
      placeholder={intl.formatMessage(placeholder || reactSelectMessages.placeholder)}
      onChange={onOptionSelected || thisOnOptionSelected}
      onInputChange={onInputChanged}
      inputValue={inputValue}
      value={value}
      blurInputOnSelect={false}
      isDisabled={isDisabled}
      components={{DropdownIndicator, Input}}
      controlShouldRenderValue={controlShouldRenderValue}
      // if null doesn't applies filter, if undefined applies default filter
      filterOption={customFilter ? null : undefined}
    />
  );
}

export default EnglexSuggest;
