import {defineMessages} from 'react-intl';

export const exerciseListMessages = defineMessages({
  DeleteExerciseConfirm: {
    id: 'Coursebook.DeleteExerciseConfirm'
  },
  ChangeUnitConfirm: {
    id: 'Coursebook.ChangeUnitConfirm'
  },
  LeavePageConfirm: {
    id: 'Coursebook.LeavePageConfirm'
  },
  UnitCoverAttachError: {
    id: 'Coursebook.Unit.CoverAttachError'
  },
  UnitCoverDeleteError: {
    id: 'Coursebook.Unit.CoverDeleteError'
  },
  CoursebookCoverDeleteConfirm: {
    id: 'Coursebook.Unit.CoverDeleteConfirm'
  },
  ClipboardUnsavedChanges: {
    id: 'Coursebook.CopyToClipboard.UnsavedChanges'
  },
  PasteExercisesError: {
    id: 'Coursebook.CopyToClipboard.PasteExercisesError'
  },
  ExceedExercises: {
    id: 'Coursebook.CopyToClipboard.ExceedExercises'
  }
});
