import React, {
  type FC,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import PanelGroup from 'react-bootstrap/lib/PanelGroup';
import Scrollbars from 'react-custom-scrollbars';

import {type AxiosResponseAction} from 'services/axios/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import * as toastr from 'components/toastr';
import Loader from 'components/Loader';
import InfiniteScroll from 'components/InfiniteScroll';
import {type SelectCallback} from 'components/interface';
import DateSeparator from 'components/DateSeparator/DateSeparator';

import FeedbackItem from './components/FeedbackItem';
import {type ExerciseFeedback, type ExerciseFeedbackDetailed} from './interface';
import {getIssue, requestIssueList} from './actions';
import {pageSize} from './static';
import PanelHeading from './components/PanelHeading';
import {Header} from './components/Header';
import {FilterContext} from './context/filter';
import './FeedbackPage.scss';

export const Feedback: FC<{locationKey?: string}> = ({locationKey}) => {
  const intl = useIntl();
  const axiosDispatch = useAxiosDispatch();
  const {showProcessed, selectedCoursebookIds} = useContext(FilterContext);

  const [activeKey, setActiveKey] = useState<number | null>(null);
  const [deletingId, setDeletingId] = useState<number | null>(null);
  const [feedbacks, setFeedbacks] = useState<ExerciseFeedback[] | undefined>();
  const [feedback, setFeedback] = useState<ExerciseFeedbackDetailed | undefined>();
  const [openingKey, setOpeningKey] = useState<number | null>(null);

  const nextPage = useRef(1);
  const hasMore = useRef(true);
  const prevDate = useRef<string | null>(null);
  const locationKeyRef = useRef(locationKey);

  const onSelect = useCallback(
    (eventKey: number | null) => {
      if (!eventKey) {
        setFeedback(undefined);
        setActiveKey(null);
        return;
      }
      setOpeningKey(eventKey);
      axiosDispatch<AxiosResponseAction<ExerciseFeedbackDetailed>>(getIssue(eventKey))
        .then(({payload: {data}}) => {
          setFeedback(data);
          setActiveKey(eventKey);
        })
        .catch()
        .finally(() => setOpeningKey(null));
    },
    [axiosDispatch]
  );

  const onProcess = useCallback(
    (id: number, processedAt: string | null) => {
      if (feedbacks) {
        if (processedAt && !showProcessed) {
          setFeedbacks(feedbacks.filter(el => el.id !== id));
        } else {
          setFeedbacks(feedbacks.map(el => (el.id === id ? {...el, processedAt} : el)));
        }
      }
      if (feedback && feedback.id === id) setFeedback({...feedback, processedAt});
    },
    [feedback, feedbacks, showProcessed]
  );

  const onDeleted = useCallback(
    (id: number, from404?: true) => {
      if (feedbacks) setFeedbacks(feedbacks.filter(el => el.id !== id));
      if (feedback && feedback.id === id) {
        setFeedback(undefined);
        setActiveKey(null);
      }
      if (!from404) toastr.success('', intl.formatMessage({id: 'Feedback.DeleteSuccessToastr'}));
      setDeletingId(null);
    },
    [intl, feedback, feedbacks]
  );

  const onDelete = useCallback(
    (id: number, from404?: true) => {
      from404 ? onDeleted(id, true) : setDeletingId(id);
    },
    [onDeleted]
  );

  const loadMore = useCallback(() => {
    return axiosDispatch<AxiosResponseAction<ExerciseFeedback[]>>(
      requestIssueList(nextPage.current, {showProcessed, coursebooks: selectedCoursebookIds})
    )
      .then(({payload: {data}}) => {
        if (data.length < pageSize) hasMore.current = false;
        nextPage.current++;
        setFeedbacks(feedbacks ? feedbacks.concat(data) : data);
        return true;
      })
      .catch();
  }, [axiosDispatch, feedbacks, selectedCoursebookIds, showProcessed]);

  useEffect(() => {
    if (locationKey && locationKey !== locationKeyRef.current && !deletingId && !openingKey) {
      locationKeyRef.current = locationKey;
      nextPage.current = 1;
      hasMore.current = true;
      prevDate.current = null;

      setActiveKey(null);
      setFeedbacks(undefined);
      setFeedback(undefined);
    }
  }, [deletingId, locationKey, openingKey, showProcessed]);

  useEffect(() => {
    prevDate.current = null;
  });

  return (
    <div className="feedback-page">
      <Header />
      <div className="feedback-page-content">
        <Scrollbars autoHide={true}>
          {!!feedbacks && !feedbacks.length ? (
            <div className="no-files">
              <FormattedMessage id="Feedback.ListIsEmpty" />
            </div>
          ) : (
            <InfiniteScroll
              hasMore={hasMore.current}
              initialLoad={true}
              loadMore={loadMore}
              loader={<Loader key="loader-key" />}
              pageStart={1}
              threshold={50}
              useWindow={false}
              withResizeDetector={true}
            >
              {!feedbacks ? null : (
                <PanelGroup
                  accordion={true}
                  id="feedback-accordion"
                  onSelect={onSelect as SelectCallback}
                  activeKey={activeKey}
                >
                  {feedbacks.map(f => {
                    const pd = prevDate.current ? new Date(prevDate.current) : undefined;
                    prevDate.current = f.createdAt;
                    return (
                      <Fragment key={f.id}>
                        <DateSeparator date={new Date(f.createdAt)} prevDate={pd} />
                        <FeedbackItem
                          activeKey={activeKey}
                          beingDeleted={deletingId === f.id}
                          eventKey={f.id}
                          feedback={f}
                          details={feedback && f.id === feedback.id ? feedback.details : undefined}
                          onDeleted={onDeleted}
                        >
                          <PanelHeading
                            id={f.id}
                            date={f.createdAt}
                            isOpening={f.id === openingKey}
                            name={`${f.createdBy.profile.lastName} ${f.createdBy.profile.firstName}`}
                            onDelete={onDelete}
                            onProcess={onProcess}
                            processedAt={f.processedAt}
                          />
                        </FeedbackItem>
                      </Fragment>
                    );
                  })}
                </PanelGroup>
              )}
            </InfiniteScroll>
          )}
        </Scrollbars>
      </div>
    </div>
  );
};
