import React, {type FC} from 'react';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import {useParams} from 'react-router-dom';
import {injectIntl, type WrappedComponentProps} from 'react-intl';

import {push} from 'store/router';
import * as toastr from 'components/toastr';
import {
  loadCoursebookData,
  type LoadCoursebookDataResponseAction
} from 'components/CoursebookLibrary/actions/action';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import {type AxiosResponseAction} from 'services/axios/interface';
import {requestCoursebookSections} from 'components/CoursebookContentsViewer/action';
import {coursebookPath, unselectedSectionPath} from 'common/paths';
import {setCoursebookUnits} from 'components/CoursebookLibrary/UnitsList/action';
import {type AppState, type CoursebookSection, type CoursebookUnit} from 'store/interface';

import HeaderView from '../views/Header';
import {
  contentsEditSaved,
  type CreateUnitSectionContentsProps,
  createUnitSectionContentsRequest,
  type DeleteUnitSectionContentsProps,
  deleteUnitSectionContentsRequest,
  editUnitSectionContentsRequest,
  openCoursebookSectionModal,
  requestUnitSectionContents,
  revertContentsEdit,
  setCoursebookSections,
  setUpdatingContents
} from '../actions/action';
import type ContentsRecord from '../ContentsRecord';
import {type SectionRouteParams} from '../../../interface';
import {sectionsMessages} from '../i18n';

interface OwnProps {
  reloadCoursebookInfo: () => void;
}

interface StateProps {
  unitSectionContents?: ContentsRecord;
  updatingContents?: boolean;
}

interface DispatchProps {
  revertContentsEdit: () => void;
  contentsEditSaved: () => void;
  setUpdatingContents: (isUpdating: boolean) => void;
  openCoursebookSectionModal: () => void;
  setUnits: (units: CoursebookUnit[]) => void;
  setCoursebookSections: (sections: CoursebookSection[]) => void;

  createUnitSectionContentsRequest: (props: CreateUnitSectionContentsProps) => Promise<Action>;
  deleteUnitSectionContentsRequest: (props: DeleteUnitSectionContentsProps) => Promise<Action>;
  editUnitSectionContentsRequest: (props: CreateUnitSectionContentsProps) => Promise<Action>;
  loadUnitsList: (coursebookId: string) => Promise<Action>;
  loadCoursebookSections: (coursebookId: string) => Promise<Action>;
  loadUnitSectionContents: (
    coursebookId: string,
    unitId: number,
    coursebookSectionId: number
  ) => Promise<Action>;
  push: (path: string) => void;
}

interface Props
  extends StateProps,
    DispatchProps,
    OwnProps,
    WrappedComponentProps,
    SectionRouteParams {}

class Header extends React.Component<Props> {
  private saveContentsRequest: CancellablePromise;
  private reloadUnitsRequest: CancellablePromise;
  private reloadSectionsRequest: CancellablePromise;
  private reloadUnitSectionContentsRequest: CancellablePromise;

  private static contentsValueChanged({unitSectionContents}: Props) {
    return Boolean(unitSectionContents && unitSectionContents.valueChanged);
  }

  public componentWillUnmount(): void {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    if (this.saveContentsRequest) {
      this.saveContentsRequest.cancel();
    }
    if (this.reloadUnitsRequest) {
      this.reloadUnitsRequest.cancel();
    }
    if (this.reloadSectionsRequest) {
      this.reloadSectionsRequest.cancel();
    }
    if (this.reloadUnitSectionContentsRequest) {
      this.reloadUnitSectionContentsRequest.cancel();
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (!Header.contentsValueChanged(prevProps) && Header.contentsValueChanged(this.props)) {
      window.addEventListener('beforeunload', this.beforeUnloadHandler);
    }
    if (Header.contentsValueChanged(prevProps) && !Header.contentsValueChanged(this.props)) {
      window.removeEventListener('beforeunload', this.beforeUnloadHandler);
    }
  }

  public render() {
    return (
      <HeaderView
        showButtons={Header.contentsValueChanged(this.props)}
        isLoading={this.props.updatingContents}
        onCancelClick={this.props.revertContentsEdit}
        onSaveClick={this.saveUnitSectionContents}
        onAddSectionClick={this.props.openCoursebookSectionModal}
      />
    );
  }

  private beforeUnloadHandler = (event: Event) => {
    event.returnValue = true;
  };

  private saveUnitSectionContents = () => {
    this.props.setUpdatingContents(true);
    const promise = this.saveRequestSwitcher();
    if (promise) {
      this.saveContentsRequest = makeCancellable(
        promise,
        this.handleSaveRequestSuccess,
        this.handleSaveRequestFail
      );
    }
  };

  private saveRequestSwitcher = () => {
    const contents = this.props.unitSectionContents!;
    const {coursebookSectionId, unitId, coursebookId} = this.props;

    if (contents.initialValueEmpty && !contents.valueEmpty) {
      return this.props.createUnitSectionContentsRequest({
        coursebookId,
        unitId: Number(unitId),
        coursebookSectionId: Number(coursebookSectionId),
        value: contents.value.toJSON()
      });
    }

    if (!contents.initialValueEmpty && contents.valueEmpty) {
      return this.props.deleteUnitSectionContentsRequest({
        coursebookId,
        unitId: Number(unitId),
        coursebookSectionId: Number(coursebookSectionId)
      });
    }

    if (!contents.initialValueEmpty && !contents.valueEmpty) {
      return this.props.editUnitSectionContentsRequest({
        coursebookId,
        unitId: Number(unitId),
        coursebookSectionId: Number(coursebookSectionId),
        value: contents.value.toJSON()
      });
    }

    return null;
  };

  private handleSaveRequestSuccess = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    this.props.setUpdatingContents(false);
    this.props.contentsEditSaved();
    // reload coursebook info so 'open contents' in coursebook dropdown can be disabled or enabled if needed
    this.props.reloadCoursebookInfo();
    toastr.success('', formatMessage(sectionsMessages.ContentChangedSuccessText));
  };

  private handleSaveRequestFail = () => {
    const {loadUnitsList, coursebookId} = this.props;
    this.reloadUnitsRequest = makeCancellable(
      loadUnitsList(coursebookId),
      this.handleUnitsRequestSuccess,
      this.handleUnknownRequestError
    );
  };

  private handleUnitsRequestSuccess = (action: LoadCoursebookDataResponseAction) => {
    const {unitId, coursebookId, setUnits, loadCoursebookSections} = this.props;
    setUnits(action.payload.data.units);
    const activeUnitWasDeleted = !action.payload.data.units.find(
      unit => unit.id === Number(unitId)
    );
    if (activeUnitWasDeleted) {
      this.handleNoSelectedUnitOrSectionError(coursebookPath(coursebookId));
    } else {
      this.reloadSectionsRequest = makeCancellable(
        loadCoursebookSections(coursebookId),
        this.handleSectionsRequestSuccess,
        this.handleUnknownRequestError
      );
    }
  };

  private handleSectionsRequestSuccess = (action: AxiosResponseAction<CoursebookSection[]>) => {
    const {coursebookSectionId, unitId, coursebookId, loadUnitSectionContents} = this.props;
    this.props.setCoursebookSections(action.payload.data);
    const selectedCoursebookSectionDeleted = !action.payload.data.find(
      coursebookSection => coursebookSection.id === Number(coursebookSectionId)
    );
    if (selectedCoursebookSectionDeleted) {
      this.handleNoSelectedUnitOrSectionError(unselectedSectionPath(coursebookId, Number(unitId)));
    } else {
      this.reloadUnitSectionContentsRequest = makeCancellable(
        loadUnitSectionContents(coursebookId, Number(unitId), Number(coursebookSectionId)),
        this.handleUnitSectionContentsRequestSuccess,
        this.handleUnknownRequestError
      );
    }
  };

  private handleUnitSectionContentsRequestSuccess = () => {
    this.handleUnknownRequestError();
  };

  private handleUnknownRequestError = () => {
    this.props.setUpdatingContents(false);
    toastr.error('', this.props.intl.formatMessage(sectionsMessages.SaveContentsOtherError));
  };

  private handleNoSelectedUnitOrSectionError = (newPath: string) => {
    this.props.setUpdatingContents(false);
    this.props.push(newPath);
    toastr.error(
      '',
      this.props.intl.formatMessage(sectionsMessages.SaveContentsNoUnitOrSectionError)
    );
  };
}

const mapStateToProps = (state: AppState): StateProps => ({
  unitSectionContents: state.coursebookPage!.sections.unitSectionContents,
  updatingContents: state.coursebookPage!.sections.updatingContents
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  revertContentsEdit: () => dispatch(revertContentsEdit()),
  setUpdatingContents: (isUpdating: boolean) => dispatch(setUpdatingContents(isUpdating)),
  createUnitSectionContentsRequest: (props: CreateUnitSectionContentsProps) =>
    dispatch(createUnitSectionContentsRequest(props)),
  deleteUnitSectionContentsRequest: (props: DeleteUnitSectionContentsProps) =>
    dispatch(deleteUnitSectionContentsRequest(props)),
  editUnitSectionContentsRequest: (props: CreateUnitSectionContentsProps) =>
    dispatch(editUnitSectionContentsRequest(props)),
  contentsEditSaved: () => dispatch(contentsEditSaved()),
  openCoursebookSectionModal: () => dispatch(openCoursebookSectionModal()),
  loadUnitsList: (coursebookId: string) => dispatch(loadCoursebookData(coursebookId)),
  loadCoursebookSections: (coursebookId: string) =>
    dispatch(requestCoursebookSections(coursebookId)),
  setUnits: (units: CoursebookUnit[]) => dispatch(setCoursebookUnits(units)),
  setCoursebookSections: (sections: CoursebookSection[]) =>
    dispatch(setCoursebookSections(sections)),
  loadUnitSectionContents: (coursebookId: string, unitId: number, coursebookSectionId: number) =>
    dispatch(requestUnitSectionContents(coursebookId, unitId, coursebookSectionId)),
  push: (path: string) => dispatch(push(path))
});

const ConnectedHeader = injectIntl(connect(mapStateToProps, mapDispatchToProps)(Header));

const UnitSectionsHeader: FC<OwnProps> = props => {
  const {coursebookId, unitId, coursebookSectionId} = useParams<SectionRouteParams>();

  return (
    <ConnectedHeader
      {...props}
      coursebookSectionId={coursebookSectionId}
      coursebookId={coursebookId!}
      unitId={unitId}
    />
  );
};

export default UnitSectionsHeader;
