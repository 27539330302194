import {type Editor} from '@englex/slate';

import {type XWidgetAction} from '../../actions/interface';

const prefix = 'XWIDGET/';

export const CHANGE_QUIZ_BLOCK_TITLE = prefix + 'CHANGE_BLOCK_TITLE';
export const CHANGE_QUIZ_BLOCK_TEXT = prefix + 'CHANGE_BLOCK_TEXT';

export interface XQuizAction extends XWidgetAction {
  blockId: string;
  change: Editor;
}

export const changeBlockTitle = (
  xwidgetId: string,
  blockId: string,
  change: Editor
): XQuizAction => ({
  type: CHANGE_QUIZ_BLOCK_TITLE,
  xwidgetId,
  blockId,
  change
});

export const changeBlockText = (
  xwidgetId: string,
  blockId: string,
  change: Editor
): XQuizAction => ({
  type: CHANGE_QUIZ_BLOCK_TEXT,
  xwidgetId,
  blockId,
  change
});
