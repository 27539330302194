import React, {
  type Dispatch,
  type FC,
  type ReducerAction,
  createContext,
  useReducer,
  useEffect,
  useCallback,
  useMemo,
  useRef
} from 'react';
import {useDispatch} from 'react-redux';

import {push} from 'store/router';

import {filterReducer, initialFilterState} from './reducer';
import {type FilterDispatchState, type FilterState} from './interface';
import {setSelectedCoursebookIds} from './actions';

export const FilterContext = createContext<FilterState>(initialFilterState);

type DispatchContext = FilterDispatchState<typeof filterReducer>;
export const FilterDispatchContext = createContext<DispatchContext>({} as DispatchContext);

export const FilterContextProvider: FC<{search: string}> = ({children, search}) => {
  const reduxDispatch = useDispatch();
  const [state, dispatch]: [FilterState, Dispatch<ReducerAction<typeof filterReducer>>] =
    useReducer(filterReducer, initialFilterState, initialState => {
      const searchParams = new URLSearchParams(search);
      const showProcessed = searchParams.get('showProcessed') === 'true';
      return {...initialState, showProcessed};
    });

  const initializedRef = useRef(false);

  const initSelectedCoursebookIds = useCallback(
    (coursebookIds: string[]) => {
      initializedRef.current = true;
      const searchParams = new URLSearchParams(search);
      const paramCoursebookIds = searchParams.get('coursebooks')?.split(',');
      if (!paramCoursebookIds?.length) return;
      let paramsAreValid = true;
      paramCoursebookIds.forEach(pci => {
        if (!coursebookIds.includes(pci)) {
          paramsAreValid = false;
        }
      });
      if (!paramsAreValid) {
        searchParams.delete('coursebooks');
        reduxDispatch(push({search: searchParams.toString()}));
      } else {
        dispatch(setSelectedCoursebookIds(paramCoursebookIds));
      }
    },
    [reduxDispatch, search]
  );

  useEffect(
    function mapStateToSearch() {
      const searchParams = new URLSearchParams(search);
      const initialSearchString = searchParams.toString();
      const showProcessed = searchParams.get('showProcessed') === 'true';

      if (showProcessed !== state.showProcessed) {
        state.showProcessed
          ? searchParams.set('showProcessed', 'true')
          : searchParams.delete('showProcessed');
      }

      const coursebookIds = searchParams.get('coursebooks');

      if (coursebookIds !== state.selectedCoursebookIds?.join(',') && initializedRef.current) {
        state.selectedCoursebookIds?.length
          ? searchParams.set('coursebooks', state.selectedCoursebookIds.join(','))
          : searchParams.delete('coursebooks');
      }

      const newSearchString = searchParams.toString();

      if (initialSearchString !== newSearchString) {
        reduxDispatch(push({search: newSearchString}));
      }
    },
    [reduxDispatch, search, state.selectedCoursebookIds, state.showProcessed]
  );

  return (
    <FilterContext.Provider value={state}>
      <FilterDispatchContext.Provider
        value={useMemo(() => ({dispatch, initSelectedCoursebookIds}), [initSelectedCoursebookIds])}
      >
        {children}
      </FilterDispatchContext.Provider>
    </FilterContext.Provider>
  );
};
