import {type Action} from 'redux';

import {LOCATION_CHANGE} from 'store/router';
import {type CoursebookSectionsState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  CHANGE_UNIT_SECTION_CONTENTS,
  CLOSE_COURSEBOOK_SECTION_MODAL,
  CONTENTS_EDIT_SAVED,
  OPEN_COURSEBOOK_SECTION_MODAL,
  RESET_SECTIONS_TAB,
  REVERT_CONTENTS_EDIT,
  SET_COURSEBOOK_SECTIONS,
  SET_UNIT_SECTION_INITIAL_CONTENTS,
  SET_UPDATING_CONTENTS
} from '../actions/actionTypes';
import {
  type ChangeUnitSectionContentsAction,
  type OpenCoursebookSectionModalAction,
  type SetCoursebookSectionsAction,
  type SetUnitSectionContentsAction,
  type SetUpdatingContentsAction
} from '../actions/action';
import ContentsRecord from '../ContentsRecord';

const ACTION_HANDLERS: ActionHandlersList<CoursebookSectionsState, Action> = {
  [RESET_SECTIONS_TAB]: (): CoursebookSectionsState => ({...defaultSectionsState}),
  [SET_COURSEBOOK_SECTIONS]: (
    state: CoursebookSectionsState,
    {sections}: SetCoursebookSectionsAction
  ): CoursebookSectionsState => ({
    ...state,
    coursebookSections: sections
  }),
  [SET_UNIT_SECTION_INITIAL_CONTENTS]: (
    state: CoursebookSectionsState,
    action: SetUnitSectionContentsAction
  ): CoursebookSectionsState => ({...state, unitSectionContents: new ContentsRecord(action.value)}),
  [CHANGE_UNIT_SECTION_CONTENTS]: (
    state: CoursebookSectionsState,
    {change}: ChangeUnitSectionContentsAction
  ): CoursebookSectionsState => ({
    ...state,
    unitSectionContents: state.unitSectionContents!.setNewValue(change.value)
  }),
  [REVERT_CONTENTS_EDIT]: (state: CoursebookSectionsState): CoursebookSectionsState => ({
    ...state,
    unitSectionContents: state.unitSectionContents!.revertEdits()
  }),
  [CONTENTS_EDIT_SAVED]: (state: CoursebookSectionsState): CoursebookSectionsState => ({
    ...state,
    unitSectionContents: state.unitSectionContents!.editSaved()
  }),
  [SET_UPDATING_CONTENTS]: (
    state: CoursebookSectionsState,
    action: SetUpdatingContentsAction
  ): CoursebookSectionsState => ({...state, updatingContents: action.isUpdating}),
  [OPEN_COURSEBOOK_SECTION_MODAL]: (
    state: CoursebookSectionsState,
    {editedCoursebookSectionId}: OpenCoursebookSectionModalAction
  ): CoursebookSectionsState => ({
    ...state,
    coursebookSectionModal: {
      ...state.coursebookSectionModal,
      show: true,
      editedCoursebookSectionId
    }
  }),
  [CLOSE_COURSEBOOK_SECTION_MODAL]: (state: CoursebookSectionsState): CoursebookSectionsState => ({
    ...state,
    coursebookSectionModal: {}
  }),
  [LOCATION_CHANGE]: (state: CoursebookSectionsState): CoursebookSectionsState => ({
    ...state,
    unitSectionContents: undefined
  })
};

export const defaultSectionsState: CoursebookSectionsState = {
  coursebookSectionModal: {}
};

export default (state: CoursebookSectionsState, action: Action): CoursebookSectionsState => {
  const reducer = ACTION_HANDLERS[action.type];
  return reducer ? reducer(state, action) : state;
};
