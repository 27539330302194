import {type Action} from 'redux';

import PreviewRecord from 'store/exercise/player/preview/PreviewRecord';
import xpreviewReducer from 'store/exercise/player/preview/previewReducer';
import {type ActionHandlersList} from 'store/reducers';
import {type ExerciseViewerState} from 'store/interface';
import ExerciseRecord from 'store/exercise/player/Exercise/ExerciseRecord';

import {
  CLOSE_EXERCISE_VIEWER,
  CREATE_VIEWER_PREVIEW,
  type CreateViewerPreviewAction,
  REMOVE_EXERCISE_VIEWER_ERROR,
  SET_EXERCISE_VIEWER_ERROR,
  VIEW_EXERCISE,
  type ViewExerciseAction
} from './action';

const ACTION_HANDLERS: ActionHandlersList<ExerciseViewerState, Action> = {
  [VIEW_EXERCISE]: (state: ExerciseViewerState, {id}: ViewExerciseAction) => ({
    ...state,
    viewedExercise: id
  }),
  [CLOSE_EXERCISE_VIEWER]: (state: ExerciseViewerState) => ({
    ...state,
    viewedExercise: undefined,
    xpreview: undefined
  }),
  [SET_EXERCISE_VIEWER_ERROR]: (state: ExerciseViewerState) => ({
    ...state,
    requestError: true
  }),
  [REMOVE_EXERCISE_VIEWER_ERROR]: (state: ExerciseViewerState) => ({
    ...state,
    requestError: undefined
  }),
  [CREATE_VIEWER_PREVIEW]: (state: ExerciseViewerState, action: CreateViewerPreviewAction) => ({
    ...state,
    xpreview: new PreviewRecord(ExerciseRecord.fromJSON(action.exercise), {
      addExerciseNumber: true
    })
  })
};

export const initialViewerState: ExerciseViewerState = {};

export default function (state: ExerciseViewerState, action: Action): ExerciseViewerState {
  const reducer = ACTION_HANDLERS[action.type];
  const newState = reducer ? reducer(state, action) : state;
  return {
    ...newState,
    xpreview: xpreviewReducer(newState.xpreview, action)
  };
}
