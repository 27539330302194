import React, {type FC, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import Confirm from '../../../../components/modals/Confirm';
import {sectionsMessages} from './Sections/i18n';
import {exerciseListMessages} from './UnitExerciseList/messages';
import {declineRedirect} from '../state/action';

interface Props {
  isSection?: boolean;
}

export const RedirectConfirm: FC<Props> = ({isSection}) => {
  const {formatMessage} = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const confirmRedirectToPath = useSelector(
    (state: AppState) => state.coursebookPage!.confirmRedirectToPath
  );

  const onAccept = useCallback(() => {
    navigate(confirmRedirectToPath!);
  }, [confirmRedirectToPath, navigate]);

  const onDecline = useCallback(() => dispatch(declineRedirect()), [dispatch]);
  const confirmMessage = isSection
    ? formatMessage(sectionsMessages.LeavePageConfirmMessage)
    : formatMessage(exerciseListMessages.LeavePageConfirm);
  return (
    <Confirm
      show={!!confirmRedirectToPath}
      onAccept={onAccept}
      onDecline={onDecline}
      headerText={confirmMessage}
      disableButtons={false}
    />
  );
};
