import React, {useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import * as toastr from 'components/toastr';
import {clearUnitsClipboard} from 'store/exercise/editor/actions/xeditor';
import {type AppState} from 'store/interface';

import {ButtonGroup} from '../../../ui/ButtonGroup/ButtonGroup';
import Icon from '../../../Icon';
import {copyUnits} from '../../actions/action';
import {coursebookLibraryMessages} from '../../messages';
import {AnimationActionsContext} from '../../../../routes/Library/CoursebookPage/AnimationElementsContext';

interface Props {
  className?: string;
}

export const PasteUnitsButton: React.FC<Props> = React.memo(({className}) => {
  const intl = useIntl();

  const dispatch: Dispatch<Action, AppState> = useDispatch();

  const {addElements} = useContext(AnimationActionsContext);

  const {coursebookId} = useParams<{coursebookId: string}>();

  const [loading, setLoading] = useState(false);

  const units = useSelector((state: AppState) => state.clipboard.units);

  const onDelete = useCallback(() => dispatch(clearUnitsClipboard()), [dispatch]);

  const onPaste = useCallback(async () => {
    try {
      const ids = await dispatch(copyUnits(coursebookId!, units));
      onDelete();
      addElements(ids);
    } catch (e) {
      toastr.error('', intl.formatMessage(coursebookLibraryMessages.PasteUnitsError));
    } finally {
      setLoading(false);
    }
  }, [dispatch, coursebookId, units, onDelete, addElements, intl]);

  if (units.length) {
    return (
      <ButtonGroup
        disabled={loading}
        className={className}
        onLeftClick={onPaste}
        onRightClick={onDelete}
        renderLeft={() => (
          <>
            <span>
              <Icon name="paste" />
            </span>
            <span>
              <FormattedMessage
                id="Coursebook.CopyToClipboard.Paste"
                values={{count: units.length}}
              />
            </span>
          </>
        )}
        renderRight={() => <Icon name="trash" />}
      />
    );
  }

  return null;
});
