import {defineMessages} from 'react-intl';

export const sectionsMessages = defineMessages({
  ContentsEditorPlaceholder: {
    id: 'Coursebook.Contents.Editor.Placeholder'
  },
  ContentChangedSuccessText: {
    id: 'Coursebook.Contents.EditSuccessText'
  },
  LeavePageConfirmMessage: {
    id: 'Coursebook.Contents.LeavePageConfirm'
  },
  ModalTitlePlaceholder: {
    id: 'Coursebook.Contents.Modal.TitlePlaceholder'
  },
  ModalCreateNewSectionOption: {
    id: 'Coursebook.Contents.Modal.CreateNewSectionOption'
  },
  ModalSectionAlreadyExists: {
    id: 'Coursebook.Contents.Modal.SectionAlreadyExists'
  },
  ValidationErrTitleEmpty: {
    id: 'Coursebook.Contents.Modal.EmptyTitleValidationError'
  },
  CreateSectionSuccessToast: {
    id: 'Coursebook.Contents.Modal.CreateSectionSuccessToast'
  },
  CreateSectionErrorToast: {
    id: 'Coursebook.Contents.Modal.CreateSectionErrorToast'
  },
  UpdateSectionSuccessToast: {
    id: 'Coursebook.Contents.Modal.UpdateSectionSuccessToast'
  },
  UpdateSectionErrorToast: {
    id: 'Coursebook.Contents.Modal.UpdateSectionErrorToast'
  },
  DeleteSectionConfirm: {
    id: 'Coursebook.Contents.DeleteSectionConfirm'
  },
  DeleteSectionSuccess: {
    id: 'Coursebook.Contents.DeleteSectionSuccess'
  },
  DeleteSectionError: {
    id: 'Coursebook.Contents.DeleteSectionError'
  },
  SaveContentsNoUnitOrSectionError: {
    id: 'Coursebook.Contents.SaveContentsNoUnitOrSectionError'
  },
  SaveContentsOtherError: {
    id: 'Coursebook.Contents.SaveContentsOtherError'
  },
  MoveCoursebookSectionSuccess: {
    id: 'Coursebook.Contents.CoursebookSection.SuccessfullyMoved'
  },
  MoveCoursebookSectionError: {
    id: 'Coursebook.Contents.CoursebookSection.MoveFailed'
  }
});
