import React, {type FC, useCallback, useState} from 'react';
import {injectIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import {UploadingPictureStatus} from 'common/ImageUpload/interface';
import Confirm from 'components/modals/Confirm';
import Icon from 'components/Icon';

import {
  type CoverUploaderProps,
  type ErrorHandler,
  type ResponseHandler,
  type Status
} from './interface';
import UploaderModal from './UploaderModal';
import './styles.scss';

const CoverUploader: FC<CoverUploaderProps> = ({
  aspect = 1,
  confirmHeaderText,
  coverExists,
  coverMinWidth,
  className,
  children,
  attachModule,
  detachModule
}) => {
  const [awaiting, setAwaiting] = useState(false);
  const [freeze, setFreeze] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState<Status | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const hideModal = useCallback(() => {
    if (!freeze) {
      setShowModal(false);
      setErrorMessage(undefined);
      setStatus(undefined);
    }
  }, [freeze]);

  const handleResponse = useCallback<ResponseHandler>(
    ({payload: {data}}) => {
      const {promiseCreator, resolve, reject} = attachModule;
      promiseCreator(data)
        .then(res => {
          resolve(res);
          setStatus(undefined);
          setShowModal(false);
        })
        .catch(rej => {
          reject(rej);
          setStatus(UploadingPictureStatus.UPLOADING_ERROR);
        })
        .finally(() => {
          setFreeze(false);
        });
    },
    [attachModule]
  );

  const handleError = useCallback<ErrorHandler>(({error: {response}}) => {
    if (response && [422, 400].includes(response.status)) {
      if (Array.isArray(response.data)) {
        setErrorMessage(response.data[0].message);
      } else if (response.data.message) {
        setErrorMessage(response.data.message);
      }
    }
    setFreeze(false);
    setStatus(UploadingPictureStatus.UPLOADING_ERROR);
  }, []);

  const detach = useCallback(() => {
    const {promiseCreator, resolve, reject} = detachModule;
    if (awaiting) return;
    setAwaiting(true);
    promiseCreator()
      .then(resolve)
      .catch(reject)
      .finally(() => {
        setAwaiting(false);
      });
  }, [awaiting, detachModule]);

  return (
    <div className={classNames('cover-uploader', className)}>
      <UploaderModal
        aspect={aspect}
        errorMessage={errorMessage}
        freeze={freeze}
        setFreeze={setFreeze}
        handleError={handleError}
        handleResponse={handleResponse}
        hideModal={hideModal}
        minWidth={coverMinWidth}
        setErrorMessage={setErrorMessage}
        setStatus={setStatus}
        status={status}
        show={showModal}
      />
      <div className="cover-uploader-overlay" onClick={() => setShowModal(true)}>
        <Confirm
          headerText={confirmHeaderText}
          show={showConfirm}
          onAccept={() => {
            detach();
            setShowConfirm(false);
          }}
          onDecline={() => setShowConfirm(false)}
          disableButtons={awaiting}
        />
        {coverExists ? (
          <Button
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              setShowConfirm(true);
            }}
            className="btn-ico"
          >
            <Icon name="trash" />
          </Button>
        ) : null}
        <Icon name="pencil" />
      </div>
      {children}
    </div>
  );
};

export default injectIntl(CoverUploader);
