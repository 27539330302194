import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {Map} from 'immutable';

import PreviewRecord from 'store/exercise/player/preview/PreviewRecord';
import xpreviewReducer from 'store/exercise/player/preview/previewReducer';
import ExerciseRecord from 'store/exercise/player/Exercise/ExerciseRecord';
import {type AxiosResponseAction} from 'services/axios/interface';
import {type ExerciseCategory} from 'store/interface';
import {type GrammarExerciseOptions} from 'store/exercise/player/interface';

import {XEditorStage, type XEditorState, XEditorTab} from './interface';
import xexerciseReducer from './xexerciseReducer';
import {
  CHANGE_STAGE,
  CHANGE_TAB,
  COURSEBOOK_UNIT_EXERCISE_CRITICAL_ERROR,
  COURSEBOOK_UNIT_EXERCISE_NOT_FOUND,
  RENAME_CATEGORY_FAIL,
  RENAME_CATEGORY_SUCCESS,
  REQUEST_CATEGORIES_FAIL,
  REQUEST_CATEGORIES_SUCCESS,
  REQUEST_COURSEBOOK_UNIT_EXERCISE,
  REQUEST_COURSEBOOK_UNIT_EXERCISE_FAIL,
  REQUEST_COURSEBOOK_UNIT_EXERCISE_SUCCESS,
  REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE_SUCCESS,
  REQUEST_COURSEBOOK_UNIT_SUCCESS,
  REQUEST_GRAMMAR_FAIL,
  REQUEST_GRAMMAR_SUCCESS,
  REQUEST_LEVELS_FAIL,
  REQUEST_LEVELS_SUCCESS,
  RESET,
  RESET_LOADING,
  SET_UNIT_EXERCISE,
  RESET_MEDIA_SOURCES_ERRORS,
  RESET_WIDGET_ERRORS,
  SET_WIDGET_ERROR,
  VALIDATION_ERRORS
} from './actionTypes/xeditor';
import {
  type CoursebookUnitExerciseResponse,
  type CoursebookUnitResponse,
  type RequestLevelsResponseAction,
  type SetUnitExerciseAction
} from './actions/xeditor';
import XExerciseRecord from './widgets/XExerciseRecord';
import {TOGGLE} from './actionTypes/xwizard';
import {type ToggleElementAction} from '../../../common/interface';
import xwizardReducer from './xwizardReducer';
import XWizardRecord from './widgets/XWizardRecord';
import {CHANGE_TITLE} from './actionTypes/xexercise';
import {CONTENT_CHANGE, TASK_CHANGE} from './actionTypes';
import {type XWidgetChangeAction} from './xwidgetActions';
import {
  XVOCABULARY_ADD_WORD,
  XVOCABULARY_SET_CATEGORY,
  XVOCABULARY_SET_ORIGINAL,
  XVOCABULARY_SET_TRANSLATION
} from './widgets/XVocabulary/actionTypes';
import xdraftExercisesReducer from './xdraftExercisesReducer';
import {draftExercisesActionCreator} from './actions/xdraftexercises';
import {DELETE_DRAFT_EXERCISE} from './actionTypes/xdraftexercises';
import {
  type DeleteDraftExerciseAction,
  type XEditorChangeTabAction,
  type XEditorClearMediaSourcesErrors,
  type XEditorStageAction,
  type XEditorValidationErrorAction,
  type XEditorWidgetErrorAction
} from './actions/interface';

const resetWidgetErrors = (state: XEditorState, action: XWidgetChangeAction) => {
  if (state.errors.hasIn(['widgets', action.xwidgetId])) {
    return {
      ...state,
      errors: state.errors.deleteIn(['widgets', action.xwidgetId])
    };
  }
  return state;
};

const resetMediaSourcesErrors = (state: XEditorState, action: XEditorClearMediaSourcesErrors) => {
  if (action.id) {
    return {
      ...state,
      errors: state.errors.removeIn(['mediaSources', action.id])
    };
  }

  return {
    ...state,
    errors: state.errors.setIn(['mediaSources'], Map())
  };
};

const REDUCERS: ReducersMapObject = {
  [CHANGE_TAB]: (state: XEditorState, action: XEditorChangeTabAction): XEditorState => {
    const exercise = new ExerciseRecord(state.xexercise.toJSON());
    return {
      ...state,
      activeTab: action.tab,
      xpreview:
        action.tab === 'preview'
          ? new PreviewRecord(exercise, {addExerciseNumber: true})
          : undefined
    };
  },
  [TOGGLE]: (state: XEditorState, action: ToggleElementAction): XEditorState => ({
    ...state,
    xwizard: action.show ? new XWizardRecord() : undefined
  }),
  [CHANGE_STAGE]: (state: XEditorState, action: XEditorStageAction): XEditorState => ({
    ...state,
    stage: action.stage,
    // clear errors on start validating
    errors: action.stage === XEditorStage.BLOCK_EDITING ? Map() : state.errors
  }),
  [RESET]: (state: XEditorState): XEditorState => ({
    ...initialState,
    draftExercises: state.draftExercises
  }),
  [VALIDATION_ERRORS]: (
    state: XEditorState,
    action: XEditorValidationErrorAction
  ): XEditorState => ({
    ...state,
    errors: action.errors
  }),
  [SET_WIDGET_ERROR]: (state: XEditorState, action: XEditorWidgetErrorAction): XEditorState => {
    return {
      ...state,
      errors: state.errors.update('widgets', errors => {
        const widgetErrors = Map.isMap(errors) ? errors : Map();

        return widgetErrors.set(action.xwidgetId, {message: action.message, meta: action.meta});
      })
    };
  },
  [CHANGE_TITLE]: (state: XEditorState): XEditorState => {
    if (state.errors.has('title') && state.stage === XEditorStage.EDITING) {
      return {
        ...state,
        errors: state.errors.delete('title')
      };
    }
    return state;
  },
  [CONTENT_CHANGE]: resetWidgetErrors,
  [TASK_CHANGE]: resetWidgetErrors,
  [XVOCABULARY_ADD_WORD]: resetWidgetErrors,
  [XVOCABULARY_SET_CATEGORY]: resetWidgetErrors,
  [XVOCABULARY_SET_TRANSLATION]: resetWidgetErrors,
  [XVOCABULARY_SET_ORIGINAL]: resetWidgetErrors,
  [RESET_WIDGET_ERRORS]: resetWidgetErrors,
  [RESET_MEDIA_SOURCES_ERRORS]: resetMediaSourcesErrors,
  [REQUEST_COURSEBOOK_UNIT_EXERCISE]: (state: XEditorState): XEditorState => ({
    ...state,
    loading: true
  }),
  [REQUEST_COURSEBOOK_UNIT_EXERCISE_SUCCESS]: (
    state: XEditorState,
    action: AxiosResponseAction<CoursebookUnitExerciseResponse>
  ): XEditorState => {
    const {exercise, unit, pageNumber, parentExerciseId, ordinal, grammar} = action.payload.data;
    return {
      ...state,
      loading: false,
      unitExercise: {unit, pageNumber, ordinal, parentExerciseId},
      xexercise: XExerciseRecord.fromJSON({...exercise!, grammar})
    };
  },
  [REQUEST_COURSEBOOK_UNIT_MAIN_EXERCISE_SUCCESS]: (
    state: XEditorState,
    action: AxiosResponseAction<CoursebookUnitExerciseResponse>
  ): XEditorState => {
    const {unit, pageNumber, ordinal, exerciseId} = action.payload.data;
    return {
      ...state,
      loading: false,
      unitExercise: {
        ...state.unitExercise,
        unit,
        parentUnitExercise: {pageNumber, ordinal},
        parentExerciseId: exerciseId!
      }
    };
  },
  [REQUEST_GRAMMAR_SUCCESS]: (
    state: XEditorState,
    action: AxiosResponseAction<GrammarExerciseOptions>
  ): XEditorState => {
    const data = action.payload.data;
    return {
      ...state,
      loading: false,
      grammar: data
    };
  },
  [REQUEST_GRAMMAR_FAIL]: (state: XEditorState): XEditorState => {
    return {
      ...state,
      loading: false
    };
  },
  [REQUEST_COURSEBOOK_UNIT_EXERCISE_FAIL]: (state: XEditorState): XEditorState => {
    return {
      ...state,
      loading: false
    };
  },
  [REQUEST_COURSEBOOK_UNIT_SUCCESS]: (
    state: XEditorState,
    action: AxiosResponseAction<CoursebookUnitResponse>
  ): XEditorState => {
    const unit = action.payload.data;
    return {
      ...state,
      loading: false,
      unitExercise: {unit, parentExerciseId: null}
    };
  },
  [REQUEST_CATEGORIES_SUCCESS]: (
    state: XEditorState,
    action: AxiosResponseAction<ExerciseCategory[]>
  ): XEditorState => {
    return {
      ...state,
      categories: action.payload.data
    };
  },
  [REQUEST_CATEGORIES_FAIL]: (state: XEditorState): XEditorState => {
    return state;
  },
  [RENAME_CATEGORY_SUCCESS]: (state: XEditorState): XEditorState => {
    return state;
  },
  [RENAME_CATEGORY_FAIL]: (state: XEditorState): XEditorState => {
    return state;
  },
  [REQUEST_LEVELS_SUCCESS]: (
    state: XEditorState,
    action: RequestLevelsResponseAction
  ): XEditorState => {
    return {
      ...state,
      levels: action.payload.data
    };
  },
  [REQUEST_LEVELS_FAIL]: (state: XEditorState): XEditorState => {
    return state;
  },
  [COURSEBOOK_UNIT_EXERCISE_CRITICAL_ERROR]: (state: XEditorState): XEditorState => ({
    ...state,
    loadingError: 'critical'
  }),
  [COURSEBOOK_UNIT_EXERCISE_NOT_FOUND]: (state: XEditorState): XEditorState => ({
    ...state,
    loadingError: 'not-found'
  }),
  [RESET_LOADING]: (state: XEditorState): XEditorState => ({
    ...state,
    loading: false
  }),
  [SET_UNIT_EXERCISE]: (state: XEditorState, action: SetUnitExerciseAction): XEditorState => {
    return {
      ...state,
      unitExercise: action.payload.unitExercise
    };
  },
  [DELETE_DRAFT_EXERCISE]: (
    state: XEditorState,
    action: DeleteDraftExerciseAction
  ): XEditorState => {
    return {
      ...state,
      draftExercises: xdraftExercisesReducer(state.draftExercises, action)
    };
  }
};

const initialState: XEditorState = {
  activeTab: XEditorTab.EDITOR,
  stage: XEditorStage.EDITING,
  errors: Map(),
  categories: [],
  levels: [],
  draftExercises: Map(),
  xexercise: new XExerciseRecord({
    id: null,
    title: '',
    grammarTitle: null,
    widgets: [],
    mediaSources: [],
    meta: {categories: [], levels: []},
    isNew: true
  })
};

function xeditorReducer(state: XEditorState = initialState, action: Action): XEditorState {
  const reducer: Reducer<XEditorState> = REDUCERS[action.type];
  state = reducer ? reducer(state, action) : state;
  const isEditing = state.stage === XEditorStage.EDITING;

  if (isEditing) {
    const xexercise = xexerciseReducer(state.xexercise, action);

    const draftExercisesAction = xexercise.isNew
      ? action
      : draftExercisesActionCreator(xexercise, action);

    state = {
      ...state,
      xexercise,
      xpreview: xpreviewReducer(state.xpreview, action),
      xwizard: state.xwizard ? xwizardReducer(state.xwizard, action) : undefined,
      draftExercises: xdraftExercisesReducer(state.draftExercises, draftExercisesAction)
    };
  }
  return state;
}

export default xeditorReducer;
