import React, {type FC, useCallback, useContext, useEffect, useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';

type Element = string | number;

interface AnimationElementProps {
  elements: Element[];
  animated: boolean;
  onAnimationEnd: () => void;
}

interface AnimationActionsProps {
  elements: Element[];
  addElements: (elements: Element | Element[]) => void;
  deleteElement: (element: Element) => void;
  hasAnimation: (element: Element) => boolean;
}

export const AnimationActionsContext = React.createContext<AnimationActionsProps>({
  elements: [],
  addElements: () => {},
  deleteElement: () => {},
  hasAnimation: () => false
});

interface Props {
  elementId: Element;
  children: (props: AnimationElementProps) => React.ReactElement;
}

interface LocationProps {
  animatedElement?: string;
}

export const AnimationContextProvider: FC = ({children}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [elements, setElements] = useState<Element[]>([]);

  const addElements = useCallback((element: Element | Element[]) => {
    setElements(prevState => Array.from(new Set(prevState.concat(element))));
  }, []);

  const deleteElement = useCallback(
    (element: Element) => setElements(prevState => prevState.filter(el => el !== element)),
    []
  );

  const hasAnimation = useCallback((element: Element) => elements.includes(element), [elements]);

  useEffect(() => {
    const {animatedElement}: LocationProps = location.state || {};

    if (animatedElement) {
      navigate(location.pathname, {replace: true, state: undefined});

      addElements(animatedElement);
    }
  }, [addElements, navigate, location]);

  return (
    <AnimationActionsContext.Provider value={{elements, addElements, deleteElement, hasAnimation}}>
      {children}
    </AnimationActionsContext.Provider>
  );
};

export const AnimationElement: FC<Props> = ({children, elementId}) => {
  const {elements, hasAnimation, deleteElement} = useContext(AnimationActionsContext);

  const onAnimationEnd = useCallback(() => deleteElement(elementId), [deleteElement, elementId]);

  const animated = hasAnimation(elementId);

  return children({elements, animated, onAnimationEnd});
};
