import React from 'react';
import './CoursebookLibraryPage.scss';

class CoursebookLibraryPage extends React.Component {
  public render() {
    return <div className="coursebook-library-page">{this.props.children}</div>;
  }
}

export default CoursebookLibraryPage;
