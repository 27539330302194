import React, {type FC, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {type NavigateOptions, useLocation, useNavigate} from 'react-router-dom';

import CoursebookLibrary from 'components/CoursebookLibrary/containers/CoursebookLibrary';
import {type UserV2} from 'components/CoursebookLibrary/interface';
import {type AppState, type Course, type CourseDetailed, type LanguageLevel} from 'store/interface';
import {methodologyOptionId} from 'components/CoursebookLibrary/components/ExtendedSearchPanel/filterAuthors';
import CoursebookContentsViewer from 'components/CoursebookContentsViewer/CoursebookContentsViewer';
import {type ViewContentsModalTab} from 'components/CoursebookContentsViewer/interface';
import GrammarPlayerPage from 'common/GrammarPlayerPage/GrammarPlayerPage';
import {libraryPath} from 'common/paths';
import {BooleanEnum, CoursebookAuthorRole} from 'common/enums';

import {getCoursebookFilter} from './helpers';
import {
  openCoursebookDataModal,
  setAvailableCourses,
  setAvailableLevels
} from '../Common/CoursebookDataModal/action';
import ViewContentsModal from '../Common/ViewContentsModal';
import CoursebookDataModal from '../Common/CoursebookDataModal';
import {CoursebookItem} from './CoursebookItem';
import CoursebookLibraryPage from './CoursebookLibraryPage';
import {CoursebookLibraryContext} from './context';

const messages = defineMessages({
  LibraryTitle: {
    id: 'CoursebookLibrary.Title'
  }
});

export const Library: FC = () => {
  const dispatch = useDispatch();
  const {formatMessage} = useIntl();
  const [editSuccessCallback, setEditSuccessCallback] = useState<() => null | void>(
    () => () => null
  );
  const [modalOpenedForCoursebook, setModalOpenedForCoursebook] = useState<string | null>(null);
  const [grammarPreviewOpenedForCoursebook, setGrammarPreviewOpenedForCoursebook] = useState<
    string | null
  >(null);

  const {search} = useLocation();
  const navigate = useNavigate();

  const availableCourses = useSelector(
    (state: AppState) => state.coursebookLibraryCommon!.coursebookDataModal.availableCourses
  );
  const availableLevels = useSelector(
    (state: AppState) => state.coursebookLibraryCommon!.coursebookDataModal.availableLevels
  );

  const dispatchSetAvailableCourses = useCallback(
    (availableCourses: CourseDetailed[]) => dispatch(setAvailableCourses(availableCourses)),
    [dispatch]
  );
  const dispatchSetAvailableLevels = useCallback(
    (availableLevels: LanguageLevel[]) => dispatch(setAvailableLevels(availableLevels)),
    [dispatch]
  );
  const dispatchOpenCoursebookModal = useCallback(
    () => dispatch(openCoursebookDataModal()),
    [dispatch]
  );

  const openModalForCoursebook = useCallback((id: string) => setModalOpenedForCoursebook(id), []);
  const openGrammarModalForCoursebook = useCallback(
    (id: string) => setGrammarPreviewOpenedForCoursebook(id),
    []
  );

  const closeModal = useCallback(() => setModalOpenedForCoursebook(null), []);
  const closeGrammarPreview = useCallback(() => setGrammarPreviewOpenedForCoursebook(null), []);

  const getEditSuccessCallback = useCallback(
    (callback: () => void) => setEditSuccessCallback(() => callback),
    []
  );

  const filter = useCallback(() => {
    return getCoursebookFilter(search);
  }, [search]);

  const resetFilters = useCallback(() => {
    const searchParams = new URLSearchParams(search);
    Object.keys(filter()).map(key => key !== 'title' && searchParams.delete(key));
    navigate({pathname: libraryPath(), search: searchParams.toString()});
  }, [filter, navigate, search]);

  const changeTitleFilter = useCallback(
    (newTitle: string) => {
      const searchParams = new URLSearchParams(search);
      newTitle === '' ? searchParams.delete('title') : searchParams.set('title', newTitle);
      navigate({pathname: libraryPath(), search: searchParams.toString()});
    },
    [navigate, search]
  );

  const changeRoleFilter = useCallback(
    (role: CoursebookAuthorRole | null, options?: NavigateOptions) => {
      const searchParams = new URLSearchParams(search);
      role ? searchParams.set('role', role) : searchParams.delete('role');
      navigate({pathname: libraryPath(), search: searchParams.toString()}, options);
    },
    [navigate, search]
  );

  const changePublishedFilter = useCallback(
    (published: BooleanEnum | null, options?: NavigateOptions) => {
      const searchParams = new URLSearchParams(search);
      published ? searchParams.set('published', published) : searchParams.delete('published');
      navigate({pathname: libraryPath(), search: searchParams.toString()}, options);
    },
    [navigate, search]
  );

  const changeCoursesFilter = useCallback(
    (courses: number[], options?: NavigateOptions) => {
      const searchParams = new URLSearchParams(search);
      courses.length
        ? searchParams.set('courses', courses.join(','))
        : searchParams.delete('courses');
      navigate({pathname: libraryPath(), search: searchParams.toString()}, options);
    },
    [navigate, search]
  );

  const changeLevelsFilter = useCallback(
    (levels: number[], options?: NavigateOptions) => {
      const searchParams = new URLSearchParams(search);
      levels.length ? searchParams.set('levels', levels.join(',')) : searchParams.delete('levels');
      navigate({pathname: libraryPath(), search: searchParams.toString()}, options);
    },
    [navigate, search]
  );

  const changeAuthorFilter = useCallback(
    (id: number | null, options?: NavigateOptions) => {
      const searchParams = new URLSearchParams(search);
      id ? searchParams.set('authorId', String(id)) : searchParams.delete('authorId');
      navigate({pathname: libraryPath(), search: searchParams.toString()}, options);
    },
    [navigate, search]
  );

  const changeOriginalAuthorFilter = useCallback(
    (id: number | null, options?: NavigateOptions) => {
      const searchParams = new URLSearchParams(search);
      id
        ? searchParams.set('originalAuthorId', String(id))
        : searchParams.delete('originalAuthorId');
      navigate({pathname: libraryPath(), search: searchParams.toString()}, options);
    },
    [navigate, search]
  );

  const validateUrlParams = (
    availableAuthors: UserV2[],
    availableOriginalAuthors: UserV2[],
    availableCourses: Course[],
    availableLevels: LanguageLevel[]
  ) => {
    const {role, published, authorId, originalAuthorId, courses, levels} = filter();
    if (role && Object.values(CoursebookAuthorRole).indexOf(role) === -1) {
      changeRoleFilter(null, {replace: true});
    }

    if (published && Object.values(BooleanEnum).indexOf(published) === -1) {
      changePublishedFilter(null, {replace: true});
    }

    if (authorId !== null) {
      if (isNaN(authorId)) {
        changeAuthorFilter(null, {replace: true});
      } else {
        const selectedAuthorExists =
          availableAuthors.find(author => author.id === authorId) ||
          authorId === methodologyOptionId;

        if (!selectedAuthorExists) {
          changeAuthorFilter(null, {replace: true});
        }
      }
    }

    if (originalAuthorId !== null) {
      if (isNaN(originalAuthorId)) {
        changeOriginalAuthorFilter(null, {replace: true});
      } else {
        const selectedAuthorExists =
          availableOriginalAuthors.find(author => author.id === originalAuthorId) ||
          originalAuthorId === methodologyOptionId;

        if (!selectedAuthorExists) {
          changeOriginalAuthorFilter(null, {replace: true});
        }
      }
    }

    if (courses) {
      const existingCourses = courses.filter(
        courseId => !!availableCourses.find(availableCourse => availableCourse.id === courseId)
      );
      if (existingCourses !== courses) {
        changeCoursesFilter(existingCourses, {replace: true});
      }
    }

    if (levels) {
      const existingLevels = levels.filter(
        levelId => !!availableLevels.find(availableLevel => availableLevel.id === levelId)
      );
      if (existingLevels !== levels) {
        changeLevelsFilter(existingLevels, {replace: true});
      }
    }
  };

  return (
    <CoursebookLibraryPage>
      <CoursebookLibraryContext.Provider
        value={{
          viewContentsOfCoursebook: openModalForCoursebook,
          viewGrammarOfCoursebook: openGrammarModalForCoursebook
        }}
      >
        <CoursebookLibrary
          filter={filter()}
          resetFilters={resetFilters}
          changeTitleFilter={changeTitleFilter}
          validateFilter={validateUrlParams}
          changeRoleFilter={changeRoleFilter}
          changeOriginalAuthorFilter={changeOriginalAuthorFilter}
          changeAuthorFilter={changeAuthorFilter}
          changeLevelsFilter={changeLevelsFilter}
          changeCoursesFilter={changeCoursesFilter}
          changePublishedFilter={changePublishedFilter}
          availableCourses={availableCourses}
          availableLevels={availableLevels}
          setAvailableCourses={dispatchSetAvailableCourses}
          setAvailableLevels={dispatchSetAvailableLevels}
          getEditSuccessCallback={getEditSuccessCallback}
          title={formatMessage(messages.LibraryTitle)}
          createCoursebook={dispatchOpenCoursebookModal}
          coursebookItemComponent={CoursebookItem}
        />
        <ViewContentsModal
          show={!!modalOpenedForCoursebook}
          close={closeModal}
          renderBody={(tab: ViewContentsModalTab) =>
            !!modalOpenedForCoursebook && (
              <CoursebookContentsViewer selectedTab={tab} coursebookId={modalOpenedForCoursebook} />
            )
          }
        />
        <ViewContentsModal
          show={!!grammarPreviewOpenedForCoursebook}
          close={closeGrammarPreview}
          isToggleGroup={true}
          title={<FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />}
          renderBody={() =>
            !!grammarPreviewOpenedForCoursebook && (
              <GrammarPlayerPage coursebookId={grammarPreviewOpenedForCoursebook} isModal={true} />
            )
          }
        />
        <CoursebookDataModal editCoursebookSuccessCallback={editSuccessCallback} />
      </CoursebookLibraryContext.Provider>
    </CoursebookLibraryPage>
  );
};
