import {List} from 'immutable';
import {type Value} from '@englex/slate';

import {type PronunciationOption, type WidgetToJSONOptions} from 'store/exercise/player/interface';
import {record} from 'immutable-record/decorator/record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import type VocabularyWordRecord from 'store/exercise/player/widgets/Vocabulary/VocabularyWordRecord';
import {valueFromText} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {pronunciationPhoneticSpellingMaxLength} from 'components/Dictionary/shared/static';
import {type PartOfSpeech} from 'components/Dictionary/shared/interface';

import {
  type VocabularyCategoryProperties,
  type VocabularyWordProperties,
  type XPronunciationModalProperties
} from './interface';
import {transformForRequestAndGetPOS} from './utils';

const Record = recordBase()(BaseRecord);

class XPronunciationModalRecord extends Record implements XPronunciationModalProperties {
  public declare readonly wordIndex: number;

  public declare readonly word: string;

  public declare readonly partOfSpeech: PartOfSpeech | undefined;

  public declare readonly pronunciationOptions?: List<null | PronunciationOption>;

  public declare readonly phoneticSpelling?: Value;

  public declare readonly selectedOptionIndex: number;

  constructor(
    wordIndex: number,
    vocabulary: List<VocabularyWordProperties | VocabularyCategoryProperties>
  ) {
    super();
    const word: VocabularyWordRecord = vocabulary.get(wordIndex) as VocabularyWordRecord;
    if (word.phoneticSpelling) {
      this.initValues({phoneticSpelling: valueFromText(word.phoneticSpelling)});
    }
    const {transformedWord, partOfSpeech} = transformForRequestAndGetPOS(wordIndex, vocabulary);
    this.initValues({word: transformedWord, partOfSpeech, wordIndex});
    return;
  }

  public get validationStatus(): 'error' | 'success' {
    if (
      !this.phoneticSpelling ||
      this.phoneticSpelling.document.text.length > pronunciationPhoneticSpellingMaxLength
    ) {
      return 'error';
    }
    return 'success';
  }

  public handleOptionsLoaded = (options: PronunciationOption[], selectedSoundId?: number) => {
    let newRecord = this.set('pronunciationOptions', List([null, ...options]));
    if (!selectedSoundId) {
      // if no sound option was selected for this wor prior, set default to the first non-empty option if it exists...
      if (newRecord.pronunciationOptions!.size > 1) {
        newRecord = newRecord.set('selectedOptionIndex', 1);
      } else {
        // ...or to the empty option, if it doesnt
        newRecord = newRecord.set('selectedOptionIndex', 0);
      }
    } else {
      const indexOfSelected = newRecord.pronunciationOptions!.findIndex(
        option => !!option && option.id === selectedSoundId
      );
      if (indexOfSelected !== -1) {
        // if loaded options have el with id that was previously selected for this word, set default to this el
        newRecord = newRecord.set('selectedOptionIndex', indexOfSelected);
      } else {
        // if loaded options have no el with id that was previously selected, add option with this id as last option
        // and set default to it
        newRecord = newRecord.set(
          'pronunciationOptions',
          newRecord.pronunciationOptions!.push({id: selectedSoundId})
        );
        newRecord = newRecord.set('selectedOptionIndex', newRecord.pronunciationOptions!.size - 1);
      }
    }
    return newRecord;
  };

  public toJSON(options?: WidgetToJSONOptions) {
    // todo: write toJSON
    return {};
  }
}

decorate(XPronunciationModalRecord, {
  wordIndex: property(0),
  word: property(''),
  partOfSpeech: property(undefined),
  pronunciationOptions: property(undefined),
  phoneticSpelling: property(''),
  selectedOptionIndex: property(0)
});
record()(XPronunciationModalRecord);
export default XPronunciationModalRecord;
