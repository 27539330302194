import React, {useCallback, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {type DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import {useNavigate, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';

import DragHandle from 'components/DragHandle/DragHandle';
import Confirm from 'components/modals/Confirm';
import {isMac} from 'helpers/shortcut';
import {editorPath, newExercisePath} from 'common/paths';
import {copyExercise} from 'store/exercise/editor/actions/xeditor';
import {CLIPBOARD_MAX_EXERCISE_COUNT} from 'store/exercise/editor/clipboardReducer';
import * as toastr from 'components/toastr';
import {type AppState} from 'store/interface';
import {isCursorInTopHalfOfElement} from 'common/Dnd/helpers';
import {DndTypes} from 'components/dnd/interface';

import UnitExerciseInfo from '../views/UnitExerciseInfo';
import {redirectFromUnitPage} from '../../../state/action';
import {dndThrottle} from '../../../state/DndThrottle';
import {ExerciseDragObjectPosition} from '../../dnd/ExerciseDragObjectPosition';
import {type UnitRouteParams} from '../../../interface';
import {exerciseListMessages} from '../messages';
import {
  dragPreviewApprove,
  dragPreviewCreate,
  dragPreviewDiscard,
  dragPreviewMakeExerciseSupplementary,
  dragPreviewMoveWithinSupplementaryList,
  type MakeUnitExerciseSupplementaryOptions,
  type MoveWithinSupplementaryListOptions
} from '../actions/dragPreviewActions';
import {deleteUnitExercise} from '../actions/unitExerciseListActions';
import {viewExercise} from '../ExerciseViewerModal/action';
import {type UnitExerciseDragObject} from '../interface';
import {AnimationElement} from '../../../AnimationElementsContext';
import {useDndEmptyImage} from '../../../../../../components/dnd/useDndEmptyImage';

const defaultElWidth = 724;

interface Props {
  unitExerciseId: number;
  pageIndex: number;
  parentExerciseId: string;
  parentIndex: number;
  index: number;
  readonly?: boolean;
  isDragItem?: boolean;
  showSupplementaryExercisesPanel: boolean;
}

export const SupplementaryExerciseContainer: React.FC<Props> = React.memo(
  ({
    index,
    pageIndex,
    parentIndex,
    isDragItem,
    unitExerciseId,
    parentExerciseId,
    showSupplementaryExercisesPanel
  }) => {
    const dispatch = useDispatch();

    const {formatMessage} = useIntl();

    const navigate = useNavigate();

    const {coursebookId, unitId} = useParams<UnitRouteParams>();

    const {
      exerciseId,
      excerpt,
      lockedBy,
      readonly,
      wasCopiedToClipboard,
      clipboardExercisesLength
    } = useSelector((state: AppState) => {
      const unitExercise = state
        .coursebookPage!.unit!.current.pages.get(pageIndex)
        .unitExerciseList.get(parentIndex)
        .supplementaryUnitExerciseList.get(index);

      return {
        excerpt: unitExercise.excerpt,
        lockedBy: unitExercise.lockedBy,
        readonly: false,
        exerciseId: unitExercise.exerciseId,
        wasCopiedToClipboard: state.clipboard.exercises.includes(unitExercise.exerciseId),
        clipboardExercisesLength: state.clipboard.exercises.length
      };
    });

    const mainElement = useRef<HTMLDivElement | null>(null);

    const [deleteExerciseConfirm, setDeleteExerciseConfirm] = useState(false);

    const [{draggedUnitExerciseId}, connectDragSource, connectDragPreview] = useDrag({
      type: DndTypes.UNIT_EXERCISE,
      item: () => {
        dispatch(dragPreviewCreate());

        const elementWidth = mainElement.current ? mainElement.current.clientWidth : defaultElWidth;
        return {
          elementWidth,
          position: new ExerciseDragObjectPosition({
            pageIndex,
            unitExerciseId,
            mainUnitExerciseIndex: parentIndex,
            suppUnitExerciseIndex: index
          })
        };
      },
      collect: monitor => {
        const item: UnitExerciseDragObject = monitor.getItem();
        const itemType = monitor.getItemType();

        return {
          isDragging: monitor.isDragging(),
          draggedUnitExerciseId:
            (item && itemType === DndTypes.UNIT_EXERCISE && item.position.unitExerciseId) ||
            undefined
        };
      },
      end: (item: UnitExerciseDragObject) => {
        if (item.position.positionChanged()) {
          // if position of exercise changed after it was dropped, add drag preview as new revision in history
          dispatch(dragPreviewApprove());
        } else {
          // else pretend nothing happened
          dispatch(dragPreviewDiscard());
        }
      }
    });

    const [, connectDropTarget] = useDrop({
      accept: DndTypes.UNIT_EXERCISE,
      hover(item: UnitExerciseDragObject, monitor: DropTargetMonitor) {
        if (!mainElement.current || !monitor.canDrop()) return null;

        const itemOnAnotherPage = item.position.pageIndex !== pageIndex;
        const isInSupplementaryList = item.position.isSuppUnitExercise;
        const itemIsParentElement =
          item.position.mainUnitExerciseIndex === parentIndex && !isInSupplementaryList;

        if (itemOnAnotherPage || itemIsParentElement) return;

        if (!isInSupplementaryList) {
          const item: UnitExerciseDragObject = monitor.getItem();
          const sourcePosition = item.position.mainUnitExerciseIndex;
          const cursorInTopHalfOfEl = isCursorInTopHalfOfElement(mainElement.current, monitor);

          // if cursor points at top half of exercise in supplementary exercises list, move source exercise
          // before this exercise, else move it after
          const targetPositionInSuppList = cursorInTopHalfOfEl ? index : index + 1;

          return dndThrottle.throttleAction(() => {
            const draggedFromTop = sourcePosition < parentIndex;
            // if source exercise was before the target main exercise, after this action target main exercise's
            // index will be decreased by 1 bc now the list has one less element before it
            const targetElementPosition = draggedFromTop ? parentIndex - 1 : parentIndex;
            const options: MakeUnitExerciseSupplementaryOptions = {
              pageIndex: pageIndex,
              sourcePosition,
              targetUnitExercisePosition: targetElementPosition,
              targetPositionInSupplementaryList: targetPositionInSuppList
            };

            item.position.makeUnitExerciseSupplementary(options);
            dispatch(dragPreviewMakeExerciseSupplementary(options));
          });
        }

        const sourceAndTargetInOneList =
          isInSupplementaryList && item.position.mainUnitExerciseIndex === parentIndex;

        if (sourceAndTargetInOneList) {
          const cursorInTopHalfOfEl = isCursorInTopHalfOfElement(mainElement.current, monitor);
          const cursorInBottomHalfOfEl = !cursorInTopHalfOfEl;
          const item: UnitExerciseDragObject = monitor.getItem();
          if (item.position.suppUnitExerciseIndex === index) {
            return;
          }

          const draggingDownwards = item.position.suppUnitExerciseIndex! < index;
          const draggingUpwards = !draggingDownwards;

          if (
            (draggingDownwards && cursorInTopHalfOfEl) ||
            (draggingUpwards && cursorInBottomHalfOfEl)
          ) {
            return;
          }
          const sourcePosition = item.position.suppUnitExerciseIndex!;
          const targetPosition = index;

          return dndThrottle.throttleAction(() => {
            const options: MoveWithinSupplementaryListOptions = {
              pageIndex: pageIndex,
              parentUnitExerciseIndex: parentIndex,
              sourcePosition,
              targetPosition
            };
            item.position.moveWithinSupplementaryList(options);
            dispatch(dragPreviewMoveWithinSupplementaryList(options));
          });
        }
      },
      canDrop(item: UnitExerciseDragObject) {
        return !item.supplementaryListHasElements;
      }
    });

    const getRef = useCallback((el: HTMLDivElement | null) => (mainElement.current = el), []);

    const confirmDeletingExercise = useCallback(() => setDeleteExerciseConfirm(true), []);

    const declineDeletingExercise = useCallback(() => setDeleteExerciseConfirm(false), []);

    const openExercise = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const openInTheNewTab = isMac ? e.metaKey : e.ctrlKey;
        const path = editorPath(coursebookId!, Number(unitId), exerciseId);
        if (!openInTheNewTab) {
          dispatch(redirectFromUnitPage(path));
        } else {
          window.open(path, '_blank', 'noopener,norefferer');
        }
      },
      [dispatch, coursebookId, exerciseId, unitId]
    );

    const onViewExercise = useCallback(
      () => dispatch(viewExercise(exerciseId)),
      [dispatch, exerciseId]
    );

    const onCopyExercise = useCallback(() => {
      navigate(newExercisePath(coursebookId!, Number(unitId), parentExerciseId), {
        state: {copiedExercise: exerciseId}
      });
    }, [coursebookId, exerciseId, navigate, parentExerciseId, unitId]);

    const copyToClipboard = useCallback(() => {
      if (clipboardExercisesLength >= CLIPBOARD_MAX_EXERCISE_COUNT) {
        return toastr.error(
          '',
          formatMessage(exerciseListMessages.ExceedExercises, {
            limit: CLIPBOARD_MAX_EXERCISE_COUNT
          })
        );
      }

      dispatch(copyExercise(exerciseId));
    }, [dispatch, clipboardExercisesLength, exerciseId, formatMessage]);

    const onDeleteUnitExercise = useCallback(() => {
      dispatch(deleteUnitExercise({pageIndex, unitExerciseId, parentIndex}));
    }, [dispatch, pageIndex, parentIndex, unitExerciseId]);

    useDndEmptyImage(connectDragPreview);

    return (
      <>
        <AnimationElement elementId={exerciseId}>
          {({animated, onAnimationEnd}) => (
            <UnitExerciseInfo
              animated={animated}
              onAnimationEnd={onAnimationEnd}
              openExercise={lockedBy ? undefined : openExercise}
              excerpt={excerpt}
              lockedBy={lockedBy && `${lockedBy.profile.lastName} ${lockedBy.profile.firstName}`}
              deleteUnitExercise={confirmDeletingExercise}
              readonly={readonly}
              isDragItem={isDragItem}
              invisible={unitExerciseId === draggedUnitExerciseId && !isDragItem}
              getRef={getRef}
              connectDropTarget={connectDropTarget}
              viewExercise={onViewExercise}
              showSupplementaryExercisesPanel={showSupplementaryExercisesPanel}
              copyExercise={onCopyExercise}
              copyToClipboard={copyToClipboard}
              wasCopiedToClipboard={wasCopiedToClipboard}
            >
              {connectDragSource(
                <div className="supplementary-exercise-drag-handle">
                  <DragHandle />
                </div>
              )}
            </UnitExerciseInfo>
          )}
        </AnimationElement>
        <Confirm
          show={deleteExerciseConfirm}
          onAccept={onDeleteUnitExercise}
          onDecline={declineDeletingExercise}
          headerText={formatMessage(exerciseListMessages.DeleteExerciseConfirm)}
          disableButtons={false}
        />
      </>
    );
  }
);
