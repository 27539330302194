import React from 'react';
import classNames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {connect} from 'react-redux';

import * as toastr from 'components/toastr';
import EmptyList from 'components/CoursebookLibrary/LibraryPageList/EmptyList';
import {type AppState, type CoursebookSection} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {CollapsedButton} from 'components/CoursebookLibrary/components/CollapsedButton/CollapsedButton';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';

import {CoursebookSectionItem} from './CoursebookSectionItem';
import {moveCoursebookSectionRequest, setCoursebookSections} from '../actions/action';
import {sectionsMessages} from '../i18n';

import './SectionsList.scss';

interface OwnProps extends WrappedComponentProps {
  coursebookId: string;
  coursebookSections: CoursebookSection[];
  reloadList: () => void;
}

interface DispatchProps {
  moveCoursebookSectionRequest: (
    coursebookId: string,
    coursebookSectionId: number,
    targetPos: number
  ) => Promise<Action>;
  setCoursebookSections: (coursebookSections: CoursebookSection[]) => void;
}

interface Props extends OwnProps, DispatchProps {}

interface State {
  dragging: boolean;
  updatedCoursebookSectionId?: number;
  coursebookSections: CoursebookSection[];
  isOpen: boolean;
}

class SectionsList extends React.Component<Props, State> {
  public state: State = {
    dragging: false,
    coursebookSections: this.props.coursebookSections,
    isOpen: false
  };

  private request: CancellablePromise;
  private listEl: HTMLDivElement | null;

  public static getDerivedStateFromProps(props: OwnProps, state: State) {
    const isUpdating = !!state.updatedCoursebookSectionId;
    if (state.dragging || isUpdating) {
      return null;
    }
    return {coursebookSections: props.coursebookSections};
  }

  public componentWillUnmount(): void {
    if (this.request) {
      this.request.cancel();
    }
  }

  public render() {
    const {coursebookSections} = this.state;
    const empty = coursebookSections.length === 0;

    const emptyMessage = <FormattedMessage id="Coursebook.Contents.SectionsList.EmptyList" />;
    return (
      <div
        className={classNames('sections-list', {empty, 'is-open': this.state.isOpen})}
        ref={this.listRef}
      >
        <CollapsedButton
          isCollapsed={!this.state.isOpen}
          isRightSide={true}
          onClick={this.switchIsOpen}
        />
        <Scrollbars autoHide={true}>
          {empty ? (
            <EmptyList message={emptyMessage} />
          ) : (
            <React.Fragment>
              {coursebookSections.map(this.renderCoursebookSectionItem)}
              <div className="empty-space" />
            </React.Fragment>
          )}
        </Scrollbars>
      </div>
    );
  }

  private listRef = (el: HTMLDivElement | null) => (this.listEl = el);

  private getListEl = () => this.listEl || document.body;

  private renderCoursebookSectionItem = (coursebookSection: CoursebookSection, index: number) => (
    <CoursebookSectionItem
      coursebookSection={coursebookSection}
      getListElement={this.getListEl}
      key={coursebookSection.id}
      reloadCoursebookSectionsList={this.props.reloadList}
      beginDrag={this.beginDrag}
      endDrag={this.endDrag}
      position={index}
      moveEntry={this.moveEntry}
      isUpdated={this.state.updatedCoursebookSectionId === coursebookSection.id}
      disableControls={!!this.state.updatedCoursebookSectionId}
    />
  );

  private beginDrag = () => this.setState({dragging: true});

  private endDrag = (draggedCoursebookSectionId: number, finalPosition: number) => {
    const initialCoursebookSections = this.props.coursebookSections;
    const coursebookSectionsAfterDrag = this.state.coursebookSections;
    const orderChanged = initialCoursebookSections.find(
      (coursebookSection: CoursebookSection, index: number) =>
        coursebookSection.id !== coursebookSectionsAfterDrag[index].id
    );
    if (!orderChanged) {
      this.setState({dragging: false});
      return;
    }
    this.setState({
      dragging: false,
      updatedCoursebookSectionId: draggedCoursebookSectionId
    });

    this.request = makeCancellable(
      this.props.moveCoursebookSectionRequest(
        this.props.coursebookId,
        draggedCoursebookSectionId,
        finalPosition
      ),
      this.handleRequestSuccess,
      this.handleRequestError
    );
  };

  private moveEntry = (sourcePos: number, targetPos: number) => {
    const rearrangedArr = [...this.state.coursebookSections];
    const [removedItem] = rearrangedArr.splice(sourcePos, 1);
    rearrangedArr.splice(targetPos, 0, removedItem);
    this.setState({coursebookSections: rearrangedArr});
  };

  private handleRequestSuccess = (action: AxiosResponseAction<CoursebookSection[]>) => {
    this.props.setCoursebookSections(action.payload.data);
    this.setState({updatedCoursebookSectionId: undefined});
    toastr.success(
      '',
      this.props.intl.formatMessage(sectionsMessages.MoveCoursebookSectionSuccess)
    );
  };

  private handleRequestError = () => {
    this.setState({updatedCoursebookSectionId: undefined});
    toastr.error('', this.props.intl.formatMessage(sectionsMessages.MoveCoursebookSectionError));
  };

  private switchIsOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState(prevState => ({isOpen: !prevState.isOpen}));
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  moveCoursebookSectionRequest: (
    coursebookId: string,
    coursebookSectionId: number,
    targetPos: number
  ) => dispatch(moveCoursebookSectionRequest(coursebookId, coursebookSectionId, targetPos)),
  setCoursebookSections: (coursebookSections: CoursebookSection[]) =>
    dispatch(setCoursebookSections(coursebookSections))
});

export default injectIntl(connect(null, mapDispatchToProps)(SectionsList));
