import React, {useCallback, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {type DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import {useNavigate, useParams} from 'react-router-dom';
import {useIntl} from 'react-intl';

import * as toastr from 'components/toastr';
import {type UserV2} from 'components/CoursebookLibrary/interface';
import Confirm from 'components/modals/Confirm';
import {isMac} from 'helpers/shortcut';
import {editorPath, newExercisePath} from 'common/paths';
import {copyExercise} from 'store/exercise/editor/actions/xeditor';
import {CLIPBOARD_MAX_EXERCISE_COUNT} from 'store/exercise/editor/clipboardReducer';
import {type AppState} from 'store/interface';
import {DndTypes} from 'components/dnd/interface';
import {isCursorInTopHalfOfElement} from 'common/Dnd/helpers';

import MainExerciseView from '../views/UnitExerciseMain';
import {redirectFromUnitPage} from '../../../state/action';
import {dndThrottle} from '../../../state/DndThrottle';
import {ExerciseDragObjectPosition} from '../../dnd/ExerciseDragObjectPosition';
import {type UnitRouteParams} from '../../../interface';
import {exerciseListMessages} from '../messages';
import {
  dragPreviewApprove,
  dragPreviewCreate,
  dragPreviewDiscard,
  dragPreviewMakeUnitExerciseMain,
  dragPreviewMoveMainExerciseBetweenPages,
  dragPreviewMoveUnitExerciseWithinPage,
  type MakeUnitExerciseMainOptions,
  type MoveUnitExerciseBetweenPagesOptions,
  type MoveUnitExerciseWithinPageOptions
} from '../actions/dragPreviewActions';
import {deleteUnitExercise, toggleSuppUnitExercisePanel} from '../actions/unitExerciseListActions';
import {viewExercise} from '../ExerciseViewerModal/action';
import {type UnitExerciseDragObject} from '../interface';
import {AnimationElement} from '../../../AnimationElementsContext';
import {useDndEmptyImage} from '../../../../../../components/dnd/useDndEmptyImage';

const defaultElWidth = 744;

interface Props {
  supplementaryListLength: number;
  unitExerciseId: number;
  excerpt: string;
  lockedBy?: UserV2;
  pageIndex: number;
  readonly?: boolean;
  index: number;
  isOnlyExerciseInPage?: boolean;
  exerciseId: string;
  isDragItem?: boolean;
}

export const MainExerciseContainer: React.FC<Props> = React.memo(
  ({
    index,
    pageIndex,
    readonly,
    isDragItem,
    excerpt,
    lockedBy,
    unitExerciseId,
    isOnlyExerciseInPage,
    supplementaryListLength,
    exerciseId
  }) => {
    const navigate = useNavigate();

    const {coursebookId, unitId} = useParams<UnitRouteParams>();

    const dispatch = useDispatch();

    const {formatMessage} = useIntl();

    const {wasCopiedToClipboard, suppPanelOpenedForExercises, clipboardExercisesLength} =
      useSelector((state: AppState) => ({
        clipboardExercisesLength: state.clipboard.exercises.length,
        wasCopiedToClipboard: state.clipboard.exercises.includes(exerciseId),
        suppPanelOpenedForExercises:
          state.coursebookPage!.suppPanelOpenForExercises.includes(unitExerciseId)
      }));

    const [deleteExerciseConfirm, setDeleteExerciseConfirm] = useState(false);

    const mainElement = useRef<HTMLDivElement | null>(null);

    const [{draggedUnitExerciseId}, connectDragSource, connectDragPreview] = useDrag({
      type: DndTypes.UNIT_EXERCISE,
      item: () => {
        dispatch(dragPreviewCreate());

        const elementWidth = mainElement.current ? mainElement.current.clientWidth : defaultElWidth;

        return {
          elementWidth,
          supplementaryListHasElements: supplementaryListLength > 0,
          position: new ExerciseDragObjectPosition({
            pageIndex,
            unitExerciseId,
            isOnlyElementInPage: isOnlyExerciseInPage,
            mainUnitExerciseIndex: index
          })
        };
      },
      collect: monitor => {
        const item: UnitExerciseDragObject = monitor.getItem();
        const itemType = monitor.getItemType();
        return {
          isDragging: monitor.isDragging(),
          draggedUnitExerciseId:
            (item && itemType === DndTypes.UNIT_EXERCISE && item.position.unitExerciseId) ||
            undefined
        };
      },
      end: (item: UnitExerciseDragObject) => {
        if (item.position.positionChanged()) {
          // if position of exercise changed after it was dropped, add drag preview as new revision in history
          dispatch(dragPreviewApprove());
        } else {
          // else pretend nothing happened
          dispatch(dragPreviewDiscard());
        }
      }
    });

    const [, connectDropTarget] = useDrop({
      accept: DndTypes.UNIT_EXERCISE,
      hover(item: UnitExerciseDragObject, monitor: DropTargetMonitor) {
        const hoveringNestedTarget = !monitor.isOver({shallow: true});

        if (!mainElement.current || hoveringNestedTarget) return null;

        if (item.position.isSuppUnitExercise) {
          // makeUnitExerciseMain
          const cursorInTopHalfOfEl = isCursorInTopHalfOfElement(mainElement.current, monitor);
          const sourceMainPosition = item.position.mainUnitExerciseIndex;
          const sourceSupplementaryPos = item.position.suppUnitExerciseIndex!;

          const targetPosition = cursorInTopHalfOfEl
            ? item.position.mainUnitExerciseIndex
            : item.position.mainUnitExerciseIndex + 1;

          return dndThrottle.throttleAction(() => {
            const options: MakeUnitExerciseMainOptions = {
              pageIndex: item.position.pageIndex,
              parentUnitExerciseIndex: sourceMainPosition,
              suppUnitExerciseIndex: sourceSupplementaryPos,
              targetPosition
            };
            item.position.makeUnitExerciseMain(options);
            dispatch(dragPreviewMakeUnitExerciseMain(options));
          });
        }

        // moveUnitExercise
        const targetPageIndex = pageIndex;
        const sourcePageIndex = item.position.pageIndex;
        const sourcePos = item.position.mainUnitExerciseIndex;
        const moveWithinPage = targetPageIndex === sourcePageIndex;
        const cursorInTopHalfOfEl = isCursorInTopHalfOfElement(mainElement.current, monitor);
        const cursorInBottomHalfOfEl = !cursorInTopHalfOfEl;

        if (moveWithinPage) {
          const targetPos = index;

          if (sourcePos === targetPos) return;

          const draggingDownwards = sourcePos < targetPos;
          const draggingUpwards = !draggingDownwards;

          if (
            (draggingDownwards && cursorInTopHalfOfEl) ||
            (draggingUpwards && cursorInBottomHalfOfEl)
          ) {
            return;
          }

          return dndThrottle.throttleAction(() => {
            const options: MoveUnitExerciseWithinPageOptions = {
              pageIndex: targetPageIndex,
              sourcePos,
              targetPos
            };
            item.position.moveUnitExerciseWithinPage(options);
            dispatch(dragPreviewMoveUnitExerciseWithinPage(options));
          });
        }

        // if cursor points at top half of exercise in list, move source exercise
        // before this exercise, else move it after
        const targetPos = cursorInTopHalfOfEl ? index : index + 1;

        dndThrottle.throttleAction(() => {
          const options: MoveUnitExerciseBetweenPagesOptions = {
            sourcePageIndex,
            targetPageIndex,
            sourcePos,
            targetPos
          };
          item.position.moveUnitExerciseBetweenPages(options);
          dispatch(dragPreviewMoveMainExerciseBetweenPages(options));
        });
      }
    });

    const getMainElementRef = useCallback(
      (el: HTMLDivElement | null) => (mainElement.current = el),
      []
    );

    const confirmDeletingExercise = useCallback(() => setDeleteExerciseConfirm(true), []);

    const toggleSupplementaryExercisePanel = useCallback(() => {
      dispatch(toggleSuppUnitExercisePanel(unitExerciseId));
    }, [dispatch, unitExerciseId]);

    const openExercise = useCallback(
      (e: React.MouseEvent<HTMLDivElement>) => {
        const openInTheNewTab = isMac ? e.metaKey : e.ctrlKey;
        const path = editorPath(coursebookId!, Number(unitId), exerciseId);
        if (!openInTheNewTab) {
          dispatch(redirectFromUnitPage(path));
        } else {
          e.preventDefault();
          e.stopPropagation();
          window.open(path, '_blank', 'noopener,norefferer');
        }
      },
      [dispatch, coursebookId, exerciseId, unitId]
    );

    const createSupplementaryExercise = useCallback(() => {
      dispatch(redirectFromUnitPage(newExercisePath(coursebookId!, Number(unitId), exerciseId)));
    }, [dispatch, coursebookId, exerciseId, unitId]);

    const onViewExercise = useCallback(
      () => dispatch(viewExercise(exerciseId)),
      [dispatch, exerciseId]
    );

    const onCopyExercise = useCallback(() => {
      navigate(newExercisePath(coursebookId!, Number(unitId)), {
        state: {copiedExercise: exerciseId}
      });
    }, [navigate, coursebookId, exerciseId, unitId]);

    const copyToClipboard = useCallback(() => {
      if (clipboardExercisesLength >= CLIPBOARD_MAX_EXERCISE_COUNT) {
        return toastr.error(
          '',
          formatMessage(exerciseListMessages.ExceedExercises, {
            limit: CLIPBOARD_MAX_EXERCISE_COUNT
          })
        );
      }

      dispatch(copyExercise(exerciseId));
    }, [dispatch, formatMessage, clipboardExercisesLength, exerciseId]);

    const onDeleteUnitExercise = useCallback(() => {
      dispatch(deleteUnitExercise({pageIndex, unitExerciseId}));
    }, [dispatch, pageIndex, unitExerciseId]);

    const declineDeletingExercise = useCallback(() => setDeleteExerciseConfirm(false), []);

    useDndEmptyImage(connectDragPreview);

    return (
      <>
        <AnimationElement elementId={exerciseId}>
          {({elements, animated, onAnimationEnd}) => {
            const [first] = elements || [];

            const withAutoScroll = animated && first === exerciseId;

            return (
              <MainExerciseView
                index={index}
                pageIndex={pageIndex}
                exerciseId={exerciseId}
                animationElements={elements}
                unitExerciseId={unitExerciseId}
                excerpt={excerpt}
                readonly={readonly}
                isDragItem={isDragItem}
                openExercise={lockedBy ? undefined : openExercise}
                lockedBy={lockedBy && `${lockedBy.profile.lastName} ${lockedBy.profile.firstName}`}
                supplementaryExercisesNumber={supplementaryListLength}
                deleteUnitExercise={confirmDeletingExercise}
                toggleSupplementaryExercisesPanel={toggleSupplementaryExercisePanel}
                isInvisible={unitExerciseId === draggedUnitExerciseId && !isDragItem}
                getMainElementRef={getMainElementRef}
                connectDragSource={connectDragSource}
                connectDropTarget={connectDropTarget}
                createSupplementaryExercise={createSupplementaryExercise}
                viewExercise={onViewExercise}
                showSupplementaryExercisesPanel={suppPanelOpenedForExercises}
                copyExercise={onCopyExercise}
                copyToClipboard={copyToClipboard}
                wasCopiedToClipboard={wasCopiedToClipboard}
                onAnimationEnd={onAnimationEnd}
                withAutoScroll={withAutoScroll}
                animated={animated}
              />
            );
          }}
        </AnimationElement>
        <Confirm
          show={deleteExerciseConfirm}
          onAccept={onDeleteUnitExercise}
          onDecline={declineDeletingExercise}
          headerText={formatMessage(exerciseListMessages.DeleteExerciseConfirm)}
          disableButtons={false}
        />
      </>
    );
  }
);
