import {type Action} from 'redux';

import {LOCATION_CHANGE} from 'store/router';
import {type CoursebookPageState, type CoursebookUnit} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';
import {IntroRecord} from 'store/intro/IntroRecord';

import {type CoursebookUnitAction} from '../interface';
import {
  CONFIRM_REDIRECT,
  DECLINE_REDIRECT,
  RESET,
  SET_COURSEBOOK_COVER,
  SET_COURSEBOOK_ERROR,
  SET_COURSEBOOK_INFO,
  SET_UPDATING_COURSEBOOK,
  TOGGLE_DETAILED_COURSEBOOK_INFO
} from './actionTypes';
import {
  type ConfirmRedirectAction,
  type SetCoursebookCoverAction,
  type SetCoursebookErrorAction,
  type SetCoursebookInfoAction,
  type SetUpdatingCoursebookAction
} from './action';
import unitReducer from '../Unit/UnitExerciseList/reducers/unitReducer';
import {type ToggleElementAction} from '../../../../common/interface';
import {serverCoursebookToClientCoursebook} from '../../../../components/CoursebookLibrary/helpers';
import {
  type SetUnitCoverAction,
  type SetUnitExerciseErrorAction,
  type SetUnitIntroAction,
  type SetUnitIsUpdatingAction,
  type ToggleSuppUnitExercisePanelAction,
  type UnitExerciseAction
} from '../Unit/UnitExerciseList/actions/unitExerciseListActions';
import {
  CLEAR_UNIT_EXERCISE_LIST,
  DELETE_UNIT_EXERCISE,
  SET_UNIT_COVER,
  SET_UNIT_EXERCISE_ERROR,
  SET_UNIT_INTRO,
  SET_UNIT_IS_UPDATING,
  TOGGLE_SUPP_UNIT_EXERCISE_PANEL
} from '../Unit/UnitExerciseList/actions/unitExerciseListActionTypes';
import viewerReducer, {
  initialViewerState
} from '../Unit/UnitExerciseList/ExerciseViewerModal/reducer';
import sectionsReducer, {defaultSectionsState} from '../Unit/Sections/reducer/reducer';
import {
  SET_COURSEBOOK_UNITS,
  SET_EDITED_UNIT,
  SET_UNIT_TO_DELETE
} from '../../../../components/CoursebookLibrary/UnitsList/actionTypes';
import {type SetCoursebookUnitsAction} from '../../../../components/CoursebookLibrary/UnitsList/action';

const mutateUnits = (units: CoursebookUnit[]): CoursebookUnit[] => {
  let ordinal = 0;
  return units.map(u => ({...u, ordinal: u.isRevision ? null : ++ordinal}));
};

const ACTION_HANDLERS: ActionHandlersList<CoursebookPageState, Action> = {
  [TOGGLE_DETAILED_COURSEBOOK_INFO]: (state: CoursebookPageState, action: ToggleElementAction) => ({
    ...state,
    showDetailedCoursebookInfo: action.show
  }),
  [SET_COURSEBOOK_UNITS]: (state: CoursebookPageState, {units}: SetCoursebookUnitsAction) => ({
    ...state,
    units: mutateUnits(units)
  }),
  [SET_COURSEBOOK_INFO]: (state: CoursebookPageState, {coursebook}: SetCoursebookInfoAction) => ({
    ...state,
    coursebookInfo: serverCoursebookToClientCoursebook(coursebook)
  }),
  [SET_COURSEBOOK_COVER]: (state: CoursebookPageState, {cover}: SetCoursebookCoverAction) => ({
    ...state,
    coursebookInfo: state.coursebookInfo ? {...state.coursebookInfo!, cover} : undefined
  }),
  [SET_UNIT_COVER]: (state: CoursebookPageState, {unitId, cover}: SetUnitCoverAction) => ({
    ...state,
    units: state.units!.map(u => (u.id === unitId ? {...u, cover} : u))
  }),
  [SET_COURSEBOOK_ERROR]: (state: CoursebookPageState, {error}: SetCoursebookErrorAction) => ({
    ...state,
    coursebookRequestError: error
  }),
  [SET_UNIT_EXERCISE_ERROR]: (state: CoursebookPageState, {error}: SetUnitExerciseErrorAction) => ({
    ...state,
    unitExerciseRequestError: error
  }),
  [RESET]: (state: CoursebookPageState): CoursebookPageState => {
    return {...initialCoursebookPageState};
  },
  [SET_EDITED_UNIT]: (
    state: CoursebookPageState,
    {unitId}: CoursebookUnitAction
  ): CoursebookPageState => ({
    ...state,
    editedUnitId: unitId
  }),
  [SET_UNIT_TO_DELETE]: (
    state: CoursebookPageState,
    {unitId}: CoursebookUnitAction
  ): CoursebookPageState => ({
    ...state,
    unitIdToDelete: unitId
  }),
  [SET_UNIT_IS_UPDATING]: (state: CoursebookPageState, {isUpdating}: SetUnitIsUpdatingAction) => ({
    ...state,
    updatingUnit: isUpdating
  }),
  [TOGGLE_SUPP_UNIT_EXERCISE_PANEL]: (
    state: CoursebookPageState,
    {unitExerciseId}: ToggleSuppUnitExercisePanelAction
  ) => {
    const {suppPanelOpenForExercises} = state;
    const suppPanelWasOpen = suppPanelOpenForExercises.includes(unitExerciseId);
    return {
      ...state,
      suppPanelOpenForExercises: suppPanelWasOpen
        ? suppPanelOpenForExercises.filter(id => id !== unitExerciseId)
        : [unitExerciseId, ...suppPanelOpenForExercises]
    };
  },
  [DELETE_UNIT_EXERCISE]: (state: CoursebookPageState, {unitExerciseId}: UnitExerciseAction) => ({
    ...state,
    suppPanelOpenForExercises: state.suppPanelOpenForExercises.filter(id => id !== unitExerciseId)
  }),
  [LOCATION_CHANGE]: (state: CoursebookPageState) => ({
    ...state,
    suppPanelOpenForExercises: [],
    confirmRedirectToPath: undefined,
    unitExerciseRequestError: undefined
  }),
  [CONFIRM_REDIRECT]: (state: CoursebookPageState, action: ConfirmRedirectAction) => ({
    ...state,
    confirmRedirectToPath: action.path
  }),
  [DECLINE_REDIRECT]: (state: CoursebookPageState) => ({
    ...state,
    confirmRedirectToPath: undefined
  }),
  [SET_UPDATING_COURSEBOOK]: (
    state: CoursebookPageState,
    {isUpdating}: SetUpdatingCoursebookAction
  ) => ({
    ...state,
    isUpdating
  }),
  [CLEAR_UNIT_EXERCISE_LIST]: (state: CoursebookPageState) => ({
    ...state,
    unitExerciseRequestError: undefined
  }),
  [SET_UNIT_INTRO]: (state: CoursebookPageState, action: SetUnitIntroAction) => ({
    ...state,
    intro: action.intro ? new IntroRecord(action.intro) : undefined
  })
};

const initialCoursebookPageState: CoursebookPageState = {
  suppPanelOpenForExercises: [],
  exerciseViewer: initialViewerState,
  sections: defaultSectionsState
};

export default function (
  state: CoursebookPageState = initialCoursebookPageState,
  action: Action
): CoursebookPageState {
  const reducer = ACTION_HANDLERS[action.type];
  const newState = reducer ? reducer(state, action) : state;

  return {
    ...newState,
    unit: unitReducer(newState.unit, action),
    exerciseViewer: viewerReducer(newState.exerciseViewer, action),
    sections: sectionsReducer(newState.sections, action)
  };
}
