import React, {Component} from 'react';
import {type WrappedComponentProps, injectIntl, FormattedMessage} from 'react-intl';

import {type Coursebook} from 'store/interface';

import {coursebookLibraryMessages} from '../../../components/CoursebookLibrary/messages';
import OriginalAuthorTooltip from '../../../components/CoursebookLibrary/components/OriginalAuthorTooltip';
import './CoursebookInfoBody.scss';

interface Props extends WrappedComponentProps {
  coursebook: Coursebook;
  collapsibleLists?: boolean;
}

class CoursebookInfoBody extends Component<Props> {
  private mainContainer: HTMLDivElement | null;

  public render() {
    const {
      coursebook: {
        author: {
          profile: {role, firstName, lastName}
        },
        originalAuthor,
        overview,
        courses,
        levels
      },
      intl: {formatMessage}
    } = this.props;
    const {unitsCount = 0, exercisesNumber = 0, testsCount = 0} = overview || {};

    const coursesNames = courses.map(c => c.name);
    const levelsTitles = levels.map(l => l.title);

    return (
      <div className="coursebook-info-body" ref={this.getRef}>
        <div className="column">
          <div className="entity author">
            <b>{formatMessage(coursebookLibraryMessages.Author)}:</b>
            <span className="text">
              {role === 'teacher'
                ? `${lastName} ${firstName}`
                : formatMessage(coursebookLibraryMessages.EnglexDepartmentOfMethodology)}
              {originalAuthor ? <OriginalAuthorTooltip originalAuthor={originalAuthor} /> : null}
            </span>
          </div>
          <div className="entity list">
            <b className="list-label">{formatMessage(coursebookLibraryMessages.Course)}:</b>
            {coursesNames.length ? (
              <span className="text" title={coursesNames.join('\n')}>
                {coursesNames.join(', ')}
              </span>
            ) : (
              <i className="text">
                <FormattedMessage id="CoursebookLibrary.NotSpecifiedPlaceholder" />
              </i>
            )}
          </div>
          <div className="entity list">
            <b className="list-label">{formatMessage(coursebookLibraryMessages.Level)}:</b>
            {levelsTitles.length ? (
              <span className="text" title={levelsTitles.join('\n')}>
                {levelsTitles.join(', ')}
              </span>
            ) : (
              <i className="text">
                <FormattedMessage id="CoursebookLibrary.NotSpecifiedPlaceholder" />
              </i>
            )}
          </div>
        </div>
        <div className="column">
          <div className="entity">
            <b>{formatMessage({id: 'ViewCoursebookContentsModal.Units'})}:</b>
            <b className="mobile">
              <FormattedMessage id="ViewCoursebookContentsModal.Units.Short" />:
            </b>
            <span>{unitsCount}</span>
          </div>
          <div className="entity">
            <b>{formatMessage({id: 'Coursebook.Tests'})}:</b>
            <b className="mobile">
              <FormattedMessage id="Coursebook.Tests.Short" />:
            </b>
            <span>{testsCount}</span>
          </div>
          <div className="entity">
            <b>{formatMessage({id: 'Coursebook.Exercises'})}:</b>
            <b className="mobile">
              <FormattedMessage id="Coursebook.Exercises.Short" />:
            </b>
            <span>{exercisesNumber}</span>
          </div>
        </div>
      </div>
    );
  }

  private getRef = (el: HTMLDivElement | null) => (this.mainContainer = el);

  private getTooltipContainer = () => this.mainContainer || document.body;
}

export default injectIntl(CoursebookInfoBody);
