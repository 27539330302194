import React from 'react';
import {type FormikBag, type FormikProps, withFormik} from 'formik';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import * as Yup from 'yup';

import ReactSuggest from 'components/ReactSelect/ReactSuggest';
import {type OptionsType, type OptionType} from 'components/ReactSelect/interface';
import {type CoursebookSection, type Section} from 'store/interface';

import {sectionsMessages} from '../i18n';
import toValidFilter from '../../../../../../helpers/toValidFilter';

interface Values {
  title: string;
}

interface Props extends WrappedComponentProps {
  sections: Section[];
  coursebookSections: CoursebookSection[];
  editedCoursebookSectionTitle?: string;
  renderFooterButtons: () => JSX.Element;
  handleSubmit: (title: string) => void;
}

type FormProps = Props & FormikProps<Values>;

class CoursebookSectionModalForm extends React.Component<FormProps> {
  public render() {
    const {values, errors, sections, touched, renderFooterButtons} = this.props;
    return (
      <form onSubmit={this.props.handleSubmit}>
        <Modal.Body>
          <FormGroup
            controlId="section-title"
            validationState={errors.title && touched.title ? 'error' : undefined}
          >
            <ControlLabel>
              <FormattedMessage id="CoursebookLibrary.DataModal.Title" />
              {': '}
            </ControlLabel>
            <ReactSuggest
              inputValue={values.title}
              onChange={this.onTitleChange}
              options={sections.map(section => ({label: section.title, value: section.title}))}
              placeholder={sectionsMessages.ModalTitlePlaceholder}
              customFilter={this.customFilter}
              noOptionsMessage={sectionsMessages.ModalSectionAlreadyExists}
            />
            <div className="help-block error">{errors.title}</div>
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>{renderFooterButtons()}</Modal.Footer>
      </form>
    );
  }

  private onTitleChange = (value: string) => {
    const {setFieldValue, touched, setFieldTouched} = this.props;
    setFieldValue('title', value);
    if (!touched.title) {
      setTimeout(() => setFieldTouched('title', true), 0);
    }
  };

  private customFilter = (options: OptionsType<OptionType>) => {
    const {
      values: {title},
      intl: {formatMessage}
    } = this.props;

    const filteredOptions = options
      .filter(this.alreadyInCoursebookFilter)
      .filter(this.suggestInputFilter);

    const shouldAddCreateSectionOption =
      title !== '' &&
      !options.find(option => option.label.toLowerCase() === title.trim().toLowerCase());

    if (shouldAddCreateSectionOption) {
      return [
        {
          label: formatMessage(sectionsMessages.ModalCreateNewSectionOption, {title}),
          value: title
        },
        ...filteredOptions
      ];
    }
    return filteredOptions;
  };

  private suggestInputFilter = (option: OptionType) =>
    option.label.toLowerCase().match(toValidFilter(this.props.values.title.trim()));

  private alreadyInCoursebookFilter = ({value}: OptionType) =>
    !this.props.coursebookSections.find(
      coursebookSection =>
        coursebookSection.section.title.toLowerCase() === value.trim().toLowerCase()
    );
}

const mapPropsToValues = (props: Props): Values => {
  return {
    title: props.editedCoursebookSectionTitle || ''
  };
};

const handleSubmit = (values: Values, {props}: FormikBag<Props, Values>) => {
  return props.handleSubmit(values.title);
};

const validationSchema = ({intl: {formatMessage}, coursebookSections}: FormProps) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(formatMessage(sectionsMessages.ValidationErrTitleEmpty))
      .notOneOf(
        coursebookSections.map(coursebookSection => coursebookSection.section.title),
        formatMessage(sectionsMessages.ModalSectionAlreadyExists)
      )
  });

const WithFormik = withFormik<Props, Values>({
  mapPropsToValues,
  handleSubmit,
  validationSchema,
  validateOnChange: true
})(CoursebookSectionModalForm);

export default injectIntl(WithFormik);
