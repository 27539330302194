import React from 'react';

import {LibraryListAlignment} from 'components/CoursebookLibrary/LibraryPageList/constants';
import LibraryPageListEl from 'components/CoursebookLibrary/LibraryPageList/LibraryPageListEl';
import LibraryPageListElHandle from 'components/CoursebookLibrary/LibraryPageList/LibraryPageListElHandle';

interface Props {
  position: number;
  elementWidth: number;
  style: React.CSSProperties;
  title: string;
}

export default class CoursebookSectionDragItem extends React.Component<Props> {
  public render() {
    const {style, elementWidth, title} = this.props;
    return (
      <div style={{...style, width: elementWidth, right: elementWidth - 25, position: 'relative'}}>
        <LibraryPageListEl
          dropdownId={``}
          dragging={false}
          isActive={false}
          processingRequest={false}
          handleClick={this.emptyFunction}
          title={title}
          dropdownButtons={[]}
          alignment={LibraryListAlignment.RIGHT}
          isDragItem={true}
          renderHandle={() => <LibraryPageListElHandle />}
        />
      </div>
    );
  }

  private emptyFunction = () => null;
}
