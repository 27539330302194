import {type Action, type ReducersMapObject} from 'redux';

import {type FilterState} from './interface';
import {
  RESET_FILTERS,
  SET_COURSEBOOK_OPTIONS,
  SET_SELECTED_COURSEBOOK_IDS,
  SET_SHOW_PROCESSED,
  type SetCoursebookOptionsAction,
  type SetSelectedCoursebookIdsAction,
  type SetShowProcessedAction
} from './actions';

export const initialFilterState = {
  showProcessed: false
};

const actionHandlers: ReducersMapObject = {
  [SET_SHOW_PROCESSED]: (
    state: FilterState,
    {showProcessed}: SetShowProcessedAction
  ): FilterState => ({...state, showProcessed}),
  [SET_COURSEBOOK_OPTIONS]: (
    state: FilterState,
    {coursebookOptions}: SetCoursebookOptionsAction
  ): FilterState => ({
    ...state,
    coursebookOptions
  }),
  [SET_SELECTED_COURSEBOOK_IDS]: (
    state: FilterState,
    {selectedCoursebookIds}: SetSelectedCoursebookIdsAction
  ) => ({...state, selectedCoursebookIds}),
  [RESET_FILTERS]: ({coursebookOptions}: FilterState): FilterState => ({
    ...initialFilterState,
    coursebookOptions
  })
};

export const filterReducer = (state: FilterState = initialFilterState, action: Action) => {
  const handler = actionHandlers[action.type];
  return handler ? handler(state, action) : state;
};
