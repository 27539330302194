import React, {type FC, useCallback} from 'react';
import {Route, Routes, useParams} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import {injectIntl, type WrappedComponentProps} from 'react-intl';

import {replace} from 'store/router';
import {coursebookPath} from 'common/paths';
import {type AppState, type CoursebookUnit} from 'store/interface';

import UnitExercisesList from './UnitExerciseList';
import Sections from './Sections';
import {type UnitRouteParams} from '../interface';
import {RedirectConfirm} from './RedirectConfirm';

interface StateProps {
  units?: CoursebookUnit[];
  confirmRedirectToPath?: string;
}

interface Props extends StateProps, WrappedComponentProps, UnitRouteParams {
  replace: (path: string) => void;
}

class UnitContainer extends React.Component<Props & UnitRouteParams> {
  public componentDidMount() {
    const {units, coursebookId, unitId, replace} = this.props;
    if (units) {
      if (!units.find(u => String(u.id) === unitId)) {
        replace(coursebookPath(coursebookId));
      }
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {units, coursebookId, unitId, replace} = this.props;
    if (!units || !prevProps.units) {
      replace(coursebookPath(coursebookId));
      return;
    }
    if (prevProps.units.length !== units.length && !units.find(u => String(u.id) === unitId)) {
      replace(coursebookPath(coursebookId));
    }
  }

  public render() {
    return (
      <>
        <Routes>
          <Route
            index={true}
            element={
              <UnitExercisesList
                selectedUnitId={Number(this.props.unitId)}
                coursebookId={this.props.coursebookId}
              />
            }
          />
          <Route path="section">
            <Route index={true} element={<Sections />} />
            <Route path=":coursebookSectionId" element={<Sections />} />
          </Route>
        </Routes>
        <Routes>
          <Route path="section/*" element={<RedirectConfirm isSection={true} />} />
          <Route index={true} element={<RedirectConfirm />} />
        </Routes>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  units: state.coursebookPage!.units
});

const ConnectedUnitContainer = injectIntl(connect(mapStateToProps)(UnitContainer));

const UnitRoute: FC = () => {
  const {unitId, coursebookId} = useParams<UnitRouteParams>();
  const dispatch = useDispatch();

  const replacePath = useCallback((path: string) => dispatch(replace(path)), [dispatch]);

  return (
    <ConnectedUnitContainer unitId={unitId} coursebookId={coursebookId!} replace={replacePath} />
  );
};

export default UnitRoute;
