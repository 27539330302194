import React, {type FC, useCallback, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import {useIntl} from 'react-intl';

import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import * as toastr from 'components/toastr';

import {deleteIssue, processIssue, unprocessIssue} from '../actions';

interface Props {
  id: number;
  processed: boolean;
  onDelete(id: number, from404?: true): void;
  onProcess(id: number, processedAt: string | null): void;
}

const Actions: FC<Props> = ({id, processed, onDelete, onProcess}) => {
  const intl = useIntl();
  const axiosDispatch = useAxiosDispatch();

  const [processing, setProcessing] = useState(false);
  const [trashing, setTrashing] = useState(false);

  const trash = useCallback(
    (e: React.MouseEvent<Button, MouseEvent>) => {
      e.stopPropagation();
      setTrashing(true);
      axiosDispatch(deleteIssue(id))
        .then(() => onDelete(id))
        .catch((e: AxiosRequestError) => {
          if (e.error.response && e.error.response.status === 404) {
            onDelete(id, true);
            toastr.error('', intl.formatMessage({id: 'Feedback.IssueAlreadyDoesNotExistError'}));
          } else {
            toastr.error('', intl.formatMessage({id: 'Feedback.DeletingIssueError'}));
          }
        })
        .finally(() => setTrashing(false));
    },
    [axiosDispatch, id, intl, onDelete]
  );

  const performProcess = useCallback(
    (request, action) => {
      if (processing) return;
      setProcessing(true);
      axiosDispatch<AxiosResponseAction<{processedAt: string | null}>>(request(id))
        .then(
          ({
            payload: {
              data: {processedAt}
            }
          }) => onProcess(id, processedAt)
        )
        .then(() =>
          toastr.success(
            '',
            action === 'process'
              ? intl.formatMessage({id: 'Feedback.ProcessSuccessToastr'})
              : intl.formatMessage({id: 'Feedback.UnprocessSuccessToastr'})
          )
        )
        .catch((e: AxiosRequestError) => {
          if (e.error.response && e.error.response.status === 404) {
            onDelete(id, true);
            toastr.error('', intl.formatMessage({id: 'Feedback.ProcessedIssueDoesNotExistError'}));
          } else {
            toastr.error('', intl.formatMessage({id: 'Feedback.ProcessingIssueError'}));
          }
        })
        .finally(() => setProcessing(false));
    },
    [axiosDispatch, id, intl, onDelete, onProcess, processing]
  );

  const process = useCallback(
    (e: React.MouseEvent<Button, MouseEvent>) => {
      e.stopPropagation();
      performProcess(processIssue, 'process');
    },
    [performProcess]
  );

  const unprocess = useCallback(
    (e: React.MouseEvent<Button, MouseEvent>) => {
      e.stopPropagation();
      performProcess(unprocessIssue, 'unprocess');
    },
    [performProcess]
  );

  const disabled = processing || trashing;

  return (
    <div className={classNames('actions', {processing: disabled})}>
      <Button className="btn-ico" onClick={trash} disabled={disabled}>
        {trashing ? <Spinner size={21} /> : <Icon name="trash" />}
      </Button>
      {processed ? (
        <Button className="btn-ico reopen" onClick={unprocess} disabled={disabled}>
          {processing ? <Spinner size={21} /> : <Icon name="times-circle" />}
        </Button>
      ) : (
        <Button className="btn-ico check" onClick={process} disabled={disabled}>
          {processing ? <Spinner size={21} /> : <Icon name="check-circle" />}
        </Button>
      )}
    </div>
  );
};

export default Actions;
