import {type Action} from 'redux';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {type CoursebookUnitExerciseResponse} from 'store/exercise/editor/actions/xeditor';
import {type ExerciseJSON} from 'store/exercise/player/interface';

const prefix = 'EXERCISE_VIEWER/';
export const VIEW_EXERCISE = prefix + 'VIEW_EXERCISE';
export const CLOSE_EXERCISE_VIEWER = prefix + 'CLOSE_EXERCISE_VIEWER';
const REQUEST_EXERCISE_FOR_VIEWER = prefix + 'REQUEST_EXERCISE_FOR_VIEWER';
export const SET_EXERCISE_VIEWER_ERROR = prefix + 'SET_EXERCISE_VIEWER_ERROR';
export const REMOVE_EXERCISE_VIEWER_ERROR = prefix + 'REMOVE_EXERCISE_VIEWER_ERROR';
export const CREATE_VIEWER_PREVIEW = prefix + 'CREATE_VIEWER_PREVIEW';

export interface ViewExerciseAction extends Action {
  id: string;
}

export const viewExercise = (id: string): ViewExerciseAction => ({
  type: VIEW_EXERCISE,
  id
});

export const closeExerciseViewer = () => ({
  type: CLOSE_EXERCISE_VIEWER
});

export const requestExerciseForViewer = (
  coursebookId: string,
  unitId: number,
  exerciseId: string
): AxiosRequestAction => ({
  type: REQUEST_EXERCISE_FOR_VIEWER,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise/${exerciseId}`
    }
  }
});

export type RequestExerciseViewerSuccessAction =
  AxiosResponseAction<CoursebookUnitExerciseResponse>;

export const setExerciseViewerError = (): Action => ({
  type: SET_EXERCISE_VIEWER_ERROR
});

export const removeExerciseViewerError = (): Action => ({
  type: REMOVE_EXERCISE_VIEWER_ERROR
});

export interface CreateViewerPreviewAction extends Action {
  exercise: ExerciseJSON;
}

export const createViewerPreview = (exercise: ExerciseJSON): CreateViewerPreviewAction => ({
  type: CREATE_VIEWER_PREVIEW,
  exercise
});
