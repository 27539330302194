import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import {type WidgetJSON} from '../player/interface';
import {
  COPY_WIDGET,
  CLEAR_WIDGET_CLIPBOARD,
  COPY_UNIT,
  CLEAR_UNITS_CLIPBOARD,
  COPY_EXERCISE,
  CLEAR_EXERCISES_CLIPBOARD
} from './actionTypes/xeditor';
import {
  type CopyWidgetAction,
  type CopyUnitAction,
  type CopyExerciseAction
} from './actions/xeditor';

export const CLIPBOARD_MAX_UNIT_COUNT = 10;

export const CLIPBOARD_MAX_EXERCISE_COUNT = 30;

export interface ClipboardState {
  widget?: WidgetJSON;
  units: number[];
  exercises: string[];
}

const initialState = {units: [], exercises: []};

const REDUCERS: ReducersMapObject = {
  [COPY_WIDGET]: (state: ClipboardState, action: CopyWidgetAction): ClipboardState => {
    return {...state, widget: action.widget};
  },
  [CLEAR_WIDGET_CLIPBOARD]: (state): ClipboardState => {
    return {...state, widget: undefined};
  },
  [COPY_UNIT]: (state: ClipboardState, action: CopyUnitAction): ClipboardState => {
    return {...state, units: Array.from(new Set([...state.units, action.unitId]))};
  },
  [CLEAR_UNITS_CLIPBOARD]: (state): ClipboardState => {
    return {...state, units: []};
  },
  [COPY_EXERCISE]: (state: ClipboardState, action: CopyExerciseAction): ClipboardState => {
    return {...state, exercises: Array.from(new Set([...state.exercises, action.exerciseId]))};
  },
  [CLEAR_EXERCISES_CLIPBOARD]: (state): ClipboardState => {
    return {...state, exercises: []};
  }
};

function clipboardReducer(state: ClipboardState = initialState, action: Action): ClipboardState {
  const reducer: Reducer<ClipboardState> = REDUCERS[action.type];

  return reducer ? reducer(state, action) : state;
}

export default clipboardReducer;
