import React, {type FC} from 'react';
import {useParams} from 'react-router-dom';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import {unitPath, unselectedSectionPath} from 'common/paths';
import Icon from 'components/Icon';

import {type UnitRouteParams} from '../interface';
import {redirectFromUnitPage} from '../state/action';

export enum SelectedUnitTab {
  EXERCISE_LIST = 'EXERCISE_LIST',
  SECTIONS = 'SECTIONS'
}

interface Props {
  selectedTab: SelectedUnitTab;
}

export const UnitTabSelector: FC<Props> = ({selectedTab}) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {unitId, coursebookId} = useParams<UnitRouteParams>();

  const onChange = (value: SelectedUnitTab) => {
    if (value === SelectedUnitTab.EXERCISE_LIST) {
      dispatch(redirectFromUnitPage(unitPath(coursebookId!, Number(unitId))));
    }
    if (value === SelectedUnitTab.SECTIONS) {
      dispatch(redirectFromUnitPage(unselectedSectionPath(coursebookId!, Number(unitId))));
    }
  };

  return (
    <ToggleButtonGroup
      type="radio"
      bsSize="sm"
      name="CoursebookPageHeader.UnitInfoTab"
      value={selectedTab}
      className="unit-info-tab-switch"
      onChange={onChange}
    >
      <ToggleButton value={SelectedUnitTab.EXERCISE_LIST}>
        <FormattedMessage id="Coursebook.Exercises" />
        <Icon name="list" title={intl.formatMessage({id: 'Coursebook.Exercises'})} />
      </ToggleButton>
      <ToggleButton value={SelectedUnitTab.SECTIONS}>
        <FormattedMessage id="Coursebook.Contents" />
        <Icon name="sitemap" title={intl.formatMessage({id: 'Coursebook.Contents'})} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
