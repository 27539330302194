const prefix = 'UNIT_EXERCISE_LIST/';

export const SET_UNIT_EXERCISE_LIST = prefix + 'SET_UNIT_EXERCISE_LIST';
export const LOAD_UNIT_EXERCISE_LIST = prefix + 'LOAD_UNIT_EXERCISE_LIST';
export const DELETE_UNIT_EXERCISE = prefix + 'DELETE_UNIT_EXERCISE';
export const TOGGLE_SUPP_UNIT_EXERCISE_PANEL = prefix + 'TOGGLE_SUPP_UNIT_EXERCISE_PANEL';
export const UNIT_HISTORY_UNDO = prefix + 'UNIT_HISTORY_UNDO';
export const UNIT_HISTORY_REDO = prefix + 'UNIT_HISTORY_REDO';
export const UNIT_HISTORY_CLEAR = prefix + 'UNIT_HISTORY_CLEAR';
export const SET_UNIT_IS_UPDATING = prefix + 'SET_UNIT_IS_UPDATING';
export const UPDATE_UNIT_REQUEST = prefix + 'UPDATE_UNIT_REQUEST';
export const UNIT_HISTORY_SAVE = prefix + 'UNIT_HISTORY_SAVE';
export const CLEAR_UNIT_EXERCISE_LIST = prefix + 'CLEAR_UNIT_EXERCISE_LIST';
export const SET_UNIT_EXERCISE_ERROR = prefix + 'SET_UNIT_EXERCISE_ERROR';
export const EDIT_UNIT_COVER = prefix + 'EDIT_UNIT_COVER';
export const SET_UNIT_COVER = prefix + 'SET_UNIT_COVER';
export const LOAD_UNIT_INTRO = prefix + 'LOAD_UNIT_INTRO';
export const SET_UNIT_INTRO = prefix + 'SET_UNIT_INTRO';
export const COPY_UNIT_EXERCISES = prefix + 'COPY_UNIT_EXERCISES';
