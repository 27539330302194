import React, {useCallback, useContext, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import * as toastr from 'components/toastr';
import {clearExercisesClipboard} from 'store/exercise/editor/actions/xeditor';
import {type AppState} from 'store/interface';
import Icon from 'components/Icon';

import {ButtonGroup} from '../../../../../../components/ui/ButtonGroup/ButtonGroup';
import Confirm from '../../../../../../components/modals/Confirm';
import {exerciseListMessages} from '../messages';
import {copyExercises} from '../actions/unitExerciseListActions';
import {AnimationActionsContext} from '../../../AnimationElementsContext';

interface Props {
  className?: string;
  hasChanges?: boolean;
  historyClear: () => void;
}

type RouteParams = {
  unitId: string;
  coursebookId: string;
};

export const PasteExercisesButton: React.FC<Props> = React.memo(
  ({className, hasChanges, historyClear}) => {
    const intl = useIntl();

    const dispatch: Dispatch<Action, AppState> = useDispatch();

    const {addElements} = useContext(AnimationActionsContext);

    const {unitId, coursebookId} = useParams<RouteParams>();

    const [loading, setLoading] = useState(false);

    const [show, setShow] = useState(false);

    const exercises = useSelector((state: AppState) => state.clipboard.exercises);

    const hide = useCallback(() => setShow(false), []);

    const onDelete = useCallback(() => dispatch(clearExercisesClipboard()), [dispatch]);

    const onPaste = useCallback(async () => {
      setLoading(true);

      try {
        const ids = await dispatch(copyExercises(coursebookId!, Number(unitId), exercises));
        onDelete();
        addElements(ids);
      } catch {
        toastr.error('', intl.formatMessage(exerciseListMessages.PasteExercisesError));
      } finally {
        setLoading(false);
      }
    }, [dispatch, coursebookId, unitId, exercises, onDelete, addElements, intl]);

    const tryPaste = useCallback(async () => {
      if (hasChanges) {
        return setShow(true);
      }

      await onPaste();
    }, [hasChanges, onPaste]);

    const onAccept = useCallback(async () => {
      historyClear();
      hide();
      await onPaste();
    }, [historyClear, hide, onPaste]);

    if (exercises.length) {
      return (
        <>
          <ButtonGroup
            disabled={loading}
            className={className}
            onLeftClick={tryPaste}
            onRightClick={onDelete}
            renderLeft={() => (
              <>
                <span>
                  <Icon name="paste" />
                </span>
                <span>
                  <FormattedMessage
                    id="Coursebook.CopyToClipboard.Paste"
                    values={{count: exercises.length}}
                  />
                </span>
              </>
            )}
            renderRight={() => <Icon name="trash" />}
          />

          <Confirm
            show={show}
            onAccept={onAccept}
            onDecline={hide}
            headerText={intl.formatMessage(exerciseListMessages.ClipboardUnsavedChanges)}
            disableButtons={false}
          />
        </>
      );
    }

    return null;
  }
);
