import React, {type PropsWithChildren} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import Label from 'react-bootstrap/lib/Label';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import {type AppState, type Coursebook} from 'store/interface';
import {CoursebookStatus, CoursebookType} from 'common/enums';

import {coursebookLibraryMessages} from '../../../components/CoursebookLibrary/messages';
import CoursebookItemDropdown from './CoursebookItemDropdown';
import {openCoursebookDataModal} from './CoursebookDataModal/action';
import './CoursebookInfoHeader.scss';

interface OwnProps {
  inItem?: true;
  coursebook: Coursebook;
  deleteCoursebook: () => void;
  openViewContentsModal: (id: string) => void;
  openViewGrammarModal: (id: string) => void;
}

interface DispatchProps {
  editCoursebook: (coursebook: Coursebook) => void;
  copyCoursebook: (coursebook: Coursebook) => void;
}

type Props = PropsWithChildren<OwnProps> & DispatchProps & WrappedComponentProps;

class CoursebookInfoHeader extends React.Component<Props> {
  public render() {
    const {
      children,
      coursebook,
      coursebook: {title},
      deleteCoursebook,
      inItem
    } = this.props;
    return (
      <div className={`coursebook-info-header${inItem ? ' in-item' : ''}`}>
        <h4 title={title}>{title}</h4>
        <span className="coursebook-info-header__labels">
          {this.renderType()}
          {this.renderStatus()}
        </span>
        <div className="custom-children-container">{children}</div>
        <CoursebookItemDropdown
          coursebook={coursebook}
          edit={this.editCoursebook}
          copy={this.copyCoursebook}
          delete={deleteCoursebook}
          viewContents={this.viewContents}
          viewGrammar={this.viewGrammar}
        />
      </div>
    );
  }

  private viewContents = () => this.props.openViewContentsModal(this.props.coursebook.id);
  private viewGrammar = () => this.props.openViewGrammarModal(this.props.coursebook.id);

  private editCoursebook = () => this.props.editCoursebook(this.props.coursebook);

  private copyCoursebook = () => this.props.copyCoursebook(this.props.coursebook);

  private renderType = () => {
    const {
      coursebook: {type},
      intl: {formatMessage}
    } = this.props;
    return (
      <Label
        bsStyle="info"
        className={classNames({'label-transparent': type === CoursebookType.PROPRIETARY})}
      >
        {type === CoursebookType.COMMON
          ? formatMessage(coursebookLibraryMessages.CoursebookTypeCommon)
          : formatMessage(coursebookLibraryMessages.CoursebookTypeProprietary)}
      </Label>
    );
  };

  private renderStatus = () => {
    const {
      coursebook: {status},
      intl: {formatMessage}
    } = this.props;
    if (status === CoursebookStatus.PUBLISHED) {
      return (
        <Label bsStyle="success">{formatMessage(coursebookLibraryMessages.StatusPublished)}</Label>
      );
    }
    return <Label bsStyle="danger">{formatMessage(coursebookLibraryMessages.StatusHidden)}</Label>;
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  editCoursebook: (coursebook: Coursebook) =>
    dispatch(openCoursebookDataModal({editedCoursebook: coursebook})),
  copyCoursebook: (coursebook: Coursebook) =>
    dispatch(openCoursebookDataModal({copiedCoursebook: coursebook}))
});

export default connect(null, mapDispatchToProps)(injectIntl(CoursebookInfoHeader));
