import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import BootstrapGroup from 'react-bootstrap/lib/ButtonGroup';
import classNames from 'classnames';

import './ButtonGroup.scss';

interface Props {
  className?: string;
  onLeftClick?: () => void;
  onRightClick?: () => void;
  disabled?: boolean;

  renderLeft: () => React.ReactElement;
  renderRight: () => React.ReactElement;
}

export function ButtonGroup({
  className,
  disabled,
  onLeftClick,
  onRightClick,
  renderLeft,
  renderRight
}: Props) {
  return (
    <div className={classNames('custom-button-group', className)}>
      <BootstrapGroup>
        <Button
          className="custom-button-group__left"
          bsStyle="success"
          bsSize="sm"
          disabled={disabled}
          onClick={onLeftClick}
        >
          {renderLeft()}
        </Button>
        <Button
          className="custom-button-group__right"
          bsStyle="success"
          bsSize="sm"
          disabled={disabled}
          onClick={onRightClick}
        >
          {renderRight()}
        </Button>
      </BootstrapGroup>
    </div>
  );
}
