import React, {type FC, useCallback, useEffect, useState} from 'react';
import Panel from 'react-bootstrap/lib/Panel';
import classNames from 'classnames';

import FeedbackContent from './FeedbackContent';
import {type ExerciseFeedback, type ExerciseFeedbackDetails} from '../interface';
import './FeedbackItem.scss';

interface Props {
  activeKey: number | null;
  beingDeleted: boolean;
  details?: ExerciseFeedbackDetails;
  eventKey: number;
  feedback: ExerciseFeedback;
  onDeleted(id: number): void;
}

const FeedbackItem: FC<Props> = ({
  activeKey,
  beingDeleted,
  children,
  details,
  eventKey,
  feedback,
  onDeleted
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const [collapsing, setCollapsing] = useState(false);
  const [detailsCopy, setDetailsCopy] = useState<ExerciseFeedbackDetails | null>(null);

  const onAnimationEnd = useCallback(() => {
    onDeleted(feedback.id);
  }, [feedback.id, onDeleted]);

  useEffect(() => {
    if (details) {
      setDetailsCopy(details);
      return;
    }
    if (!details && collapsed) {
      setDetailsCopy(null);
    }
  }, [collapsed, details]);

  useEffect(() => {
    if (activeKey === eventKey) setCollapsed(false);
  }, [activeKey, eventKey]);

  return (
    <Panel
      eventKey={eventKey}
      className={classNames('feedback-item', {
        'being-deleted': beingDeleted,
        expanded: !collapsed && !collapsing,
        processed: feedback.processedAt
      })}
      onAnimationEnd={onAnimationEnd}
    >
      {children}
      <Panel.Collapse
        onExiting={() => {
          setCollapsing(true);
        }}
        onExited={() => {
          setCollapsed(true);
          setCollapsing(false);
        }}
      >
        <Panel.Body>
          {collapsed || !detailsCopy ? null : (
            <FeedbackContent feedback={feedback} details={detailsCopy} />
          )}
        </Panel.Body>
      </Panel.Collapse>
    </Panel>
  );
};

export default FeedbackItem;
