import React, {type FC, type ReactElement, useCallback} from 'react';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import Scrollbars from 'react-custom-scrollbars';
import {FormattedMessage, injectIntl, useIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';
import {useNavigate, useParams} from 'react-router-dom';

import * as toastr from 'components/toastr';
import {type AppState, type CoursebookUnit} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {RenameUnitModal} from 'routes/Library/CoursebookPage/UnitsList/containers/RenameUnitModal';
import {DeleteUnitModal} from 'routes/Library/CoursebookPage/UnitsList/containers/DeleteUnitModal';
import {selectedSectionPath, unitPath, unselectedSectionPath} from 'common/paths';
import {sectionsMessages} from 'routes/Library/CoursebookPage/Unit/Sections/i18n';
import {exerciseListMessages} from 'routes/Library/CoursebookPage/Unit/UnitExerciseList/messages';

import {UnitItem} from './containers/UnitItem';
import {messages} from './i18n';
import {requestUnitsRearrangement, setCoursebookUnits} from './action';
import UnitPreviewModal from '../../modals/UnitPreview/UnitPreviewModal';
import {type SectionRouteParams} from '../../../routes/Library/CoursebookPage/interface';
import EmptyList from '../LibraryPageList/EmptyList';
import {CollapsedButton} from '../components/CollapsedButton/CollapsedButton';
import './styles.scss';

interface OwnProps {
  editCoursebookSuccessCallback: () => void;
  shouldScrollToId?: true;
}

interface StateProps {
  units: CoursebookUnit[];
}

interface DispatchProps {
  endDrag: (
    coursebookId: string,
    sequence: number,
    unitId: number
  ) => Promise<AxiosResponseAction<CoursebookUnit[]>>;
  refreshUnitsList: (units: CoursebookUnit[]) => void;
}

interface Props
  extends OwnProps,
    StateProps,
    DispatchProps,
    WrappedComponentProps,
    SectionRouteParams {
  selectUnitId: (id: number) => void;
  confirmMessage: string | ReactElement;
}

interface State {
  dragId: number | null;
  hoverSequence: number | null;
  processingRequest: boolean;
  units: CoursebookUnit[];
  previewUnitId: number | null;
  isOpen: boolean;
}

class UnitsListComponent extends React.Component<Props, State> {
  public state: State = {
    dragId: null,
    hoverSequence: null,
    processingRequest: false,
    units: this.props.units.sort((u1, u2) => u1.sequence - u2.sequence),
    previewUnitId: null,
    isOpen: false
  };

  public componentDidMount(): void {
    this.scrollToUnitId();
  }

  private component: HTMLDivElement | null;

  public static getDerivedStateFromProps(props: Props, state: State) {
    if (state.dragId) {
      return null;
    }
    return {units: props.units.sort((u1, u2) => u1.sequence - u2.sequence)};
  }

  public render() {
    const {Units} = this;
    const {units} = this.state;
    const empty = !units.length;

    const emptyListMessage = <FormattedMessage id="Coursebook.UnitsList.EmptyList" />;
    return (
      <div
        className={classNames(`units-list${empty ? ' empty' : ''}`, {'is-open': this.state.isOpen})}
        ref={this.componentRef}
      >
        <CollapsedButton isCollapsed={!this.state.isOpen} onClick={this.switchIsOpen} />
        <Scrollbars autoHide={true}>
          {empty && <EmptyList message={emptyListMessage} />}
          <Units />
        </Scrollbars>
      </div>
    );
  }

  private componentRef = (el: HTMLDivElement | null) => (this.component = el);

  private endDrag = async () => {
    const {
      endDrag,
      intl: {formatMessage},
      refreshUnitsList,
      units,
      coursebookId
    } = this.props;
    const {dragId, hoverSequence} = this.state;
    if (hoverSequence === null || dragId === null) {
      this.setState({dragId: null, hoverSequence: null, units: this.props.units});
      return;
    }
    // if unit is not actually moved - don't bother server with request/response cycle
    const dragUnitIsHoverUnit = !!units.find(u => u.sequence === hoverSequence && u.id === dragId);
    if (dragUnitIsHoverUnit) {
      this.setState({dragId: null, hoverSequence: null});
      return;
    }
    this.setState({processingRequest: true});
    let response: AxiosResponseAction<CoursebookUnit[]> | null = null;
    try {
      response = await endDrag(coursebookId, hoverSequence, dragId);
      toastr.success('', formatMessage(messages.unitsRearrangeSuccess));
    } catch (e) {
      toastr.error('', formatMessage(messages.unitsRearrangeFail));
    }
    if (response) {
      refreshUnitsList(response.payload.data);
    }
    this.setState({dragId: null, hoverSequence: null, processingRequest: false});
  };

  private moveItem = (dragSequence: number, hoverSequence: number, dragId: number) => {
    const movedUp: boolean = dragSequence > hoverSequence;
    let changedUnits = [...this.state.units];
    const dragItem = this.state.units.find(u => u.id === dragId)!;
    if (Math.abs(dragSequence - hoverSequence) === 1) {
      changedUnits = changedUnits.map(u => {
        if (u.id === dragId) {
          return {...u, sequence: hoverSequence};
        }
        if (u.sequence === hoverSequence) {
          return {...u, sequence: dragSequence};
        }
        return u;
      });
    } else {
      const sequencesToShift = Array.from(
        {length: Math.abs(dragSequence - hoverSequence) + 1},
        (v, k) => k + Math.min(dragSequence, hoverSequence)
      );
      changedUnits = changedUnits
        .filter(u => u.id !== dragItem.id)
        .map(u =>
          sequencesToShift.includes(u.sequence)
            ? {...u, sequence: movedUp ? u.sequence + 1 : u.sequence - 1}
            : u
        )
        .concat({...dragItem, sequence: hoverSequence});
    }
    changedUnits.sort((u1, u2) => u1.sequence - u2.sequence);
    this.setState(() => ({hoverSequence, dragId, units: changedUnits}));
  };

  private Units = () => {
    const {units, previewUnitId} = this.state;
    if (!units.length) {
      return null;
    }
    const {editCoursebookSuccessCallback, coursebookId} = this.props;
    return (
      <React.Fragment>
        <RenameUnitModal editCoursebookSuccessCallback={editCoursebookSuccessCallback} />
        <DeleteUnitModal editCoursebookSuccessCallback={editCoursebookSuccessCallback} />
        {previewUnitId && (
          <UnitPreviewModal
            unitId={previewUnitId}
            coursebookId={coursebookId}
            onHide={() => this.setState({previewUnitId: null})}
          />
        )}
        {units.map((u: CoursebookUnit) => (
          <UnitItem
            elementId={this.props.shouldScrollToId && `unit-item-id-${u.id}`}
            key={u.id}
            endDrag={this.endDrag}
            moveItem={this.moveItem}
            processingRequest={this.state.processingRequest}
            showSpinner={u.id === this.state.dragId}
            getContainer={() => this.component!}
            openUnitPreview={() => this.setState({previewUnitId: u.id})}
            selectUnitId={this.props.selectUnitId}
            confirmMessage={this.props.confirmMessage}
            {...u}
          />
        ))}
        <div className="empty-space" />
      </React.Fragment>
    );
  };

  private get activeId(): number | null {
    if (!this.props.units.length) {
      return null;
    }
    const unitIdParam = this.props.unitId && Number(this.props.unitId);
    return unitIdParam || null;
  }

  private scrollToUnitId = () => {
    if (this.props.shouldScrollToId) {
      const activeId = this.activeId;
      const unitItemNode = activeId && document.getElementById(`unit-item-id-${activeId}`);
      unitItemNode && unitItemNode.scrollIntoView({behavior: 'auto', block: 'center'});
    }
  };

  private switchIsOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState(prevState => ({isOpen: !prevState.isOpen}));
  };
}

const mapStateToProps = (state: AppState): StateProps => {
  const units = state.coursebookPage!.units!;
  return {units};
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  endDrag: (coursebookId: string, sequence: number, unitId: number) =>
    dispatch(requestUnitsRearrangement(coursebookId, sequence, unitId)),
  refreshUnitsList: (units: CoursebookUnit[]) => dispatch(setCoursebookUnits(units))
});

const Connected = injectIntl(connect(mapStateToProps, mapDispatchToProps)(UnitsListComponent));

const UnitsList: FC<OwnProps> = props => {
  const {
    coursebookId,
    unitId,
    coursebookSectionId,
    '*': wildcard
  } = useParams<SectionRouteParams & {'*': string}>();
  const navigate = useNavigate();
  const intl = useIntl();

  const isUnselectedSection = wildcard === 'section';

  const changeSelectedUnitSection = useCallback(
    (id: number) =>
      Number(unitId) !== id &&
      navigate(selectedSectionPath(coursebookId!, id, Number(coursebookSectionId))),
    [coursebookId, coursebookSectionId, navigate, unitId]
  );

  const changeUnitSection = useCallback(
    (id: number) => Number(unitId) !== id && navigate(unselectedSectionPath(coursebookId!, id)),
    [coursebookId, navigate, unitId]
  );

  const changeUnit = useCallback(
    (id: number) => Number(unitId) !== id && navigate(unitPath(coursebookId!, id)),
    [coursebookId, navigate, unitId]
  );

  const selectUnitId = coursebookSectionId
    ? changeSelectedUnitSection
    : isUnselectedSection
      ? changeUnitSection
      : changeUnit;

  const confirmMessage =
    coursebookSectionId || isUnselectedSection
      ? intl.formatMessage(sectionsMessages.LeavePageConfirmMessage)
      : intl.formatMessage(exerciseListMessages.ChangeUnitConfirm);
  return (
    <Connected
      {...props}
      coursebookId={coursebookId!}
      unitId={unitId}
      selectUnitId={selectUnitId}
      confirmMessage={confirmMessage}
    />
  );
};

export default UnitsList;
