import React, {type FC, type PropsWithChildren} from 'react';
import {useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {
  FormattedMessage,
  injectIntl,
  type MessageDescriptor,
  type WrappedComponentProps
} from 'react-intl';

import {push} from 'store/router';
import * as toastr from 'components/toastr';
import {coursebookLibraryMessages} from 'components/CoursebookLibrary/messages';
import {type ServerCoursebook} from 'components/CoursebookLibrary/interface';
import {deleteCoursebook, loadCoursebookData} from 'components/CoursebookLibrary/actions/action';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import {type ViewContentsModalTab} from 'components/CoursebookContentsViewer/interface';
import CoursebookContentsViewer from 'components/CoursebookContentsViewer/CoursebookContentsViewer';
import {type AppState, type Coursebook, type Cover, type EnglexImage} from 'store/interface';
import GrammarPlayerPage from 'common/GrammarPlayerPage/GrammarPlayerPage';
import {commonMessages} from 'i18n/commonMessages';
import {libraryPath} from 'common/paths';

import HeaderView from './HeaderView';
import {type CoursebookRouteParams} from '../interface';
import {
  editCoursebookCover,
  redirectFromUnitPage,
  setCoursebookCover,
  setUpdatingCoursebook,
  toggleDetailedCoursebookInfo
} from '../state/action';
import ViewContentsModal from '../../Common/ViewContentsModal';

interface OwnProps {}

interface StateProps {
  coursebook: Coursebook;
  showDetailedCoursebookInfo?: boolean;
}

interface DispatchProps {
  editCover: (
    id: string,
    title: string,
    coverId: number | null
  ) => Promise<AxiosResponseAction<{}>>;
  loadCoursebookData: (id: string) => Promise<AxiosResponseAction<ServerCoursebook>>;
  setCoursebookCover: (cover?: Cover) => void;
  toggleDetailedCoursebookInfo: (show: boolean) => void;
  redirectFromUnitPage: (path: string) => void;
  deleteCoursebook: (id: string) => Promise<AxiosResponseAction<{}>>;
  setUpdatingCoursebook: (isUpdating: boolean) => void;
  goToLibrary: () => void;
}

interface Props
  extends StateProps,
    DispatchProps,
    OwnProps,
    WrappedComponentProps,
    CoursebookRouteParams {}

interface State {
  showLeavePageConfirm?: boolean;
  showViewContentsModal?: boolean;
  showViewGrammarModal?: boolean;
}

class HeaderComponent extends React.Component<Props, State> {
  public state: State = {};

  private deleteCoursebookRequest?: CancellablePromise;

  public componentWillUnmount(): void {
    if (this.deleteCoursebookRequest) {
      this.deleteCoursebookRequest.cancel();
    }
  }

  public render() {
    const {children, coursebook, showDetailedCoursebookInfo, intl} = this.props;
    const coursebookId = this.state.showViewContentsModal ? this.props.coursebook.id : undefined;
    const showCoursebookId = !!coursebookId;
    return (
      <>
        <HeaderView
          attachCoverModule={this.attachCoverModule}
          detachCoverModule={this.detachCoverModule}
          navigateBack={this.navigateBack}
          coursebook={coursebook}
          showDetailedCoursebookInfo={showDetailedCoursebookInfo}
          toggleDetailedCoursebookInfo={this.props.toggleDetailedCoursebookInfo}
          deleteCoursebook={this.deleteCoursebook}
          openViewContentsModal={this.openViewContentsModal}
          openViewGrammarModal={this.openViewGrammarModal}
          intl={intl}
        >
          {children}
        </HeaderView>
        <ViewContentsModal
          show={showCoursebookId}
          close={this.closeViewContentsModal}
          renderBody={(tab: ViewContentsModalTab) =>
            showCoursebookId && (
              <CoursebookContentsViewer selectedTab={tab} coursebookId={coursebookId!} />
            )
          }
        />
        <ViewContentsModal
          show={!!this.state.showViewGrammarModal}
          close={this.closeViewGrammarModal}
          isToggleGroup={true}
          title={<FormattedMessage id="Exercise.Sidebar.SectionHeading.Grammar" />}
          renderBody={() => (
            <GrammarPlayerPage coursebookId={this.props.coursebook.id} isModal={true} />
          )}
        />
      </>
    );
  }

  private get attachCoverModule() {
    const {
      coursebook: {id, title},
      editCover,
      intl,
      loadCoursebookData,
      setCoursebookCover
    } = this.props;
    return {
      promiseCreator: (data: EnglexImage) => editCover(id, title, data.id),
      resolve: ({
        payload: {
          data: {id}
        }
      }: AxiosResponseAction<ServerCoursebook>) => {
        loadCoursebookData(id)
          .then(({payload: {data}}) => setCoursebookCover(data.cover!))
          .catch(() =>
            toastr.error(
              '',
              intl.formatMessage(coursebookLibraryMessages.CoursebookInfoRefreshError)
            )
          );
      },
      reject: () => this.onCoverEditFailure(coursebookLibraryMessages.CoursebookCoverAttachError)
    };
  }

  private get detachCoverModule() {
    const {
      coursebook: {id, title},
      editCover,
      loadCoursebookData,
      setCoursebookCover
    } = this.props;
    return {
      promiseCreator: () => editCover(id, title, null),
      resolve: (res: AxiosResponseAction<ServerCoursebook>) => {
        loadCoursebookData(id)
          .then(() => {
            setCoursebookCover(undefined);
          })
          .catch(() => {
            // todo: catch?
          });
      },
      reject: (rej: AxiosRequestError) =>
        this.onCoverEditFailure(coursebookLibraryMessages.CoursebookCoverDeleteError)
    };
  }

  private onCoverEditFailure = (messageDescriptor: MessageDescriptor) => () =>
    toastr.error('', this.props.intl.formatMessage(messageDescriptor));

  private closeViewContentsModal = () => this.setState({showViewContentsModal: false});

  private openViewContentsModal = () => this.setState({showViewContentsModal: true});

  private closeViewGrammarModal = () => this.setState({showViewGrammarModal: false});
  private openViewGrammarModal = () => this.setState({showViewGrammarModal: true});

  private navigateBack = () => this.props.redirectFromUnitPage(libraryPath());

  private deleteCoursebook = () => {
    const {
      coursebook: {id}
    } = this.props;
    this.props.setUpdatingCoursebook(true);
    this.deleteCoursebookRequest = makeCancellable(
      this.props.deleteCoursebook(id),
      this.handleCoursebookDeleted,
      this.coursebookDeleteRequestFail
    );
  };

  private handleCoursebookDeleted = () => {
    const {
      intl: {formatMessage},
      goToLibrary
    } = this.props;
    this.props.setUpdatingCoursebook(false);
    goToLibrary();
    toastr.success('', formatMessage(coursebookLibraryMessages.CoursebookDeletedSuccessToast));
  };

  private coursebookDeleteRequestFail = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    this.props.setUpdatingCoursebook(false);
    toastr.error('', formatMessage(commonMessages.DeleteRequestFailed));
  };
}

const mapStateToProps = (state: AppState): StateProps => ({
  coursebook: state.coursebookPage!.coursebookInfo!,
  showDetailedCoursebookInfo: state.coursebookPage!.showDetailedCoursebookInfo
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  editCover: (id: string, title: string, coverId: number | null) =>
    dispatch(editCoursebookCover(id, title, coverId)),
  loadCoursebookData: (id: string) => dispatch(loadCoursebookData(id)),
  setCoursebookCover: (cover?: Cover) => dispatch(setCoursebookCover(cover)),
  toggleDetailedCoursebookInfo: (show: boolean) => dispatch(toggleDetailedCoursebookInfo(show)),
  redirectFromUnitPage: (path: string) => dispatch(redirectFromUnitPage(path)),
  deleteCoursebook: (id: string) => dispatch(deleteCoursebook(id)),
  setUpdatingCoursebook: (isUpdating: boolean) => dispatch(setUpdatingCoursebook(isUpdating)),
  goToLibrary: () => dispatch(push(libraryPath()))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(injectIntl(HeaderComponent));

const HeaderContainer: FC<PropsWithChildren<{}>> = ({children}) => {
  const {coursebookId} = useParams<CoursebookRouteParams>();

  return <Connected coursebookId={coursebookId!}>{children}</Connected>;
};

export default HeaderContainer;
