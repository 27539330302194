import React, {Component} from 'react';
import RetinaImage from '@englex/react-retina-image';
import Collapse from 'react-bootstrap/lib/Collapse';
import classNames from 'classnames';
import {FormattedMessage, type IntlShape} from 'react-intl';
import {Route, Routes} from 'react-router-dom';

import Icon from 'components/Icon';
import EnglexCoursebookCover from 'components/EnglexCoursebookCover';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';
import {type Coursebook, type EnglexImage} from 'store/interface';

import CoursebookInfoHeader from '../../Common/CoursebookInfoHeader';
import CoursebookInfoBody from '../../Common/CoursebookInfoBody';
import {SelectedUnitTab, UnitTabSelector} from './UnitTabSelector';
import CoverUploader from '../../Common/CoverUploader';

import './Header.scss';
import './CoursebookPageColumnHeader.scss';

const coursebookCoverAspect = 2 / 3;
const coursebookCoverMinWidth = 112;

interface Props {
  attachCoverModule: {
    promiseCreator(data: EnglexImage): Promise<AxiosResponseAction<{}>>;
    resolve(res: AxiosResponseAction<{}>): void;
    reject(rej: AxiosRequestError): void;
  };
  detachCoverModule: {
    promiseCreator(): Promise<{}>;
    resolve(res: AxiosResponseAction<{}>): void;
    reject(rej: AxiosRequestError): void;
  };
  coursebook: Coursebook;
  showDetailedCoursebookInfo?: boolean;

  navigateBack: () => void;
  toggleDetailedCoursebookInfo: (show: boolean) => void;
  deleteCoursebook: () => void;
  openViewContentsModal: () => void;
  openViewGrammarModal: () => void;
  intl: IntlShape;
}

export default class HeaderView extends Component<Props> {
  public render() {
    const {
      attachCoverModule,
      detachCoverModule,
      navigateBack,
      coursebook,
      showDetailedCoursebookInfo,
      deleteCoursebook,
      openViewContentsModal,
      openViewGrammarModal
    } = this.props;
    const detailedCoursebookInfoBtnClass = classNames('toggle-detailed-coursebook-info', {
      active: showDetailedCoursebookInfo
    });
    return (
      <div className="coursebook-page-header">
        <div className="header-top">
          <div className="navigate-back-block" onClick={navigateBack}>
            <Icon name="arrow-circle-o-left" size="lg" />
          </div>
          <div className="coursebook-info">
            <CoursebookInfoHeader
              coursebook={coursebook}
              deleteCoursebook={deleteCoursebook}
              openViewContentsModal={openViewContentsModal}
              openViewGrammarModal={openViewGrammarModal}
            >
              <button
                className={detailedCoursebookInfoBtnClass}
                onClick={this.toggleDetailedCoursebookInfo}
              >
                <Icon name="info" />
              </button>
              <Routes>
                <Route path="unit/:unitId">
                  <Route
                    index={true}
                    element={<UnitTabSelector selectedTab={SelectedUnitTab.EXERCISE_LIST} />}
                  />
                  <Route
                    path="section/*"
                    element={<UnitTabSelector selectedTab={SelectedUnitTab.SECTIONS} />}
                  />
                </Route>
              </Routes>
            </CoursebookInfoHeader>

            <Collapse in={Boolean(showDetailedCoursebookInfo)}>
              <div>
                <div className="coursebook-info-body-wrapper">
                  <CoverUploader
                    aspect={coursebookCoverAspect}
                    className="book-cover"
                    confirmHeaderText={
                      <FormattedMessage id="CoursebookLibrary.Coursebook.CoverDeleteConfirm" />
                    }
                    coverExists={!!coursebook.cover}
                    coverMinWidth={coursebookCoverMinWidth}
                    attachModule={attachCoverModule}
                    detachModule={detachCoverModule}
                  >
                    {coursebook.cover ? (
                      <>
                        <div className="mask" />
                        <RetinaImage src={coursebook.cover.urls} />
                      </>
                    ) : (
                      <EnglexCoursebookCover fontSize={6} />
                    )}
                  </CoverUploader>
                  <CoursebookInfoBody coursebook={coursebook} />
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="columns-headers">{this.props.children}</div>
      </div>
    );
  }

  private toggleDetailedCoursebookInfo = () =>
    this.props.toggleDetailedCoursebookInfo(!this.props.showDetailedCoursebookInfo);
}
