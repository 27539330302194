import React from 'react';

interface Props {
  onClick?: (e: React.MouseEvent) => void;
  bsRole: string;
  className?: string;
}

export default class CustomToggle extends React.Component<Props> {
  public handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.onClick && this.props.onClick(e);
  };

  public render() {
    const {className} = this.props;
    return (
      <a href="" onClick={this.handleClick} className={className}>
        {this.props.children}
      </a>
    );
  }
}
