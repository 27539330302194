import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {List} from 'immutable';

import xwidgetReducer from './xwidgetReducer';
import {ADD_WIDGET, DELETE_WIDGET, MOVE_WIDGET_DOWN, MOVE_WIDGET_UP} from './actionTypes/xwidgets';
import {type XWidgetProperties} from './widgets/interface';
import {type AddWidgetAction, type XEditorWidgetsAction} from './actions/interface';

const REDUCERS: ReducersMapObject = {
  [ADD_WIDGET]: (
    state: List<XWidgetProperties>,
    action: AddWidgetAction
  ): List<XWidgetProperties> => {
    return state.push(action.xwidget);
  },
  [DELETE_WIDGET]: (
    state: List<XWidgetProperties>,
    action: XEditorWidgetsAction
  ): List<XWidgetProperties> => {
    const widgetKey = state.findKey(
      (xwidget: XWidgetProperties) => action.xwidgetId === xwidget.id
    );
    return state.delete(widgetKey);
  },
  [MOVE_WIDGET_UP]: (
    state: List<XWidgetProperties>,
    action: XEditorWidgetsAction
  ): List<XWidgetProperties> => {
    const srcKey = state.findKey((xwidget: XWidgetProperties) => action.xwidgetId === xwidget.id);

    if (srcKey <= 0) {
      return state;
    }

    const dstKey = srcKey - 1;

    const srcWidget = state.get(srcKey);
    const dstWidget = state.get(dstKey);

    let newState = state.set(dstKey, srcWidget);
    newState = newState.set(srcKey, dstWidget);
    return newState;
  },
  [MOVE_WIDGET_DOWN]: (
    state: List<XWidgetProperties>,
    action: XEditorWidgetsAction
  ): List<XWidgetProperties> => {
    const srcKey = state.findKey((xwidget: XWidgetProperties) => action.xwidgetId === xwidget.id);

    if (srcKey >= state.count() - 1) {
      return state;
    }

    const dstKey = srcKey + 1;

    const srcWidget = state.get(srcKey);
    const dstWidget = state.get(dstKey);

    let newState = state.set(dstKey, srcWidget);
    newState = newState.set(srcKey, dstWidget);

    return newState;
  }
};

const initialState = List<XWidgetProperties>();

function xwidgetsReducer(
  state: List<XWidgetProperties> = initialState,
  action: Action
): List<XWidgetProperties> {
  const reducer: Reducer<List<XWidgetProperties>> = REDUCERS[action.type];
  let newState: List<XWidgetProperties> = reducer ? reducer(state, action) : state;

  newState = newState.map((xwidget: XWidgetProperties) => xwidgetReducer(xwidget, action)).toList();

  return newState;
}

export default xwidgetsReducer;
