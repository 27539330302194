import React from 'react';
import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import UnitExerciseInfo from '../Unit/UnitExerciseList/views/UnitExerciseInfo';
import DragHandle from '../../../../components/DragHandle/DragHandle';
import {type UserV2} from '../../../../components/CoursebookLibrary/interface';
import {type UnitExerciseDragObject} from '../Unit/UnitExerciseList/interface';

interface OwnProps {
  style: React.CSSProperties;
  elementWidth: number;
  dragItem: UnitExerciseDragObject;
}

interface StateProps {
  excerpt: string;
  lockedBy?: UserV2;
}

interface Props extends StateProps, OwnProps {}

class UnitExerciseSupplementaryDragItem extends React.Component<Props> {
  public render() {
    const {style, elementWidth, excerpt, lockedBy} = this.props;
    return (
      <div style={{...style, width: elementWidth, position: 'relative', right: elementWidth - 13}}>
        <UnitExerciseInfo
          openExercise={this.emptyFunction}
          excerpt={excerpt}
          lockedBy={lockedBy && `${lockedBy.profile.lastName} ${lockedBy.profile.firstName}`}
          deleteUnitExercise={this.emptyFunction}
          readonly={false}
          hideDropdown={true}
          isDragItem={true}
          invisible={false}
          getRef={this.emptyFunction}
          viewExercise={this.emptyFunction}
        >
          <div className="supplementary-exercise-drag-handle">
            <DragHandle />
          </div>
        </UnitExerciseInfo>
      </div>
    );
  }

  private emptyFunction = () => null;
}

const mapStateToProps = (state: AppState, {dragItem: item}: OwnProps): StateProps => {
  const {pageIndex, mainUnitExerciseIndex, suppUnitExerciseIndex} = item.position;

  const mainUnitExercise = state
    .coursebookPage!.unit!.current.pages.get(pageIndex)
    .unitExerciseList.get(mainUnitExerciseIndex);

  const {excerpt, lockedBy} = item.position.isSuppUnitExercise
    ? mainUnitExercise.supplementaryUnitExerciseList.get(suppUnitExerciseIndex!)
    : mainUnitExercise;

  return {
    excerpt,
    lockedBy
  };
};

export default connect(mapStateToProps)(UnitExerciseSupplementaryDragItem);
