import {type Method} from 'axios';

import {type AxiosRequestAction} from 'services/axios/interface';

import {pageSize} from './static';

const prefix = 'FEEDBACK/';
const REQUEST_ISSUE_LIST = prefix + 'REQUEST_ISSUE_LIST';
const REQUEST_ISSUE = prefix + 'REQUEST_ISSUE';
const REQUEST_ISSUE_DELETE = prefix + 'REQUEST_ISSUE_DELETE';
const REQUEST_ISSUE_PROCESS = prefix + 'REQUEST_ISSUE_PROCESS';
const REQUEST_ISSUE_UNPROCESS = prefix + 'REQUEST_ISSUE_UNPROCESS';
const REQUEST_COURSEBOOKS = prefix + 'REQUEST_COURSEBOOKS';

export const requestIssueList = (
  pageNumber: number = 1,
  params: {showProcessed: boolean; coursebooks?: string[]}
): AxiosRequestAction => ({
  type: REQUEST_ISSUE_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/exercise-feedback`,
      params: {
        pageNumber,
        pageSize,
        ...params
      }
    }
  }
});

export const requestCoursebooks = (): AxiosRequestAction => ({
  type: REQUEST_COURSEBOOKS,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: '/v2/coursebook',
      params: {published: true}
    }
  }
});

const requestIssue = (type: string, method: Method, urlTail: string): AxiosRequestAction => ({
  type,
  payload: {
    client: 'v2',
    request: {
      method,
      url: `/v2/exercise-feedback/${urlTail}`
    }
  }
});

export const getIssue = (id: number) => requestIssue(REQUEST_ISSUE, 'get', `${id}`);
export const deleteIssue = (id: number) => requestIssue(REQUEST_ISSUE_DELETE, 'delete', `${id}`);
export const processIssue = (id: number) =>
  requestIssue(REQUEST_ISSUE_PROCESS, 'patch', `${id}/process`);
export const unprocessIssue = (id: number) =>
  requestIssue(REQUEST_ISSUE_UNPROCESS, 'patch', `${id}/unprocess`);
