import {List} from 'immutable';

import {type UnitExerciseJSON} from 'store/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import UnitExerciseSupplementaryRecord from './UnitExerciseSupplementaryRecord';
import {type SupplementaryExerciseRecordProps} from './interface';

interface MainExerciseRecordProps {
  readonly supplementaryUnitExerciseList: List<UnitExerciseSupplementaryRecord>;
}

interface Props extends SupplementaryExerciseRecordProps, MainExerciseRecordProps {}

class UnitExerciseMainRecord extends UnitExerciseSupplementaryRecord implements Props {
  public declare readonly supplementaryUnitExerciseList: List<UnitExerciseSupplementaryRecord>;

  public constructor(unitExercise: UnitExerciseJSON, supplementary: UnitExerciseJSON[]) {
    super(unitExercise);
    this.initValues({
      supplementaryUnitExerciseList: List(
        supplementary.map(
          supplementaryExercise => new UnitExerciseSupplementaryRecord(supplementaryExercise)
        )
      )
    });
  }

  public toJSON(): UnitExerciseJSON[] {
    return super.toJSON().concat(
      this.supplementaryUnitExerciseList
        .map((suppExercise: UnitExerciseSupplementaryRecord, index: number) => ({
          ...suppExercise.toJSON()[0],
          parentExerciseId: this.exerciseId,
          ordinal: index
        }))
        .toJS()
    );
  }
}

decorate(UnitExerciseMainRecord, {
  supplementaryUnitExerciseList: property()
});
record()(UnitExerciseMainRecord);
export default UnitExerciseMainRecord;
