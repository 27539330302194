import {captureException} from '@sentry/react';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import {type IntlShape} from 'react-intl';
import {type ValidationError} from 'yup';

import {push} from 'store/router';
import {type AxiosRequestAction} from 'services/axios/interface';
import {
  loadCoursebookData,
  type LoadCoursebookDataResponseAction
} from 'components/CoursebookLibrary/actions/action';
import {
  requestUnitForPreview,
  type RequestUnitForPreviewResponseAction
} from 'components/modals/UnitPreview/actions';
import {unitPath} from 'common/paths';

import {type AppState, type Coursebook, type CoursebookUnit} from '../interface';
import {type XIntroSavingData} from './interface';
import {type IntroRecord} from './IntroRecord';

export enum IntoActionTypes {
  LoadIntroData = 'XINTRO/LOAD_INTRO_DATA',
  LoadIntroDataSuccess = 'XINTRO/LOAD_INTRO_DATA_SUCCESS',
  LoadIntroDataError = 'XINTRO/LOAD_INTRO_DATA_FAIL',
  ChangeTab = 'XINTRO/CHANGE_TAB',
  ChangeIntro = 'XINTRO/CHANGE_INTRO',
  SaveIntro = 'XINTRO/SAVE_INTRO',
  SaveIntroSuccess = 'XINTRO/SAVE_INTRO_SUCCESS',
  SaveIntroError = 'XINTRO/SAVE_INTRO_FAIL',
  SetValidationError = 'XINTRO/SET_VALIDATION_ERROR',
  Reset = 'XINTRO/RESET'
}

export interface LoadIntroDataAction {
  type: IntoActionTypes.LoadIntroData;
  payload: {
    coursebook: Coursebook;
    unit: CoursebookUnit;
  };
}

export interface ChangeIntroAction {
  type: IntoActionTypes.ChangeIntro;
  payload: Partial<IntroRecord>;
}

export interface SetValidationErrorAction {
  type: IntoActionTypes.SetValidationError;
  payload: {error?: ValidationError};
}

export const loadIntroData =
  (coursebookId: string, unitId: number) =>
  async (dispatch: Dispatch<AxiosRequestAction | Action, AppState>): Promise<void> => {
    try {
      dispatch({type: IntoActionTypes.LoadIntroData});

      const [coursebookResponse, unitResponse] = await Promise.all([
        dispatch(loadCoursebookData(coursebookId)),
        dispatch(requestUnitForPreview(coursebookId, unitId))
      ]);

      const {
        payload: {data: coursebook}
      } = coursebookResponse as LoadCoursebookDataResponseAction;

      const {
        payload: {data: unit}
      } = unitResponse as RequestUnitForPreviewResponseAction;

      dispatch({type: IntoActionTypes.LoadIntroDataSuccess, payload: {coursebook, unit}});
    } catch (error) {
      captureException(error);

      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error while load intro data', error);
      }

      dispatch({type: IntoActionTypes.LoadIntroDataError});
    }
  };

export function change(data: Partial<IntroRecord>) {
  return {
    type: IntoActionTypes.ChangeIntro,
    payload: data
  };
}

const requestSave = (
  coursebookId: string,
  unitId: number,
  data: XIntroSavingData
): AxiosRequestAction => {
  return {
    type: IntoActionTypes.SaveIntro,
    payload: {
      client: 'v2',
      request: {
        method: 'put',
        url: `/v2/coursebook/${coursebookId}/unit/${unitId}/intro`,
        data
      }
    }
  };
};

export const save =
  (intl: IntlShape, coursebookId: string, unitId: number, withRedirect = true) =>
  async (
    dispatch: Dispatch<AxiosRequestAction | Action, AppState>,
    getState: () => AppState
  ): Promise<void> => {
    try {
      const state = getState();

      const {
        xintro: {intro}
      } = state;

      const data = intro!.toJSON();

      await dispatch(validate(intl));

      await dispatch(requestSave(coursebookId, unitId, data));

      if (withRedirect) {
        dispatch(push(unitPath(coursebookId, unitId), {state: {animatedElement: 'intro'}}));
      }
    } catch (exception) {
      const error: Error = exception.error || exception;

      if (error) {
        captureException(error);
      }

      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.error('Error while saving intro', exception);
      }
    }
  };

export const validate =
  (intl: IntlShape) =>
  async (
    dispatch: Dispatch<AxiosRequestAction | Action, AppState>,
    getState: () => AppState
  ): Promise<boolean> => {
    try {
      const state = getState();

      const {
        xintro: {intro}
      } = state;

      await intro!.validate(intl);

      return true;
    } catch (error) {
      dispatch(setError(error));

      return false;
    }
  };

export function setError(error?: ValidationError) {
  return {
    type: IntoActionTypes.SetValidationError,
    payload: {error}
  };
}

export function reset() {
  return {
    type: IntoActionTypes.Reset
  };
}
