import React from 'react';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {FormattedMessage, type WrappedComponentProps, injectIntl, defineMessages} from 'react-intl';

import {type Coursebook} from 'store/interface';
import {CoursebookStatus} from 'common/enums';

import Icon from '../../../components/Icon';
import {dropdownDropupCheckCreator} from '../../../helpers/dropdownDropupCheck';
import Confirm from '../../../components/modals/Confirm';

import './CoursebookItemDropdown.scss';

const messages = defineMessages({
  DeleteCoursebookConfirm: {
    id: 'CoursebookLibrary.CoursebookItemDropdown.DeleteConfirm'
  }
});

interface Props extends WrappedComponentProps {
  coursebook: Coursebook;
  edit: () => void;
  copy: () => void;
  delete: () => void;
  viewContents: () => void;
  viewGrammar: () => void;
}

interface State {
  dropup: boolean;
  confirmDeletingCoursebook?: boolean;
}

class CoursebookItemDropdown extends React.Component<Props, State> {
  private static dropdownHeight = 230;
  public state: State = {
    dropup: false
  };

  private dropdownDropupCheck = dropdownDropupCheckCreator(
    (dropup: boolean) => this.setState({dropup}),
    CoursebookItemDropdown.dropdownHeight
  );

  public render() {
    const {
      intl: {formatMessage}
    } = this.props;
    return (
      <>
        <DropdownButton
          className="coursebook-item-dropdown"
          id="coursebook-item-dropdown"
          title={<Icon name="virc-actions" />}
          dropup={this.state.dropup}
          onClick={this.dropdownDropupCheck}
        >
          <MenuItem onClick={this.onTableOfContentsItemClick} disabled={this.disableViewContents}>
            <Icon name="sitemap" />
            <FormattedMessage id="CoursebookLibrary.CoursebookItemDropdown.TableOfContents" />
          </MenuItem>
          <MenuItem onClick={this.onViewGrammarClick} disabled={this.disableViewGrammar}>
            <Icon name="pc-course" />
            <FormattedMessage id="CoursebookLibrary.CoursebookItemDropdown.OpenGrammar" />
          </MenuItem>
          <MenuItem onClick={this.onViewInOCItemClick} disabled={true}>
            <Icon name="play-circle" />
            <FormattedMessage id="CoursebookLibrary.CoursebookItemDropdown.ViewInClassroom" />
          </MenuItem>
          <MenuItem onClick={this.onEditInfoItemClick}>
            <Icon name="gear" />
            <FormattedMessage id="CoursebookLibrary.CoursebookItemDropdown.EditInfo" />
          </MenuItem>
          {this.renderHidePublishMenuItem()}
          <MenuItem onClick={this.onCopyItemClick}>
            <Icon name="copy" />
            <FormattedMessage id="CoursebookLibrary.CoursebookItemDropdown.Copy" />
          </MenuItem>
          <MenuItem onClick={this.onDeleteItemClick}>
            <Icon name="trash" />
            <FormattedMessage id="Common.Delete" />
          </MenuItem>
        </DropdownButton>
        <div onClick={this.stopPropagation}>
          <Confirm
            show={this.state.confirmDeletingCoursebook}
            onAccept={this.confirmDeleting}
            onDecline={this.declineDeleting}
            headerText={formatMessage(messages.DeleteCoursebookConfirm)}
            disableButtons={false}
          />
        </div>
      </>
    );
  }

  private renderHidePublishMenuItem = () => {
    const {coursebook} = this.props;
    if (coursebook.status === CoursebookStatus.PUBLISHED) {
      return (
        <MenuItem onClick={this.onHideItemClick} disabled={true}>
          <Icon name="eye-slash" />
          <FormattedMessage id="Common.Hide" />
        </MenuItem>
      );
    }
    return (
      <MenuItem onClick={this.onPublishItemClick} disabled={true}>
        <Icon name="eye" />
        <FormattedMessage id="CoursebookLibrary.CoursebookItemDropdown.Publish" />
      </MenuItem>
    );
  };

  private stopPropagation = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  private onTableOfContentsItemClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    if (this.disableViewContents) {
      return;
    }
    this.props.viewContents();
  };

  private onViewGrammarClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    if (this.disableViewGrammar) {
      return;
    }
    this.props.viewGrammar();
  };

  private onViewInOCItemClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
  };

  private onEditInfoItemClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    this.props.edit();
  };

  private onCopyItemClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
    this.props.copy();
  };

  private onDeleteItemClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    this.setState({confirmDeletingCoursebook: true});
    e.stopPropagation();
  };

  private declineDeleting = () => this.setState({confirmDeletingCoursebook: false});

  private confirmDeleting = () => {
    this.setState({confirmDeletingCoursebook: false});
    this.props.delete();
  };

  private onHideItemClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
  };

  private onPublishItemClick = (e: React.MouseEvent<{}, MouseEvent>) => {
    e.stopPropagation();
  };

  private get disableViewContents() {
    return this.props.coursebook.overview!.unitSectionsNumber === 0;
  }

  private get disableViewGrammar() {
    return !this.props.coursebook.hasGrammar;
  }
}

export default injectIntl(CoursebookItemDropdown);
