import {type Editor} from '@englex/slate';
import {type List} from 'immutable';

import {type PictureChoiceQuestionProperties} from 'store/exercise/player/widgets/PictureChoice/interface';

import {type CardSizeType} from '../XWordPictureSet/XPictureSet/interface';
import {type XWidgetAction} from '../../actions/interface';

const prefix = 'XEDITOR/';

export const PICTURE_CHOICE_DELETE_QUESTION = prefix + 'PICTURE_CHOICE_DELETE_QUESTION';
export const PICTURE_CHOICE_MOVE_QUESTION = prefix + 'PICTURE_CHOICE_MOVE_QUESTION';
export const PICTURE_CHOICE_ADD_QUESTION = prefix + 'PICTURE_CHOICE_ADD_QUESTION';
export const PICTURE_CHOICE_CHANGE_QUESTION_CONTENT =
  prefix + 'PICTURE_CHOICE_CHANGE_QUESTION_CONTENT';

export const PICTURE_CHOICE_ADD_CARD = prefix + 'PICTURE_CHOICE_ADD_CARD';
export const PICTURE_CHOICE_SET_CARD_IMAGE_DATA = prefix + 'PICTURE_CHOICE_SET_CARD_IMAGE_DATA';
export const PICTURE_CHOICE_DELETE_CARD = prefix + 'PICTURE_CHOICE_DELETE_CARD';

export const PICTURE_CHOICE_CHOOSE_ANSWER = prefix + 'PICTURE_CHOICE_CHOOSE_ANSWER';

export const PICTURE_CHOICE_CHANGE_CARD_SIZE_TYPE = prefix + 'PICTURE_CHOICE_CHANGE_CARD_SIZE_TYPE';

export const PICTURE_CHOICE_MOVE_CARD = prefix + 'PICTURE_CHOICE_MOVE_CARD';
export const PICTURE_CHOICE_ROLL_BACK_CARDS = prefix + 'PICTURE_CHOICE_ROLL_BACK_CARDS';

export interface QuestionIndex extends XWidgetAction {
  questionIndex: number;
}

export interface QuestionId extends XWidgetAction {
  questionId: string;
}

interface CardIndex extends XWidgetAction {
  cardIndex: number;
}

interface CardId extends XWidgetAction {
  cardId: string;
}
export interface MoveQuestion extends XWidgetAction {
  from: number;
  to: number;
}

export interface ChangeQuestionContent extends QuestionIndex {
  change: Editor;
}

export interface SetImageData extends QuestionIndex, CardIndex {
  imageId: number;
  url: string;
}

export interface PictureChoiceMoveCard extends QuestionId {
  moveCardIndex: number;
  targetCardIndex: number;
}

export interface PictureChoiceRollBackCards extends XWidgetAction {
  questions: List<PictureChoiceQuestionProperties>;
}

export interface SetCardSizeType extends QuestionIndex, CardIndex {
  cardSizeType: CardSizeType;
}

export interface QuestionCardIds extends QuestionId, CardId {}
