import React, {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {type CompleteCrop} from 'react-image-crop';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';

import {
  UploadingPictureStatus,
  UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import {validateImageFile} from 'common/ImageUpload/functions';
import {md5Chunk} from 'services/common-methods';
import Icon from 'components/Icon';
import CropComponent from 'components/Crop';
import ImageFileInput from 'components/Slate/SlateEditor/plugins/button/Image/Modal/ImageFileInput';

import {Phase, type UploaderModalProps, type ValidatedFile} from './interface';
import UploaderFooter from './UploaderFooter';

const UploaderModal: FC<UploaderModalProps> = props => {
  const {
    aspect,
    errorMessage,
    hideModal,
    minWidth,
    setErrorMessage,
    setFreeze,
    setStatus,
    status,
    show
  } = props;
  const intl = useIntl();

  const [file, setFile] = useState<File | null>(null);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const [validatedFile, setValidatedFile] = useState<ValidatedFile | null>(null);
  const [phase, setPhase] = useState<Phase>(Phase.DEFAULT);
  const [crop, setCrop] = useState<CompleteCrop | undefined>();
  const [retry, setRetry] = useState(false);
  const [uploadingStarted, setUploadingStarted] = useState(false);

  const minHeight = useMemo(
    () => (aspect === 1 ? minWidth : Math.ceil(minWidth / aspect)),
    [aspect, minWidth]
  );

  const isErrorStatus = useMemo(() => {
    if (errorMessage) {
      return true;
    }
    return (
      !!status &&
      (status! in UploadingPictureValidationError ||
        status === UploadingPictureStatus.UPLOADING_ERROR)
    );
  }, [errorMessage, status]);

  const startUploading = useCallback(() => {
    setUploadingStarted(true);
    setFreeze(true);
    setRetry(isErrorStatus);
    setErrorMessage(undefined);
  }, [isErrorStatus, setUploadingStarted, setErrorMessage, setFreeze, setRetry]);

  const turnOffRetry = useCallback(() => setRetry(false), []);

  useEffect(() => {
    if (file && dataUrl) {
      if (status) setStatus(undefined);
      (async (): Promise<ValidatedFile> => {
        const md5 = await md5Chunk(file);
        const status = await validateImageFile(file, md5, undefined, undefined, [
          minWidth,
          minHeight
        ]);
        if (status in UploadingPictureValidationError) {
          throw new Error(status);
        }
        return {file: {md5, file}, dataUrl};
      })()
        .finally(() => {
          setFile(null);
          setDataUrl(null);
        })
        .then(response => {
          setValidatedFile(response);
          setPhase(Phase.CROP);
        })
        .catch(e => setStatus(e.message));
    }
  }, [aspect, dataUrl, file, intl, minWidth, minHeight, setStatus, status]);

  const clear = useCallback(() => {
    setPhase(Phase.DEFAULT);
    setCrop(undefined);
    setUploadingStarted(false);
    setValidatedFile(null);
    setFile(null);
    setDataUrl(null);
  }, []);

  return (
    <Modal
      backdrop="static"
      className="image-cropper-modal dnd-image-modal"
      onHide={hideModal}
      show={show}
      onExited={clear}
    >
      <Modal.Header className="dnd-image-modal-header">
        <FormattedMessage id="XEditorWidget.ImageMatching.Modal.Header" />
        <Button className="btn-xs" onClick={hideModal}>
          <Icon name="pc-close" />
        </Button>
      </Modal.Header>
      <Modal.Body className="dnd-image-modal-body">
        {phase !== Phase.DEFAULT && validatedFile && (
          <div className="dnd-image">
            <CropComponent
              aspectRatio={aspect}
              getCrop={setCrop}
              imageDataUrl={validatedFile.dataUrl}
              minHeight={minHeight}
              minWidth={minWidth}
            />
          </div>
        )}
        {phase === Phase.DEFAULT && (
          <ImageFileInput
            dispatchFile={setFile}
            dispatchImageDataUrl={setDataUrl}
            intl={intl}
            minWidth={minWidth}
            minHeight={minHeight}
          />
        )}
      </Modal.Body>
      <UploaderFooter
        {...props}
        crop={crop}
        isErrorStatus={isErrorStatus}
        minHeight={minHeight}
        retry={retry}
        startUploading={startUploading}
        turnOffRetry={turnOffRetry}
        uploadingStarted={uploadingStarted}
        validatedFile={validatedFile}
      />
    </Modal>
  );
};

export default UploaderModal;
