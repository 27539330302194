import {type Action} from 'redux';

import {LOCATION_CHANGE} from 'store/router';
import {type ActionHandlersList} from 'store/reducers';

import UnitRecord from '../dataRecords/UnitRecord';
import HistoryRecord from '../dataRecords/HistoryRecord';
import unitDragPreviewReducer from './unitDragPreviewReducer';
import type UnitExerciseMainRecord from '../dataRecords/UnitExerciseMainRecord';
import {
  DRAG_PREVIEW_APPROVE,
  DRAG_PREVIEW_CREATE,
  DRAG_PREVIEW_DISCARD
} from '../actions/dragPreviewActionTypes';
import {
  CLEAR_UNIT_EXERCISE_LIST,
  DELETE_UNIT_EXERCISE,
  SET_UNIT_EXERCISE_LIST,
  UNIT_HISTORY_CLEAR,
  UNIT_HISTORY_REDO,
  UNIT_HISTORY_SAVE,
  UNIT_HISTORY_UNDO
} from '../actions/unitExerciseListActionTypes';
import {
  type SetExercisesListAction,
  type UnitExerciseAction
} from '../actions/unitExerciseListActions';

const ACTION_HANDLERS: ActionHandlersList<HistoryRecord<UnitRecord> | undefined, Action> = {
  [SET_UNIT_EXERCISE_LIST]: (state: HistoryRecord<UnitRecord>, action: SetExercisesListAction) => {
    return new HistoryRecord(new UnitRecord(action.pages));
  },
  [DELETE_UNIT_EXERCISE]: (
    state: HistoryRecord<UnitRecord>,
    {pageIndex, unitExerciseId, parentIndex}: UnitExerciseAction
  ) => {
    const newStep = (() => {
      if (typeof parentIndex === 'number') {
        const supplementaryExerciseIndex = state.current.pages
          .get(pageIndex)
          .unitExerciseList.get(parentIndex)
          .supplementaryUnitExerciseList.findIndex(exercise => exercise!.id === unitExerciseId);

        return state.current.deleteIn([
          '_pages',
          pageIndex,
          'unitExerciseList',
          parentIndex,
          'supplementaryUnitExerciseList',
          supplementaryExerciseIndex
        ]);
      }

      const exerciseIndex = state.current.pages
        .get(pageIndex)
        .unitExerciseList.findIndex((ue: UnitExerciseMainRecord) => ue.id === unitExerciseId);
      return state.current.deleteIn(['_pages', pageIndex, 'unitExerciseList', exerciseIndex]);
    })();

    return state.addNewStep(newStep);
  },
  [UNIT_HISTORY_UNDO]: (state: HistoryRecord<UnitRecord>) => {
    return state.undo();
  },
  [UNIT_HISTORY_REDO]: (state: HistoryRecord<UnitRecord>) => {
    return state.redo();
  },
  [UNIT_HISTORY_CLEAR]: (state: HistoryRecord<UnitRecord>) => {
    return state.clear();
  },
  [UNIT_HISTORY_SAVE]: (state: HistoryRecord<UnitRecord>) => {
    return state.save();
  },
  [DRAG_PREVIEW_CREATE]: (state: HistoryRecord<UnitRecord>) => {
    return state.setIn(['current', 'dragPreview'], state.current);
  },
  [DRAG_PREVIEW_APPROVE]: (state: HistoryRecord<UnitRecord>) => {
    const dragPreviewState = state.current.dragPreview!;
    return state.deleteIn(['current', 'dragPreview']).addNewStep(dragPreviewState);
  },
  [DRAG_PREVIEW_DISCARD]: (state: HistoryRecord<UnitRecord>) => {
    return state.deleteIn(['current', 'dragPreview']);
  },
  [LOCATION_CHANGE]: () => undefined,
  [CLEAR_UNIT_EXERCISE_LIST]: () => undefined
};

export default function (
  state: HistoryRecord<UnitRecord> | undefined,
  action: Action
): HistoryRecord<UnitRecord> | undefined {
  const reducer = ACTION_HANDLERS[action.type];
  let newState = reducer ? reducer(state, action) : state;
  if (newState) {
    newState = newState.set('current', newState.current.removeEmptyPages());
    if (newState.current.dragPreview) {
      newState = newState.setIn(
        ['current', 'dragPreview'],
        unitDragPreviewReducer(newState.current.dragPreview, action)
      );
    }
  }
  return newState;
}
