import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import './Header.scss';

interface Props {
  showButtons: boolean;
  isLoading?: boolean;
  onCancelClick: () => void;
  onSaveClick: () => void;
  onAddSectionClick: () => void;
}

export default class Header extends React.Component<Props> {
  public render() {
    const {showButtons, isLoading, onCancelClick, onSaveClick, onAddSectionClick} = this.props;
    return (
      <React.Fragment>
        <div className="contents-header coursebook-page-column-header">
          <div id="contents-toolbar-portal" className="contents-toolbar-portal" />
          {showButtons ? (
            <React.Fragment>
              <Button
                className="btn-transparent"
                bsSize="sm"
                onClick={onCancelClick}
                disabled={isLoading}
              >
                <FormattedMessage id="XEditor.Cancel" />
              </Button>
              <Button bsStyle="primary" bsSize="sm" onClick={onSaveClick} disabled={isLoading}>
                <FormattedMessage id="Common.Save" />
              </Button>
            </React.Fragment>
          ) : null}
        </div>
        <div className="sections-list-header coursebook-page-column-header">
          <Button bsStyle="success" bsSize="sm" onClick={onAddSectionClick}>
            <Icon name="plus-circle" />
            <span className="title-text">
              <FormattedMessage id="Coursebook.Contents.Section" />
            </span>
          </Button>
        </div>
      </React.Fragment>
    );
  }
}
