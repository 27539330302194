import React from 'react';
import {FormattedMessage} from 'react-intl';

import fltrArrow from 'components/CoursebookLibrary/LibraryPageList/assets/fltr-arrow.png';
import fltrArrow2x from 'components/CoursebookLibrary/LibraryPageList/assets/fltr-arrow@2x.png';
import './EmptyListHint.scss';

export default class EmptyListHint extends React.Component {
  public render() {
    return (
      <div className="empty-list-hint">
        <img src={fltrArrow} srcSet={`${fltrArrow2x} 2x`} className="arrow" alt="" />
        <b className="empty-list-message">
          <FormattedMessage id="Coursebook.ExerciseList.EmptyList" />
        </b>
      </div>
    );
  }
}
