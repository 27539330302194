const prefix = 'XWIDGET/';

export const SET_CARD_PHRASE = prefix + 'SET_CARD_PHRASE';
export const SET_CARD_IMAGE_DATA = prefix + 'SET_CARD_IMAGE_DATA';
export const DELETE_CARD = prefix + 'DELETE_CARD';
export const ADD_CARD = prefix + 'ADD_CARD';
export const UPDATE_CARD = prefix + 'UPDATE_CARD';
export const IMAGE_MOVE_CARD = prefix + 'IMAGE_MOVE_CARD';
export const IMAGE_ROLL_BACK_CARS = prefix + 'IMAGE_ROLL_BACK_CARS';
export const TOGGLE_PRE_FILL_VALUES = prefix + 'TOGGLE_PRE_FILL_VALUES';
export const CHANGE_CARD_SIZE_TYPE = prefix + 'CHANGE_CARD_SIZE_TYPE';
