import React from 'react';

interface LibraryContext {
  viewContentsOfCoursebook: (id: string) => void;
  viewGrammarOfCoursebook: (id: string) => void;
}

export const CoursebookLibraryContext = React.createContext<LibraryContext>({
  viewContentsOfCoursebook: () => null,
  viewGrammarOfCoursebook: () => null
});
