import React from 'react';

import fltrArrow from './assets/fltr-arrow.png';
import fltrArrow2x from './assets/fltr-arrow@2x.png';
import './EmptyList.scss';

interface Props {
  message: JSX.Element;
}

export default class EmptyList extends React.Component<Props> {
  public render() {
    return (
      <div className="empty-units-list">
        <div className="empty-list-hint">
          <img src={fltrArrow} srcSet={`${fltrArrow2x} 2x`} className="arrow" alt="" />
          <b className="empty-list-message">{this.props.message}</b>
        </div>
      </div>
    );
  }
}
