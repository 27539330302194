import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  yes: {
    id: 'Common.Yes'
  },
  no: {
    id: 'Common.No'
  },
  createModalSuccess: {
    id: `ServerCoursebook.UnitsList.CreateModal.Success`
  },
  createTestSuccess: {
    id: `ServerCoursebook.UnitsList.CreateTest.Success`
  },
  createModalFail: {
    id: `ServerCoursebook.UnitsList.CreateModal.Fail`
  },
  createModalTestFail: {
    id: `ServerCoursebook.UnitsList.CreateModalTest.Fail`
  },
  renameModalLabel: {
    id: `ServerCoursebook.UnitsList.RenameModal.Label`
  },
  renameModalPlaceholder: {
    id: `ServerCoursebook.UnitsList.RenameModal.Placeholder`
  },
  renameModalSuccess: {
    id: `ServerCoursebook.UnitsList.RenameModal.Success`
  },
  renameTestSuccess: {
    id: `ServerCoursebook.UnitsList.RenameTest.Success`
  },
  renameModalFail: {
    id: `ServerCoursebook.UnitsList.RenameModal.Fail`
  },
  renameModalTestFail: {
    id: `ServerCoursebook.UnitsList.RenameModalTest.Fail`
  },
  deleteModalTitle: {
    id: `ServerCoursebook.UnitsList.DeleteModal.Title`
  },
  editModalValidationRequired: {
    id: `ServerCoursebook.UnitsList.EditModal.Validation.Required`
  },
  editModalValidationMaxLength: {
    id: `ServerCoursebook.UnitsList.EditModal.Validation.Max`
  },
  deleteModalSuccess: {
    id: `ServerCoursebook.UnitsList.DeleteModal.Success`
  },
  deleteModalTestSuccess: {
    id: `ServerCoursebook.UnitsList.DeleteModalTest.Success`
  },
  deleteModalFail: {
    id: `ServerCoursebook.UnitsList.DeleteModal.Fail`
  },
  deleteModalTestFail: {
    id: `ServerCoursebook.UnitsList.DeleteModalTest.Fail`
  },
  unitsRearrangeSuccess: {
    id: `ServerCoursebook.UnitsList.UnitsList.Rearrange.Success`
  },
  unitsRearrangeFail: {
    id: `ServerCoursebook.UnitsList.UnitsList.Rearrange.Fail`
  }
});
