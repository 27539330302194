import React, {type FC, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useNavigate, useParams} from 'react-router-dom';

import Confirm from 'components/modals/Confirm';
import {type AppState} from 'store/interface';
import {newExercisePath} from 'common/paths';

import {exerciseListMessages} from '../messages';
import HeaderView from '../views/Header';
import {type UnitRouteParams} from '../../../interface';
import {
  unitHistoryClear,
  unitHistoryRedo,
  unitHistoryUndo,
  updateUnit as updateUnitAction
} from '../actions/unitExerciseListActions';

interface Props {
  reloadUnit: () => void;
}

export const Header: FC<Props> = ({reloadUnit}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const {coursebookId, unitId} = useParams<UnitRouteParams>();

  const [showConfirm, setShowConfirm] = useState<boolean | undefined>();

  const hasUndos = useSelector(
    (state: AppState) => state.coursebookPage!.unit && state.coursebookPage!.unit.hasUndos
  );
  const hasRedos = useSelector(
    (state: AppState) => state.coursebookPage!.unit && state.coursebookPage!.unit.hasRedos
  );
  const isUpdatingUnit = useSelector((state: AppState) => state.coursebookPage!.updatingUnit);

  const undo = useCallback(() => dispatch(unitHistoryUndo()), [dispatch]);
  const redo = useCallback(() => dispatch(unitHistoryRedo()), [dispatch]);
  const clean = useCallback(() => dispatch(unitHistoryClear()), [dispatch]);

  const update = useCallback(
    () => dispatch(updateUnitAction(coursebookId!, Number(unitId), intl, reloadUnit)),
    [coursebookId, dispatch, intl, reloadUnit, unitId]
  );

  const onAccept = useCallback(() => {
    navigate(newExercisePath(coursebookId!, Number(unitId)));
  }, [coursebookId, navigate, unitId]);

  const onDecline = useCallback(() => setShowConfirm(false), []);

  const createNewExercise = useCallback(() => {
    if (hasRedos || hasUndos) {
      setShowConfirm(true);
    } else {
      onAccept();
    }
  }, [hasRedos, hasUndos, onAccept]);

  return (
    <>
      <HeaderView
        showHistoryControlButtons={hasRedos || hasUndos}
        disableUndoButton={!hasUndos}
        disableRedoButton={!hasRedos}
        unitHistoryUndo={undo}
        unitHistoryRedo={redo}
        unitHistoryClear={clean}
        saveChanges={update}
        disableAllControls={isUpdatingUnit}
        createNewExercise={createNewExercise}
      />
      <Confirm
        show={showConfirm}
        onAccept={onAccept}
        onDecline={onDecline}
        headerText={intl.formatMessage(exerciseListMessages.LeavePageConfirm)}
        disableButtons={false}
      />
    </>
  );
};
