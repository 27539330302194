import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import {PasteExercisesButton} from './PasteExercisesButton';

import './Header.scss';

interface Props {
  showHistoryControlButtons?: boolean;
  disableUndoButton?: boolean;
  disableRedoButton?: boolean;
  disableAllControls?: boolean;

  unitHistoryUndo: () => void;
  unitHistoryRedo: () => void;
  unitHistoryClear: () => void;
  saveChanges: () => void;
  createNewExercise: () => void;
}

export default class Header extends React.Component<Props> {
  public render() {
    const {showHistoryControlButtons, disableAllControls, createNewExercise} = this.props;
    return (
      <div className="exercise-list-header coursebook-page-column-header">
        <DropdownButton
          bsStyle="success"
          bsSize="sm"
          title={this.renderDropdownTitle()}
          id="exercise-list-actions-dropdown"
          noCaret={true}
          disabled={disableAllControls}
        >
          <MenuItem onClick={createNewExercise}>
            <Icon name="plus-circle" />
            <FormattedMessage id="Coursebook.Exercise.CreateNew" />
          </MenuItem>
          <MenuItem disabled={true}>
            <Icon name="folder-open" />
            <FormattedMessage id="Coursebook.Exercise.AddExisting" />
          </MenuItem>
        </DropdownButton>

        <PasteExercisesButton
          className="offset-left"
          hasChanges={showHistoryControlButtons}
          historyClear={this.props.unitHistoryClear}
        />

        {showHistoryControlButtons ? this.renderHistoryControls() : null}
      </div>
    );
  }

  private renderDropdownTitle = () => (
    <>
      <Icon name="plus-circle" />
      <span className="title-text">
        <FormattedMessage id="Coursebook.Exercise" />
      </span>
      <Icon name="angle-down" />
    </>
  );

  private renderHistoryControls = () => {
    const {
      unitHistoryUndo,
      disableRedoButton,
      disableUndoButton,
      unitHistoryRedo,
      unitHistoryClear,
      saveChanges,
      disableAllControls
    } = this.props;
    return (
      <div className="button-group">
        <ButtonGroup className="undo-redo-btn-group">
          <Button
            bsSize="sm"
            onClick={unitHistoryUndo}
            disabled={disableUndoButton || disableAllControls}
          >
            <Icon name="undo" />
          </Button>
          <Button
            bsSize="sm"
            onClick={unitHistoryRedo}
            disabled={disableRedoButton || disableAllControls}
          >
            <Icon name="repeat" />
          </Button>
        </ButtonGroup>
        <div className="submit-group">
          <Button
            bsSize="sm"
            onClick={unitHistoryClear}
            className="btn-transparent"
            disabled={disableAllControls}
          >
            <FormattedMessage id="Coursebook.Cancel" />
          </Button>

          <Button bsSize="sm" onClick={saveChanges} bsStyle="primary" disabled={disableAllControls}>
            <FormattedMessage id="Common.Save" />
          </Button>
        </div>
      </div>
    );
  };
}
