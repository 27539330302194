import React from 'react';

import Loader from '../../../components/Loader';
import './UpdatingUnitOverlay.scss';

export default class UpdatingUnitOverlay extends React.Component {
  public render() {
    return (
      <div className="updating-unit-overlay">
        <Loader />
      </div>
    );
  }
}
