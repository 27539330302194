import {type Action} from 'redux';

import {
  DRAG_PREVIEW_APPROVE,
  DRAG_PREVIEW_CREATE,
  DRAG_PREVIEW_CREATE_PAGE_WITH_UNIT_EXERCISE,
  DRAG_PREVIEW_DISCARD,
  DRAG_PREVIEW_MAKE_UNIT_EXERCISE_MAIN,
  DRAG_PREVIEW_MAKE_UNIT_EXERCISE_SUPPLEMENTARY,
  DRAG_PREVIEW_MOVE_PAGE,
  DRAG_PREVIEW_MOVE_UNIT_EXERCISE_BETWEEN_PAGES,
  DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_PAGE,
  DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_SUPPLEMENTARY_LIST
} from './dragPreviewActionTypes';

export const dragPreviewCreate = () => ({
  type: DRAG_PREVIEW_CREATE
});

export const dragPreviewApprove = () => ({
  type: DRAG_PREVIEW_APPROVE
});

export const dragPreviewDiscard = () => ({
  type: DRAG_PREVIEW_DISCARD
});

export const dragPreviewMovePage = (sourcePos: number, targetPos: number): MoveUnitPageAction => ({
  type: DRAG_PREVIEW_MOVE_PAGE,
  sourcePos,
  targetPos
});

export interface MoveUnitPageAction extends Action {
  sourcePos: number;
  targetPos: number;
}

export interface CreatePageWithUnitExerciseOptions {
  sourcePageIndex: number;
  unitExerciseIndex: number;
  createdPageIndex: number;
  createdPageId: string;
}

export interface CreatePageWithUnitExerciseAction
  extends Action,
    CreatePageWithUnitExerciseOptions {}

export const dragPreviewCreatePageWithUnitExercise = (
  options: CreatePageWithUnitExerciseOptions
): CreatePageWithUnitExerciseAction => ({
  type: DRAG_PREVIEW_CREATE_PAGE_WITH_UNIT_EXERCISE,
  ...options
});

export interface MoveUnitExerciseWithinPageOptions {
  pageIndex: number;
  sourcePos: number;
  targetPos: number;
}

export interface MoveUnitExerciseWithinPageAction
  extends MoveUnitExerciseWithinPageOptions,
    Action {}

export const dragPreviewMoveUnitExerciseWithinPage = (
  options: MoveUnitExerciseWithinPageOptions
): MoveUnitExerciseWithinPageAction => ({
  type: DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_PAGE,
  ...options
});

export interface MoveUnitExerciseBetweenPagesOptions {
  sourcePageIndex: number;
  targetPageIndex: number;
  sourcePos: number;
  targetPos: number;
}

export interface MoveUnitExerciseBetweenPagesAction
  extends MoveUnitExerciseBetweenPagesOptions,
    Action {}

export const dragPreviewMoveMainExerciseBetweenPages = (
  options: MoveUnitExerciseBetweenPagesOptions
): MoveUnitExerciseBetweenPagesAction => ({
  type: DRAG_PREVIEW_MOVE_UNIT_EXERCISE_BETWEEN_PAGES,
  ...options
});

export interface MakeUnitExerciseMainOptions {
  pageIndex: number;
  parentUnitExerciseIndex: number;
  suppUnitExerciseIndex: number;
  targetPosition: number;
}

export interface MakeUnitExerciseMainAction extends MakeUnitExerciseMainOptions, Action {}

export const dragPreviewMakeUnitExerciseMain = (
  options: MakeUnitExerciseMainOptions
): MakeUnitExerciseMainAction => ({
  type: DRAG_PREVIEW_MAKE_UNIT_EXERCISE_MAIN,
  ...options
});

export interface MakeUnitExerciseSupplementaryOptions {
  pageIndex: number;
  sourcePosition: number;
  targetUnitExercisePosition: number;
  targetPositionInSupplementaryList: number;
}

export interface MakeUnitExerciseSupplementaryAction
  extends MakeUnitExerciseSupplementaryOptions,
    Action {}

export const dragPreviewMakeExerciseSupplementary = (
  options: MakeUnitExerciseSupplementaryOptions
): MakeUnitExerciseSupplementaryAction => ({
  type: DRAG_PREVIEW_MAKE_UNIT_EXERCISE_SUPPLEMENTARY,
  ...options
});

export interface MoveWithinSupplementaryListOptions {
  pageIndex: number;
  parentUnitExerciseIndex: number;
  sourcePosition: number;
  targetPosition: number;
}

export interface MoveWithinSupplementaryListAction
  extends MoveWithinSupplementaryListOptions,
    Action {}

export const dragPreviewMoveWithinSupplementaryList = (
  options: MoveWithinSupplementaryListOptions
): MoveWithinSupplementaryListAction => ({
  type: DRAG_PREVIEW_MOVE_UNIT_EXERCISE_WITHIN_SUPPLEMENTARY_LIST,
  ...options
});
