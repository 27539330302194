import {type Action} from 'redux';
import {type IntlShape} from 'react-intl';
import {type Dispatch} from 'redux-axios-middleware';
import {captureMessage, withScope} from '@sentry/react';

import * as toastr from 'components/toastr';
import {
  type AppState,
  type Cover,
  type UnitExerciseJSON,
  type UnitExerciseRequestError,
  type UnitIntroJSON
} from 'store/interface';
import {
  type AxiosRequestAction,
  type AxiosRequestError,
  type AxiosResponseAction
} from 'services/axios/interface';

import {coursebookMessages} from '../../../messages';
import {
  CLEAR_UNIT_EXERCISE_LIST,
  COPY_UNIT_EXERCISES,
  DELETE_UNIT_EXERCISE,
  EDIT_UNIT_COVER,
  LOAD_UNIT_EXERCISE_LIST,
  LOAD_UNIT_INTRO,
  SET_UNIT_COVER,
  SET_UNIT_EXERCISE_ERROR,
  SET_UNIT_EXERCISE_LIST,
  SET_UNIT_INTRO,
  SET_UNIT_IS_UPDATING,
  TOGGLE_SUPP_UNIT_EXERCISE_PANEL,
  UNIT_HISTORY_CLEAR,
  UNIT_HISTORY_REDO,
  UNIT_HISTORY_SAVE,
  UNIT_HISTORY_UNDO,
  UPDATE_UNIT_REQUEST
} from './unitExerciseListActionTypes';

export type LoadUnitExerciseSuccessAction = AxiosResponseAction<UnitExerciseJSON[]>;

export type LoadUnitIntroSuccessAction = AxiosResponseAction<UnitIntroJSON>;

export interface SetUnitIntroAction extends Action {
  intro: UnitIntroJSON | null;
}

export const loadUnitExerciseList = (coursebookId: string, unitId: number): AxiosRequestAction => ({
  type: LOAD_UNIT_EXERCISE_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise`
    }
  }
});

export const editUnitCover = (
  coursebookId: string,
  unitId: number,
  unitTitle: string,
  coverId: number | null
): AxiosRequestAction => ({
  type: EDIT_UNIT_COVER,
  payload: {
    client: 'v2',
    request: {
      headers: {'Content-Type': 'application/json'},
      data: {title: unitTitle, coverId},
      method: 'put',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}`
    }
  }
});

export interface SetUnitCoverAction extends Action {
  unitId: number;
  cover?: Cover;
}

export const setUnitCover = (unitId: number, cover?: Cover): SetUnitCoverAction => ({
  type: SET_UNIT_COVER,
  unitId,
  cover
});

export interface SetExercisesListAction extends Action {
  pages: UnitExerciseJSON[];
}

export const setUnitExerciseList = (pages: UnitExerciseJSON[]): SetExercisesListAction => ({
  type: SET_UNIT_EXERCISE_LIST,
  pages
});

export interface SetUnitExerciseErrorAction extends Action {
  error: UnitExerciseRequestError;
}

export const setUnitExerciseError = (
  error: UnitExerciseRequestError
): SetUnitExerciseErrorAction => ({
  type: SET_UNIT_EXERCISE_ERROR,
  error
});

interface UnitExerciseActionOptions {
  pageIndex: number;
  unitExerciseId: number;
  parentIndex?: number;
}

export interface UnitExerciseAction extends Action, UnitExerciseActionOptions {}

export const deleteUnitExercise = (options: UnitExerciseActionOptions): UnitExerciseAction => ({
  type: DELETE_UNIT_EXERCISE,
  ...options
});

export interface ToggleSuppUnitExercisePanelAction extends Action {
  unitExerciseId: number;
}

export const toggleSuppUnitExercisePanel = (
  unitExerciseId: number
): ToggleSuppUnitExercisePanelAction => ({
  type: TOGGLE_SUPP_UNIT_EXERCISE_PANEL,
  unitExerciseId
});

export const unitHistoryUndo = () => ({
  type: UNIT_HISTORY_UNDO
});

export const unitHistoryRedo = () => ({
  type: UNIT_HISTORY_REDO
});

export const unitHistoryClear = () => ({
  type: UNIT_HISTORY_CLEAR
});

export interface SetUnitIsUpdatingAction extends Action {
  isUpdating?: boolean;
}

const setUnitIsUpdating = (isUpdating?: boolean): SetUnitIsUpdatingAction => ({
  type: SET_UNIT_IS_UPDATING,
  isUpdating
});

const updateUnitRequest = (
  coursebookId: string,
  unitId: number,
  unitExercises: UnitExerciseJSON[]
): AxiosRequestAction => ({
  type: UPDATE_UNIT_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      url: `v2/coursebook/${coursebookId}/unit/${unitId}/exercise`,
      data: {
        unitExercises
      }
    }
  }
});

export const clearUnitExerciseList = (): Action => ({
  type: CLEAR_UNIT_EXERCISE_LIST
});

export const updateUnit =
  (coursebookId: string, unitId: number, {formatMessage}: IntlShape, reloadUnit: () => void) =>
  (dispatch: Dispatch<Action, AppState>, getState: () => AppState) => {
    dispatch(setUnitIsUpdating(true));
    const coursebookState = getState().coursebookPage!;
    const initialUnitExerciseList = coursebookState.unit!.initialState.toJSON();
    const resultingUnitExerciseList = coursebookState.unit!.current.toJSON();
    const deletedUnitExercises = initialUnitExerciseList
      .filter(
        initialUE => !resultingUnitExerciseList.find(resultingUE => initialUE.id === resultingUE.id)
      )
      .map(UE => ({...UE, deleted: true}));

    dispatch(
      updateUnitRequest(
        coursebookId,
        unitId,
        resultingUnitExerciseList.concat(deletedUnitExercises)
      )
    )
      .then((action: AxiosResponseAction<UnitExerciseJSON[]>) => {
        dispatch(setUnitIsUpdating(false));
        dispatch(unitHistorySave());
        dispatch(setUnitExerciseList(action.payload.data));
        reloadUnit();
        toastr.success('', formatMessage(coursebookMessages.UnitUpdateSuccessful));
      })

      .catch((action: AxiosRequestError) => {
        const isValidationErr = action.error.response && action.error.response.data[0];
        const isUnitUpdatedByAnotherUserError =
          isValidationErr && action.error.response!.data[0].constraints['exist-array'];

        if (isUnitUpdatedByAnotherUserError) {
          toastr.error('', formatMessage(coursebookMessages.UnitUpdatedByAnotherUserError));
          dispatch(unitHistoryClear());
          dispatch(clearUnitExerciseList());
          dispatch(setUnitIsUpdating(false));
        }

        if (!isUnitUpdatedByAnotherUserError) {
          toastr.error('', formatMessage(coursebookMessages.UnitUpdateError));
          dispatch(setUnitIsUpdating(false));
          if (isValidationErr) {
            withScope(scope => {
              scope.setExtra('err', action.error);
              captureMessage('Unit exercise list validation error', 'warning');
            });
          }
        }
      });
  };

const unitHistorySave = () => ({
  type: UNIT_HISTORY_SAVE
});

export const loadUnitIntro = (coursebookId: string, unitId: number): AxiosRequestAction => ({
  type: LOAD_UNIT_INTRO,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/intro`
    }
  }
});

export const deleteUnitIntro = (coursebookId: string, unitId: number): AxiosRequestAction => ({
  type: LOAD_UNIT_INTRO,
  payload: {
    client: 'v2',
    request: {
      method: 'delete',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/intro`
    }
  }
});

export const setUnitIntro = (intro: UnitIntroJSON | null): SetUnitIntroAction => ({
  type: SET_UNIT_INTRO,
  intro
});

const copyUnitExercises = (
  coursebookId: string,
  unitId: number,
  exerciseIds: string[]
): AxiosRequestAction => ({
  type: COPY_UNIT_EXERCISES,
  payload: {
    client: 'v2',
    request: {
      method: 'patch',
      url: `/v2/coursebook/${coursebookId}/unit/${unitId}/exercise`,
      data: {
        exerciseIds
      }
    }
  }
});

type CloneExercisesResult = Array<{
  sourceId: string;
  cloneId: string;
}>;

export const copyExercises =
  (coursebookId: string, unitId: number, exercises: string[]) =>
  async (dispatch: Dispatch<Action, AppState>) => {
    const {
      payload: {data: copied}
    } = await dispatch<AxiosResponseAction<CloneExercisesResult>>(
      copyUnitExercises(coursebookId, unitId, exercises)
    );

    const copiedExercises = copied.map(exercise => exercise.cloneId);

    const {
      payload: {data: pages}
    }: LoadUnitExerciseSuccessAction = await dispatch(loadUnitExerciseList(coursebookId, unitId));

    await dispatch(setUnitExerciseList(pages));

    return copiedExercises;
  };
