import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {List} from 'immutable';

import xwidgetsReducer from './xwidgetsReducer';
import xmediaSourcesReducer from './xmediaSourcesReducer';
import XExerciseRecord from './widgets/XExerciseRecord';
import {
  CHANGE_GRAMMAR_HEADER,
  CHANGE_TITLE,
  NEW_EXERCISE,
  OPEN_EXERCISE
} from './actionTypes/xexercise';
import xMetaReducer from './xMetaReducer';
import {type XExerciseProperties} from './widgets/interface';
import {
  type AddWidgetAction,
  type ChangeGrammarTitleAction,
  type ChangeTitleAction,
  type GrammarSelectAction,
  type NewXExerciseAction,
  type OpenXExerciseAction,
  type XEditorWidgetsAction
} from './actions/interface';
import {valueFromText} from '../../../components/Slate/utils';
import {ADD_WIDGET, DELETE_WIDGET} from './actionTypes/xwidgets';
import {WidgetType} from '../player/interface';
import {SELECT_GRAMMAR} from './actionTypes/xeditor';

const REDUCERS: ReducersMapObject = {
  [OPEN_EXERCISE]: (state: XExerciseRecord, action: OpenXExerciseAction): XExerciseProperties => {
    return new XExerciseRecord(action.xexercise);
  },
  [NEW_EXERCISE]: (state: XExerciseRecord, action: NewXExerciseAction): XExerciseProperties => {
    return new XExerciseRecord(action.xexercise);
  },
  [CHANGE_TITLE]: (state: XExerciseRecord, action: ChangeTitleAction): XExerciseProperties => {
    return state.set('title', action.title);
  },
  [CHANGE_GRAMMAR_HEADER]: (
    state: XExerciseRecord,
    action: ChangeGrammarTitleAction
  ): XExerciseProperties => {
    return state.set('grammarTitle', action.change);
  },
  [ADD_WIDGET]: (state: XExerciseRecord, action: AddWidgetAction): XExerciseProperties => {
    const isGrammar = action.xwidget.type === WidgetType.GRAMMAR_RULES;
    return !isGrammar
      ? state
      : state.withMutations(s => {
          if (s.grammarTitle === null) s.set('grammarTitle', valueFromText());
          s.remove('grammar');
        });
  },
  [DELETE_WIDGET]: (state: XExerciseRecord, action: XEditorWidgetsAction): XExerciseProperties => {
    if (state.grammarTitle !== null) {
      const isGrammarRuleWidget =
        state.widgets.find(widget => widget?.id === action.xwidgetId).type ===
        WidgetType.GRAMMAR_RULES;
      const isLastGrammarRule =
        state.widgets.filter(i => i?.type === WidgetType.GRAMMAR_RULES).size === 1;
      state = isGrammarRuleWidget && isLastGrammarRule ? state.set('grammarTitle', null) : state;
    }
    return state;
  },
  [SELECT_GRAMMAR]: (state: XExerciseRecord, action: GrammarSelectAction) => {
    return state.set('grammar', List(action.grammar));
  }
};

const initialState: XExerciseProperties = new XExerciseRecord({
  id: null,
  title: '',
  grammarTitle: null,
  widgets: [],
  mediaSources: [],
  meta: {
    categories: [],
    levels: []
  },
  grammar: []
});

function xexerciseReducer(
  state: XExerciseProperties = initialState,
  action: Action
): XExerciseProperties {
  const reducer: Reducer<XExerciseProperties> = REDUCERS[action.type];
  let newState = reducer ? reducer(state, action) : state;
  newState = newState.withMutations(s => {
    s.set('widgets', xwidgetsReducer(newState.widgets, action));
    s.set('meta', xMetaReducer(newState.meta, action));
    s.set('mediaSources', xmediaSourcesReducer(newState.mediaSources, action));
  });

  return newState;
}

export default xexerciseReducer;
