import React, {type FC} from 'react';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import {useParams} from 'react-router-dom';
import {type Dispatch} from 'redux-axios-middleware';

import {stopMedia} from 'store/media/actions';
import {changePreviewRole} from 'store/exercise/player/preview/actions';
import {type XPreviewState} from 'store/exercise/player/preview/interface';
import {type ExerciseJSON} from 'store/exercise/player/interface';
import {type AppState, type Role} from 'store/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';

import ExerciseViewerView from './ExerciseViewer';
import {type UnitRouteParams} from '../../../interface';
import {
  closeExerciseViewer,
  createViewerPreview,
  removeExerciseViewerError,
  requestExerciseForViewer,
  type RequestExerciseViewerSuccessAction,
  setExerciseViewerError
} from './action';

interface OwnProps extends UnitRouteParams {}

interface StateProps {
  viewedExercise?: string;
  xpreview: XPreviewState;
  error?: boolean;
}

interface DispatchProps {
  closeViewer: () => void;
  requestExercise: (
    coursebookId: string,
    unitId: number,
    exerciseId: string
  ) => Promise<RequestExerciseViewerSuccessAction>;
  changeRole: (role: Role) => void;
  setError: () => void;
  stopMedia: () => void;
  removeError: () => void;
  createPreview: (exercise: ExerciseJSON) => void;
}

interface Props extends StateProps, DispatchProps, OwnProps {}

class ExerciseViewerContainer extends React.Component<Props> {
  private request?: CancellablePromise;

  public componentWillUnmount(): void {
    this.props.closeViewer();
    if (this.request) {
      this.request.cancel();
      this.deleteRequest();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const viewerWasOpened = !prevProps.viewedExercise && this.props.viewedExercise;
    const viewerWasClosed = prevProps.viewedExercise && !this.props.viewedExercise;

    if (viewerWasOpened) {
      this.loadExercise();
    }

    if (viewerWasClosed && this.request) {
      this.request.cancel();
      this.deleteRequest();
    }
  }

  public render() {
    const {viewedExercise, closeViewer, xpreview, error} = this.props;
    return (
      <ExerciseViewerView
        viewedExercise={viewedExercise}
        close={closeViewer}
        xpreview={xpreview}
        changeRole={this.changeRole}
        reloadData={this.loadExercise}
        error={error}
      />
    );
  }

  private changeRole = (role: Role) => {
    this.props.stopMedia();
    this.props.changeRole(role);
  };

  private loadExercise = () => {
    if (this.request) {
      return;
    }
    const {coursebookId, unitId, error, removeError, viewedExercise} = this.props;
    if (error) {
      removeError();
    }

    this.request = makeCancellable(
      this.props.requestExercise(coursebookId, Number(unitId), viewedExercise!),
      this.handleRequestSuccess,
      this.handleRequestError
    );
  };

  private handleRequestError = () => {
    this.props.setError();
    this.deleteRequest();
  };

  private handleRequestSuccess = (action: RequestExerciseViewerSuccessAction) => {
    const {exercise, grammar} = action.payload.data;

    this.deleteRequest();
    this.props.createPreview({...exercise!, grammar});
  };

  private deleteRequest = () => delete this.request;
}

const mapStateToProps = (state: AppState): StateProps => ({
  viewedExercise: state.coursebookPage!.exerciseViewer.viewedExercise,
  xpreview: state.coursebookPage!.exerciseViewer.xpreview,
  error: state.coursebookPage!.exerciseViewer.requestError
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  closeViewer: () => dispatch(closeExerciseViewer()),
  requestExercise: (coursebookId: string, unitId: number, exerciseId: string) =>
    dispatch(requestExerciseForViewer(coursebookId, unitId, exerciseId)),
  changeRole: (role: Role) => dispatch(changePreviewRole(role)),
  setError: () => dispatch(setExerciseViewerError()),
  stopMedia: () => dispatch(stopMedia()),
  removeError: () => dispatch(removeExerciseViewerError()),
  createPreview: (exercise: ExerciseJSON) => dispatch(createViewerPreview(exercise))
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(ExerciseViewerContainer);

const ExerciseViewer: FC = () => {
  const {coursebookId, unitId} = useParams<UnitRouteParams>();
  return <Connected coursebookId={coursebookId!} unitId={unitId} />;
};

export default ExerciseViewer;
