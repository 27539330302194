import React from 'react';
import {useDrop} from 'react-dnd';
import {useDispatch} from 'react-redux';

import genKey from 'components/Slate/utils/genKey';
import {DndTypes} from 'components/dnd/interface';

import {dndThrottle} from '../../../state/DndThrottle';
import {
  type CreatePageWithUnitExerciseOptions,
  dragPreviewCreatePageWithUnitExercise
} from '../actions/dragPreviewActions';
import {type UnitExerciseDragObject} from '../interface';

interface Props {
  isHidden?: boolean;
  previousPageIndex: number;
}

export const BetweenPagesDropTarget: React.FC<Props> = React.memo(
  ({isHidden, previousPageIndex}) => {
    const dispatch = useDispatch();

    const [, connectDropTarget] = useDrop({
      accept: DndTypes.UNIT_EXERCISE,
      hover(item: UnitExerciseDragObject) {
        if (item.position.isOnlyElementInPage || item.position.isSuppUnitExercise) {
          return null;
        }

        const createdPageId = genKey();
        const sourcePageIndex = item.position.pageIndex;
        const elementIndex = item.position.mainUnitExerciseIndex;
        const createdPageIndex = previousPageIndex + 1;

        return dndThrottle.throttleAction(() => {
          const options: CreatePageWithUnitExerciseOptions = {
            sourcePageIndex,
            unitExerciseIndex: elementIndex,
            createdPageIndex,
            createdPageId
          };
          item.position.createPageWithUnitExercise(options);
          dispatch(dragPreviewCreatePageWithUnitExercise(options));
        });
      }
    });

    return connectDropTarget(
      <div
        className="between-pages-margin"
        style={{height: 15, display: isHidden ? 'none' : 'block'}}
      />
    );
  }
);
