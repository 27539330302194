import {type Action} from 'redux';

import {type CoursebookLibraryCommonState} from 'store/interface';

import coursebookDataModalReducer, {
  initialCoursebookDataModalState
} from './CoursebookDataModal/coursebookDataModalReducer';

const initialState: CoursebookLibraryCommonState = {
  coursebookDataModal: initialCoursebookDataModalState
};

export default function (
  state: CoursebookLibraryCommonState = initialState,
  action: Action
): CoursebookLibraryCommonState {
  return {
    coursebookDataModal: coursebookDataModalReducer(state.coursebookDataModal, action)
  };
}
