import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {type Value} from '@englex/slate';
import {List} from 'immutable';

import {
  ADD_EXTRA_CHOICE,
  DISPLAY_BUTTON_ICON_CHANGE,
  DISPLAY_BUTTON_TITLE_CHANGE,
  CONTENT_CHANGE,
  DELETE_EXTRA_CHOICE,
  GRAMMAR_TITLE_CHANGE,
  QUESTIONS_CHANGE,
  QUESTIONS_EXAMPLE_ANSWER_CHANGE,
  SCRAMBLED_SENTENCES_CHANGE,
  SET_EXTRA_CHOICE_VALUE,
  TASK_CHANGE,
  TOGGLE_DISPLAY_AS_BUTTON,
  TOGGLE_CONTAINS_EXAMPLE,
  TOGGLE_FIRST_QUESTION_ITEM_EXAMPLE
} from './actionTypes';
import {
  ADD_CARD,
  CHANGE_CARD_SIZE_TYPE,
  DELETE_CARD,
  IMAGE_MOVE_CARD,
  IMAGE_ROLL_BACK_CARS,
  SET_CARD_IMAGE_DATA,
  SET_CARD_PHRASE,
  TOGGLE_PRE_FILL_VALUES,
  UPDATE_CARD
} from './widgets/XImage/actionTypes';
import {
  type ChangeQuestionContent,
  type MoveQuestion,
  PICTURE_CHOICE_ADD_CARD,
  PICTURE_CHOICE_ADD_QUESTION,
  PICTURE_CHOICE_CHANGE_CARD_SIZE_TYPE,
  PICTURE_CHOICE_CHANGE_QUESTION_CONTENT,
  PICTURE_CHOICE_CHOOSE_ANSWER,
  PICTURE_CHOICE_DELETE_CARD,
  PICTURE_CHOICE_DELETE_QUESTION,
  PICTURE_CHOICE_MOVE_CARD,
  PICTURE_CHOICE_MOVE_QUESTION,
  PICTURE_CHOICE_ROLL_BACK_CARDS,
  PICTURE_CHOICE_SET_CARD_IMAGE_DATA,
  type PictureChoiceMoveCard,
  type PictureChoiceRollBackCards,
  type QuestionCardIds,
  type QuestionId,
  type QuestionIndex,
  type SetCardSizeType,
  type SetImageData
} from './widgets/XPictureChoice/actionTypes';
import {
  type DeleteExtraChoiceAction,
  type SetDisplayButtonIconAction,
  type SetExtraChoiceValueAction,
  type ToggleFirstQuestionItemExampleAction,
  type XWidgetChangeAction
} from './xwidgetActions';
import {
  type XImageCardChangeType,
  type XImageCardDeleteAction,
  type XImageCardUpdateAction,
  type XImageMoveCardAction,
  type XImageRollBackCardsAction,
  type XImageSetCardDataAction,
  type XImageSetPhraseAction
} from './widgets/XImage/actions';
import XFormattedTextRecord from './widgets/XFormattedText/XFormattedTextRecord';
import {valueJSONFromText} from '../../../components/Slate/utils';
import {isVocabularyWordJSON} from '../player/widgets/Vocabulary/utils';
import genKey from '../../../components/Slate/utils/genKey';
import {WidgetType} from '../player/interface';
import {ATTACH_AUDIO, CLEAR_AUDIO} from '../../media/audio/actionTypes';
import {type AttachAudioAction} from '../../media/audio/actions';
import type XImageMatchingCardRecord from './widgets/XImage/XImageMatching/XImageMatchingCardRecord';
import {
  XVOCABULARY_ADD_CATEGORY,
  XVOCABULARY_ADD_ROWS,
  XVOCABULARY_ADD_WORD,
  XVOCABULARY_CHANGE_LIST_TITLE,
  XVOCABULARY_CHANGE_PRONUNCIATIONS_FOR_WORD,
  XVOCABULARY_CHANGE_QUIZLET_URL,
  XVOCABULARY_CLOSE_PRONUNCIATION_MODAL,
  XVOCABULARY_MODAL_CHANGE_PHONETIC_SPELLING,
  XVOCABULARY_MODAL_PRONUNCIATIONS_LOADED,
  XVOCABULARY_MODAL_SELECT_PRONUNCIATION_OPTION,
  XVOCABULARY_MOVE_ENTRY,
  XVOCABULARY_OPEN_PRONUNCIATION_MODAL,
  XVOCABULARY_REMOVE_ENTRY,
  XVOCABULARY_RESET_PRONUNCIATION,
  XVOCABULARY_SET_CATEGORY,
  XVOCABULARY_SET_ORIGINAL,
  XVOCABULARY_SET_PRONUNCIATIONS_LOADING_FOR_WORD,
  XVOCABULARY_SET_TRANSLATION,
  XVOCABULARY_STRIP_SPACES
} from './widgets/XVocabulary/actionTypes';
import {
  type ChangePhoneticSpellingAction,
  type ChangePronunciationAction,
  type PronunciationsLoadedAction,
  type SelectPronunciationOptionAction,
  type SetPronunciationsLoadingAction,
  type XVocabularyAddRowsAction,
  type XVocabularyCategoryAction,
  type XVocabularyChangeCategoryAction,
  type XVocabularyChangeQuizletURLAction,
  type XVocabularyChangeWordAction,
  type XVocabularyMoveEntryAction,
  type XVocabularyWordAction
} from './widgets/XVocabulary/actions';
import VocabularyWordRecord from '../player/widgets/Vocabulary/VocabularyWordRecord';
import VocabularyCategoryRecord from '../player/widgets/Vocabulary/VocabularyCategoryRecord';
import XPronunciationModalRecord from './widgets/XVocabulary/XVocabularyPronunciationModal';
import {ATTACH_POSTER, ATTACH_VIDEO} from './widgets/XVideo/actionTypes';
import {type AttachPosterAction, type AttachVideoAction} from './widgets/XVideo/actions';
import {type XWidgetProperties} from './widgets/interface';
import {TOGGLE_MULTIPLE_CHOICE_ALIGNMENT} from './widgets/XMultipleChoice/actionTypes';
import {type ToggleMultipleChoiceAlignmentAction} from './widgets/XMultipleChoice/actions';
import {
  type CardContentAction,
  type ChangeCardContentAction,
  type ChangeCardImageAction,
  type MoveCardAction
} from './widgets/XCards/actions';
import type XEditorCardRecord from './widgets/XCards/XEditorCardRecord';
import {
  CARDS_CHANGE_CARD_IMAGE,
  CARDS_CHANGE_CARD_TEXT,
  CARDS_CHANGE_CARD_TITLE,
  CARDS_CREATE_CARD,
  CARDS_DELETE_CARD,
  CARDS_MOVE_CARD
} from './widgets/XCards/actionTypes';
import {
  CHANGE_QUIZ_BLOCK_TEXT,
  CHANGE_QUIZ_BLOCK_TITLE,
  type XQuizAction
} from './widgets/XQuiz/actions';
import {type XFormattedTextProperties} from './widgets/XFormattedText/interface';
import {type XQuizProperties} from './widgets/XQuiz/XQuizRecord';
import {type XAudioProperties} from './widgets/XAudio/interface';
import {type XCardsProperties} from './widgets/XCards/interface';
import {type XGapFillProperties} from './widgets/XGapFill/interface';
import {type XImageMatchingProperties} from './widgets/XImage/XImageMatching/interface';
import {
  type XMatchingFreeChoiceRecordProperties,
  type XMatchingProperties
} from './widgets/XMatching/interface';
import {type XMultipleChoiceProperties} from './widgets/XMultipleChoice/interface';
import {type XQuestsProperties} from './widgets/XQuests/interface';
import {type XVideoProperties} from './widgets/XVideo/interface';
import {type XVocabularyProperties} from './widgets/XVocabulary/interface';
import {type XScrambledProperties} from './widgets/XScrambled/interface';
import {type XImageLabelingProperties} from './widgets/XImage/XImageLabeling/interface';
import {type XPictureChoiceProperties} from './widgets/XPictureChoice/interface';
import {
  DIALOGUE_ADD_MESSAGE,
  DIALOGUE_ADD_SPEAKER,
  DIALOGUE_CHANGE_AVATAR,
  DIALOGUE_CHANGE_COLOR_SCHEMA,
  DIALOGUE_CHANGE_MESSAGE,
  DIALOGUE_CHANGE_NAME,
  DIALOGUE_CHANGE_SPEAKER,
  DIALOGUE_MOVE_MESSAGE_DOWN,
  DIALOGUE_MOVE_MESSAGE_UP,
  DIALOGUE_REMOVE_MESSAGE,
  DIALOGUE_REMOVE_SPEAKER
} from './widgets/XDialogue/actionTypes';
import {type XDialogueProperties} from './widgets/XDialogue/interface';
import {
  type ChangeAvatarAction,
  type ChangeMessageAction,
  type ChangeNameAction,
  type ChangeSpeakerAction,
  type DialogueColorSchemaAction,
  type MessageContentAction,
  type SpeakerContentAction
} from './widgets/XDialogue/actions';
import {
  MATCHING_EXTRA_CONTENT_CHANGE,
  type XMatchingFreeChoiceActions
} from './widgets/XMatching/actions';
import {
  WORD_PICTURE_SET_ADD_CARD,
  WORD_PICTURE_SET_CHANGE_CARD_SIZE_TYPE,
  WORD_PICTURE_SET_CHANGE_IMAGE,
  WORD_PICTURE_SET_CHANGE_TEXT,
  WORD_PICTURE_SET_CHANGE_VISIBILITY,
  WORD_PICTURE_SET_CHANGE_WIDGET_THEME,
  WORD_PICTURE_SET_MOVE_CARD,
  WORD_PICTURE_SET_REMOVE_CARD,
  WORD_PICTURE_SET_ROLL_BACK_CARS,
  WORD_PICTURE_SET_SWITCH_INTERACTIVE
} from './widgets/XWordPictureSet/actionType';
import {type XWordSetProperties} from './widgets/XWordPictureSet/XWordSet/interface';
import {type XPictureSetProperties} from './widgets/XWordPictureSet/XPictureSet/interface';
import {type XWordPictureSetProperties} from './widgets/XWordPictureSet/XWordPictureSet/interface';
import {
  type WordPictureSetCardAction,
  type WordPictureSetChangeCardImageAction,
  type WordPictureSetChangeCardSizeTypeAction,
  type WordPictureSetChangeCardTextAction,
  type WordPictureSetChangeWidgetThemeAction,
  type WordPictureSetMoveCard,
  type WordPictureSetRollBackCards
} from './widgets/XWordPictureSet/actions';
import {TOGGLE_COMMENT_COLLAPSED_CHECKBOX} from './widgets/XComment/actionTypes';
import {type XCommentProperties} from './widgets/XComment/interface';
import {
  FLIP_CARDS_ADD_CARD,
  FLIP_CARDS_CHANGE_CARD_SIZE_TYPE,
  FLIP_CARDS_CHANGE_DEFAULT_FLIPPED,
  FLIP_CARDS_CHANGE_IMAGE,
  FLIP_CARDS_CHANGE_TEXT,
  FLIP_CARDS_DELETE_CARD,
  FLIP_CARDS_MOVE_CARD,
  FLIP_CARDS_ROLL_BACK_CARDS
} from './widgets/XFlipCards/actionTypes';
import type XFlipCardsRecord from './widgets/XFlipCards/XFlipCardsRecord';
import {
  type FlipCardsCardAction,
  type FlipCardsChangeCardSizeTypeAction,
  type FlipCardsChangeImageAction,
  type FlipCardsChangeTextAction,
  type FlipCardsMoveCard,
  type FlipCardsRollBackCards
} from './widgets/XFlipCards/actions';
import {
  STYLED_LIST_CHANGE_CONTENT,
  STYLED_LIST_CHANGE_STYLE,
  STYLED_LIST_CHANGE_TWO_COLUMNS,
  STYLED_LIST_SWITCH_WITH_NUMBERING
} from './widgets/XStyledList/actionTypes';
import type XHorizontalStyledList from './widgets/XStyledList/XHorizontalStyledListRecord';
import type XVerticalStyledList from './widgets/XStyledList/XVerticalStyledListRecord';
import {
  type XStyledListChangeContent,
  type XStyledListChangeStyle,
  type XStyledListChangeTwoColumnsAction
} from './widgets/XStyledList/actions';
import {SPELLING_SET_ORDINAL} from './widgets/XSpelling/actionsType';
import {type XSpellingProperties} from './widgets/XSpelling/interface';
import {type SpellingSetOrdinalAction} from './widgets/XSpelling/actions';
import {type XEditorWidgetsAction, type XWidgetAction} from './actions/interface';
import {DisplayButtonRecord} from '../player/DisplayButtonRecord';
import {type XNoteProperties} from './widgets/XNote/interface';
import {TOGGLE_NOTE_COLLAPSED} from './widgets/XNote/actionTypes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const REDUCERS: ReducersMapObject<any, XWidgetAction> = {
  [TASK_CHANGE]: (state: XWidgetProperties, action: XWidgetChangeAction): XWidgetProperties => {
    return state.set('task', action.change.value);
  },
  [CONTENT_CHANGE]: (
    state: XFormattedTextProperties,
    action: XWidgetChangeAction
  ): XFormattedTextProperties => {
    return state.set('content', action.change.value);
  },
  [TOGGLE_DISPLAY_AS_BUTTON]: (state: XFormattedTextProperties): XFormattedTextProperties => {
    return state.update('displayButton', value =>
      value ? undefined : DisplayButtonRecord.create()
    );
  },
  [DISPLAY_BUTTON_TITLE_CHANGE]: (
    state: XFormattedTextProperties,
    action: XWidgetChangeAction
  ): XFormattedTextProperties => {
    return state.setIn(['displayButton', 'title'], action.change.value);
  },
  [DISPLAY_BUTTON_ICON_CHANGE]: (
    state: XFormattedTextProperties,
    action: SetDisplayButtonIconAction
  ): XFormattedTextProperties => {
    return state.setIn(['displayButton', 'icon'], action.icon);
  },
  [GRAMMAR_TITLE_CHANGE]: (
    state: XFormattedTextProperties,
    action: XWidgetChangeAction<Value>
  ): XFormattedTextProperties => {
    return state.set('grammarTitle', action.change);
  },
  [XVOCABULARY_SET_ORIGINAL]: (
    state: XVocabularyProperties,
    action: XVocabularyChangeWordAction
  ): XVocabularyProperties => {
    const wordIndex = state.vocabulary.findIndex(
      entry => (entry as VocabularyWordRecord).wordId === action.wordId
    );

    state = state.setIn(['vocabulary', wordIndex, 'original'], action.value);
    return state;
  },
  [XVOCABULARY_CHANGE_QUIZLET_URL]: (
    state: XVocabularyProperties,
    {value}: XVocabularyChangeQuizletURLAction
  ): XVocabularyProperties => {
    return state.set('quizletURL', value);
  },
  [XVOCABULARY_CHANGE_LIST_TITLE]: (
    state: XVocabularyProperties,
    {value}: XVocabularyChangeQuizletURLAction
  ) => state.set('listTitle', value),
  [XVOCABULARY_RESET_PRONUNCIATION]: (
    state: XVocabularyProperties,
    {wordId}: XVocabularyWordAction
  ) => {
    const wordIndex = state.vocabulary.findIndex(
      entry => (entry as VocabularyWordRecord).wordId === wordId
    );
    state = state.setIn(['vocabulary', wordIndex, 'phoneticSpelling'], undefined);
    state = state.setIn(['vocabulary', wordIndex, 'soundId'], undefined);
    return state;
  },
  [XVOCABULARY_SET_TRANSLATION]: (
    state: XVocabularyProperties,
    action: XVocabularyChangeWordAction
  ): XVocabularyProperties => {
    const wordIndex = state.vocabulary.findIndex(
      entry => (entry as VocabularyWordRecord).wordId === action.wordId
    );
    return state.setIn(['vocabulary', wordIndex, 'translation'], action.value);
  },
  [XVOCABULARY_SET_CATEGORY]: (
    state: XVocabularyProperties,
    action: XVocabularyChangeCategoryAction
  ): XVocabularyProperties => {
    return state.setIn(['vocabulary', action.positionInVocabulary, 'name'], action.value);
  },
  [XVOCABULARY_ADD_WORD]: (state: XVocabularyProperties) => {
    return state.set(
      'vocabulary',
      state.vocabulary.push(
        new VocabularyWordRecord({wordId: genKey(), original: '', translation: ''})
      )
    );
  },
  [XVOCABULARY_ADD_ROWS]: (state: XVocabularyProperties, action: XVocabularyAddRowsAction) => {
    let {vocabulary} = state;
    const {wordId} = action;
    const before = vocabulary.takeUntil((w: VocabularyWordRecord) => w.wordId === wordId).toList();
    const currentAndAfter = vocabulary
      .skipUntil((w: VocabularyWordRecord) => w.wordId === wordId)
      .toList();
    let current = currentAndAfter.first() as VocabularyWordRecord;
    const after = currentAndAfter.shift();
    const {rows} = action;

    const firstRow = rows.shift();
    if (firstRow) {
      current = current.withMutations(w => {
        w.set('original', firstRow.original);
        w.set('translation', firstRow.translation);
        return w;
      });
    }

    const addWords = List(
      rows.map(
        r =>
          new VocabularyWordRecord({
            wordId: genKey(),
            original: r.original,
            translation: r.translation
          })
      )
    );

    vocabulary = before.push(current).concat(addWords, after).toList();
    return state.set('vocabulary', vocabulary);
  },
  [XVOCABULARY_ADD_CATEGORY]: (state: XVocabularyProperties, action: XWidgetAction) => {
    return state.set('vocabulary', state.vocabulary.push(new VocabularyCategoryRecord({name: ''})));
  },
  [XVOCABULARY_REMOVE_ENTRY]: (state: XVocabularyProperties, action: XVocabularyCategoryAction) => {
    return state.set('vocabulary', state.vocabulary.splice(action.positionInVocabulary, 1));
  },
  [XVOCABULARY_MOVE_ENTRY]: (state: XVocabularyProperties, action: XVocabularyMoveEntryAction) => {
    const entry = state.vocabulary.get(action.sourcePos);
    state = state.set('vocabulary', state.vocabulary.splice(action.sourcePos, 1));
    state = state.set('vocabulary', state.vocabulary.insert(action.targetPos, entry));
    return state;
  },
  [XVOCABULARY_OPEN_PRONUNCIATION_MODAL]: (
    state: XVocabularyProperties,
    {wordId}: XVocabularyWordAction
  ) => {
    const wordIndex = state.vocabulary.findIndex(
      entry => !!entry && isVocabularyWordJSON(entry) && entry.wordId === wordId
    );
    state = state.setIn(
      ['vocabulary', wordIndex],
      (state.vocabulary.get(wordIndex) as VocabularyWordRecord).stripSpaces()
    );
    return state.set(
      'pronunciationModal',
      new XPronunciationModalRecord(wordIndex, state.vocabulary)
    );
  },
  [XVOCABULARY_CLOSE_PRONUNCIATION_MODAL]: (state: XVocabularyProperties) => {
    return state.set('pronunciationModal', null);
  },
  [XVOCABULARY_MODAL_PRONUNCIATIONS_LOADED]: (
    state: XVocabularyProperties,
    action: PronunciationsLoadedAction
  ) => {
    const word = state.vocabulary.get(state.pronunciationModal!.wordIndex) as VocabularyWordRecord;
    return state.set(
      'pronunciationModal',
      state.pronunciationModal!.handleOptionsLoaded(action.options, word.soundId)
    );
  },
  [XVOCABULARY_MODAL_SELECT_PRONUNCIATION_OPTION]: (
    state: XVocabularyProperties,
    action: SelectPronunciationOptionAction
  ) => {
    return state.setIn(['pronunciationModal', 'selectedOptionIndex'], action.index);
  },
  [XVOCABULARY_MODAL_CHANGE_PHONETIC_SPELLING]: (
    state: XVocabularyProperties,
    action: ChangePhoneticSpellingAction
  ) => {
    return state.setIn(['pronunciationModal', 'phoneticSpelling'], action.value);
  },
  [XVOCABULARY_CHANGE_PRONUNCIATIONS_FOR_WORD]: (
    state: XVocabularyProperties,
    {soundId, phoneticSpelling, wordIndex}: ChangePronunciationAction
  ) => {
    state = state.setIn(['vocabulary', wordIndex, 'phoneticSpelling'], phoneticSpelling);
    return state.setIn(['vocabulary', wordIndex, 'soundId'], soundId);
  },
  [XVOCABULARY_STRIP_SPACES]: (state: XVocabularyProperties) => {
    return state.set(
      'vocabulary',
      state.vocabulary.map(entry => {
        return entry!.stripSpaces();
      })
    );
  },
  [XVOCABULARY_SET_PRONUNCIATIONS_LOADING_FOR_WORD]: (
    state: XVocabularyProperties,
    {wordId, isLoading}: SetPronunciationsLoadingAction
  ) => {
    const wordIndex = state.vocabulary.findIndex(
      entry => !!entry && isVocabularyWordJSON(entry) && entry.wordId === wordId
    );
    return state.setIn(['vocabulary', wordIndex, 'loadingPronunciations'], isLoading || undefined);
  },
  [QUESTIONS_CHANGE]: (
    state: XQuestsProperties,
    {change}: XWidgetChangeAction
  ): XQuestsProperties => state.set('content', change.value),
  [QUESTIONS_EXAMPLE_ANSWER_CHANGE]: (
    state: XQuestsProperties,
    {change}: XWidgetChangeAction
  ): XQuestsProperties => state.set('questsExampleContent', change.value),
  [SCRAMBLED_SENTENCES_CHANGE]: (
    state: XScrambledProperties,
    {change}: XWidgetChangeAction
  ): XScrambledProperties => {
    return state.set('sentencesValue', change.value);
  },
  [TOGGLE_COMMENT_COLLAPSED_CHECKBOX]: (state: XCommentProperties): XCommentProperties =>
    state.toggleCollapsedCheckbox(),
  [TOGGLE_NOTE_COLLAPSED]: (state: XNoteProperties): XNoteProperties => state.toggleCollapsed(),
  [ATTACH_AUDIO]: (
    state: XAudioProperties,
    {xwidgetId, audio}: AttachAudioAction
  ): XAudioProperties => {
    if (state.id === xwidgetId) {
      return state.set('audio', audio).set('audioId', audio.id);
    }
    return state;
  },
  [CLEAR_AUDIO]: (state: XAudioProperties, {xwidgetId}: XWidgetAction): XAudioProperties => {
    if (state.id === xwidgetId) {
      return state.delete('audio').delete('audioId');
    }
    return state;
  },
  [SET_CARD_PHRASE]: (
    state: XImageMatchingProperties,
    {choiceId, value}: XImageSetPhraseAction
  ): XImageMatchingProperties => {
    const cardIndex = state.cards.findIndex(
      (c: XImageMatchingCardRecord) => c.choiceId === choiceId
    );
    return state.setIn(['cards', cardIndex, 'phrase'], value);
  },
  [SET_CARD_IMAGE_DATA]: (
    state: XImageMatchingProperties,
    {index, imageId}: XImageSetCardDataAction
  ): XImageMatchingProperties => {
    return state.setIn(['cards', index, 'imageId'], imageId);
  },
  [ADD_CARD]: (
    state: XImageMatchingProperties | XImageLabelingProperties
  ): XImageMatchingProperties | XImageLabelingProperties => {
    return state.createNewCard();
  },
  [DELETE_CARD]: (
    state: XImageMatchingProperties,
    {index}: XImageCardDeleteAction
  ): XImageMatchingProperties => {
    const cards = state.cards.delete(index);
    return state.set('cards', cards);
  },
  [TOGGLE_PRE_FILL_VALUES]: (state: XImageMatchingProperties): XImageMatchingProperties => {
    return state.set('hasPreFillValues', !state.get('hasPreFillValues'));
  },
  [CHANGE_CARD_SIZE_TYPE]: (
    state: XImageMatchingProperties,
    {cardId, cardSizeType}: XImageCardChangeType
  ): XImageMatchingProperties => {
    return state.changeCardSizeType(cardId, cardSizeType);
  },
  [UPDATE_CARD]: (
    state: XImageLabelingProperties,
    {cardId, fields}: XImageCardUpdateAction
  ): XImageLabelingProperties => {
    return state.updateCard(cardId, fields);
  },
  [IMAGE_MOVE_CARD]: (
    state: XImageLabelingProperties | XImageMatchingProperties,
    {moveCardIndex, targetIndex}: XImageMoveCardAction
  ): XImageLabelingProperties | XImageMatchingProperties => {
    return state.moveCard(moveCardIndex, targetIndex);
  },
  [IMAGE_ROLL_BACK_CARS]: (
    state: XImageLabelingProperties | XImageMatchingProperties,
    {cards}: XImageRollBackCardsAction
  ): XImageLabelingProperties | XImageMatchingProperties => {
    return state.rollBackCards(cards);
  },
  [TOGGLE_FIRST_QUESTION_ITEM_EXAMPLE]: (
    state: XMatchingProperties,
    action: ToggleFirstQuestionItemExampleAction
  ): XMatchingProperties => {
    return state.setFirstQuestionIsExample(action.example);
  },
  [TOGGLE_CONTAINS_EXAMPLE]: (
    state: XScrambledProperties | XQuestsProperties,
    {xwidgetId}: XEditorWidgetsAction
  ): XScrambledProperties | XQuestsProperties => {
    if (state.id === xwidgetId) {
      return state.toggleExample();
    }
    return state;
  },
  [ATTACH_VIDEO]: (
    state: XVideoProperties,
    {video, xwidgetId, options}: AttachVideoAction
  ): XVideoProperties => {
    if (state.id === xwidgetId) {
      return state.set('video', video).set('videoOptions', options).delete('posterId');
    }
    return state;
  },
  [ATTACH_POSTER]: (
    state: XVideoProperties,
    {id, xwidgetId}: AttachPosterAction
  ): XVideoProperties => {
    if (state.id === xwidgetId) {
      return state.set('posterId', id);
    }
    return state;
  },
  [TOGGLE_MULTIPLE_CHOICE_ALIGNMENT]: (
    state: XMultipleChoiceProperties,
    {xwidgetId}: ToggleMultipleChoiceAlignmentAction
  ) => (state.id === xwidgetId ? state.toggleAlignment() : state),
  [ADD_EXTRA_CHOICE]: (
    state: XGapFillProperties,
    {xwidgetId}: XWidgetAction
  ): XGapFillProperties => (state.id === xwidgetId ? state.addExtraChoice() : state),
  [TOGGLE_PRE_FILL_VALUES]: (state: XGapFillProperties): XGapFillProperties =>
    state.togglePreFillValues(),
  [SET_EXTRA_CHOICE_VALUE]: (
    state: XGapFillProperties,
    {xwidgetId, extraChoiceId, value}: SetExtraChoiceValueAction
  ): XGapFillProperties =>
    state.id === xwidgetId ? state.setExtraChoiceValue(extraChoiceId, value) : state,
  [DELETE_EXTRA_CHOICE]: (
    state: XGapFillProperties,
    {xwidgetId, extraChoiceId}: DeleteExtraChoiceAction
  ): XGapFillProperties =>
    state.id === xwidgetId ? state.deleteExtraChoice(extraChoiceId) : state,

  // CARDS
  [CARDS_CHANGE_CARD_TITLE]: (
    state: XCardsProperties,
    {cardId, change}: ChangeCardContentAction
  ) => {
    const cardIndex = state.cardsList.findIndex((card: XEditorCardRecord) => card.id === cardId);
    return state.setIn(['cardsList', cardIndex, 'title'], change.value);
  },
  [CARDS_CHANGE_CARD_TEXT]: (
    state: XCardsProperties,
    {cardId, change}: ChangeCardContentAction
  ) => {
    const cardIndex = state.cardsList.findIndex((card: XEditorCardRecord) => card.id === cardId);
    return state.setIn(['cardsList', cardIndex, 'text'], change.value);
  },
  [CARDS_CHANGE_CARD_IMAGE]: (state: XCardsProperties, {cardId, image}: ChangeCardImageAction) => {
    const cardIndex = state.cardsList.findIndex((card: XEditorCardRecord) => card.id === cardId);
    return state.setIn(['cardsList', cardIndex, 'image'], image);
  },
  [CARDS_CREATE_CARD]: (state: XCardsProperties) => {
    return state.addEmptyCard();
  },
  [CARDS_DELETE_CARD]: (state: XCardsProperties, {cardId}: CardContentAction) => {
    return state.deleteCardById(cardId);
  },
  [CARDS_MOVE_CARD]: (state: XCardsProperties, {from, to}: MoveCardAction) => {
    return state.moveCard(from, to);
  },
  [CHANGE_QUIZ_BLOCK_TITLE]: (
    state: XQuizProperties,
    {blockId, change}: XQuizAction
  ): XQuizProperties => {
    return state.changeBlockTitle(blockId, change);
  },
  [CHANGE_QUIZ_BLOCK_TEXT]: (
    state: XQuizProperties,
    {blockId, change}: XQuizAction
  ): XQuizProperties => {
    return state.changeBlockText(blockId, change);
  },

  // Styled list
  [STYLED_LIST_CHANGE_CONTENT]: (
    state: XHorizontalStyledList | XVerticalStyledList,
    {content}: XStyledListChangeContent
  ): XHorizontalStyledList | XVerticalStyledList => {
    return state.changeContent(content);
  },
  [STYLED_LIST_CHANGE_STYLE]: (
    state: XHorizontalStyledList | XVerticalStyledList,
    {styleName}: XStyledListChangeStyle
  ): XHorizontalStyledList | XVerticalStyledList => {
    return state.changeStyleName(styleName);
  },
  [STYLED_LIST_SWITCH_WITH_NUMBERING]: (state: XHorizontalStyledList): XHorizontalStyledList => {
    return state.switchWithNumbering();
  },
  [STYLED_LIST_CHANGE_TWO_COLUMNS]: (
    state: XVerticalStyledList,
    {twoColumnsCheckbox}: XStyledListChangeTwoColumnsAction
  ): XVerticalStyledList => state.changeTwoColumns(twoColumnsCheckbox),

  //PictureChoice
  [PICTURE_CHOICE_DELETE_QUESTION]: (state: XPictureChoiceProperties, {questionId}: QuestionId) =>
    state.deleteQuestion(questionId),
  [PICTURE_CHOICE_MOVE_QUESTION]: (state: XPictureChoiceProperties, {from, to}: MoveQuestion) =>
    state.moveQuestion(from, to),
  [PICTURE_CHOICE_ADD_QUESTION]: (state: XPictureChoiceProperties) => state.addEmptyQuestion(),
  [PICTURE_CHOICE_CHANGE_QUESTION_CONTENT]: (
    state: XPictureChoiceProperties,
    {questionIndex, change}: ChangeQuestionContent
  ) => state.changeQuestionContent(questionIndex, change),
  [PICTURE_CHOICE_ADD_CARD]: (state: XPictureChoiceProperties, {questionIndex}: QuestionIndex) =>
    state.addEmptyCard(questionIndex),
  [PICTURE_CHOICE_SET_CARD_IMAGE_DATA]: (
    state: XPictureChoiceProperties,
    {questionIndex, cardIndex, imageId}: SetImageData
  ) => state.setCardImage(questionIndex, cardIndex, imageId),
  [PICTURE_CHOICE_CHANGE_CARD_SIZE_TYPE]: (
    state: XPictureChoiceProperties,
    {questionIndex, cardIndex, cardSizeType}: SetCardSizeType
  ) => state.setCardSizeType(questionIndex, cardIndex, cardSizeType),
  [PICTURE_CHOICE_MOVE_CARD]: (
    state: XPictureChoiceProperties,
    {questionId, moveCardIndex, targetCardIndex}: PictureChoiceMoveCard
  ) => state.moveCard(questionId, moveCardIndex, targetCardIndex),
  [PICTURE_CHOICE_ROLL_BACK_CARDS]: (
    state: XPictureChoiceProperties,
    {questions}: PictureChoiceRollBackCards
  ) => state.rollBackCards(questions),
  [PICTURE_CHOICE_DELETE_CARD]: (
    state: XPictureChoiceProperties,
    {questionId, cardId}: QuestionCardIds
  ) => state.deleteCard(questionId, cardId),
  [PICTURE_CHOICE_CHOOSE_ANSWER]: (
    state: XPictureChoiceProperties,
    {questionId, cardId}: QuestionCardIds
  ) => state.toggleAnswer(questionId, cardId),

  //DIALOGUE
  [DIALOGUE_ADD_SPEAKER]: (state: XDialogueProperties) => state.addSpeaker(),
  [DIALOGUE_REMOVE_SPEAKER]: (state: XDialogueProperties, {speakerId}: SpeakerContentAction) =>
    state.removeSpeaker(speakerId),
  [DIALOGUE_CHANGE_AVATAR]: (state: XDialogueProperties, {speakerId, avatar}: ChangeAvatarAction) =>
    state.changeAvatar(speakerId, avatar),

  [DIALOGUE_CHANGE_NAME]: (state: XDialogueProperties, {speakerId, name}: ChangeNameAction) =>
    state.changeName(speakerId, name),
  [DIALOGUE_ADD_MESSAGE]: (state: XDialogueProperties, {speakerId}: SpeakerContentAction) =>
    state.addMessage(speakerId),
  [DIALOGUE_REMOVE_MESSAGE]: (state: XDialogueProperties, {messageId}: MessageContentAction) =>
    state.removeMessage(messageId),
  [DIALOGUE_CHANGE_MESSAGE]: (
    state: XDialogueProperties,
    {messageId, message}: ChangeMessageAction
  ) => state.changeMessage(messageId, message),
  [DIALOGUE_MOVE_MESSAGE_DOWN]: (state: XDialogueProperties, {messageId}: MessageContentAction) =>
    state.moveMessageDown(messageId),
  [DIALOGUE_MOVE_MESSAGE_UP]: (state: XDialogueProperties, {messageId}: MessageContentAction) =>
    state.moveMessageUp(messageId),
  [DIALOGUE_CHANGE_SPEAKER]: (
    state: XDialogueProperties,
    {messageId, speakerId}: ChangeSpeakerAction
  ) => state.changeSpeaker(messageId, speakerId),
  [DIALOGUE_CHANGE_COLOR_SCHEMA]: (
    state: XDialogueProperties,
    {styleName}: DialogueColorSchemaAction
  ) => state.changeColorSchema(styleName),

  //FlipCards
  [FLIP_CARDS_ADD_CARD]: (state: XFlipCardsRecord) => state.addCard(),
  [FLIP_CARDS_DELETE_CARD]: (state: XFlipCardsRecord, {cardId}: FlipCardsCardAction) =>
    state.deleteCard(cardId),
  [FLIP_CARDS_CHANGE_DEFAULT_FLIPPED]: (state: XFlipCardsRecord) => state.changeDefaultFlipped(),
  [FLIP_CARDS_CHANGE_IMAGE]: (
    state: XFlipCardsRecord,
    {cardId, imageId}: FlipCardsChangeImageAction
  ) => state.changeImage(cardId, imageId),
  [FLIP_CARDS_CHANGE_CARD_SIZE_TYPE]: (
    state: XFlipCardsRecord,
    {cardId, cardSizeType}: FlipCardsChangeCardSizeTypeAction
  ) => state.changeCardSizeType(cardId, cardSizeType),
  [FLIP_CARDS_CHANGE_TEXT]: (state: XFlipCardsRecord, {cardId, text}: FlipCardsChangeTextAction) =>
    state.changeText(cardId, text),
  [FLIP_CARDS_MOVE_CARD]: (
    state: XFlipCardsRecord,
    {moveCardIndex, targetIndex}: FlipCardsMoveCard
  ) => state.moveCard(moveCardIndex, targetIndex),
  [FLIP_CARDS_ROLL_BACK_CARDS]: (state: XFlipCardsRecord, {cards}: FlipCardsRollBackCards) =>
    state.rollBackCards(cards),

  // Matching
  [MATCHING_EXTRA_CONTENT_CHANGE]: (
    state: XMatchingFreeChoiceRecordProperties,
    action: XMatchingFreeChoiceActions
  ) => {
    return state.set('extraContent', action.change.value);
  },

  //WordPictureSet
  [WORD_PICTURE_SET_ADD_CARD]: (
    state: XWordSetProperties | XPictureSetProperties | XWordPictureSetProperties
  ): XWordSetProperties | XPictureSetProperties | XWordPictureSetProperties => {
    return state.addCard();
  },
  [WORD_PICTURE_SET_REMOVE_CARD]: (
    state: XWordSetProperties | XPictureSetProperties | XWordPictureSetProperties,
    {cardId}: WordPictureSetCardAction
  ): XWordSetProperties | XPictureSetProperties | XWordPictureSetProperties => {
    return state.removeCard(cardId);
  },
  [WORD_PICTURE_SET_CHANGE_VISIBILITY]: (
    state: XWordSetProperties | XPictureSetProperties | XWordPictureSetProperties
  ): XWordSetProperties | XPictureSetProperties | XWordPictureSetProperties => {
    return state.changeIsDefaultHidden();
  },
  [WORD_PICTURE_SET_SWITCH_INTERACTIVE]: (
    state: XWordSetProperties | XPictureSetProperties | XWordPictureSetProperties
  ): XWordSetProperties | XPictureSetProperties | XWordPictureSetProperties => {
    return state.switchIsNotInteractive();
  },
  [WORD_PICTURE_SET_CHANGE_IMAGE]: (
    state: XPictureSetProperties | XWordPictureSetProperties,
    {cardId, image}: WordPictureSetChangeCardImageAction
  ): XPictureSetProperties | XWordPictureSetProperties => {
    return state.changeCardImage(cardId, image);
  },
  [WORD_PICTURE_SET_CHANGE_TEXT]: (
    state: XWordSetProperties | XWordPictureSetProperties,
    {cardId, editor}: WordPictureSetChangeCardTextAction
  ): XWordSetProperties | XWordPictureSetProperties => {
    return state.changeCardText(cardId, editor);
  },
  [WORD_PICTURE_SET_CHANGE_CARD_SIZE_TYPE]: (
    state: XPictureSetProperties | XWordPictureSetProperties,
    {cardId, cardSizeType}: WordPictureSetChangeCardSizeTypeAction
  ): XPictureSetProperties | XWordPictureSetProperties => {
    return state.changeCardSizeType(cardId, cardSizeType);
  },
  [WORD_PICTURE_SET_MOVE_CARD]: (
    state: XPictureSetProperties | XWordPictureSetProperties,
    {moveCardIndex, targetIndex}: WordPictureSetMoveCard
  ): XPictureSetProperties | XWordPictureSetProperties => {
    return state.moveCard(moveCardIndex, targetIndex);
  },
  [WORD_PICTURE_SET_ROLL_BACK_CARS]: (
    state: XPictureSetProperties | XWordPictureSetProperties,
    {cardIds}: WordPictureSetRollBackCards
  ): XPictureSetProperties | XWordPictureSetProperties => {
    return state.rollBackCards(cardIds);
  },
  [WORD_PICTURE_SET_CHANGE_WIDGET_THEME]: (
    state: XPictureSetProperties | XWordPictureSetProperties,
    {widgetTheme}: WordPictureSetChangeWidgetThemeAction
  ): XPictureSetProperties | XWordPictureSetProperties => {
    return state.changeWidgetTheme(widgetTheme);
  },

  //Spelling
  [SPELLING_SET_ORDINAL]: (state: XSpellingProperties, {ordinal}: SpellingSetOrdinalAction) => {
    return state.setOrdinal(ordinal);
  }
};

const initialState: XWidgetProperties = new XFormattedTextRecord({
  id: genKey(),
  type: WidgetType.FORMATTED_TEXT,
  task: valueJSONFromText('Test Task Formatted Text'),
  content: valueJSONFromText('Lorem ipsum dolor...')
});

function isWidgetAction(action: Action): action is XWidgetAction {
  return !!(action as XWidgetAction).xwidgetId;
}

function xwidgetReducer(
  state: XWidgetProperties = initialState,
  action: Action
): XWidgetProperties {
  if (!isWidgetAction(action) || action.xwidgetId !== state.id) {
    return state;
  }
  const reducer: Reducer<XWidgetProperties> = REDUCERS[action.type];
  const newState = reducer ? reducer(state, action) : state;

  return newState;
}

export default xwidgetReducer;
