import React from 'react';
import {type Editor, type Value} from '@englex/slate';
import Scrollbars from 'react-custom-scrollbars';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import SlateEditor from 'components/Slate/SlateEditor/SlateEditor';
import Undo from 'components/Slate/SlateEditor/plugins/button/History/Undo';
import Redo from 'components/Slate/SlateEditor/plugins/button/History/Redo';
import Bold from 'components/Slate/SlateEditor/plugins/button/Bold';
import Italic from 'components/Slate/SlateEditor/plugins/button/Italic';
import CharSelector from 'components/Slate/SlateEditor/plugins/button/CharSelector';
import ClearFormatting from 'components/Slate/SlateEditor/plugins/button/ClearFormatting';
import OrderedList from 'components/Slate/SlateEditor/plugins/button/List/OrderedList';
import UnorderedList from 'components/Slate/SlateEditor/plugins/button/List/UnorderedList';
import ListItem from 'components/Slate/SlateEditor/plugins/button/List/ListItem';
import FormatPainter from 'components/Slate/SlateEditor/plugins/button/FormatPainter';

import {sectionsMessages} from '../i18n';
import './ContentsEditor.scss';

interface Props extends WrappedComponentProps {
  value: Value;
  onChange: (change: Editor) => void;
}

class ContentsEditor extends React.Component<Props> {
  public static plugins = [
    new Undo(),
    new Redo(),
    new Bold(),
    new Italic(),
    new OrderedList(),
    new UnorderedList(),
    new ListItem(),
    new CharSelector(),
    new FormatPainter(),
    new ClearFormatting()
  ];

  public render() {
    const {
      intl: {formatMessage},
      onChange
    } = this.props;
    return (
      <div className="unit-section-contents-editor">
        <Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
          <div className="editor-container">
            <SlateEditor
              value={this.props.value}
              bsSize="lg"
              toolbar={{portalId: 'contents-toolbar-portal'}}
              plugins={ContentsEditor.plugins}
              placeholder={formatMessage(sectionsMessages.ContentsEditorPlaceholder)}
              onChange={onChange}
            />
          </div>
        </Scrollbars>
      </div>
    );
  }
}

export default injectIntl(ContentsEditor);
