import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {persistReducer} from 'redux-persist';

import {PersistCrossTab} from 'components/PersistCrossTab';
import xeditorReducer from 'store/exercise/editor/xeditorReducer';
import injectReducer from 'store/injectReducer';
import {persistClipboardConfig, persistXeditorConfig} from 'store/persist';
import {type XEditorState} from 'store/exercise/editor/interface';
import clipboardReducer, {type ClipboardState} from 'store/exercise/editor/clipboardReducer';
import introReducer from 'store/intro/introReducer';
import {AuthRolesGuard} from 'authentication';

import {PageNotFound} from '../PageNotFound';
import XEditorRoute from './XEditorPage';
import NewExerciseRoute from './XEditorPage/AsyncNewExerciseRoute';
import coursebookLibraryCommonReducer from './Common/reducer';
import {Library} from './CoursebookLibrary/Library';
import Coursebook from './CoursebookPage';
import {FeedbackPage} from './Feedback/FeedbackPage';
import XIntroRoute from './XIntroPage/AsyncIntroRoute';

import './ImageModal.scss';

/**
 * Base route for manage library entities in Online Class
 */
class LibraryRoute extends React.Component {
  public render() {
    return (
      <PersistCrossTab persistConfig={persistClipboardConfig}>
        <Routes>
          <Route index={true} element={<Library />} />

          <Route path="coursebook/:coursebookId/*">
            <Route path="unit/:unitId/exercise">
              <Route path=":exerciseId" element={<XEditorRoute />} />
              <Route path="new/:mainExerciseId?" element={<NewExerciseRoute />} />
            </Route>

            <Route path="unit/:unitId/intro" element={<XIntroRoute />} />
            <Route path="*" element={<Coursebook />} />
          </Route>

          <Route path="feedback" element={<FeedbackPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </PersistCrossTab>
    );
  }
}

const InjectedReducerLibraryRoute = injectReducer({
  xeditor: persistReducer<XEditorState>(persistXeditorConfig, xeditorReducer),
  xintro: introReducer,
  clipboard: persistReducer<ClipboardState>(persistClipboardConfig, clipboardReducer),
  coursebookLibraryCommon: coursebookLibraryCommonReducer
})(LibraryRoute);

export default () => {
  return (
    <AuthRolesGuard roles="methodist">
      <InjectedReducerLibraryRoute />
    </AuthRolesGuard>
  );
};
