import {type UserV2} from 'components/CoursebookLibrary/interface';
import {type UnitExerciseJSON} from 'store/interface';
import {BaseRecord} from 'immutable-record/Record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {type SupplementaryExerciseRecordProps} from './interface';

interface Props extends SupplementaryExerciseRecordProps {}

const Record = recordBase()(BaseRecord);

class UnitExerciseSupplementaryRecord extends Record implements Props {
  public declare readonly id: number;

  public declare readonly excerpt: string;

  public declare readonly lockedBy: UserV2;

  public declare readonly exerciseId: string;

  public constructor(unitExerciseJSON: UnitExerciseJSON) {
    super();
    this.initValues({
      id: unitExerciseJSON.id,
      excerpt: unitExerciseJSON.exercise.lastRevision.excerpt,
      exerciseId: unitExerciseJSON.exercise.id
    });
  }

  public toJSON(): UnitExerciseJSON[] {
    return [
      {
        id: this.id,
        parentExerciseId: null,
        exercise: {
          id: this.exerciseId,
          lastRevision: {
            excerpt: this.excerpt
          }
        }
      } as UnitExerciseJSON
    ];
  }
}

decorate(UnitExerciseSupplementaryRecord, {
  id: property(0),
  excerpt: property(''),
  lockedBy: property(),
  exerciseId: property()
});
record()(UnitExerciseSupplementaryRecord);
export default UnitExerciseSupplementaryRecord;
