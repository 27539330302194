import React from 'react';
import classNames from 'classnames';
import {type ConnectDragSource, type ConnectDropTarget} from 'react-dnd';

import UnitExerciseInfo from './UnitExerciseInfo';
import SupplementaryUnitExercisesPanel from '../containers/SupplementaryExercisesPanel';

import './UnitExerciseMain.scss';

interface Props {
  unitExerciseId: number;
  index: number;
  excerpt: string;
  lockedBy?: string;
  supplementaryExercisesNumber: number;
  pageIndex: number;
  readonly?: boolean;
  isInsideDragItem?: boolean;
  isDragItem?: boolean;
  isDragItemInSupplementaryPosition?: boolean;
  isInvisible?: boolean;
  exerciseId: string;
  wasCopiedToClipboard?: boolean;

  animationElements?: Array<string | number>;
  animated?: boolean;
  onAnimationEnd?: () => void;
  withAutoScroll?: boolean;

  deleteUnitExercise: () => void;
  openExercise?: (e: React.MouseEvent<HTMLDivElement>) => void;
  toggleSupplementaryExercisesPanel: () => void;
  connectDragSource?: ConnectDragSource;
  getMainElementRef: (el: HTMLDivElement | null) => void;
  connectDropTarget?: ConnectDropTarget;
  createSupplementaryExercise: () => void;
  viewExercise: () => void;
  showSupplementaryExercisesPanel: boolean;
  copyExercise?(): void;
  copyToClipboard?(): void;
}

interface State {
  awaitsScroll?: true;
}

export default class MainExerciseView extends React.PureComponent<Props, State> {
  public state: State = {};

  private component: HTMLDivElement | null;

  componentDidMount() {
    if (this.props.withAutoScroll && this.component) {
      this.component.scrollIntoView({block: 'center', behavior: 'smooth'});
    }
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (!prevProps.withAutoScroll && this.props.withAutoScroll && this.component) {
      return this.component.scrollIntoView({block: 'center', behavior: 'smooth'});
    }

    if (!prevState.awaitsScroll && this.state.awaitsScroll && this.component) {
      this.setState({awaitsScroll: undefined});
      this.component.scrollIntoView({
        block: 'center',
        behavior: 'auto'
      });
    }
  }

  public render() {
    const {
      lockedBy,
      supplementaryExercisesNumber,
      openExercise,
      excerpt,
      deleteUnitExercise,
      toggleSupplementaryExercisesPanel,
      readonly,
      isInsideDragItem,
      connectDragSource,
      isDragItem,
      isInvisible,
      connectDropTarget,
      index,
      pageIndex,
      unitExerciseId,
      isDragItemInSupplementaryPosition,
      exerciseId,
      createSupplementaryExercise,
      animationElements,
      animated,
      onAnimationEnd,
      viewExercise,
      showSupplementaryExercisesPanel,
      copyExercise,
      copyToClipboard,
      wasCopiedToClipboard
    } = this.props;
    const className = classNames('unit-exercise-main', {
      'drag-item': isDragItem,
      'invisible-unit-exercise': isInvisible,
      animated: animated,
      readonly
    });
    const handle = (
      <div className="unit-exercise-main-drag-handle ">
        <div className="handle-image" />
      </div>
    );
    const content = (
      <div className={className} ref={this.componentRef} onAnimationEnd={onAnimationEnd}>
        <UnitExerciseInfo
          supplementaryExercisesNumber={supplementaryExercisesNumber}
          openExercise={openExercise}
          excerpt={excerpt}
          lockedBy={lockedBy}
          handleSupplementaryExercisesTrigger={toggleSupplementaryExercisesPanel}
          deleteUnitExercise={deleteUnitExercise}
          readonly={readonly}
          hideDropdown={isInsideDragItem || isDragItem}
          createSupplementaryExercise={createSupplementaryExercise}
          viewExercise={viewExercise}
          showSupplementaryExercisesPanel={showSupplementaryExercisesPanel}
          copyExercise={copyExercise}
          copyToClipboard={copyToClipboard}
          wasCopiedToClipboard={wasCopiedToClipboard}
        />
        <SupplementaryUnitExercisesPanel
          animationElements={animationElements}
          unitExerciseIndex={index}
          pageIndex={pageIndex}
          isInDragItem={isDragItem || isInsideDragItem}
          unitExerciseId={unitExerciseId}
          isDragItemInSupplementaryPosition={isDragItemInSupplementaryPosition}
          toggleSupplementaryExercisesPanel={toggleSupplementaryExercisesPanel}
          exerciseId={exerciseId}
          scrollToParent={this.scrollToComponent}
        />
        {connectDragSource ? connectDragSource(handle) : handle}
      </div>
    );
    return connectDropTarget ? connectDropTarget(content) : content;
  }

  private componentRef = (el: HTMLDivElement | null) => {
    this.component = el;
    this.props.getMainElementRef(el);
  };

  private scrollToComponent = () => this.setState({awaitsScroll: true});
}
