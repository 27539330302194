import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {push} from 'store/router';
import {requestLevels} from 'store/exercise/editor/actions/xeditor';
import {coursebookPath} from 'common/paths';
import {type AppState, type CourseDetailed, type LanguageLevel} from 'store/interface';

import CoursebookDataModal from './CoursebookDataModal';
import {
  closeCoursebookDataModal,
  copyCoursebook,
  createCoursebook,
  type CoursebookOptions,
  editCoursebook,
  type CoursebookOptionsWithId,
  requestAvailableCourses,
  setAvailableCourses,
  setAvailableLevels
} from './action';
import {
  type CoursebookDataModalDispatchProps,
  type CoursebookDataModalStateProps
} from './interface';

const mapStateToProps = (state: AppState): CoursebookDataModalStateProps => {
  const {editedCoursebook, copiedCoursebook, availableCourses, availableLevels, isOpen} =
    state.coursebookLibraryCommon!.coursebookDataModal;
  return {
    availableLevels,
    availableCourses,
    editedCoursebook,
    copiedCoursebook,
    isOpen
  };
};

const mapDispatchToProps = (
  dispatch: Dispatch<Action, AppState>
): CoursebookDataModalDispatchProps => ({
  close: () => dispatch(closeCoursebookDataModal()),
  requestCourses: () => dispatch(requestAvailableCourses()),
  setAvailableCourses: (data: CourseDetailed[]) => dispatch(setAvailableCourses(data)),
  requestLevels: () => dispatch(requestLevels()),
  setAvailableLevels: (levels: LanguageLevel[]) => dispatch(setAvailableLevels(levels)),
  redirectToCoursebook: (coursebookId: string) => dispatch(push(coursebookPath(coursebookId))),
  createCoursebook: (opts: CoursebookOptions) => dispatch(createCoursebook(opts)),
  editCoursebook: (opts: CoursebookOptionsWithId) => dispatch(editCoursebook(opts)),
  copyCoursebook: (opts: CoursebookOptionsWithId) => dispatch(copyCoursebook(opts))
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursebookDataModal);
