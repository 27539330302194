import React, {type FC} from 'react';
import RetinaImage from '@englex/react-retina-image';
import {FormattedMessage} from 'react-intl';

import {type CoursebookUnit} from 'store/interface';

import CoverUploader from '../../../Common/CoverUploader';
import {
  type AttachCoverModule,
  type DetachCoverModule
} from '../../../Common/CoverUploader/interface';

import './CoverAndTitle.scss';

const unitCoverMinDimension = 251;

interface Props {
  attachModule: AttachCoverModule;
  detachModule: DetachCoverModule;
  unit: CoursebookUnit;
}

const CoverAndTitle: FC<Props> = ({attachModule, detachModule, unit: {cover, ordinal, title}}) => (
  <div className="unit-cover-title">
    {ordinal ? (
      <CoverUploader
        coverMinWidth={unitCoverMinDimension}
        attachModule={attachModule}
        confirmHeaderText={<FormattedMessage id="Coursebook.Unit.CoverDeleteConfirm" />}
        coverExists={!!cover}
        detachModule={detachModule}
      >
        {cover ? (
          <RetinaImage src={cover.urls} className="unit-cover" />
        ) : (
          <div className="unit-cover-placeholder">
            <div className="unit-letter">U</div>
          </div>
        )}
      </CoverUploader>
    ) : null}
    <div className="unit-info-block">
      {ordinal ? <div className="unit-ordinal">Unit {ordinal}</div> : null}
      <div className="unit-title" title={title}>
        {title}
      </div>
    </div>
  </div>
);

export default CoverAndTitle;
