import {Stack} from 'immutable';

import {BaseRecord} from 'immutable-record/Record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

interface HistoryRecordProps<O> {
  readonly _undos: Stack<O>;
  readonly _redos: Stack<O>;
  readonly current: O;
}

interface Props<O> extends HistoryRecordProps<O> {}

const Record = recordBase()(BaseRecord);

class HistoryRecord<O> extends Record implements Props<O> {
  public declare readonly _undos: Stack<O>;

  public declare readonly _redos: Stack<O>;

  public declare readonly current: O;

  public constructor(initialState: O) {
    super();
    this.initValues({current: initialState});
  }

  public addNewStep(step: O) {
    return this.set('_undos', this._undos.unshift(this.current))
      .set('current', step)
      .set('_redos', Stack());
  }

  public undo() {
    return this.set('_redos', this._redos.unshift(this.current))
      .set('current', this._undos.first())
      .set('_undos', this._undos.shift());
  }

  public redo() {
    return this.set('_undos', this._undos.unshift(this.current))
      .set('current', this._redos.first())
      .set('_redos', this._redos.shift());
  }

  public clear() {
    return this.set('current', this._undos.last() || this.current)
      .set('_undos', Stack())
      .set('_redos', Stack());
  }

  public save() {
    return this.set('_undos', Stack()).set('_redos', Stack());
  }

  public get hasUndos() {
    return !!this._undos.size;
  }

  public get hasRedos() {
    return !!this._redos.size;
  }

  public get initialState() {
    return this._undos.last() || this.current;
  }
}

decorate(HistoryRecord, {
  _undos: property(Stack()),
  _redos: property(Stack()),
  current: property()
});
record()(HistoryRecord);
export default HistoryRecord;
