import React, {type FC, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';

import {type AppState} from 'store/interface';
import * as toastr from 'components/toastr';
import {messages} from 'components/CoursebookLibrary/UnitsList/i18n';
import EditTitleModal from 'components/modals/EditTitleModal/EditTitleModal';
import {renameUnit, setEditedUnitId} from 'components/CoursebookLibrary/UnitsList/action';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';

import {type CoursebookRouteParams} from '../../interface';

const MAX_LENGTH = 256;

interface Props {
  editCoursebookSuccessCallback: () => void;
}

export const RenameUnitModal: FC<Props> = ({editCoursebookSuccessCallback: onSuccess}) => {
  const dispatch = useAxiosDispatch();
  const intl = useIntl();

  const {coursebookId} = useParams<CoursebookRouteParams>();
  const [submittingForm, setSubmittingForm] = useState(false);

  const {editedUnitId, editedUnitTitle, isRevision} = useSelector((state: AppState) => {
    const coursebookPage = state.coursebookPage!;
    const editedUnitId = coursebookPage.editedUnitId;

    const {title, isRevision} = editedUnitId
      ? coursebookPage.units!.find(u => u.id === editedUnitId)!
      : {title: undefined, isRevision: undefined};

    return {editedUnitId, editedUnitTitle: title, isRevision};
  });

  const rename = useCallback(
    (coursebookId: string, unitId: number, newTitle: string) =>
      dispatch(renameUnit(coursebookId, unitId, newTitle)),
    [dispatch]
  );
  const onHide = useCallback(() => dispatch(setEditedUnitId()), [dispatch]);

  const submit = useCallback(
    (title: string) => {
      setSubmittingForm(true);

      rename(coursebookId!, editedUnitId!, title)
        .then(() => {
          onSuccess();

          toastr.success(
            '',
            intl.formatMessage(
              isRevision ? messages.renameTestSuccess : messages.renameModalSuccess
            )
          );
        })
        .catch(() =>
          toastr.error(
            '',
            intl.formatMessage(isRevision ? messages.renameModalTestFail : messages.renameModalFail)
          )
        )
        .finally(() => {
          onHide();
          setSubmittingForm(false);
        });
    },
    [coursebookId, rename, onHide, onSuccess, editedUnitId, intl, isRevision]
  );

  const modalTitle = isRevision
    ? intl.formatMessage({id: 'ServerCoursebook.UnitsList.RenameRevisionModal.Title'})
    : intl.formatMessage({id: 'ServerCoursebook.UnitsList.RenameUnitModal.Title'});

  const label = intl.formatMessage({id: 'ServerCoursebook.UnitsList.RenameModal.Label'});

  const placeholder = intl.formatMessage(messages.renameModalPlaceholder);

  const maxLengthErrorMessage = intl.formatMessage(messages.editModalValidationMaxLength, {
    count: MAX_LENGTH
  });

  const requiredErrorMessage = intl.formatMessage(messages.editModalValidationRequired);

  const show = !!editedUnitId;

  return (
    <EditTitleModal
      editedTitle={editedUnitTitle ? editedUnitTitle : ''}
      handleSubmit={submit}
      onHide={onHide}
      show={show}
      submitting={submittingForm}
      title={modalTitle}
      label={label}
      placeholder={placeholder}
      maxLengthErrorMessage={maxLengthErrorMessage}
      requiredErrorMessage={requiredErrorMessage}
    />
  );
};
