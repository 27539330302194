import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {connect} from 'react-redux';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import classNames from 'classnames';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import ModalError from 'components/ModalError';
import Loader from 'components/Loader';
import {type AppState, type CoursebookSection, type Section} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';

import {
  closeCoursebookSectionModal,
  type CreateCoursebookSectionParams,
  createCoursebookSectionRequest,
  requestSections,
  type UpdateCoursebookSectionParams,
  updateCoursebookSectionRequest
} from '../actions/action';
import CoursebookSectionModalForm from './CoursebookSectionModalForm';
import {
  type CancellablePromise,
  makeCancellable
} from '../../../../../../helpers/cancellablePromise';
import {sectionsMessages} from '../i18n';

import './CoursebookSectionModal.scss';

interface OwnProps extends WrappedComponentProps {
  coursebookId: string;
  requestCoursebookSections: () => void;
}

interface StateProps {
  show?: boolean;
  editedCoursebookSectionId?: number;
  coursebookSections: CoursebookSection[];
}

interface DispatchProps {
  close: () => void;
  requestSections: () => Promise<Action>;
  createCoursebookSectionRequest: (params: CreateCoursebookSectionParams) => Promise<Action>;
  updateCoursebookSectionRequest: (params: UpdateCoursebookSectionParams) => Promise<Action>;
}

interface Props extends StateProps, OwnProps, DispatchProps {}

interface State {
  requestSectionsError?: boolean;
  sections?: Section[];
  submittingForm?: boolean;
}

class CoursebookSectionModal extends React.Component<Props, State> {
  public state: State = {};

  private getSectionsRequest: CancellablePromise;
  private submitFormRequest: CancellablePromise;

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.show && !prevProps.show) {
      this.requestSections();
    }
  }

  public render() {
    const {show, editedCoursebookSectionId} = this.props;
    return (
      <Modal
        backdrop="static"
        show={show}
        onHide={this.closeModal}
        className="coursebook-section-modal"
      >
        <Modal.Header>
          <Modal.Title>
            {editedCoursebookSectionId ? (
              <FormattedMessage id="Coursebook.Contents.Modal.EditHeader" />
            ) : (
              <FormattedMessage id="Coursebook.Contents.Modal.CreateHeader" />
            )}
            <a onClick={this.closeModal}>
              <Icon name="pc-close" tag="i" />
            </a>
          </Modal.Title>
        </Modal.Header>
        {this.renderBodyAndFooter()}
      </Modal>
    );
  }

  private renderBodyAndFooter = () => {
    if (this.state.requestSectionsError) {
      return (
        <React.Fragment>
          <Modal.Body>{this.renderModalError()}</Modal.Body>
          <Modal.Footer>{this.renderCancelBtn()}</Modal.Footer>
        </React.Fragment>
      );
    }
    if (!this.state.sections) {
      return (
        <React.Fragment>
          <Modal.Body>
            <Loader />
          </Modal.Body>
          <Modal.Footer>{this.renderCancelBtn()}</Modal.Footer>
        </React.Fragment>
      );
    }
    return (
      <CoursebookSectionModalForm
        sections={this.state.sections}
        editedCoursebookSectionTitle={
          this.editedCoursebookSection && this.editedCoursebookSection.section.title
        }
        coursebookSections={this.props.coursebookSections}
        handleSubmit={this.handleFormSubmit}
        renderFooterButtons={() => (
          <React.Fragment>
            {this.renderCancelBtn()}
            {this.renderSubmitBtn()}
          </React.Fragment>
        )}
      />
    );
  };

  private renderModalError = () => (
    <ModalError
      reload={this.requestSections}
      errorTitle={<FormattedMessage id="CoursebookLibrary.DataModal.LoadDataErrorHeader" />}
      errorText={<FormattedMessage id="Coursebook.Contents.Modal.SectionsListRequestError" />}
    />
  );

  private renderCancelBtn = () => {
    return (
      <Button
        bsStyle="default"
        className="btn-transparent"
        type="reset"
        bsSize="sm"
        onClick={this.props.close}
        disabled={this.state.submittingForm}
      >
        <FormattedMessage id="Common.Cancel" />
      </Button>
    );
  };

  private renderSubmitBtn = () => {
    const {editedCoursebookSectionId} = this.props;
    const {submittingForm} = this.state;
    const className = classNames('submit-section-modal', {submitting: submittingForm});
    return (
      <Button
        bsStyle="primary"
        bsSize="sm"
        type="submit"
        disabled={submittingForm}
        className={className}
      >
        {submittingForm ? (
          <div className="loader-button-positioning-helper">
            <Loader shouldRender={submittingForm} />
          </div>
        ) : null}
        {editedCoursebookSectionId ? (
          <FormattedMessage id="Common.Save" />
        ) : (
          <FormattedMessage id="Common.Create" />
        )}
      </Button>
    );
  };

  private closeModal = () => {
    // don't allow to close modal while form is submitting, bc then we may have irrelevant coursebook section list
    if (this.state.submittingForm) {
      return;
    }
    if (this.getSectionsRequest) {
      this.getSectionsRequest.cancel();
    }
    if (this.submitFormRequest) {
      this.submitFormRequest.cancel();
    }
    this.props.close();
  };

  private requestSections = () => {
    if (this.state.requestSectionsError) {
      this.setState({requestSectionsError: false});
    }
    this.getSectionsRequest = makeCancellable(
      this.props.requestSections(),
      this.handleSectionsLoaded,
      this.handleRequestSectionsError
    );
  };

  private handleSectionsLoaded = (action: AxiosResponseAction<Section[]>) => {
    this.setState({sections: action.payload.data});
  };

  private handleRequestSectionsError = () => {
    this.setState({requestSectionsError: true});
  };

  private handleFormSubmit = (title: string) => {
    const {sections} = this.state;
    const {
      updateCoursebookSectionRequest: update,
      createCoursebookSectionRequest: create,
      coursebookId,
      editedCoursebookSectionId
    } = this.props;
    const sectionWithEnteredTitle = sections!.find(section => section.title === title);
    const data = sectionWithEnteredTitle ? {sectionId: sectionWithEnteredTitle.id} : {title};
    this.setState({submittingForm: true});
    if (editedCoursebookSectionId) {
      this.submitFormRequest = makeCancellable(
        update({coursebookId, coursebookSectionId: editedCoursebookSectionId, requestData: data}),
        this.handleUpdateSectionSuccess,
        this.handleUpdateSectionError
      );
    } else {
      this.submitFormRequest = makeCancellable(
        create({coursebookId, requestData: data}),
        this.handleCreateSectionSuccess,
        this.handleCreateSectionError
      );
    }
  };

  private get editedCoursebookSection() {
    const {editedCoursebookSectionId, coursebookSections} = this.props;
    if (!editedCoursebookSectionId) {
      return undefined;
    }
    return coursebookSections.find(
      coursebookSection => coursebookSection.id === editedCoursebookSectionId
    );
  }

  private handleCreateSectionSuccess = () => {
    const {
      intl: {formatMessage},
      requestCoursebookSections
    } = this.props;
    this.setState({submittingForm: false});
    this.closeModal();
    toastr.success('', formatMessage(sectionsMessages.CreateSectionSuccessToast));
    requestCoursebookSections();
  };

  private handleCreateSectionError = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    this.setState({submittingForm: false});
    toastr.error('', formatMessage(sectionsMessages.CreateSectionErrorToast));
  };

  private handleUpdateSectionSuccess = () => {
    const {
      intl: {formatMessage},
      requestCoursebookSections
    } = this.props;
    this.setState({submittingForm: false});
    this.closeModal();
    toastr.success('', formatMessage(sectionsMessages.UpdateSectionSuccessToast));
    requestCoursebookSections();
  };

  private handleUpdateSectionError = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    this.setState({submittingForm: false});
    toastr.error('', formatMessage(sectionsMessages.UpdateSectionErrorToast));
  };
}

const mapStateToProps = (state: AppState): StateProps => {
  const {
    coursebookSectionModal: {show, editedCoursebookSectionId},
    coursebookSections
  } = state.coursebookPage!.sections;

  return {
    show,
    editedCoursebookSectionId,
    coursebookSections: coursebookSections!
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  close: () => dispatch(closeCoursebookSectionModal()),
  requestSections: () => dispatch(requestSections()),
  createCoursebookSectionRequest: (params: CreateCoursebookSectionParams) =>
    dispatch(createCoursebookSectionRequest(params)),
  updateCoursebookSectionRequest: (params: UpdateCoursebookSectionParams) =>
    dispatch(updateCoursebookSectionRequest(params))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(CoursebookSectionModal));
