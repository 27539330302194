import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import Loader from 'components/Loader';
import ModalError from 'components/ModalError';
import XPreview from 'components/XPreview/XPreview';
import {type XPreviewProps} from 'store/exercise/player/preview/interface';

import './ExerciseViewer.scss';

interface Props extends XPreviewProps {
  viewedExercise?: string;
  error?: boolean;
  close: () => void;
  reloadData: () => void;
}

export default class ExerciseViewer extends React.Component<Props> {
  public render() {
    const {viewedExercise, close} = this.props;
    return (
      <Modal
        show={Boolean(viewedExercise)}
        onHide={close}
        className="coursebook-page-exercise-viewer"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="Coursebook.ExerciseViewerTitle" />
            <a onClick={close}>
              <Icon name="pc-close" tag="i" />
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <Button bsSize="sm" bsStyle="primary" onClick={close}>
            <FormattedMessage id="Common.Close" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private renderBody = () => {
    const {xpreview, error, reloadData, changeRole} = this.props;
    if (error) {
      return (
        <ModalError
          errorTitle={<FormattedMessage id="Coursebook.ExerciseViewerErrorTitle" />}
          errorText={<FormattedMessage id="LibraryError.Description" />}
          reload={reloadData}
        />
      );
    }
    if (xpreview) {
      return <XPreview changeRole={changeRole} xpreview={xpreview} isModal={true} />;
    }
    return <Loader />;
  };
}
