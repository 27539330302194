import React from 'react';
import {FormattedMessage} from 'react-intl';
import {type ConnectDropTarget} from 'react-dnd';
import classNames from 'classnames';
import './EmptySupplementaryListDropTarget.scss';

interface Props {
  connectDropTarget?: ConnectDropTarget;
  canDrop: boolean;
  dropDisallowed?: boolean;
}

export default class EmptySupplementaryList extends React.Component<Props> {
  public render() {
    const {connectDropTarget} = this.props;
    return connectDropTarget ? connectDropTarget(this.renderView()) : this.renderView();
  }

  private renderView = () => {
    const {canDrop, dropDisallowed} = this.props;
    const className = classNames('supplementary-exercise-drop-target', {
      'can-drop': canDrop,
      'drop-disallowed': dropDisallowed
    });
    return (
      <div className={className}>
        {dropDisallowed ? (
          <FormattedMessage id="Coursebook.SuppExerciseDropTargetDisallowed" />
        ) : (
          <FormattedMessage id="Coursebook.SuppExerciseDropTarget" />
        )}
      </div>
    );
  };
}
