import React from 'react';
import {type Dispatch as ReduxDispatch} from 'redux';
import {connect} from 'react-redux';
import {type CompleteCrop} from 'react-image-crop';
import {type AxiosAction} from 'redux-axios-middleware';

import {type EnglexImage} from 'store/interface';
import {
  type AxiosRequestError,
  type AxiosResponseAction,
  type UploadProgressHandler
} from 'services/axios/interface';
import {
  createCrop,
  createThumbnail,
  imageExistRequest,
  postCroppedImage,
  postImage
} from 'components/Chat/components/Body/UploadingImages/action';
import Spinner from 'components/Spinner';
import {getImageSize} from 'helpers/file';

import {type ValidatedImageFile} from './functions';
import PictureUploadingStatus from './PictureUploadingStatus';
import {UploadingPictureStatus, UploadingPictureValidationError} from './interface';

import './ImageCropUploader.scss';

const {CLONING, PREPARING, UPLOADING, UPLOADING_ERROR} = UploadingPictureStatus;

interface OwnProps {
  errorMessage?: string;
  file: ValidatedImageFile;
  handleError: (error: AxiosRequestError) => void;
  handleResponse: (response: AxiosResponseAction<EnglexImage>) => void;
  isErrorStatus: boolean;
  crop: CompleteCrop;
  shouldRetry?: boolean;
  turnOffRetry?: () => void;
  setStatus: (status: UploadingPictureStatus) => void;
  status?: UploadingPictureStatus | UploadingPictureValidationError;
  thumbnail?: number[];
}

interface DispatchedProps {
  imageExistRequest: () => Promise<AxiosResponseAction<EnglexImage>>;
  uploadFile: (
    isOriginal: boolean,
    data: FormData,
    width: number,
    height: number,
    x: number,
    y: number,
    onUploadProgress: UploadProgressHandler
  ) => Promise<AxiosResponseAction<EnglexImage>>;
  createCrop: (
    id: number,
    width: number,
    height: number,
    x: number,
    y: number
  ) => Promise<AxiosResponseAction<EnglexImage>>;
  createThumbnail: (
    id: number,
    width: number,
    height: number
  ) => Promise<AxiosResponseAction<EnglexImage>>;
}

interface State {
  percentsUploaded: number;
}

interface Props extends OwnProps, DispatchedProps {}

class ImageUploader extends React.Component<Props, State> {
  public state: State = {percentsUploaded: 0};

  public componentDidMount() {
    const {status} = this.props;
    if (!(status && status in UploadingPictureValidationError)) {
      this.checkFileExistence();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.shouldRetry && this.props.shouldRetry) {
      if (this.props.turnOffRetry) {
        this.props.turnOffRetry();
        this.setState({percentsUploaded: 0});
      }
      this.checkFileExistence();
    }
  }

  public render() {
    const {errorMessage, isErrorStatus, status} = this.props;
    return (
      <div className="englex-image-crop-uploader">
        <PictureUploadingStatus
          isError={isErrorStatus}
          status={status!}
          serverValidationMessage={errorMessage}
        >
          {this.renderPercentageOrSpinner()}
        </PictureUploadingStatus>
        {this.renderScale()}
      </div>
    );
  }

  private renderScale = () => {
    const {status} = this.props;
    return status === UPLOADING || status === UPLOADING_ERROR ? (
      <div className={`uploading-scale ${status === UPLOADING_ERROR ? 'error' : ''}`}>
        <div style={{width: `${this.state.percentsUploaded}%`}} />
      </div>
    ) : null;
  };

  private renderPercentageOrSpinner = () => {
    const [{status}, {percentsUploaded}] = [this.props, this.state];
    if (status && (status in UploadingPictureValidationError || status === UPLOADING_ERROR)) {
      return null;
    }
    const shouldRenderSpinner =
      status === PREPARING || status === CLONING || percentsUploaded === 100;
    return shouldRenderSpinner ? (
      <Spinner size={15} />
    ) : (
      <span className="percentage">{percentsUploaded}%</span>
    );
  };

  private checkFileExistence = () =>
    this.props.imageExistRequest().then(this.cloneFile).catch(this.handleImageExistRequestFail);

  private handleImageExistRequestFail = (e: AxiosRequestError) => {
    if (e.error.response && e.error.response.status === 404) {
      this.uploadFile();
    } else {
      this.props.handleError(e);
    }
  };

  private cloneFile = async (response: AxiosResponseAction<EnglexImage>) => {
    const {crop, handleError, handleResponse, setStatus, thumbnail} = this.props;
    const {width, height, x, y} = crop;
    setStatus(CLONING);
    if (!response.payload.data.urls) {
      return;
    }
    let result: AxiosResponseAction<EnglexImage> | undefined = undefined;
    try {
      result = await this.props.createCrop(
        response.payload.data.id,
        Math.round(width),
        Math.round(height),
        Math.round(x),
        Math.round(y)
      );
    } catch (e) {
      handleError(e);
    }
    if (!result) {
      return;
    }
    if (thumbnail) {
      this.thumbnailImage(result);
    } else {
      handleResponse(result);
    }
  };

  private uploadFile = async () => {
    const {crop, file, handleError, handleResponse, setStatus, thumbnail, uploadFile} = this.props;
    const {width, height, x, y} = crop;
    setStatus(UPLOADING);

    const formData = new FormData();
    formData.append('file', file.file);

    let response: AxiosResponseAction<EnglexImage> | undefined = undefined;
    try {
      const imageSize = await getImageSize(file.file);
      const isOriginal = imageSize.width === width && imageSize.height === height;

      response = await uploadFile(
        isOriginal,
        formData,
        Math.round(width),
        Math.round(height),
        Math.round(x),
        Math.round(y),
        this.progressHandler
      );
    } catch (e) {
      handleError(e);
    }
    if (!response) {
      return;
    }
    if (thumbnail) {
      this.thumbnailImage(response);
    } else {
      handleResponse(response);
    }
  };

  private thumbnailImage = async ({
    payload: {
      data: {id}
    }
  }: AxiosResponseAction<EnglexImage>) => {
    const {handleError, handleResponse, thumbnail} = this.props;
    if (!thumbnail) {
      return;
    }
    const [width, height] = thumbnail;
    let response: AxiosResponseAction<EnglexImage> | undefined = undefined;
    try {
      response = await this.props.createThumbnail(id, width, height ? height : width);
    } catch (e) {
      handleError(e);
    }
    if (response) {
      handleResponse(response);
    }
  };

  private progressHandler = (e: ProgressEvent) =>
    this.setState({percentsUploaded: e.total ? Math.round((e.loaded * 100) / e.total) : 0});
}

interface Dispatch<R> extends ReduxDispatch {
  (action: AxiosAction): Promise<AxiosResponseAction<R>>;
}

const mapDispatchToProps = (dispatch: Dispatch<EnglexImage>, ownProps: OwnProps) => ({
  uploadFile: (
    isOriginal: boolean,
    data: FormData,
    width: number,
    height: number,
    x: number,
    y: number,
    onUploadProgress: UploadProgressHandler
  ) =>
    isOriginal
      ? dispatch(postImage(data, onUploadProgress))
      : dispatch(postCroppedImage(data, width, height, x, y, onUploadProgress)),
  createCrop: (id: number, width: number, height: number, x: number, y: number) =>
    dispatch(createCrop(id, width, height, x, y)),
  createThumbnail: (id: number, width: number, height: number) =>
    dispatch(createThumbnail(id, width, height)),
  imageExistRequest: () => dispatch(imageExistRequest(ownProps.file.md5))
});

export default connect(null, mapDispatchToProps)(ImageUploader);
