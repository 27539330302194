import {type FC, useCallback, useLayoutEffect} from 'react';
import {type PersistConfig, type RehydrateAction} from 'redux-persist/es/types';
import {useDispatch} from 'react-redux';
import {getStoredState, REHYDRATE} from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import {PERSIST_PREFIX} from 'store/persist';

interface Props {
  persistConfig: PersistConfig<unknown>;
}

export const PersistCrossTab: FC<Props> = ({persistConfig, children}) => {
  const dispatch = useDispatch();
  const onStorageEvent = useCallback(
    async (e: StorageEvent) => {
      const targetKey = PERSIST_PREFIX + persistConfig.key;
      const isHardSet = persistConfig.stateReconciler !== hardSet;

      if (!isHardSet && import.meta.env.MODE === 'development') {
        throw new Error(
          `PersistCrossTab works properly only for persist configs with 'stateReconciler=hardSet'`
        );
      }

      if (e.key !== targetKey || e.oldValue === e.newValue) {
        return;
      }

      const state = await getStoredState(persistConfig);

      const action: RehydrateAction = {
        type: REHYDRATE,
        key: persistConfig.key,
        payload: state
      };
      await dispatch(action);
    },
    [dispatch, persistConfig]
  );

  useLayoutEffect(() => {
    window.addEventListener('storage', onStorageEvent);
    return () => window.removeEventListener('storage', onStorageEvent);
  }, [onStorageEvent]);

  return <>{children}</>;
};
