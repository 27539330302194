import React, {type FC, useCallback, useMemo, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {FormattedMessage, useIntl} from 'react-intl';
import {type DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import {unstable_batchedUpdates} from 'react-dom';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';
import Confirm from 'components/modals/Confirm';
import Spinner from 'components/Spinner';
import {isCursorInTopHalfOfElement} from 'common/Dnd/helpers';
import {selectedSectionPath} from 'common/paths';
import {LibraryListAlignment} from 'components/CoursebookLibrary/LibraryPageList/constants';
import LibraryPageListEl from 'components/CoursebookLibrary/LibraryPageList/LibraryPageListEl';
import LibraryPageListElHandle from 'components/CoursebookLibrary/LibraryPageList/LibraryPageListElHandle';
import {type CoursebookSection} from 'store/interface';
import {DndTypes} from 'components/dnd/interface';
import {useDndEmptyImage} from 'components/dnd/useDndEmptyImage';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';

import {type SectionRouteParams} from '../../../interface';
import {redirectFromUnitPage} from '../../../state/action';
import {deleteCoursebookSectionRequest, openCoursebookSectionModal} from '../actions/action';
import {sectionsMessages} from '../i18n';
import {type CoursebookSectionDragObject} from '../../UnitExerciseList/interface';

interface OwnProps {
  coursebookSection: CoursebookSection;
  getListElement: () => HTMLElement;
  reloadCoursebookSectionsList: () => void;
  beginDrag: () => void;
  endDrag: (draggedCoursebookSectionPosition: number, finalPosition: number) => void;
  position: number;
  moveEntry: (sourcePos: number, targetPos: number) => void;
  isUpdated?: boolean;
  disableControls?: boolean;
}

interface Props extends OwnProps {}

export const CoursebookSectionItem: FC<Props> = ({
  endDrag,
  beginDrag,
  position,
  getListElement,
  isUpdated,
  disableControls,
  reloadCoursebookSectionsList,
  moveEntry,
  coursebookSection: {
    id,
    section: {title}
  }
}) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean | undefined>();
  const [deleteRequested, setDeleteRequested] = useState<boolean>(false);
  const mainEl = useRef<HTMLDivElement | null>(null);
  const getRef = useCallback((el: HTMLDivElement) => (mainEl.current = el), []);
  const intl = useIntl();
  const dispatch = useAxiosDispatch();

  const [{dragItemPosition}, connectDragSource, preview] = useDrag({
    type: DndTypes.COURSEBOOK_SECTION,
    item: () => {
      beginDrag();
      return {
        position,
        elementWidth: mainEl.current ? mainEl.current.clientWidth : LibraryPageListEl.defaultWidth,
        title
      };
    },
    collect: monitor => {
      const item: CoursebookSectionDragObject = monitor.getItem();
      return {
        dragItemPosition: item && item.position
      };
    },
    end: (draggedItem: CoursebookSectionDragObject) => {
      endDrag(id, draggedItem.position);
    }
  });

  const [, connectDropTarget] = useDrop({
    accept: DndTypes.COURSEBOOK_SECTION,
    hover(item: CoursebookSectionDragObject, monitor: DropTargetMonitor) {
      const dragIndex = item.position;
      const hoverIndex = position;
      if (dragIndex === hoverIndex) {
        return;
      }
      const cursorInTopHalfOfEl = isCursorInTopHalfOfElement(mainEl.current, monitor);
      const cursorInBottomHalfOfEl = !cursorInTopHalfOfEl;
      const draggingDownwards = dragIndex < hoverIndex;
      const draggingUpwards = !draggingDownwards;

      if (
        (draggingDownwards && cursorInTopHalfOfEl) ||
        (draggingUpwards && cursorInBottomHalfOfEl)
      ) {
        return;
      }

      item.position = hoverIndex;
      moveEntry(dragIndex, hoverIndex);
      return;
    }
  });

  const {coursebookSectionId, coursebookId, unitId} = useParams<SectionRouteParams>();

  const selectCoursebookSection = useCallback(() => {
    if (Number(coursebookSectionId) === id) return;
    dispatch(redirectFromUnitPage(selectedSectionPath(coursebookId!, Number(unitId), id)));
  }, [coursebookId, coursebookSectionId, dispatch, id, unitId]);

  const editCoursebookSection = useCallback(() => {
    dispatch(openCoursebookSectionModal(id));
  }, [dispatch, id]);

  const onDeleteClick = useCallback(() => {
    setConfirmDelete(true);
  }, []);

  const onDeleteDeclined = useCallback(() => {
    setConfirmDelete(false);
  }, []);

  const handleDeleted = useCallback(() => {
    unstable_batchedUpdates(() => {
      setConfirmDelete(false);
      setDeleteRequested(false);
    });
    toastr.success('', intl.formatMessage(sectionsMessages.DeleteSectionSuccess));
    reloadCoursebookSectionsList();
  }, [intl, reloadCoursebookSectionsList]);

  const handleDeleteFailed = useCallback(() => {
    unstable_batchedUpdates(() => {
      setConfirmDelete(false);
      setDeleteRequested(false);
    });
    toastr.error('', intl.formatMessage(sectionsMessages.DeleteSectionError));
    reloadCoursebookSectionsList();
  }, [intl, reloadCoursebookSectionsList]);

  const onDeleteConfirmed = useCallback(() => {
    setDeleteRequested(true);

    dispatch(deleteCoursebookSectionRequest(coursebookId!, id)).then(
      handleDeleted,
      handleDeleteFailed
    );
  }, [coursebookId, dispatch, handleDeleteFailed, handleDeleted, id]);

  const menuItems = useMemo(() => {
    if (disableControls) {
      return [];
    }
    return [
      <MenuItem key="1" onClick={editCoursebookSection}>
        <Icon name="pencil" />
        <FormattedMessage id="Common.Rename" />
      </MenuItem>,
      <MenuItem key="2" onClick={onDeleteClick}>
        <Icon name="trash" />
        <FormattedMessage id="Common.Delete" />
      </MenuItem>
    ];
  }, [disableControls, editCoursebookSection, onDeleteClick]);

  useDndEmptyImage(preview);

  const dragging = dragItemPosition === null ? false : position === dragItemPosition;
  const isActive = id === Number(coursebookSectionId);

  return (
    <>
      <LibraryPageListEl
        dropdownId={`coursebook-section-actions-${id}`}
        dragging={dragging}
        getContainer={getListElement}
        isActive={isActive}
        processingRequest={false}
        handleClick={selectCoursebookSection}
        title={title}
        dropdownButtons={menuItems}
        alignment={LibraryListAlignment.RIGHT}
        getRef={getRef}
        renderHandle={() =>
          disableControls ? null : <LibraryPageListElHandle connectDragSource={connectDragSource} />
        }
        connectDropTarget={connectDropTarget}
      >
        {isUpdated ? <Spinner size={15} /> : null}
      </LibraryPageListEl>
      <Confirm
        show={confirmDelete}
        headerText={intl.formatMessage(sectionsMessages.DeleteSectionConfirm)}
        onDecline={onDeleteDeclined}
        onAccept={onDeleteConfirmed}
        disableButtons={deleteRequested}
      />
    </>
  );
};
