import {
  type CreatePageWithUnitExerciseOptions,
  type MakeUnitExerciseMainOptions,
  type MakeUnitExerciseSupplementaryOptions,
  type MoveUnitExerciseBetweenPagesOptions,
  type MoveUnitExerciseWithinPageOptions,
  type MoveWithinSupplementaryListOptions
} from '../UnitExerciseList/actions/dragPreviewActions';

interface Options {
  pageIndex: number;
  unitExerciseId: number;
  mainUnitExerciseIndex: number;
  suppUnitExerciseIndex?: number;
  isOnlyElementInPage?: boolean;
}

export class ExerciseDragObjectPosition {
  public pageIndex: number;
  public unitExerciseId: number;
  // if dragged exercise is currently in a position of main exercise, mainUnitExerciseIndex is it's index on the page,
  // if it is in a position of supplementary exercise mainUnitExerciseIndex is it's parent index on the page
  public mainUnitExerciseIndex: number;
  // only for supplementary exercises. position in supplementary exercises list
  public suppUnitExerciseIndex?: number;
  public isOnlyElementInPage?: boolean;

  private readonly initialPageIndex: number;
  private readonly initialMainUnitExerciseIndex: number;
  private readonly initialSuppUnitExerciseIndex?: number;
  private readonly initiallyWasOnlyElementInPage?: boolean;

  public constructor({
    pageIndex,
    unitExerciseId,
    mainUnitExerciseIndex,
    suppUnitExerciseIndex,
    isOnlyElementInPage
  }: Options) {
    this.unitExerciseId = unitExerciseId;

    this.pageIndex = this.initialPageIndex = pageIndex;
    this.mainUnitExerciseIndex = this.initialMainUnitExerciseIndex = mainUnitExerciseIndex;
    this.suppUnitExerciseIndex = this.initialSuppUnitExerciseIndex = suppUnitExerciseIndex;
    this.isOnlyElementInPage = this.initiallyWasOnlyElementInPage = isOnlyElementInPage;
  }

  public positionChanged = () => {
    return (
      this.initialMainUnitExerciseIndex !== this.mainUnitExerciseIndex ||
      this.initialPageIndex !== this.pageIndex ||
      this.initialSuppUnitExerciseIndex !== this.suppUnitExerciseIndex ||
      this.isOnlyElementInPage !== this.initiallyWasOnlyElementInPage
    );
  };

  public get isSuppUnitExercise() {
    return typeof this.suppUnitExerciseIndex === 'number';
  }

  public get initiallyWasSupplementary() {
    return typeof this.initialSuppUnitExerciseIndex === 'number';
  }

  public moveUnitExerciseWithinPage({targetPos}: MoveUnitExerciseWithinPageOptions) {
    this.mainUnitExerciseIndex = targetPos;
  }

  public moveUnitExerciseBetweenPages({
    targetPos,
    targetPageIndex,
    sourcePageIndex
  }: MoveUnitExerciseBetweenPagesOptions) {
    if (sourcePageIndex < targetPageIndex && this.isOnlyElementInPage) {
      // if exercise was dragged from page that was placed before the page it was dragged into,
      // and was only exercise on that page, that means source page will be deleted and target page index
      // decreases by 1
      this.pageIndex = targetPageIndex - 1;
    } else {
      this.pageIndex = targetPageIndex;
    }
    this.mainUnitExerciseIndex = targetPos;
    this.isOnlyElementInPage = false;
  }

  public makeUnitExerciseMain({targetPosition}: MakeUnitExerciseMainOptions) {
    this.suppUnitExerciseIndex = undefined;
    this.mainUnitExerciseIndex = targetPosition;
  }

  public createPageWithUnitExercise({createdPageIndex}: CreatePageWithUnitExerciseOptions) {
    this.pageIndex = createdPageIndex;
    this.mainUnitExerciseIndex = 0;
    this.isOnlyElementInPage = true;
  }

  public moveWithinSupplementaryList({targetPosition}: MoveWithinSupplementaryListOptions) {
    this.suppUnitExerciseIndex = targetPosition;
  }

  public makeUnitExerciseSupplementary({
    targetUnitExercisePosition,
    targetPositionInSupplementaryList
  }: MakeUnitExerciseSupplementaryOptions) {
    this.mainUnitExerciseIndex = targetUnitExercisePosition;
    this.suppUnitExerciseIndex = targetPositionInSupplementaryList;
  }
}
