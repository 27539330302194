import React from 'react';
import {connect} from 'react-redux';

import {type AppState, type UnitPage} from 'store/interface';

import {type UnitPageDragObject} from '../Unit/UnitExerciseList/interface';
import {UnitPageContainer} from '../Unit/UnitExerciseList/containers/UnitPage';

interface OwnProps {
  dragItem: UnitPageDragObject;
  elementWidth: number;
  style: React.CSSProperties;
}

interface StateProps {
  page: UnitPage;
  pageIndex: number;
}

interface Props extends StateProps, OwnProps {}

class PageDragItem extends React.Component<Props> {
  public render() {
    const {style, elementWidth, page, pageIndex} = this.props;
    return (
      <div
        style={{
          ...style,
          width: elementWidth,
          right: elementWidth - 13,
          position: 'relative',
          bottom: 20
        }}
      >
        <UnitPageContainer page={page} pageIndex={pageIndex} isDragItem={true} />
      </div>
    );
  }
}

const mapStateToProps = (state: AppState, {dragItem}: OwnProps): StateProps => {
  return {
    page: state.coursebookPage!.unit!.current.pages.toJS()[dragItem.pageIndex],
    pageIndex: dragItem.pageIndex
  };
};

export default connect(mapStateToProps)(PageDragItem);
