import React from 'react';

import {DragLayer} from 'components/dnd/DragLayer';
import {DndTypes} from 'components/dnd/interface';

import {
  type CoursebookSectionDragObject,
  type UnitExerciseDragObject,
  type UnitItemDragObject,
  type UnitPageDragObject
} from '../Unit/UnitExerciseList/interface';
import MainExerciseDragItem from './UnitExerciseMainDragItem';
import UnitItemDragItem from './UnitItemDragItem';
import PageDragItem from './PageDragItem';
import SupplementaryExerciseDragItem from './UnitExerciseSupplementaryDragItem';
import CoursebookSectionDragElement from './CoursebookSectionDragItem';

const renderCoursebookDragItem = (
  itemType: DndTypes,
  item:
    | UnitPageDragObject
    | UnitExerciseDragObject
    | UnitItemDragObject
    | CoursebookSectionDragObject,
  style: React.CSSProperties
) => {
  switch (itemType) {
    case DndTypes.UNIT_PAGE:
      const unitItem = item as UnitPageDragObject;
      return (
        <PageDragItem elementWidth={unitItem.elementWidth} style={style} dragItem={unitItem} />
      );
    case DndTypes.UNIT_EXERCISE:
      const exerciseItem = item as UnitExerciseDragObject;
      const renderSuppExerciseDragItem = exerciseItem.position.initiallyWasSupplementary;

      if (renderSuppExerciseDragItem) {
        return (
          <SupplementaryExerciseDragItem
            dragItem={exerciseItem}
            style={style}
            elementWidth={exerciseItem.elementWidth}
          />
        );
      } else {
        return (
          <MainExerciseDragItem
            style={style}
            elementWidth={exerciseItem.elementWidth}
            dragItem={exerciseItem}
          />
        );
      }
    case DndTypes.UNIT_ITEM:
      const {elementWidth, isRevision, ordinal, title} = item as UnitItemDragObject;
      return (
        <UnitItemDragItem
          elementWidth={elementWidth}
          isRevision={isRevision}
          ordinal={ordinal}
          style={style}
          title={title}
        />
      );
    case DndTypes.COURSEBOOK_SECTION:
      const {position} = item as CoursebookSectionDragObject;
      return (
        <CoursebookSectionDragElement
          position={position}
          title={(item as CoursebookSectionDragObject).title}
          elementWidth={item.elementWidth}
          style={style}
        />
      );
    default:
      return null;
  }
};

export default class CoursebookPageDragLayer extends React.Component {
  public render() {
    return <DragLayer renderItem={renderCoursebookDragItem} />;
  }
}
