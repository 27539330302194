import React from 'react';
import Collapse from 'react-bootstrap/lib/Collapse';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';

import {SupplementaryExerciseContainer} from '../containers/UnitExerciseSupplementary';
import {EmptySupplementaryListDropTarget} from '../containers/EmptySupplementaryListDropTarget';
import EmptySupplementaryList from './EmptySupplementaryList';
import InsideItemUnitExerciseSupplementary from '../../../DragLayer/InsideItemUnitExerciseSupplementary';
import './SupplementaryUnitExercisePanel.scss';

interface Props {
  parentExerciseId: string;
  supplementaryIds: number[];
  showSupplementaryExercisesPanel: boolean;
  pageIndex: number;
  unitExerciseIndex: number;
  isInDragItem?: boolean;
  savedSupplementaryExerciseId?: string;

  createSupplementaryExercise: () => void;
}

export default class SupplementaryUnitExercisePanel extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Props): boolean {
    const idsArrChanged = this.idsArraysChanged(
      nextProps.supplementaryIds,
      this.props.supplementaryIds
    );

    if (idsArrChanged) {
      return true;
    }

    return !!Object.keys(nextProps)
      .filter(propName => propName !== 'supplementaryIds')
      .find(propName => this.props[propName] !== nextProps[propName]);
  }

  public render() {
    const {showSupplementaryExercisesPanel, createSupplementaryExercise} = this.props;
    return (
      <Collapse in={showSupplementaryExercisesPanel}>
        <div className="supplementary-exercises-panel">
          {this.renderSupplementaryList()}
          <div className="controls">
            <a onClick={createSupplementaryExercise} className="create-supplementary">
              <Icon name="plus-circle" />
              <FormattedMessage id="Coursebook.Exercise.CreateNew" />
            </a>
            <a className="add-supplementary">
              <Icon name="folder-open" />
              <FormattedMessage id="Coursebook.Exercise.AddExisting" />
            </a>
          </div>
        </div>
      </Collapse>
    );
  }

  private renderSupplementaryList = () => {
    const {supplementaryIds, isInDragItem} = this.props;
    if (supplementaryIds.length) {
      return (
        <div className="supplementary-exercises-list">
          {supplementaryIds.map(this.renderSupplementaryExercise)}
        </div>
      );
    }

    if (isInDragItem) {
      return <EmptySupplementaryList canDrop={false} />;
    }

    return (
      <EmptySupplementaryListDropTarget
        unitExerciseIndex={this.props.unitExerciseIndex}
        pageIndex={this.props.pageIndex}
      />
    );
  };

  private idsArraysChanged = (firstArr: number[], secondArr: number[]) => {
    if (firstArr.length !== secondArr.length) {
      return true;
    }
    for (let i = 0; i < firstArr.length; i++) {
      if (firstArr[i] !== secondArr[i]) {
        return true;
      }
    }
    return false;
  };

  private renderSupplementaryExercise = (id: number, index: number) => {
    const {
      isInDragItem,
      showSupplementaryExercisesPanel,
      unitExerciseIndex,
      pageIndex,
      parentExerciseId
    } = this.props;
    if (isInDragItem) {
      return (
        <InsideItemUnitExerciseSupplementary
          pageIndex={pageIndex}
          parentIndex={unitExerciseIndex}
          index={index}
          key={id}
        />
      );
    }
    return (
      <SupplementaryExerciseContainer
        parentExerciseId={parentExerciseId}
        showSupplementaryExercisesPanel={showSupplementaryExercisesPanel}
        unitExerciseId={id}
        key={id}
        parentIndex={unitExerciseIndex}
        pageIndex={pageIndex}
        index={index}
      />
    );
  };
}
