import {type DropTargetMonitor, type XYCoord} from 'react-dnd';

export function isCursorInTopHalfOfElement(element: Element | null, monitor: DropTargetMonitor) {
  // Determine rectangle on screen
  const hoverBoundingRect = element?.getBoundingClientRect();
  if (!hoverBoundingRect) return false;
  // Get vertical middle
  const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  // Determine mouse position
  const clientOffset = monitor.getClientOffset();
  // Get pixels to the top
  const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
  return hoverClientY < hoverMiddleY;
}
